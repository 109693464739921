.footer {
  align-items: flex-start;
  background-color: #ffffff;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  padding: 14px 92px;
  position: relative;
}

.footer .frame-18 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  position: relative;
}

.footer .frame-19 {
  height: 149px;
  position: relative;
  width: 1736px;
}

.footer .frame-20 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 30px;
  left: 0;
  position: absolute;
  top: 0;
}

.footer .frame-21 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  position: relative;
}

.footer .frame-22 {
  height: 23px;
  position: relative;
  width: 124px;
}

.footer .text-wrapper-13 {
  color: var(--text-800);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  left: 0;
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.footer .frame-23 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 14px;
  position: relative;
}

.footer .text-wrapper-14 {
  color: var(--text-900);
  font-family: "ABeeZee", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.32px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.footer .text-wrapper-15 {
  color: var(--text-900);
  font-family: "ABeeZee", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.32px;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.footer .frame-24 {
  height: 77px;
  left: 1627px;
  position: absolute;
  top: 0;
  width: 109px;
}

.footer .frame-25 {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: relative;
  width: 109px;
}

.footer .text-wrapper-16 {
  color: var(--text-800);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  margin-top: -1px;
  position: relative;
  text-align: right;
  width: 109px;
}

.footer .group {
  height: 40px;
  position: relative;
  width: 99px;
}

.footer .twitter-2 {
  background-color: #1c1c1c;
  border-radius: 500px;
  height: 40px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 40px;
}

.footer .x-6 {
  height: 24px !important;
  left: 8px !important;
  position: absolute !important;
  top: 8px !important;
  width: 24px !important;
}

.footer .discord {
  height: 40px;
  left: 59px;
  position: absolute;
  top: 0;
  width: 40px;
}

.footer .element-goober-all-wrapper {
  border-color: var(--text-300);
  border-top-style: solid;
  border-top-width: 2px;
  height: 61px;
  position: relative;
  width: 1736px;
}

.footer .element-goober-all {
  color: var(--text-900);
  font-family: "DM Sans", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 662px;
  letter-spacing: 0.32px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 18px;
}

.footer .text-wrapper-17 {
  letter-spacing: 0.05px;
}

.footer .text-wrapper-18 {
  font-family: "ABeeZee", Helvetica;
  letter-spacing: 0.05px;
}

.footer .text-wrapper-19 {
  font-family: "ABeeZee", Helvetica;
  letter-spacing: 0.05px;
  text-decoration: underline;
}
