.stake {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.stake .overlap-5 {
  height: 1357px;
  left: 0;
  position: absolute;
  top: 0;
  width: 375px;
}

.stake .rectangle-23 {
  background-color: #ffffff;
  height: 1317px;
  left: 0;
  position: absolute;
  top: 0;
  width: 375px;
}

.stake .frame-281 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 32px;
  left: 16px;
  position: absolute;
  top: 125px;
}

.stake .frame-282 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  position: relative;
}

.stake .frame-283 {
  height: 184px;
  position: relative;
  width: 278.58px;
}

.stake .frame-284 {
  height: 82px;
  left: 0;
  position: absolute;
  top: 0;
  width: 263px;
}

.stake .group-88 {
  height: 27px;
  left: 0;
  position: absolute;
  top: 0;
  width: 147px;
}

.stake .frame-285 {
  align-items: center;
  background-color: var(--text-150);
  border-radius: 4px;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  padding: 8px 10px;
  position: relative;
}

.stake .text-wrapper-158 {
  color: var(--primary-500main);
  font-family: var(--mobile-10px-font-family);
  font-size: var(--mobile-10px-font-size);
  font-style: var(--mobile-10px-font-style);
  font-weight: var(--mobile-10px-font-weight);
  letter-spacing: var(--mobile-10px-letter-spacing);
  line-height: var(--mobile-10px-line-height);
  margin-top: -0.52px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.stake .frame-286 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 4px;
  left: 0;
  position: absolute;
  top: 30px;
}

.stake .stake-to-mint-GOO-8 {
  color: transparent;
  font-family: var(--small-title-bold-font-family);
  font-size: var(--small-title-bold-font-size);
  font-style: var(--small-title-bold-font-style);
  font-weight: var(--small-title-bold-font-weight);
  letter-spacing: var(--small-title-bold-letter-spacing);
  line-height: var(--small-title-bold-line-height);
  margin-top: -0.52px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.stake .text-wrapper-159 {
  color: #202020;
  font-family: var(--small-title-bold-font-family);
  font-size: var(--small-title-bold-font-size);
  font-style: var(--small-title-bold-font-style);
  font-weight: var(--small-title-bold-font-weight);
  letter-spacing: var(--small-title-bold-letter-spacing);
  line-height: var(--small-title-bold-line-height);
}

.stake .text-wrapper-160 {
  color: #ffba15;
  font-family: var(--small-title-bold-font-family);
  font-size: var(--small-title-bold-font-size);
  font-style: var(--small-title-bold-font-style);
  font-weight: var(--small-title-bold-font-weight);
  letter-spacing: var(--small-title-bold-letter-spacing);
  line-height: var(--small-title-bold-line-height);
}

.stake .text-wrapper-161 {
  color: var(--text-600);
  font-family: "ABeeZee", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.stake .frame-287 {
  height: 80px;
  left: 0;
  position: absolute;
  top: 104px;
  width: 279px;
}

.stake .frame-288 {
  height: 33px;
  left: 0;
  position: absolute;
  top: 0;
  width: 279px;
}

.stake .frame-289 {
  height: 33px;
  left: 0;
  position: absolute;
  top: 0;
  width: 134px;
}

.stake .text-wrapper-162 {
  color: var(--text-600);
  font-family: var(--mobile-10px-font-family);
  font-size: var(--mobile-10px-font-size);
  font-style: var(--mobile-10px-font-style);
  font-weight: var(--mobile-10px-font-weight);
  left: 0;
  letter-spacing: var(--mobile-10px-letter-spacing);
  line-height: var(--mobile-10px-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.stake .frame-290 {
  align-items: center;
  display: inline-flex;
  gap: 2.14px;
  left: 0;
  position: absolute;
  top: 15px;
}

.stake .text-wrapper-163 {
  color: var(--text-900);
  font-family: var(--notes-bold-font-family);
  font-size: var(--notes-bold-font-size);
  font-style: var(--notes-bold-font-style);
  font-weight: var(--notes-bold-font-weight);
  letter-spacing: var(--notes-bold-letter-spacing);
  line-height: var(--notes-bold-line-height);
  margin-top: -0.53px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.stake .frame-291 {
  height: 33px;
  left: 144px;
  position: absolute;
  top: 0;
  width: 134px;
}

.stake .frame-292 {
  height: 33px;
  left: 0;
  position: absolute;
  top: 47px;
  width: 279px;
}

.stake .instance-node-7 {
  flex: 0 0 auto !important;
}

.stake .frame-293 {
  height: 700px;
  position: relative;
  width: 343px;
}

.stake .group-89 {
  height: 19px;
  left: 0;
  position: absolute;
  top: 0;
  width: 343px;
}

.stake .vector-6 {
  height: 1px;
  left: 0;
  position: absolute;
  top: 19px;
  width: 343px;
}

.stake .frame-294 {
  height: 17px;
  left: 0;
  position: absolute;
  top: 0;
  width: 159px;
}

.stake .frame-295 {
  align-items: center;
  display: inline-flex;
  gap: 3.96px;
  left: 0;
  position: absolute;
  top: 0;
}

.stake .iconify-5 {
  height: 16px !important;
  position: relative !important;
  width: 16px !important;
}

.stake .text-wrapper-164 {
  color: var(--text-900);
  font-family: var(--notes-bold-font-family);
  font-size: var(--notes-bold-font-size);
  font-style: var(--notes-bold-font-style);
  font-weight: var(--notes-bold-font-weight);
  letter-spacing: var(--notes-bold-letter-spacing);
  line-height: var(--notes-bold-line-height);
  margin-top: -0.4px;
  position: relative;
  width: 37px;
}

.stake .vector-7 {
  height: 1px;
  left: 0;
  position: absolute;
  top: 19px;
  width: 168px;
}

.stake .frame-296 {
  height: 17px;
  left: 176px;
  position: absolute;
  top: 0;
  width: 159px;
}

.stake .text-wrapper-165 {
  color: var(--text-500);
  font-family: var(--notes-bold-font-family);
  font-size: var(--notes-bold-font-size);
  font-style: var(--notes-bold-font-style);
  font-weight: var(--notes-bold-font-weight);
  letter-spacing: var(--notes-bold-letter-spacing);
  line-height: var(--notes-bold-line-height);
  margin-top: -0.4px;
  position: relative;
  width: 37px;
}

.stake .vector-8 {
  height: 1px;
  left: 0;
  position: absolute;
  top: 19px;
  width: 169px;
}

.stake .instance-node-8 {
  background-color: var(--primary-500main) !important;
}

.stake .mobile-tiers-3 {
  left: 0 !important;
  position: absolute !important;
  top: 39px !important;
}

.stake .mobile-tiers-4 {
  margin-left: -5px !important;
  position: relative !important;
}

.stake .mobile-tiers-5 {
  color: var(--white) !important;
}

.stake .instance-node-9 {
  height: 14px !important;
  left: 5px !important;
  position: absolute !important;
  top: 5px !important;
  width: 14px !important;
}

.stake .mobile-tiers-6 {
  left: 0 !important;
  position: absolute !important;
  top: 266px !important;
}

.stake .instance-node-10 {
  margin-left: -5px !important;
}

.stake .mobile-tiers-7 {
  align-items: flex-start;
  background-color: #fafafa;
  border-radius: 10px;
  display: inline-flex;
  flex-direction: column;
  gap: 16px;
  left: 0;
  padding: 14px;
  position: absolute;
  top: 493px;
}

.stake .frame-297 {
  height: 123px;
  position: relative;
  width: 315px;
}

.stake .frame-298 {
  height: 50px;
  left: 0;
  position: absolute;
  top: 0;
  width: 315px;
}

.stake .frame-299 {
  height: 30px;
  left: 0;
  position: absolute;
  top: 0;
  width: 55px;
}

.stake .frame-300 {
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.stake .icons-40 {
  height: 30px !important;
  position: relative !important;
  width: 30px !important;
}

.stake .stake-NUTS-for-free-7 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  left: 0;
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  position: absolute;
  top: 31px;
  width: 315px;
}

.stake .frame-301 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 13px;
  left: 0;
  position: absolute;
  top: 56px;
}

.stake .frame-302 {
  height: 24px;
  position: relative;
  width: 315px;
}

.stake .text-wrapper-166 {
  color: #000000;
  font-family: "ABeeZee", Helvetica;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  left: 269px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: -1px;
  width: 46px;
}

.stake .group-90 {
  background-color: #ffffff;
  border-radius: 25px;
  height: 10px;
  left: 0;
  position: absolute;
  top: 14px;
  width: 315px;
}

.stake .rectangle-24 {
  background-color: var(--primary-500main);
  border-radius: 25px;
  height: 10px;
  width: 10px;
}

.stake .frame-303 {
  height: 30px;
  position: relative;
  width: 315px;
}

.stake .frame-304 {
  align-items: flex-start;
  display: inline-flex;
  gap: 20px;
  position: relative;
  top: 1px;
}

.stake .frame-305 {
  height: 27px;
  position: relative;
  width: 80px;
}

.stake .icons-24 {
  border: 0.48px solid !important;
  border-radius: 12px !important;
  height: 24px !important;
  left: 0 !important;
  position: absolute !important;
  top: 2px !important;
  width: 24px !important;
}

.stake .frame-306 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 2px;
  left: 27px;
  position: absolute;
  top: 0;
}

.stake .text-wrapper-167 {
  color: var(--text-600);
  font-family: var(--mobile-10px-font-family);
  font-size: var(--mobile-10px-font-size);
  font-style: var(--mobile-10px-font-style);
  font-weight: var(--mobile-10px-font-weight);
  letter-spacing: var(--mobile-10px-letter-spacing);
  line-height: var(--mobile-10px-line-height);
  margin-top: -0.55px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.stake .frame-307 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 2.59px;
  position: relative;
}

.stake .text-wrapper-168 {
  color: var(--text-900);
  font-family: "ABeeZee", Helvetica;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.65px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.stake .frame-308 {
  height: 27px;
  position: relative;
  width: 103px;
}

.stake .icons-27 {
  height: 24px !important;
  left: 0 !important;
  position: absolute !important;
  top: 2px !important;
  width: 24px !important;
}

.stake .frame-309 {
  height: 27px;
  position: relative;
  width: 51px;
}

.stake .wallet-19 {
  color: var(--white) !important;
  white-space: unset !important;
}

.stake .footer-8 {
  left: 0 !important;
  position: absolute !important;
  top: 1151px !important;
}

.stake .frame-310 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  left: 0;
  position: fixed;
  top: 0;
}

.stake .status-bar-5 {
  background-color: #ffffff !important;
  width: 375px !important;
}

.stake .status-bar-6 {
  color: var(--themes-black-100) !important;
}

.stake .nav-10 {
  background-color: #ffffff;
  height: 54px;
  position: relative;
  width: 375px;
}

.stake .logo-9 {
  align-items: center;
  display: inline-flex;
  gap: 5.44px;
  left: 15px;
  position: absolute;
  top: 10px;
}

.stake .group-91 {
  height: 33.98px;
  position: relative;
  width: 33.98px;
}

.stake .frame-311 {
  background-color: #ffffff;
  border-radius: 16.86px;
  height: 34px;
  width: 34px;
}

.stake .group-92 {
  background-image: url(../../../static/img/vector-15.svg);
  background-size: 100% 100%;
  height: 20px;
  left: 7px;
  position: relative;
  top: 7px;
  width: 20px;
}

.stake .group-93 {
  background-color: #ffffff;
  border-radius: 2.47px;
  height: 5px;
  left: 6px;
  position: absolute;
  top: 7px;
  width: 5px;
}

.stake .ellipse-41 {
  background-color: #000000;
  border-radius: 1.81px;
  height: 4px;
  left: 1px;
  position: relative;
  top: 1px;
  width: 4px;
}

.stake .group-94 {
  background-color: #ffffff;
  border-radius: 2.47px;
  height: 5px;
  left: 12px;
  position: absolute;
  top: 2px;
  width: 5px;
}

.stake .group-95 {
  height: 22.34px;
  position: relative;
  width: 72.59px;
}

.stake .group-96 {
  height: 18px;
  left: 0;
  position: absolute;
  top: 4px;
  width: 12px;
}

.stake .group-97 {
  border: 2.49px solid;
  border-color: #000000;
  border-radius: 6px;
  height: 12px;
  left: 13px;
  position: absolute;
  top: 4px;
  width: 12px;
}

.stake .ellipse-42 {
  background-color: #000000;
  border-radius: 2.35px;
  height: 5px;
  left: 2px;
  position: relative;
  top: 3px;
  width: 5px;
}

.stake .group-98 {
  border: 2.49px solid;
  border-color: #000000;
  border-radius: 6px;
  height: 12px;
  left: 26px;
  position: absolute;
  top: 4px;
  width: 12px;
}

.stake .ellipse-43 {
  background-color: #000000;
  border-radius: 2.35px;
  height: 5px;
  left: 4px;
  position: relative;
  top: 3px;
  width: 5px;
}

.stake .group-99 {
  height: 16px;
  left: 39px;
  position: absolute;
  top: 0;
  width: 12px;
}

.stake .overlap-group-11 {
  height: 16px;
  position: relative;
}

.stake .ellipse-44 {
  border: 2.49px solid;
  border-color: #000000;
  border-radius: 6px;
  height: 12px;
  left: 0;
  position: absolute;
  top: 4px;
  width: 12px;
}

.stake .rectangle-25 {
  background-color: #000000;
  border-radius: 0px 0px 1.24px 1.24px;
  height: 12px;
  left: 0;
  position: absolute;
  top: 0;
  width: 2px;
}

.stake .subtract-9 {
  height: 12px;
  left: 52px;
  position: absolute;
  top: 4px;
  width: 12px;
}

.stake .frame-312 {
  align-items: flex-start;
  display: flex;
  height: 12px;
  left: 65px;
  position: absolute;
  top: 4px;
  width: 7px;
}

.stake .rectangle-26 {
  background-color: #000000;
  height: 11.99px;
  position: relative;
  transform: rotate(180deg);
  width: 2.47px;
}

.stake .ellipse-45 {
  background-color: #000000;
  border-radius: 2.35px;
  height: 4.7px;
  position: relative;
  width: 4.7px;
}

.stake .menu-3 {
  height: 24px;
  left: 335px;
  position: absolute;
  top: 15px;
  width: 24px;
}

.stake .overlap-6 {
  background-color: #ffffff;
  height: 2083px;
  position: relative;
}

.stake .frame-313 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 80px;
  left: 92px;
  position: absolute;
  top: 150px;
}

.stake .frame-314 {
  height: 319px;
  position: relative;
  width: 1733px;
}

.stake .frame-315 {
  height: 187px;
  left: 0;
  position: absolute;
  top: 0;
  width: 557px;
}

.stake .group-100 {
  height: 50px;
  left: 0;
  position: absolute;
  top: 0;
  width: 238px;
}

.stake .frame-316 {
  align-items: center;
  background-color: var(--text-150);
  border-radius: 10px;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  padding: 16px 14px;
  position: relative;
}

.stake .text-wrapper-169 {
  color: var(--primary-500main);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.stake .frame-317 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 14px;
  left: 0;
  position: absolute;
  top: 64px;
}

.stake .stake-to-mint-GOO-9 {
  color: transparent;
  font-family: var(--h3-bold-font-family);
  font-size: var(--h3-bold-font-size);
  font-style: var(--h3-bold-font-style);
  font-weight: var(--h3-bold-font-weight);
  letter-spacing: var(--h3-bold-letter-spacing);
  line-height: var(--h3-bold-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.stake .text-wrapper-170 {
  color: #202020;
  font-family: var(--h3-bold-font-family);
  font-size: var(--h3-bold-font-size);
  font-style: var(--h3-bold-font-style);
  font-weight: var(--h3-bold-font-weight);
  letter-spacing: var(--h3-bold-letter-spacing);
  line-height: var(--h3-bold-line-height);
}

.stake .text-wrapper-171 {
  color: #ffba15;
  font-family: var(--h3-bold-font-family);
  font-size: var(--h3-bold-font-size);
  font-style: var(--h3-bold-font-style);
  font-weight: var(--h3-bold-font-weight);
  letter-spacing: var(--h3-bold-letter-spacing);
  line-height: var(--h3-bold-line-height);
}

.stake .text-wrapper-172 {
  color: var(--text-600);
  font-family: "ABeeZee", Helvetica;
  font-size: 28px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.stake .frame-318 {
  height: 72px;
  left: 0;
  position: absolute;
  top: 247px;
  width: 1733px;
}

.stake .frame-319 {
  height: 72px;
  left: 0;
  position: absolute;
  top: 0;
  width: 293px;
}

.stake .text-wrapper-173 {
  color: var(--text-600);
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  left: 0;
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.stake .frame-320 {
  align-items: center;
  display: inline-flex;
  gap: 4.66px;
  left: 0;
  position: absolute;
  top: 37px;
}

.stake .text-wrapper-174 {
  color: var(--text-900);
  font-family: var(--small-title-bold-font-family);
  font-size: var(--small-title-bold-font-size);
  font-style: var(--small-title-bold-font-style);
  font-weight: var(--small-title-bold-font-weight);
  letter-spacing: var(--small-title-bold-letter-spacing);
  line-height: var(--small-title-bold-line-height);
  margin-top: -1.17px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.stake .frame-321 {
  height: 72px;
  left: 480px;
  position: absolute;
  top: 0;
  width: 293px;
}

.stake .frame-322 {
  height: 72px;
  left: 960px;
  position: absolute;
  top: 0;
  width: 293px;
}

.stake .frame-323 {
  height: 72px;
  left: 1440px;
  position: absolute;
  top: 0;
  width: 293px;
}

.stake .user-profile-4 {
  align-items: flex-start;
  background-color: #fafafa;
  border-radius: 20px;
  display: inline-flex;
  gap: 180px;
  justify-content: flex-end;
  left: 799px;
  overflow: hidden;
  padding: 50px 84px;
  position: absolute;
  top: 7px;
}

.stake .nuts-4 {
  height: 72px;
  position: relative;
  width: 302px;
}

.stake .icons-26 {
  border: 1.36px solid !important;
  border-radius: 34px !important;
  height: 68px !important;
  left: 0 !important;
  position: absolute !important;
  top: 2px !important;
  width: 68px !important;
}

.stake .icons-28 {
  height: 52px !important;
  left: 7px !important;
  top: 7px !important;
  width: 52px !important;
}

.stake .element-7 {
  height: 72px;
  left: 78px;
  position: absolute;
  top: 0;
  width: 224px;
}

.stake .sol-5 {
  height: 72px;
  position: relative;
  width: 284px;
}

.stake .icons-29 {
  height: 36px !important;
  left: 14px !important;
  top: 14px !important;
  width: 36px !important;
}

.stake .element-8 {
  height: 72px;
  left: 78px;
  position: absolute;
  top: 0;
  width: 206px;
}

.stake .frame-324 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  position: relative;
}

.stake .tabs-10 {
  position: relative !important;
}

.stake .tiers-8 {
  align-items: flex-start;
  background-color: #fafafa;
  border-radius: 20px;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 70px;
  padding: 50px 44px;
  position: relative;
}

.stake .frame-325 {
  height: 94px;
  position: relative;
  width: 1644px;
}

.stake .frame-326 {
  height: 60px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1644px;
}

.stake .frame-327 {
  align-items: center;
  display: inline-flex;
  gap: 10px;
  left: 0;
  position: absolute;
  top: 0;
}

.stake .frame-328 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.stake .icons-31 {
  height: 60px !important;
  position: relative !important;
  width: 60px !important;
}

.stake .icons-32 {
  margin-left: -13px !important;
}

.stake .div-12 {
  color: transparent;
  font-family: "ABeeZee", Helvetica;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.stake .text-wrapper-175 {
  color: #202020;
  font-style: italic;
}

.stake .text-wrapper-176 {
  color: #ffba15;
  font-style: italic;
}

.stake .text-wrapper-177 {
  color: #000000;
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  left: 1598px;
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  position: absolute;
  text-align: right;
  top: 18px;
  width: 46px;
}

.stake .view-4 {
  left: 0 !important;
  position: absolute !important;
  top: 100px !important;
}

.stake .frame-329 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 1644px;
}

.stake .frame-330 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 78px;
  position: relative;
}

.stake .frame-331 {
  align-items: center;
  display: flex;
  gap: 10px;
  position: relative;
  width: 232px;
}

.stake .instance-node-11 {
  height: 34px !important;
  left: 12px !important;
  position: absolute !important;
  top: 12px !important;
  width: 34px !important;
}

.stake .frame-332 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6px;
  position: relative;
}

.stake .text-wrapper-178 {
  color: var(--text-600);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.stake .frame-333 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4.66px;
  position: relative;
}

.stake .text-wrapper-179 {
  color: var(--text-900);
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  margin-top: -1.17px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.stake .wallet-20 {
  flex: 0 0 auto !important;
  padding: 16px !important;
}

.stake .wallet-21 {
  margin-top: -1px !important;
}

.stake .frame-334 {
  align-items: center;
  display: flex;
  justify-content: space-between;
  left: 0;
  position: absolute;
  top: 0;
  width: 1644px;
}

.stake .frame-335 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
}

.stake .text-wrapper-180 {
  color: #000000;
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  position: relative;
  text-align: right;
  width: 46px;
}

.stake .stake-NUTS-for-free-8 {
  color: var(--text-900);
  font-family: "ABeeZee", Helvetica;
  font-size: 24px;
  font-style: italic;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.stake .footer-9 {
  left: 0 !important;
  position: absolute !important;
  top: 1789px !important;
}

.stake .footer-10 {
  left: 656px !important;
}

.stake .nav-11 {
  height: 100px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1920px;
}

.stake .nav-12 {
  background-image: url(../../../static/img/image.svg);
  background-size: 100% 100%;
  height: 100px;
}

.stake .group-101 {
  height: 51px;
  left: 92px;
  position: relative;
  top: 24px;
  width: 1736px;
}

.stake .logo-10 {
  align-items: center;
  display: inline-flex;
  gap: 8px;
  left: 0;
  position: absolute;
  top: 0;
}

.stake .group-102 {
  height: 50px;
  position: relative;
  width: 50px;
}

.stake .frame-336 {
  background-color: #ffffff;
  border-radius: 24.82px;
  height: 50px;
}

.stake .group-103 {
  background-image: url(../../../static/img/vector.svg);
  background-size: 100% 100%;
  height: 29px;
  left: 10px;
  position: relative;
  top: 10px;
  width: 29px;
}

.stake .group-104 {
  background-color: #ffffff;
  border-radius: 3.63px;
  height: 7px;
  left: 9px;
  position: absolute;
  top: 10px;
  width: 7px;
}

.stake .ellipse-46 {
  background-color: #000000;
  border-radius: 2.66px;
  height: 5px;
  left: 1px;
  position: relative;
  top: 1px;
  width: 5px;
}

.stake .group-105 {
  background-color: #ffffff;
  border-radius: 3.63px;
  height: 7px;
  left: 17px;
  position: absolute;
  top: 3px;
  width: 7px;
}

.stake .group-106 {
  height: 32.88px;
  position: relative;
  width: 106.81px;
}

.stake .group-107 {
  height: 27px;
  left: 0;
  position: absolute;
  top: 6px;
  width: 18px;
}

.stake .group-108 {
  border: 3.66px solid;
  border-color: #000000;
  border-radius: 8.82px;
  height: 18px;
  left: 19px;
  position: absolute;
  top: 6px;
  width: 18px;
}

.stake .ellipse-47 {
  background-color: #000000;
  border-radius: 3.46px;
  height: 7px;
  left: 3px;
  position: relative;
  top: 5px;
  width: 7px;
}

.stake .group-109 {
  border: 3.66px solid;
  border-color: #000000;
  border-radius: 8.82px;
  height: 18px;
  left: 39px;
  position: absolute;
  top: 6px;
  width: 18px;
}

.stake .ellipse-48 {
  background-color: #000000;
  border-radius: 3.46px;
  height: 7px;
  left: 5px;
  position: relative;
  top: 5px;
  width: 7px;
}

.stake .group-110 {
  height: 24px;
  left: 58px;
  position: absolute;
  top: 0;
  width: 18px;
}

.stake .overlap-group-12 {
  height: 24px;
  position: relative;
}

.stake .ellipse-49 {
  border: 3.66px solid;
  border-color: #000000;
  border-radius: 8.82px;
  height: 18px;
  left: 0;
  position: absolute;
  top: 6px;
  width: 18px;
}

.stake .rectangle-27 {
  background-color: #000000;
  border-radius: 0px 0px 1.82px 1.82px;
  height: 17px;
  left: 0;
  position: absolute;
  top: 0;
  width: 4px;
}

.stake .subtract-10 {
  height: 18px;
  left: 77px;
  position: absolute;
  top: 6px;
  width: 18px;
}

.stake .frame-337 {
  align-items: flex-start;
  display: flex;
  height: 18px;
  left: 96px;
  position: absolute;
  top: 6px;
  width: 11px;
}

.stake .rectangle-28 {
  background-color: #000000;
  height: 17.65px;
  position: relative;
  transform: rotate(180deg);
  width: 3.64px;
}

.stake .ellipse-50 {
  background-color: #000000;
  border-radius: 3.46px;
  height: 6.91px;
  position: relative;
  width: 6.91px;
}

.stake .frame-338 {
  height: 18px;
  left: 657px;
  position: absolute;
  top: 16px;
  width: 429px;
}

.stake .text-wrapper-181 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  left: 0;
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.stake .text-wrapper-182 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  left: 103px;
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.stake .text-wrapper-183 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  left: 209px;
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.stake .text-wrapper-184 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  left: 341px;
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.stake .frame-339 {
  align-items: center;
  display: inline-flex;
  gap: 20px;
  left: 1414px;
  position: absolute;
  top: 1px;
}

.stake .frame-340 {
  height: 40px;
  position: relative;
  width: 114px;
}

.stake .twitter-8 {
  background-color: #1c1c1c;
  border-radius: 500px;
  height: 40px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 40px;
}

.stake .x-4 {
  height: 24px !important;
  left: 8px !important;
  position: absolute !important;
  top: 8px !important;
  width: 24px !important;
}

.stake .discord-10 {
  height: 40px;
  left: 64px;
  position: absolute;
  top: 0;
  width: 40px;
}
