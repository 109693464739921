.wallet-wrapper {
  align-items: center;
  background-color: var(--primary-100);
  border-radius: 10px;
  display: flex;
  gap: 10px;
  height: 44px;
  justify-content: center;
  padding: 12px 62px;
  position: relative;
  width: 315px;
}

.wallet-wrapper .frame-26 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6px;
  justify-content: center;
  position: relative;
}

.wallet-wrapper .iconify-8 {
  height: 20px !important;
  position: relative !important;
  width: 20px !important;
}

.wallet-wrapper .connect-wallet-to {
  color: var(--primary-500main);
  font-family: var(--notes-bold-font-family);
  font-size: var(--notes-bold-font-size);
  font-style: var(--notes-bold-font-style);
  font-weight: var(--notes-bold-font-weight);
  letter-spacing: var(--notes-bold-letter-spacing);
  line-height: var(--notes-bold-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}
