.stake-tiers {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.stake-tiers .overlap-wrapper {
  height: 2067px;
  overflow: hidden;
  width: 1920px;
}

.stake-tiers .overlap-7 {
  background-color: #ffffff;
  height: 2083px;
  position: relative;
}

.stake-tiers .frame-341 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 80px;
  left: 92px;
  position: absolute;
  top: 150px;
}

.stake-tiers .frame-342 {
  height: 319px;
  position: relative;
  width: 1733px;
}

.stake-tiers .frame-343 {
  height: 187px;
  left: 0;
  position: absolute;
  top: 0;
  width: 557px;
}

.stake-tiers .group-111 {
  height: 50px;
  left: 0;
  position: absolute;
  top: 0;
  width: 238px;
}

.stake-tiers .frame-344 {
  align-items: center;
  background-color: var(--text-150);
  border-radius: 10px;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  padding: 16px 14px;
  position: relative;
}

.stake-tiers .text-wrapper-185 {
  color: var(--primary-500main);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.stake-tiers .frame-345 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 14px;
  left: 0;
  position: absolute;
  top: 64px;
}

.stake-tiers .stake-to-mint-GOO-10 {
  color: transparent;
  font-family: var(--h3-bold-font-family);
  font-size: var(--h3-bold-font-size);
  font-style: var(--h3-bold-font-style);
  font-weight: var(--h3-bold-font-weight);
  letter-spacing: var(--h3-bold-letter-spacing);
  line-height: var(--h3-bold-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.stake-tiers .text-wrapper-186 {
  color: #202020;
  font-family: var(--h3-bold-font-family);
  font-size: var(--h3-bold-font-size);
  font-style: var(--h3-bold-font-style);
  font-weight: var(--h3-bold-font-weight);
  letter-spacing: var(--h3-bold-letter-spacing);
  line-height: var(--h3-bold-line-height);
}

.stake-tiers .text-wrapper-187 {
  color: #ffba15;
  font-family: var(--h3-bold-font-family);
  font-size: var(--h3-bold-font-size);
  font-style: var(--h3-bold-font-style);
  font-weight: var(--h3-bold-font-weight);
  letter-spacing: var(--h3-bold-letter-spacing);
  line-height: var(--h3-bold-line-height);
}

.stake-tiers .text-wrapper-188 {
  color: var(--text-600);
  font-family: "ABeeZee", Helvetica;
  font-size: 28px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.stake-tiers .frame-346 {
  height: 72px;
  left: 0;
  position: absolute;
  top: 247px;
  width: 1733px;
}

.stake-tiers .frame-347 {
  height: 72px;
  left: 0;
  position: absolute;
  top: 0;
  width: 293px;
}

.stake-tiers .text-wrapper-189 {
  color: var(--text-600);
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  left: 0;
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.stake-tiers .frame-348 {
  align-items: center;
  display: inline-flex;
  gap: 4.66px;
  left: 0;
  position: absolute;
  top: 37px;
}

.stake-tiers .text-wrapper-190 {
  color: var(--text-900);
  font-family: var(--small-title-bold-font-family);
  font-size: var(--small-title-bold-font-size);
  font-style: var(--small-title-bold-font-style);
  font-weight: var(--small-title-bold-font-weight);
  letter-spacing: var(--small-title-bold-letter-spacing);
  line-height: var(--small-title-bold-line-height);
  margin-top: -1.17px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.stake-tiers .frame-349 {
  height: 72px;
  left: 480px;
  position: absolute;
  top: 0;
  width: 293px;
}

.stake-tiers .frame-350 {
  height: 72px;
  left: 960px;
  position: absolute;
  top: 0;
  width: 293px;
}

.stake-tiers .frame-351 {
  height: 72px;
  left: 1440px;
  position: absolute;
  top: 0;
  width: 293px;
}

.stake-tiers .user-profile-5 {
  align-items: flex-start;
  background-color: #fafafa;
  border-radius: 20px;
  display: inline-flex;
  gap: 180px;
  justify-content: flex-end;
  left: 799px;
  overflow: hidden;
  padding: 50px 84px;
  position: absolute;
  top: 7px;
}

.stake-tiers .nuts-5 {
  height: 72px;
  position: relative;
  width: 302px;
}

.stake-tiers .icons-33 {
  border: 1.36px solid !important;
  border-radius: 34px !important;
  height: 68px !important;
  left: 0 !important;
  position: absolute !important;
  top: 2px !important;
  width: 68px !important;
}

.stake-tiers .icons-34 {
  height: 52px !important;
  left: 7px !important;
  top: 7px !important;
  width: 52px !important;
}

.stake-tiers .element-9 {
  height: 72px;
  left: 78px;
  position: absolute;
  top: 0;
  width: 224px;
}

.stake-tiers .sol-6 {
  height: 72px;
  position: relative;
  width: 284px;
}

.stake-tiers .icons-35 {
  height: 36px !important;
  left: 14px !important;
  top: 14px !important;
  width: 36px !important;
}

.stake-tiers .element-10 {
  height: 72px;
  left: 78px;
  position: absolute;
  top: 0;
  width: 206px;
}

.stake-tiers .frame-352 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  position: relative;
}

.stake-tiers .tabs-11 {
  position: relative !important;
}

.stake-tiers .tiers-9 {
  align-items: flex-start;
  background-color: #fafafa;
  border-radius: 20px;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 70px;
  padding: 50px 44px;
  position: relative;
}

.stake-tiers .frame-353 {
  height: 94px;
  position: relative;
  width: 1644px;
}

.stake-tiers .frame-354 {
  height: 60px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1644px;
}

.stake-tiers .frame-355 {
  align-items: center;
  display: inline-flex;
  gap: 10px;
  left: 0;
  position: absolute;
  top: 0;
}

.stake-tiers .frame-356 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.stake-tiers .icons-37 {
  height: 60px !important;
  position: relative !important;
  width: 60px !important;
}

.stake-tiers .icons-38 {
  margin-left: -13px !important;
}

.stake-tiers .div-13 {
  color: transparent;
  font-family: "ABeeZee", Helvetica;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.stake-tiers .text-wrapper-191 {
  color: #202020;
  font-style: italic;
}

.stake-tiers .text-wrapper-192 {
  color: #ffba15;
  font-style: italic;
}

.stake-tiers .text-wrapper-193 {
  color: #000000;
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  left: 1598px;
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  position: absolute;
  text-align: right;
  top: 18px;
  width: 46px;
}

.stake-tiers .view-5 {
  left: 0 !important;
  position: absolute !important;
  top: 100px !important;
}

.stake-tiers .frame-357 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 1644px;
}

.stake-tiers .frame-358 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 78px;
  position: relative;
}

.stake-tiers .frame-359 {
  align-items: center;
  display: flex;
  gap: 10px;
  position: relative;
  width: 232px;
}

.stake-tiers .iconify-92 {
  height: 34px !important;
  left: 12px !important;
  position: absolute !important;
  top: 12px !important;
  width: 34px !important;
}

.stake-tiers .frame-360 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6px;
  position: relative;
}

.stake-tiers .text-wrapper-194 {
  color: var(--text-600);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.stake-tiers .frame-361 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4.66px;
  position: relative;
}

.stake-tiers .text-wrapper-195 {
  color: var(--text-900);
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  margin-top: -1.17px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.stake-tiers .wallet-22 {
  background-color: #ffffff !important;
  flex: 0 0 auto !important;
  padding: 16px !important;
}

.stake-tiers .wallet-23 {
  color: var(--text-500) !important;
  margin-top: -1px !important;
}

.stake-tiers .frame-362 {
  align-items: center;
  display: flex;
  justify-content: space-between;
  left: 0;
  position: absolute;
  top: 0;
  width: 1644px;
}

.stake-tiers .frame-363 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
}

.stake-tiers .text-wrapper-196 {
  color: #000000;
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  position: relative;
  text-align: right;
  width: 46px;
}

.stake-tiers .stake-NUTS-for-free-9 {
  color: var(--text-900);
  font-family: "Aeonik TRIAL-Bold", Helvetica;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.stake-tiers .wallet-24 {
  flex: 0 0 auto !important;
  padding: 16px !important;
}

.stake-tiers .wallet-25 {
  margin-top: -1px !important;
}

.stake-tiers .footer-11 {
  left: 0 !important;
  position: absolute !important;
  top: 1789px !important;
}

.stake-tiers .footer-12 {
  left: 656px !important;
}

.stake-tiers .nav-13 {
  height: 100px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1920px;
}

.stake-tiers .nav-14 {
  background-image: url(../../../static/img/rectangle-2-10.svg);
  background-size: 100% 100%;
  height: 100px;
}

.stake-tiers .group-112 {
  height: 51px;
  left: 92px;
  position: relative;
  top: 24px;
  width: 1736px;
}

.stake-tiers .logo-11 {
  align-items: center;
  display: inline-flex;
  gap: 8px;
  left: 0;
  position: absolute;
  top: 0;
}

.stake-tiers .group-113 {
  height: 50px;
  position: relative;
  width: 50px;
}

.stake-tiers .frame-364 {
  background-color: #ffffff;
  border-radius: 24.82px;
  height: 50px;
}

.stake-tiers .group-114 {
  background-image: url(../../../static/img/vector.svg);
  background-size: 100% 100%;
  height: 29px;
  left: 10px;
  position: relative;
  top: 10px;
  width: 29px;
}

.stake-tiers .group-115 {
  background-color: #ffffff;
  border-radius: 3.63px;
  height: 7px;
  left: 9px;
  position: absolute;
  top: 10px;
  width: 7px;
}

.stake-tiers .ellipse-51 {
  background-color: #000000;
  border-radius: 2.66px;
  height: 5px;
  left: 1px;
  position: relative;
  top: 1px;
  width: 5px;
}

.stake-tiers .group-116 {
  background-color: #ffffff;
  border-radius: 3.63px;
  height: 7px;
  left: 17px;
  position: absolute;
  top: 3px;
  width: 7px;
}

.stake-tiers .group-117 {
  height: 32.88px;
  position: relative;
  width: 106.81px;
}

.stake-tiers .group-118 {
  height: 27px;
  left: 0;
  position: absolute;
  top: 6px;
  width: 18px;
}

.stake-tiers .group-119 {
  border: 3.66px solid;
  border-color: #000000;
  border-radius: 8.82px;
  height: 18px;
  left: 19px;
  position: absolute;
  top: 6px;
  width: 18px;
}

.stake-tiers .ellipse-52 {
  background-color: #000000;
  border-radius: 3.46px;
  height: 7px;
  left: 3px;
  position: relative;
  top: 5px;
  width: 7px;
}

.stake-tiers .group-120 {
  border: 3.66px solid;
  border-color: #000000;
  border-radius: 8.82px;
  height: 18px;
  left: 39px;
  position: absolute;
  top: 6px;
  width: 18px;
}

.stake-tiers .ellipse-53 {
  background-color: #000000;
  border-radius: 3.46px;
  height: 7px;
  left: 5px;
  position: relative;
  top: 5px;
  width: 7px;
}

.stake-tiers .group-121 {
  height: 24px;
  left: 58px;
  position: absolute;
  top: 0;
  width: 18px;
}

.stake-tiers .overlap-group-13 {
  height: 24px;
  position: relative;
}

.stake-tiers .ellipse-54 {
  border: 3.66px solid;
  border-color: #000000;
  border-radius: 8.82px;
  height: 18px;
  left: 0;
  position: absolute;
  top: 6px;
  width: 18px;
}

.stake-tiers .rectangle-29 {
  background-color: #000000;
  border-radius: 0px 0px 1.82px 1.82px;
  height: 17px;
  left: 0;
  position: absolute;
  top: 0;
  width: 4px;
}

.stake-tiers .subtract-11 {
  height: 18px;
  left: 77px;
  position: absolute;
  top: 6px;
  width: 18px;
}

.stake-tiers .frame-365 {
  align-items: flex-start;
  display: flex;
  height: 18px;
  left: 96px;
  position: absolute;
  top: 6px;
  width: 11px;
}

.stake-tiers .rectangle-30 {
  background-color: #000000;
  height: 17.65px;
  position: relative;
  transform: rotate(180deg);
  width: 3.64px;
}

.stake-tiers .ellipse-55 {
  background-color: #000000;
  border-radius: 3.46px;
  height: 6.91px;
  position: relative;
  width: 6.91px;
}

.stake-tiers .frame-366 {
  height: 18px;
  left: 657px;
  position: absolute;
  top: 16px;
  width: 429px;
}

.stake-tiers .text-wrapper-197 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  left: 0;
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.stake-tiers .text-wrapper-198 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  left: 103px;
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.stake-tiers .text-wrapper-199 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  left: 209px;
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.stake-tiers .text-wrapper-200 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  left: 341px;
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.stake-tiers .frame-367 {
  align-items: center;
  display: inline-flex;
  gap: 20px;
  left: 1414px;
  position: absolute;
  top: 1px;
}

.stake-tiers .frame-368 {
  height: 40px;
  position: relative;
  width: 114px;
}

.stake-tiers .twitter-9 {
  background-color: #1c1c1c;
  border-radius: 500px;
  height: 40px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 40px;
}

.stake-tiers .x-5 {
  height: 24px !important;
  left: 8px !important;
  position: absolute !important;
  top: 8px !important;
  width: 24px !important;
}

.stake-tiers .discord-11 {
  height: 40px;
  left: 64px;
  position: absolute;
  top: 0;
  width: 40px;
}

.stake-tiers .wallet-26 {
  flex: 0 0 auto !important;
}
