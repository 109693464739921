.icons {
  height: 30px;
  width: 30px;
}

.icons .property-NUTS-instance {
  border: 0.6px solid !important;
  border-radius: 15px !important;
  height: 30px !important;
  width: unset !important;
}

.icons .property-1-NUTS-instance {
  height: 23px !important;
  left: 3px !important;
  top: 3px !important;
  width: 23px !important;
}

.icons .image-2 {
  height: 16px;
  left: 6px;
  object-fit: cover;
  position: absolute;
  top: 6px;
  width: 16px;
}

.icons.sol {
  background-color: #ffffff;
  border: 0.6px solid;
  border-color: var(--text-300);
  border-radius: 15px;
  overflow: hidden;
  position: relative;
}
