.property-NUTS {
  background-color: #ffffff;
  border: 1.2px solid;
  border-color: var(--text-300);
  border-radius: 30px;
  height: 60px;
  overflow: hidden;
  position: relative;
  width: 60px;
}

.property-NUTS .image {
  height: 46px;
  left: 6px;
  object-fit: cover;
  position: absolute;
  top: 6px;
  width: 46px;
}
