.tiers {
  align-items: flex-start;
  background-color: #fafafa;
  border-radius: 20px;
  display: inline-flex;
  flex-direction: column;
  gap: 70px;
  padding: 50px 44px;
  position: relative;
}

.tiers .frame-9 {
  height: 94px;
  position: relative;
  width: 1644px;
}

.tiers .frame-10 {
  align-items: center;
  display: flex;
  justify-content: space-between;
  left: 0;
  position: absolute;
  top: 0;
  width: 1644px;
}

.tiers .frame-11 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
}

.tiers .frame-12 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.tiers .stake-NUTS-for-free {
  color: transparent;
  font-family: "Aeonik TRIAL-Bold", Helvetica;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.tiers .span {
  color: #202020;
}

.tiers .text-wrapper-9 {
  color: #ffba15;
}

.tiers .text-wrapper-10 {
  color: #000000;
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  position: relative;
  text-align: right;
  width: 46px;
}

.tiers .component-5508 {
  left: 0 !important;
  position: absolute !important;
  top: 100px !important;
}

.tiers .frame-13 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 1644px;
}

.tiers .frame-14 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 78px;
  position: relative;
}

.tiers .frame-15 {
  align-items: center;
  display: flex;
  gap: 10px;
  position: relative;
  width: 232px;
}

.tiers .frame-16 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6px;
  position: relative;
}

.tiers .text-wrapper-11 {
  color: var(--text-600);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.tiers .frame-17 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4.66px;
  position: relative;
}

.tiers .text-wrapper-12 {
  color: var(--text-900);
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  margin-top: -1.17px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.tiers .property-1-money-bill-wave-alt {
  height: 34px !important;
  left: 12px !important;
  position: absolute !important;
  top: 12px !important;
  width: 34px !important;
}

.tiers .wallet-instance {
  background-color: var(--primary-100) !important;
  flex: 0 0 auto !important;
}

.tiers .design-component-instance-node {
  color: var(--primary-500main) !important;
}

.tiers .iconify-26 {
  height: 24px !important;
  position: relative !important;
  width: 24px !important;
}

.tiers .property-1-NUTS {
  margin-left: -13px !important;
}

.tiers .icon-instance-node {
  height: 60px !important;
  position: relative !important;
  width: 60px !important;
}
