.connect-wallet {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.connect-wallet .div-9 {
  height: 1080px;
  overflow: hidden;
  position: relative;
  width: 1920px;
}

.connect-wallet .overlap-3 {
  background-color: #ffffff;
  height: 2083px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1920px;
}

.connect-wallet .frame-199 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 80px;
  left: 92px;
  position: relative;
  top: 150px;
}

.connect-wallet .frame-200 {
  height: 319px;
  position: relative;
  width: 1733px;
}

.connect-wallet .frame-201 {
  height: 187px;
  left: 0;
  position: absolute;
  top: 0;
  width: 557px;
}

.connect-wallet .group-66 {
  height: 50px;
  left: 0;
  position: absolute;
  top: 0;
  width: 238px;
}

.connect-wallet .frame-202 {
  align-items: center;
  background-color: var(--text-150);
  border-radius: 10px;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  padding: 16px 14px;
  position: relative;
}

.connect-wallet .text-wrapper-116 {
  color: var(--primary-500main);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.connect-wallet .frame-203 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 14px;
  left: 0;
  position: absolute;
  top: 64px;
}

.connect-wallet .stake-to-mint-GOO-6 {
  color: transparent;
  font-family: var(--h3-bold-font-family);
  font-size: var(--h3-bold-font-size);
  font-style: var(--h3-bold-font-style);
  font-weight: var(--h3-bold-font-weight);
  letter-spacing: var(--h3-bold-letter-spacing);
  line-height: var(--h3-bold-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.connect-wallet .text-wrapper-117 {
  color: #202020;
  font-family: var(--h3-bold-font-family);
  font-size: var(--h3-bold-font-size);
  font-style: var(--h3-bold-font-style);
  font-weight: var(--h3-bold-font-weight);
  letter-spacing: var(--h3-bold-letter-spacing);
  line-height: var(--h3-bold-line-height);
}

.connect-wallet .text-wrapper-118 {
  color: #ffba15;
  font-family: var(--h3-bold-font-family);
  font-size: var(--h3-bold-font-size);
  font-style: var(--h3-bold-font-style);
  font-weight: var(--h3-bold-font-weight);
  letter-spacing: var(--h3-bold-letter-spacing);
  line-height: var(--h3-bold-line-height);
}

.connect-wallet .text-wrapper-119 {
  color: var(--text-600);
  font-family: "ABeeZee", Helvetica;
  font-size: 28px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.connect-wallet .frame-204 {
  height: 72px;
  left: 0;
  position: absolute;
  top: 247px;
  width: 1733px;
}

.connect-wallet .frame-205 {
  height: 72px;
  left: 0;
  position: absolute;
  top: 0;
  width: 293px;
}

.connect-wallet .text-wrapper-120 {
  color: var(--text-600);
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  left: 0;
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.connect-wallet .frame-206 {
  align-items: center;
  display: inline-flex;
  gap: 4.66px;
  left: 0;
  position: absolute;
  top: 37px;
}

.connect-wallet .text-wrapper-121 {
  color: var(--text-900);
  font-family: var(--small-title-bold-font-family);
  font-size: var(--small-title-bold-font-size);
  font-style: var(--small-title-bold-font-style);
  font-weight: var(--small-title-bold-font-weight);
  letter-spacing: var(--small-title-bold-letter-spacing);
  line-height: var(--small-title-bold-line-height);
  margin-top: -1.17px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.connect-wallet .frame-207 {
  height: 72px;
  left: 480px;
  position: absolute;
  top: 0;
  width: 293px;
}

.connect-wallet .frame-208 {
  height: 72px;
  left: 960px;
  position: absolute;
  top: 0;
  width: 293px;
}

.connect-wallet .frame-209 {
  height: 72px;
  left: 1440px;
  position: absolute;
  top: 0;
  width: 293px;
}

.connect-wallet .frame-210 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  position: relative;
}

.connect-wallet .tabs-8 {
  position: relative !important;
}

.connect-wallet .design-component-instance-node-2 {
  flex: 0 0 auto !important;
}

.connect-wallet .icons-15 {
  margin-left: -13px !important;
}

.connect-wallet .iconify-15 {
  height: 34px !important;
  left: 12px !important;
  position: absolute !important;
  top: 12px !important;
  width: 34px !important;
}

.connect-wallet .iconify-4 {
  height: 24px !important;
  position: relative !important;
  width: 24px !important;
}

.connect-wallet .icons-36 {
  height: 29px !important;
  position: relative !important;
  width: 60px !important;
}

.connect-wallet .tiers-4 {
  font-family: "ABeeZee", Helvetica !important;
  font-weight: 400 !important;
}

.connect-wallet .tiers-5 {
  font-style: italic !important;
}

.connect-wallet .tiers-6 {
  align-items: flex-start;
  background-color: #fafafa;
  border-radius: 20px;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 70px;
  padding: 50px 44px;
  position: relative;
}

.connect-wallet .frame-211 {
  height: 94px;
  position: relative;
  width: 1644px;
}

.connect-wallet .frame-212 {
  align-items: center;
  display: flex;
  justify-content: space-between;
  left: 0;
  position: absolute;
  top: 0;
  width: 1644px;
}

.connect-wallet .frame-213 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
}

.connect-wallet .frame-214 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.connect-wallet .stake-NUTS-for-free-5 {
  color: var(--text-900);
  font-family: "ABeeZee", Helvetica;
  font-size: 24px;
  font-style: italic;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.connect-wallet .text-wrapper-122 {
  color: #000000;
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  position: relative;
  text-align: right;
  width: 46px;
}

.connect-wallet .view-2 {
  left: 0 !important;
  position: absolute !important;
  top: 100px !important;
}

.connect-wallet .frame-215 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 1644px;
}

.connect-wallet .frame-216 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 78px;
  position: relative;
}

.connect-wallet .frame-217 {
  align-items: center;
  display: flex;
  gap: 10px;
  position: relative;
  width: 232px;
}

.connect-wallet .frame-218 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6px;
  position: relative;
}

.connect-wallet .text-wrapper-123 {
  color: var(--text-600);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.connect-wallet .frame-219 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4.66px;
  position: relative;
}

.connect-wallet .text-wrapper-124 {
  color: var(--text-900);
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  margin-top: -1.17px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.connect-wallet .wallet-14 {
  background-color: var(--primary-100) !important;
  flex: 0 0 auto !important;
}

.connect-wallet .wallet-15 {
  color: var(--primary-500main) !important;
}

.connect-wallet .nav-7 {
  background-image: url(../../../static/img/rectangle-2-7.svg);
  background-size: 100% 100%;
  height: 100px;
  left: 0;
  position: fixed;
  top: 0;
  width: 1920px;
}

.connect-wallet .group-67 {
  height: 51px;
  left: 92px;
  position: relative;
  top: 24px;
  width: 1736px;
}

.connect-wallet .logo-7 {
  align-items: center;
  display: inline-flex;
  gap: 8px;
  left: 0;
  position: absolute;
  top: 0;
}

.connect-wallet .group-68 {
  height: 50px;
  position: relative;
  width: 50px;
}

.connect-wallet .frame-220 {
  background-color: #ffffff;
  border-radius: 24.82px;
  height: 50px;
}

.connect-wallet .group-69 {
  background-image: url(../../../static/img/vector.svg);
  background-size: 100% 100%;
  height: 29px;
  left: 10px;
  position: relative;
  top: 10px;
  width: 29px;
}

.connect-wallet .group-70 {
  background-color: #ffffff;
  border-radius: 3.63px;
  height: 7px;
  left: 9px;
  position: absolute;
  top: 10px;
  width: 7px;
}

.connect-wallet .ellipse-31 {
  background-color: #000000;
  border-radius: 2.66px;
  height: 5px;
  left: 1px;
  position: relative;
  top: 1px;
  width: 5px;
}

.connect-wallet .group-71 {
  background-color: #ffffff;
  border-radius: 3.63px;
  height: 7px;
  left: 17px;
  position: absolute;
  top: 3px;
  width: 7px;
}

.connect-wallet .group-72 {
  height: 32.88px;
  position: relative;
  width: 106.81px;
}

.connect-wallet .group-73 {
  height: 27px;
  left: 0;
  position: absolute;
  top: 6px;
  width: 18px;
}

.connect-wallet .group-74 {
  border: 3.66px solid;
  border-color: #000000;
  border-radius: 8.82px;
  height: 18px;
  left: 19px;
  position: absolute;
  top: 6px;
  width: 18px;
}

.connect-wallet .ellipse-32 {
  background-color: #000000;
  border-radius: 3.46px;
  height: 7px;
  left: 3px;
  position: relative;
  top: 5px;
  width: 7px;
}

.connect-wallet .group-75 {
  border: 3.66px solid;
  border-color: #000000;
  border-radius: 8.82px;
  height: 18px;
  left: 39px;
  position: absolute;
  top: 6px;
  width: 18px;
}

.connect-wallet .ellipse-33 {
  background-color: #000000;
  border-radius: 3.46px;
  height: 7px;
  left: 5px;
  position: relative;
  top: 5px;
  width: 7px;
}

.connect-wallet .group-76 {
  height: 24px;
  left: 58px;
  position: absolute;
  top: 0;
  width: 18px;
}

.connect-wallet .overlap-group-9 {
  height: 24px;
  position: relative;
}

.connect-wallet .ellipse-34 {
  border: 3.66px solid;
  border-color: #000000;
  border-radius: 8.82px;
  height: 18px;
  left: 0;
  position: absolute;
  top: 6px;
  width: 18px;
}

.connect-wallet .rectangle-17 {
  background-color: #000000;
  border-radius: 0px 0px 1.82px 1.82px;
  height: 17px;
  left: 0;
  position: absolute;
  top: 0;
  width: 4px;
}

.connect-wallet .subtract-7 {
  height: 18px;
  left: 77px;
  position: absolute;
  top: 6px;
  width: 18px;
}

.connect-wallet .frame-221 {
  align-items: flex-start;
  display: flex;
  height: 18px;
  left: 96px;
  position: absolute;
  top: 6px;
  width: 11px;
}

.connect-wallet .rectangle-18 {
  background-color: #000000;
  height: 17.65px;
  position: relative;
  transform: rotate(180deg);
  width: 3.64px;
}

.connect-wallet .ellipse-35 {
  background-color: #000000;
  border-radius: 3.46px;
  height: 6.91px;
  position: relative;
  width: 6.91px;
}

.connect-wallet .navbar-3 {
  height: 18px;
  left: 657px;
  position: absolute;
  top: 16px;
  width: 429px;
}

.connect-wallet .text-wrapper-125 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  left: 0;
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.connect-wallet .text-wrapper-126 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  left: 103px;
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.connect-wallet .text-wrapper-127 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  left: 209px;
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.connect-wallet .text-wrapper-128 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  left: 341px;
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.connect-wallet .frame-222 {
  align-items: center;
  display: inline-flex;
  gap: 20px;
  left: 1421px;
  position: absolute;
  top: 1px;
}

.connect-wallet .frame-223 {
  height: 40px;
  position: relative;
  width: 114px;
}

.connect-wallet .twitter-6 {
  background-color: #1c1c1c;
  border-radius: 500px;
  height: 40px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 40px;
}

.connect-wallet .x-2 {
  height: 24px !important;
  left: 8px !important;
  position: absolute !important;
  top: 8px !important;
  width: 24px !important;
}

.connect-wallet .discord-8 {
  height: 40px;
  left: 64px;
  position: absolute;
  top: 0;
  width: 40px;
}

.connect-wallet .rectangle-19 {
  height: 1080px;
  left: 0;
  position: fixed;
  top: 0;
  width: 1920px;
}

.connect-wallet .wallet-connect-3 {
  align-items: flex-start;
  background-color: #11141d;
  border-radius: 20px;
  display: inline-flex;
  flex-direction: column;
  gap: 20px;
  left: 684px;
  padding: 50px 0px;
  position: fixed;
  top: 359px;
}

.connect-wallet .frame-224 {
  height: 62px;
  position: relative;
  width: 551px;
}

.connect-wallet .frame-225 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 12px;
  padding: 0px 40px;
  position: relative;
}

.connect-wallet .frame-226 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  position: relative;
}

.connect-wallet .frame-227 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 246px;
  position: relative;
}

.connect-wallet .text-wrapper-129 {
  color: #ffffff;
  font-family: var(--small-title-bold-font-family);
  font-size: var(--small-title-bold-font-size);
  font-style: var(--small-title-bold-font-style);
  font-weight: var(--small-title-bold-font-weight);
  letter-spacing: var(--small-title-bold-letter-spacing);
  line-height: var(--small-title-bold-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.connect-wallet .text-wrapper-130 {
  color: #ffffff;
  font-family: "ABeeZee", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.32px;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.connect-wallet .frame-228 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  position: relative;
}

.connect-wallet .frame-229 {
  height: 180px;
  position: relative;
  width: 552px;
}

.connect-wallet .frame-230 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  position: relative;
}

.connect-wallet .frame-231 {
  background-color: #191d29;
  height: 60px;
  position: relative;
  width: 552px;
}

.connect-wallet .frame-232 {
  align-items: center;
  display: inline-flex;
  gap: 12px;
  left: 40px;
  position: absolute;
  top: 16px;
}

.connect-wallet .frame-233 {
  height: 28px;
  position: relative;
  width: 28px;
}

.connect-wallet .text-wrapper-131 {
  color: #ffffff;
  font-family: "ABeeZee", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.36px;
  line-height: normal;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.connect-wallet .text-wrapper-132 {
  color: #ffffff;
  font-family: "DM Sans", Helvetica;
  font-size: 14px;
  font-weight: 400;
  height: 18px;
  left: 451px;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.6;
  position: absolute;
  text-align: right;
  top: 20px;
}

.connect-wallet .frame-234 {
  height: 60px;
  position: relative;
  width: 552px;
}

.connect-wallet .image-3 {
  height: 28px;
  object-fit: cover;
  position: relative;
  width: 28px;
}
