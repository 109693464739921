.property-money {
  background-color: #ffffff;
  border: 1.2px solid;
  border-color: var(--text-300);
  border-radius: 30px;
  height: 60px;
  overflow: hidden;
  position: relative;
  width: 60px;
}
