.footer-wrapper {
  align-items: flex-start;
  background-color: #ffffff;
  display: inline-flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px 30px;
  position: relative;
}

.footer-wrapper .frame-40 {
  height: 108px;
  position: relative;
  width: 315px;
}

.footer-wrapper .frame-41 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 6px;
  left: 0;
  position: absolute;
  top: 0;
}

.footer-wrapper .frame-42 {
  height: 16px;
  position: relative;
  width: 124px;
}

.footer-wrapper .text-wrapper-25 {
  color: var(--text-800);
  font-family: "ABeeZee", Helvetica;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.footer-wrapper .frame-43 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  position: relative;
}

.footer-wrapper .text-wrapper-26 {
  color: var(--text-900);
  font-family: "ABeeZee", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.24px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.footer-wrapper .text-wrapper-27 {
  color: var(--text-900);
  font-family: "ABeeZee", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.24px;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.footer-wrapper .frame-44 {
  height: 77px;
  left: 206px;
  position: absolute;
  top: 0;
  width: 109px;
}

.footer-wrapper .frame-45 {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 6px;
  position: relative;
  width: 109px;
}

.footer-wrapper .text-wrapper-28 {
  color: var(--text-800);
  font-family: "ABeeZee", Helvetica;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: right;
  width: 109px;
}

.footer-wrapper .group-2 {
  height: 30px;
  position: relative;
  width: 67.5px;
}

.footer-wrapper .twitter-3 {
  background-color: #1c1c1c;
  border-radius: 375px;
  height: 30px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 30px;
}

.footer-wrapper .x-15 {
  height: 18px !important;
  left: 6px !important;
  position: absolute !important;
  top: 6px !important;
  width: 18px !important;
}

.footer-wrapper .discord-2 {
  height: 30px;
  left: 38px;
  position: absolute;
  top: 0;
  width: 30px;
}

.footer-wrapper .frame-46 {
  border-color: var(--text-300);
  border-top-style: solid;
  border-top-width: 1px;
  height: 26px;
  position: relative;
  width: 315px;
}

.footer-wrapper .element-goober-all-2 {
  color: var(--text-900);
  font-family: "DM Sans", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 3px;
  letter-spacing: 0.24px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 4px;
  width: 309px;
}

.footer-wrapper .text-wrapper-29 {
  letter-spacing: 0.03px;
}

.footer-wrapper .text-wrapper-30 {
  font-family: "ABeeZee", Helvetica;
  letter-spacing: 0.03px;
}

.footer-wrapper .text-wrapper-31 {
  font-family: "ABeeZee", Helvetica;
  letter-spacing: 0.03px;
  text-decoration: underline;
}
