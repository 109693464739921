.wallet {
  display: inline-flex;
  position: relative;
}

.wallet .div-2 {
  align-items: center;
  background-color: var(--primary-500main);
  border-radius: 10px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 13px 16px;
  position: relative;
}

.wallet .frame-5 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6px;
  justify-content: center;
  position: relative;
}

.wallet .phantom-instance {
  position: relative !important;
}

.wallet .text-wrapper-5 {
  color: #ffffff;
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.wallet .iconify-2 {
  height: 24px !important;
  position: relative !important;
  width: 24px !important;
}

.wallet .frame-wrapper {
  background-color: #ffffff;
  border: 1px solid;
  border-color: var(--text-300);
  border-radius: 10px;
  height: 144px;
  overflow: hidden;
  position: relative;
  width: 188px;
}

.wallet .frame-6 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  position: relative;
}

.wallet .div-wrapper {
  height: 48px;
  position: relative;
  width: 188px;
}

.wallet .frame-7 {
  align-items: center;
  display: inline-flex;
  gap: 8px;
  left: 16px;
  position: relative;
  top: 16px;
}

.wallet .text-wrapper-6 {
  color: var(--text-800);
  font-family: "ABeeZee", Helvetica;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.wallet .frame-8 {
  background-color: var(--primary-500main);
  height: 48px;
  position: relative;
  width: 188px;
}

.wallet .text-wrapper-7 {
  color: #ffffff;
  font-family: "ABeeZee", Helvetica;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.wallet .text-wrapper-8 {
  color: var(--text-800);
  font-family: "ABeeZee", Helvetica;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: 94px;
}

.wallet.wallet-connect {
  align-items: center;
  background-color: var(--primary-500main);
  border-radius: 10px;
  gap: 10px;
  justify-content: center;
  padding: 13px 16px;
}

.wallet.phantom-wallet-connected {
  align-items: center;
  background-color: var(--primary-500main);
  border-radius: 10px;
  gap: 10px;
  justify-content: center;
  padding: 13px 16px;
}

.wallet.expand {
  align-items: flex-start;
  flex-direction: column;
  gap: 6px;
}
