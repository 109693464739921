.stake-page-tiers-screen {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.stake-page-tiers-screen .stake-page-tiers-2 {
  background-color: #ffffff;
  height: 2083px;
  position: relative;
  width: 1920px;
}

.stake-page-tiers-screen .frame-415 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 80px;
  left: 92px;
  position: absolute;
  top: 150px;
}

.stake-page-tiers-screen .frame-416 {
  height: 319px;
  position: relative;
  width: 1733px;
}

.stake-page-tiers-screen .frame-417 {
  height: 187px;
  left: 0;
  position: absolute;
  top: 0;
  width: 557px;
}

.stake-page-tiers-screen .group-133 {
  height: 50px;
  left: 0;
  position: absolute;
  top: 0;
  width: 238px;
}

.stake-page-tiers-screen .frame-418 {
  align-items: center;
  background-color: var(--text-150);
  border-radius: 10px;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  padding: 16px 14px;
  position: relative;
}

.stake-page-tiers-screen .text-wrapper-225 {
  color: var(--primary-500main);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.stake-page-tiers-screen .frame-419 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 14px;
  left: 0;
  position: absolute;
  top: 64px;
}

.stake-page-tiers-screen .stake-to-mint-GOO-12 {
  color: transparent;
  font-family: var(--h3-bold-font-family);
  font-size: var(--h3-bold-font-size);
  font-style: var(--h3-bold-font-style);
  font-weight: var(--h3-bold-font-weight);
  letter-spacing: var(--h3-bold-letter-spacing);
  line-height: var(--h3-bold-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.stake-page-tiers-screen .text-wrapper-226 {
  color: #202020;
  font-family: var(--h3-bold-font-family);
  font-size: var(--h3-bold-font-size);
  font-style: var(--h3-bold-font-style);
  font-weight: var(--h3-bold-font-weight);
  letter-spacing: var(--h3-bold-letter-spacing);
  line-height: var(--h3-bold-line-height);
}

.stake-page-tiers-screen .text-wrapper-227 {
  color: #ffba15;
  font-family: var(--h3-bold-font-family);
  font-size: var(--h3-bold-font-size);
  font-style: var(--h3-bold-font-style);
  font-weight: var(--h3-bold-font-weight);
  letter-spacing: var(--h3-bold-letter-spacing);
  line-height: var(--h3-bold-line-height);
}

.stake-page-tiers-screen .text-wrapper-228 {
  color: var(--text-600);
  font-family: "ABeeZee", Helvetica;
  font-size: 28px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.stake-page-tiers-screen .frame-420 {
  height: 72px;
  left: 0;
  position: absolute;
  top: 247px;
  width: 1733px;
}

.stake-page-tiers-screen .frame-421 {
  height: 72px;
  left: 0;
  position: absolute;
  top: 0;
  width: 293px;
}

.stake-page-tiers-screen .text-wrapper-229 {
  color: var(--text-600);
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  left: 0;
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.stake-page-tiers-screen .frame-422 {
  align-items: center;
  display: inline-flex;
  gap: 4.66px;
  left: 0;
  position: absolute;
  top: 37px;
}

.stake-page-tiers-screen .text-wrapper-230 {
  color: var(--text-900);
  font-family: var(--small-title-bold-font-family);
  font-size: var(--small-title-bold-font-size);
  font-style: var(--small-title-bold-font-style);
  font-weight: var(--small-title-bold-font-weight);
  letter-spacing: var(--small-title-bold-letter-spacing);
  line-height: var(--small-title-bold-line-height);
  margin-top: -1.17px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.stake-page-tiers-screen .frame-423 {
  height: 72px;
  left: 480px;
  position: absolute;
  top: 0;
  width: 293px;
}

.stake-page-tiers-screen .element-13 {
  color: transparent;
  font-family: "Aeonik TRIAL-Bold", Helvetica;
  font-size: 28px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.17px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.stake-page-tiers-screen .text-wrapper-231 {
  color: #202020;
}

.stake-page-tiers-screen .text-wrapper-232 {
  color: #d4d4d4;
}

.stake-page-tiers-screen .frame-424 {
  height: 72px;
  left: 960px;
  position: absolute;
  top: 0;
  width: 293px;
}

.stake-page-tiers-screen .frame-425 {
  height: 72px;
  left: 1440px;
  position: absolute;
  top: 0;
  width: 293px;
}

.stake-page-tiers-screen .frame-426 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  position: relative;
}

.stake-page-tiers-screen .tabs-13 {
  position: relative !important;
}

.stake-page-tiers-screen .design-component-instance-node-3 {
  flex: 0 0 auto !important;
}

.stake-page-tiers-screen .icons-48 {
  height: 60px !important;
  position: relative !important;
  width: 60px !important;
}

.stake-page-tiers-screen .icons-49 {
  margin-left: -13px !important;
}

.stake-page-tiers-screen .iconify-42 {
  height: 34px !important;
  left: 12px !important;
  position: absolute !important;
  top: 12px !important;
  width: 34px !important;
}

.stake-page-tiers-screen .tiers-11 {
  font-family: "Aeonik TRIAL-RegularItalic", Helvetica !important;
  font-size: 20px !important;
  font-style: italic !important;
  font-weight: 400 !important;
  letter-spacing: 0 !important;
  line-height: normal !important;
}

.stake-page-tiers-screen .iconify-instance {
  height: 24px !important;
  position: relative !important;
  width: 24px !important;
}

.stake-page-tiers-screen .footer-13 {
  left: 0 !important;
  position: absolute !important;
  top: 1805px !important;
}

.stake-page-tiers-screen .footer-14 {
  left: 656px !important;
}

.stake-page-tiers-screen .nav-17 {
  background-image: url(../../../static/img/rectangle-2-4-2.svg);
  background-size: 100% 100%;
  height: 100px;
  left: 0;
  position: fixed;
  top: 0;
  width: 1920px;
}

.stake-page-tiers-screen .group-134 {
  height: 51px;
  left: 92px;
  position: relative;
  top: 24px;
  width: 1736px;
}

.stake-page-tiers-screen .logo-13 {
  align-items: center;
  display: inline-flex;
  gap: 8px;
  left: 0;
  position: absolute;
  top: 0;
}

.stake-page-tiers-screen .group-135 {
  height: 50px;
  position: relative;
  width: 50px;
}

.stake-page-tiers-screen .frame-427 {
  background-color: #ffffff;
  border-radius: 24.82px;
  height: 50px;
}

.stake-page-tiers-screen .group-136 {
  background-image: url(../../../static/img/vector.svg);
  background-size: 100% 100%;
  height: 29px;
  left: 10px;
  position: relative;
  top: 10px;
  width: 29px;
}

.stake-page-tiers-screen .group-137 {
  background-color: #ffffff;
  border-radius: 3.63px;
  height: 7px;
  left: 9px;
  position: absolute;
  top: 10px;
  width: 7px;
}

.stake-page-tiers-screen .ellipse-61 {
  background-color: #000000;
  border-radius: 2.66px;
  height: 5px;
  left: 1px;
  position: relative;
  top: 1px;
  width: 5px;
}

.stake-page-tiers-screen .group-138 {
  background-color: #ffffff;
  border-radius: 3.63px;
  height: 7px;
  left: 17px;
  position: absolute;
  top: 3px;
  width: 7px;
}

.stake-page-tiers-screen .group-139 {
  height: 32.88px;
  position: relative;
  width: 106.81px;
}

.stake-page-tiers-screen .group-140 {
  height: 27px;
  left: 0;
  position: absolute;
  top: 6px;
  width: 18px;
}

.stake-page-tiers-screen .group-141 {
  border: 3.66px solid;
  border-color: #000000;
  border-radius: 8.82px;
  height: 18px;
  left: 19px;
  position: absolute;
  top: 6px;
  width: 18px;
}

.stake-page-tiers-screen .ellipse-62 {
  background-color: #000000;
  border-radius: 3.46px;
  height: 7px;
  left: 3px;
  position: relative;
  top: 5px;
  width: 7px;
}

.stake-page-tiers-screen .group-142 {
  border: 3.66px solid;
  border-color: #000000;
  border-radius: 8.82px;
  height: 18px;
  left: 39px;
  position: absolute;
  top: 6px;
  width: 18px;
}

.stake-page-tiers-screen .ellipse-63 {
  background-color: #000000;
  border-radius: 3.46px;
  height: 7px;
  left: 5px;
  position: relative;
  top: 5px;
  width: 7px;
}

.stake-page-tiers-screen .group-143 {
  height: 24px;
  left: 58px;
  position: absolute;
  top: 0;
  width: 18px;
}

.stake-page-tiers-screen .overlap-group-15 {
  height: 24px;
  position: relative;
}

.stake-page-tiers-screen .ellipse-64 {
  border: 3.66px solid;
  border-color: #000000;
  border-radius: 8.82px;
  height: 18px;
  left: 0;
  position: absolute;
  top: 6px;
  width: 18px;
}

.stake-page-tiers-screen .rectangle-34 {
  background-color: #000000;
  border-radius: 0px 0px 1.82px 1.82px;
  height: 17px;
  left: 0;
  position: absolute;
  top: 0;
  width: 4px;
}

.stake-page-tiers-screen .subtract-13 {
  height: 18px;
  left: 77px;
  position: absolute;
  top: 6px;
  width: 18px;
}

.stake-page-tiers-screen .frame-428 {
  align-items: flex-start;
  display: flex;
  height: 18px;
  left: 96px;
  position: absolute;
  top: 6px;
  width: 11px;
}

.stake-page-tiers-screen .rectangle-35 {
  background-color: #000000;
  height: 17.65px;
  position: relative;
  transform: rotate(180deg);
  width: 3.64px;
}

.stake-page-tiers-screen .ellipse-65 {
  background-color: #000000;
  border-radius: 3.46px;
  height: 6.91px;
  position: relative;
  width: 6.91px;
}

.stake-page-tiers-screen .navbar-4 {
  height: 18px;
  left: 657px;
  position: absolute;
  top: 16px;
  width: 429px;
}

.stake-page-tiers-screen .text-wrapper-233 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  left: 0;
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.stake-page-tiers-screen .text-wrapper-234 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  left: 103px;
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.stake-page-tiers-screen .text-wrapper-235 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  left: 209px;
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.stake-page-tiers-screen .text-wrapper-236 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  left: 341px;
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.stake-page-tiers-screen .frame-429 {
  align-items: center;
  display: inline-flex;
  gap: 20px;
  left: 1421px;
  position: absolute;
  top: 1px;
}

.stake-page-tiers-screen .frame-430 {
  height: 40px;
  position: relative;
  width: 114px;
}

.stake-page-tiers-screen .twitter-11 {
  background-color: #1c1c1c;
  border-radius: 500px;
  height: 40px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 40px;
}

.stake-page-tiers-screen .x-7 {
  height: 24px !important;
  left: 8px !important;
  position: absolute !important;
  top: 8px !important;
  width: 24px !important;
}

.stake-page-tiers-screen .discord-13 {
  height: 40px;
  left: 64px;
  position: absolute;
  top: 0;
  width: 40px;
}
