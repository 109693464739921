.property-wrapper {
  background-color: #ffffff;
  border-radius: 25px;
  height: 10px;
  width: 1639px;
}

.property-wrapper .rectangle {
  background-color: var(--primary-500main);
  border-radius: 25px;
  height: 10px;
}

.property-wrapper .one {
  width: 26px;
}

.property-wrapper .fifty {
  width: 837px;
}
