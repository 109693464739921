.stake-in-NUTS {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.stake-in-NUTS .div-14 {
  height: 1080px;
  overflow: hidden;
  position: relative;
  width: 1920px;
}

.stake-in-NUTS .overlap-8 {
  background-color: #ffffff;
  height: 2083px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1920px;
}

.stake-in-NUTS .frame-369 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 80px;
  left: 92px;
  position: absolute;
  top: 150px;
}

.stake-in-NUTS .frame-370 {
  height: 319px;
  position: relative;
  width: 1733px;
}

.stake-in-NUTS .frame-371 {
  height: 187px;
  left: 0;
  position: absolute;
  top: 0;
  width: 557px;
}

.stake-in-NUTS .group-122 {
  height: 50px;
  left: 0;
  position: absolute;
  top: 0;
  width: 238px;
}

.stake-in-NUTS .frame-372 {
  align-items: center;
  background-color: var(--text-150);
  border-radius: 10px;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  padding: 16px 14px;
  position: relative;
}

.stake-in-NUTS .text-wrapper-201 {
  color: var(--primary-500main);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.stake-in-NUTS .frame-373 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 14px;
  left: 0;
  position: absolute;
  top: 64px;
}

.stake-in-NUTS .stake-to-mint-GOO-11 {
  color: transparent;
  font-family: var(--h3-bold-font-family);
  font-size: var(--h3-bold-font-size);
  font-style: var(--h3-bold-font-style);
  font-weight: var(--h3-bold-font-weight);
  letter-spacing: var(--h3-bold-letter-spacing);
  line-height: var(--h3-bold-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.stake-in-NUTS .text-wrapper-202 {
  color: #202020;
  font-family: var(--h3-bold-font-family);
  font-size: var(--h3-bold-font-size);
  font-style: var(--h3-bold-font-style);
  font-weight: var(--h3-bold-font-weight);
  letter-spacing: var(--h3-bold-letter-spacing);
  line-height: var(--h3-bold-line-height);
}

.stake-in-NUTS .text-wrapper-203 {
  color: #ffba15;
  font-family: var(--h3-bold-font-family);
  font-size: var(--h3-bold-font-size);
  font-style: var(--h3-bold-font-style);
  font-weight: var(--h3-bold-font-weight);
  letter-spacing: var(--h3-bold-letter-spacing);
  line-height: var(--h3-bold-line-height);
}

.stake-in-NUTS .text-wrapper-204 {
  color: var(--text-600);
  font-family: "ABeeZee", Helvetica;
  font-size: 28px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.stake-in-NUTS .frame-374 {
  height: 72px;
  left: 0;
  position: absolute;
  top: 247px;
  width: 1733px;
}

.stake-in-NUTS .frame-375 {
  height: 72px;
  left: 0;
  position: absolute;
  top: 0;
  width: 293px;
}

.stake-in-NUTS .text-wrapper-205 {
  color: var(--text-600);
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  left: 0;
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.stake-in-NUTS .frame-376 {
  align-items: center;
  display: inline-flex;
  gap: 4.66px;
  left: 0;
  position: absolute;
  top: 37px;
}

.stake-in-NUTS .text-wrapper-206 {
  color: var(--text-900);
  font-family: var(--small-title-bold-font-family);
  font-size: var(--small-title-bold-font-size);
  font-style: var(--small-title-bold-font-style);
  font-weight: var(--small-title-bold-font-weight);
  letter-spacing: var(--small-title-bold-letter-spacing);
  line-height: var(--small-title-bold-line-height);
  margin-top: -1.17px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.stake-in-NUTS .frame-377 {
  height: 72px;
  left: 480px;
  position: absolute;
  top: 0;
  width: 293px;
}

.stake-in-NUTS .frame-378 {
  height: 72px;
  left: 960px;
  position: absolute;
  top: 0;
  width: 293px;
}

.stake-in-NUTS .frame-379 {
  height: 72px;
  left: 1440px;
  position: absolute;
  top: 0;
  width: 293px;
}

.stake-in-NUTS .user-profile-6 {
  align-items: flex-start;
  background-color: #fafafa;
  border-radius: 20px;
  display: inline-flex;
  gap: 180px;
  justify-content: flex-end;
  left: 799px;
  overflow: hidden;
  padding: 50px 84px;
  position: absolute;
  top: 7px;
}

.stake-in-NUTS .nuts-6 {
  height: 72px;
  position: relative;
  width: 302px;
}

.stake-in-NUTS .icons-39 {
  border: 1.36px solid !important;
  border-radius: 34px !important;
  height: 68px !important;
  left: 0 !important;
  position: absolute !important;
  top: 2px !important;
  width: 68px !important;
}

.stake-in-NUTS .icons-41 {
  height: 52px !important;
  left: 7px !important;
  top: 7px !important;
  width: 52px !important;
}

.stake-in-NUTS .element-11 {
  height: 72px;
  left: 78px;
  position: absolute;
  top: 0;
  width: 224px;
}

.stake-in-NUTS .sol-7 {
  height: 72px;
  position: relative;
  width: 284px;
}

.stake-in-NUTS .icons-42 {
  height: 36px !important;
  left: 14px !important;
  top: 14px !important;
  width: 36px !important;
}

.stake-in-NUTS .element-12 {
  height: 72px;
  left: 78px;
  position: absolute;
  top: 0;
  width: 206px;
}

.stake-in-NUTS .frame-380 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  position: relative;
}

.stake-in-NUTS .tabs-12 {
  position: relative !important;
}

.stake-in-NUTS .tiers-10 {
  align-items: flex-start;
  background-color: #fafafa;
  border-radius: 20px;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 70px;
  padding: 50px 44px;
  position: relative;
}

.stake-in-NUTS .frame-381 {
  height: 94px;
  position: relative;
  width: 1644px;
}

.stake-in-NUTS .frame-382 {
  height: 60px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1644px;
}

.stake-in-NUTS .frame-383 {
  align-items: center;
  display: inline-flex;
  gap: 10px;
  left: 0;
  position: absolute;
  top: 0;
}

.stake-in-NUTS .frame-384 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.stake-in-NUTS .icons-45 {
  margin-left: -13px !important;
}

.stake-in-NUTS .div-15 {
  color: transparent;
  font-family: "ABeeZee", Helvetica;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.stake-in-NUTS .text-wrapper-207 {
  color: #202020;
  font-style: italic;
}

.stake-in-NUTS .text-wrapper-208 {
  color: #ffba15;
  font-style: italic;
}

.stake-in-NUTS .text-wrapper-209 {
  color: #000000;
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  left: 1598px;
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  position: absolute;
  text-align: right;
  top: 18px;
  width: 46px;
}

.stake-in-NUTS .view-6 {
  left: 0 !important;
  position: absolute !important;
  top: 100px !important;
}

.stake-in-NUTS .frame-385 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 1644px;
}

.stake-in-NUTS .frame-386 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 78px;
  position: relative;
}

.stake-in-NUTS .frame-387 {
  align-items: center;
  display: flex;
  gap: 10px;
  position: relative;
  width: 232px;
}

.stake-in-NUTS .frame-388 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6px;
  position: relative;
}

.stake-in-NUTS .text-wrapper-210 {
  color: var(--text-600);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.stake-in-NUTS .frame-389 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4.66px;
  position: relative;
}

.stake-in-NUTS .text-wrapper-211 {
  color: var(--text-900);
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  margin-top: -1.17px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.stake-in-NUTS .wallet-27 {
  flex: 0 0 auto !important;
  padding: 16px !important;
}

.stake-in-NUTS .wallet-28 {
  margin-top: -1px !important;
}

.stake-in-NUTS .frame-390 {
  align-items: center;
  display: flex;
  justify-content: space-between;
  left: 0;
  position: absolute;
  top: 0;
  width: 1644px;
}

.stake-in-NUTS .frame-391 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
}

.stake-in-NUTS .text-wrapper-212 {
  color: #000000;
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  position: relative;
  text-align: right;
  width: 46px;
}

.stake-in-NUTS .stake-NUTS-for-free-10 {
  color: var(--text-900);
  font-family: "ABeeZee", Helvetica;
  font-size: 24px;
  font-style: italic;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.stake-in-NUTS .nav-15 {
  height: 100px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1920px;
}

.stake-in-NUTS .nav-16 {
  background-size: 100% 100%;
  height: 100px;
}

.stake-in-NUTS .group-123 {
  height: 51px;
  left: 92px;
  position: relative;
  top: 24px;
  width: 1736px;
}

.stake-in-NUTS .logo-12 {
  align-items: center;
  display: inline-flex;
  gap: 8px;
  left: 0;
  position: absolute;
  top: 0;
}

.stake-in-NUTS .group-124 {
  height: 50px;
  position: relative;
  width: 50px;
}

.stake-in-NUTS .frame-392 {
  background-color: #ffffff;
  border-radius: 24.82px;
  height: 50px;
}

.stake-in-NUTS .group-125 {
  background-size: 100% 100%;
  height: 29px;
  left: 10px;
  position: relative;
  top: 10px;
  width: 29px;
}

.stake-in-NUTS .group-126 {
  background-color: #ffffff;
  border-radius: 3.63px;
  height: 7px;
  left: 9px;
  position: absolute;
  top: 10px;
  width: 7px;
}

.stake-in-NUTS .ellipse-56 {
  background-color: #000000;
  border-radius: 2.66px;
  height: 5px;
  left: 1px;
  position: relative;
  top: 1px;
  width: 5px;
}

.stake-in-NUTS .group-127 {
  background-color: #ffffff;
  border-radius: 3.63px;
  height: 7px;
  left: 17px;
  position: absolute;
  top: 3px;
  width: 7px;
}

.stake-in-NUTS .group-128 {
  height: 32.88px;
  position: relative;
  width: 106.81px;
}

.stake-in-NUTS .group-129 {
  height: 27px;
  left: 0;
  position: absolute;
  top: 6px;
  width: 18px;
}

.stake-in-NUTS .group-130 {
  border: 3.66px solid;
  border-color: #000000;
  border-radius: 8.82px;
  height: 18px;
  left: 19px;
  position: absolute;
  top: 6px;
  width: 18px;
}

.stake-in-NUTS .ellipse-57 {
  background-color: #000000;
  border-radius: 3.46px;
  height: 7px;
  left: 3px;
  position: relative;
  top: 5px;
  width: 7px;
}

.stake-in-NUTS .group-131 {
  border: 3.66px solid;
  border-color: #000000;
  border-radius: 8.82px;
  height: 18px;
  left: 39px;
  position: absolute;
  top: 6px;
  width: 18px;
}

.stake-in-NUTS .ellipse-58 {
  background-color: #000000;
  border-radius: 3.46px;
  height: 7px;
  left: 5px;
  position: relative;
  top: 5px;
  width: 7px;
}

.stake-in-NUTS .group-132 {
  height: 24px;
  left: 58px;
  position: absolute;
  top: 0;
  width: 18px;
}

.stake-in-NUTS .overlap-group-14 {
  height: 24px;
  position: relative;
}

.stake-in-NUTS .ellipse-59 {
  border: 3.66px solid;
  border-color: #000000;
  border-radius: 8.82px;
  height: 18px;
  left: 0;
  position: absolute;
  top: 6px;
  width: 18px;
}

.stake-in-NUTS .rectangle-31 {
  background-color: #000000;
  border-radius: 0px 0px 1.82px 1.82px;
  height: 17px;
  left: 0;
  position: absolute;
  top: 0;
  width: 4px;
}

.stake-in-NUTS .subtract-12 {
  height: 18px;
  left: 77px;
  position: absolute;
  top: 6px;
  width: 18px;
}

.stake-in-NUTS .frame-393 {
  align-items: flex-start;
  display: flex;
  height: 18px;
  left: 96px;
  position: absolute;
  top: 6px;
  width: 11px;
}

.stake-in-NUTS .rectangle-32 {
  background-color: #000000;
  height: 17.65px;
  position: relative;
  transform: rotate(180deg);
  width: 3.64px;
}

.stake-in-NUTS .ellipse-60 {
  background-color: #000000;
  border-radius: 3.46px;
  height: 6.91px;
  position: relative;
  width: 6.91px;
}

.stake-in-NUTS .frame-394 {
  height: 18px;
  left: 657px;
  position: absolute;
  top: 16px;
  width: 429px;
}

.stake-in-NUTS .text-wrapper-213 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  left: 0;
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.stake-in-NUTS .text-wrapper-214 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  left: 103px;
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.stake-in-NUTS .text-wrapper-215 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  left: 209px;
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.stake-in-NUTS .text-wrapper-216 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  left: 341px;
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.stake-in-NUTS .frame-395 {
  align-items: center;
  display: inline-flex;
  gap: 20px;
  left: 1414px;
  position: absolute;
  top: 1px;
}

.stake-in-NUTS .frame-396 {
  height: 40px;
  position: relative;
  width: 114px;
}

.stake-in-NUTS .twitter-10 {
  background-color: #1c1c1c;
  border-radius: 500px;
  height: 40px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 40px;
}

.stake-in-NUTS .discord-12 {
  height: 40px;
  left: 64px;
  position: absolute;
  top: 0;
  width: 40px;
}

.stake-in-NUTS .wallet-29 {
  background-image: unset !important;
  position: relative !important;
}

.stake-in-NUTS .wallet-30 {
  flex: 0 0 auto !important;
}

.stake-in-NUTS .rectangle-33 {
  height: 1080px;
  left: 0;
  position: fixed;
  top: 0;
  width: 1920px;
}

.stake-in-NUTS .wallet-connect-5 {
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 20px;
  display: block;
  flex-direction: column;
  gap: 20px;
  left: 684px;
  opacity: 0;
  padding: 50px 0px;
  position: fixed;
  top: 359px;
  transform: scale(1.1);
}

.stake-in-NUTS .frame-397 {
  height: 62px;
  position: relative;
  width: 551px;
}

.stake-in-NUTS .frame-398 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 12px;
  padding: 0px 40px;
  position: relative;
}

.stake-in-NUTS .frame-399 {
  height: 32px;
  position: relative;
  width: 471px;
}

.stake-in-NUTS .frame-400 {
  height: 32px;
  position: relative;
}

.stake-in-NUTS .text-wrapper-217 {
  color: var(--text-900);
  font-family: var(--small-title-bold-font-family);
  font-size: var(--small-title-bold-font-size);
  font-style: var(--small-title-bold-font-style);
  font-weight: var(--small-title-bold-font-weight);
  left: 0;
  letter-spacing: var(--small-title-bold-letter-spacing);
  line-height: var(--small-title-bold-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.stake-in-NUTS .please-note-you-can-2 {
  color: var(--text-900);
  font-family: "ABeeZee", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.32px;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.stake-in-NUTS .text-wrapper-218 {
  letter-spacing: 0.05px;
}

.stake-in-NUTS .text-wrapper-219 {
  font-style: italic;
  letter-spacing: 0.05px;
}

.stake-in-NUTS .frame-401 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  height: 94px;
  position: relative;
}

.stake-in-NUTS .frame-402 {
  height: 144px;
  margin-bottom: -50px;
  position: relative;
  width: 552px;
}

.stake-in-NUTS .frame-403 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  position: relative;
}

.stake-in-NUTS .frame-404 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  padding: 0px 40px;
  position: relative;
}

.stake-in-NUTS .frame-405 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  position: relative;
}

.stake-in-NUTS .frame-406 {
  height: 18px;
  position: relative;
  width: 472px;
}

.stake-in-NUTS .frame-407 {
  height: 18px;
  left: 0;
  position: absolute;
  top: 0;
  width: 129px;
}

.stake-in-NUTS .text-wrapper-220 {
  color: var(--text-600);
  font-family: "ABeeZee", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0.32px;
  line-height: normal;
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.stake-in-NUTS .text-wrapper-221 {
  color: #000000;
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  left: 80px;
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.stake-in-NUTS .frame-408 {
  height: 18px;
  left: 320px;
  position: absolute;
  top: 0;
  width: 152px;
}

.stake-in-NUTS .text-wrapper-222 {
  color: #000000;
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  left: 78px;
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.stake-in-NUTS .frame-409 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: var(--primary-500main);
  border-radius: 10px;
  height: 66px;
  position: relative;
  width: 472px;
}

.stake-in-NUTS .icons-46 {
  border: 0.84px solid !important;
  border-radius: 21px !important;
  height: 42px !important;
  left: 20px !important;
  position: absolute !important;
  top: 12px !important;
  width: 42px !important;
}

.stake-in-NUTS .icons-47 {
  height: 32px !important;
  left: 4px !important;
  top: 4px !important;
  width: 32px !important;
}

.stake-in-NUTS .frame-410 {
  align-items: flex-start;
  display: inline-flex;
  gap: 187px;
  left: 72px;
  position: absolute;
  top: 24px;
}

.stake-in-NUTS .enter-NUTS-amounts {
  background-color: transparent;
  border: 0;
  color: var(--text-600);
  flex: 0 0 auto;
  font-family: "ABeeZee", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.32px;
  line-height: normal;
  margin-top: -1px;
  padding: 0;
  position: relative;
  white-space: nowrap;
}

.stake-in-NUTS .text-wrapper-223 {
  color: #000000;
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.stake-in-NUTS .frame-411 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  height: 66px;
  position: relative;
}

.stake-in-NUTS .frame-412 {
  height: 115px;
  margin-bottom: -49px;
  position: relative;
  width: 552px;
}

.stake-in-NUTS .frame-413 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  position: relative;
}

.stake-in-NUTS .frame-414 {
  background-color: var(--primary-500main);
  border: 1px solid;
  border-color: var(--primary-500main);
  border-radius: 10px;
  height: 66px;
  position: relative;
  width: 472px;
}

.stake-in-NUTS .text-wrapper-224 {
  color: #ffffff;
  font-family: "ABeeZee", Helvetica;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  left: 190px;
  letter-spacing: 0.32px;
  line-height: normal;
  position: absolute;
  top: 23px;
  white-space: nowrap;
}
