.success-share-on-tw {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.success-share-on-tw .div-6 {
  height: 1080px;
  overflow: hidden;
  position: relative;
  width: 1920px;
}

.success-share-on-tw .overlap {
  background-color: #ffffff;
  height: 2083px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1920px;
}

.success-share-on-tw .frame-113 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 80px;
  left: 92px;
  position: absolute;
  top: 150px;
}

.success-share-on-tw .frame-114 {
  height: 319px;
  position: relative;
  width: 1733px;
}

.success-share-on-tw .frame-115 {
  height: 187px;
  left: 0;
  position: absolute;
  top: 0;
  width: 557px;
}

.success-share-on-tw .group-32 {
  height: 50px;
  left: 0;
  position: absolute;
  top: 0;
  width: 238px;
}

.success-share-on-tw .frame-116 {
  align-items: center;
  background-color: var(--text-150);
  border-radius: 10px;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  padding: 16px 14px;
  position: relative;
}

.success-share-on-tw .text-wrapper-73 {
  color: var(--primary-500main);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.success-share-on-tw .frame-117 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 14px;
  left: 0;
  position: absolute;
  top: 64px;
}

.success-share-on-tw .stake-to-mint-GOO-3 {
  color: transparent;
  font-family: var(--h3-bold-font-family);
  font-size: var(--h3-bold-font-size);
  font-style: var(--h3-bold-font-style);
  font-weight: var(--h3-bold-font-weight);
  letter-spacing: var(--h3-bold-letter-spacing);
  line-height: var(--h3-bold-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.success-share-on-tw .text-wrapper-74 {
  color: #202020;
  font-family: var(--h3-bold-font-family);
  font-size: var(--h3-bold-font-size);
  font-style: var(--h3-bold-font-style);
  font-weight: var(--h3-bold-font-weight);
  letter-spacing: var(--h3-bold-letter-spacing);
  line-height: var(--h3-bold-line-height);
}

.success-share-on-tw .text-wrapper-75 {
  color: #ffba15;
  font-family: var(--h3-bold-font-family);
  font-size: var(--h3-bold-font-size);
  font-style: var(--h3-bold-font-style);
  font-weight: var(--h3-bold-font-weight);
  letter-spacing: var(--h3-bold-letter-spacing);
  line-height: var(--h3-bold-line-height);
}

.success-share-on-tw .text-wrapper-76 {
  color: var(--text-600);
  font-family: "ABeeZee", Helvetica;
  font-size: 28px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.success-share-on-tw .frame-118 {
  height: 72px;
  left: 0;
  position: absolute;
  top: 247px;
  width: 1733px;
}

.success-share-on-tw .frame-119 {
  height: 72px;
  left: 0;
  position: absolute;
  top: 0;
  width: 293px;
}

.success-share-on-tw .text-wrapper-77 {
  color: var(--text-600);
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  left: 0;
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.success-share-on-tw .frame-120 {
  align-items: center;
  display: inline-flex;
  gap: 4.66px;
  left: 0;
  position: absolute;
  top: 37px;
}

.success-share-on-tw .text-wrapper-78 {
  color: var(--text-900);
  font-family: var(--small-title-bold-font-family);
  font-size: var(--small-title-bold-font-size);
  font-style: var(--small-title-bold-font-style);
  font-weight: var(--small-title-bold-font-weight);
  letter-spacing: var(--small-title-bold-letter-spacing);
  line-height: var(--small-title-bold-line-height);
  margin-top: -1.17px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.success-share-on-tw .frame-121 {
  height: 72px;
  left: 480px;
  position: absolute;
  top: 0;
  width: 293px;
}

.success-share-on-tw .frame-122 {
  height: 72px;
  left: 960px;
  position: absolute;
  top: 0;
  width: 293px;
}

.success-share-on-tw .frame-123 {
  height: 72px;
  left: 1440px;
  position: absolute;
  top: 0;
  width: 293px;
}

.success-share-on-tw .user-profile-2 {
  align-items: flex-start;
  background-color: #fafafa;
  border-radius: 20px;
  display: inline-flex;
  gap: 180px;
  justify-content: flex-end;
  left: 799px;
  overflow: hidden;
  padding: 50px 84px;
  position: absolute;
  top: 7px;
}

.success-share-on-tw .nuts-2 {
  height: 72px;
  position: relative;
  width: 302px;
}

.success-share-on-tw .icons-9 {
  border: 1.36px solid !important;
  border-radius: 34px !important;
  height: 68px !important;
  left: 0 !important;
  position: absolute !important;
  top: 2px !important;
  width: 68px !important;
}

.success-share-on-tw .icons-10 {
  height: 52px !important;
  left: 7px !important;
  top: 7px !important;
  width: 52px !important;
}

.success-share-on-tw .element-3 {
  height: 72px;
  left: 78px;
  position: absolute;
  top: 0;
  width: 224px;
}

.success-share-on-tw .sol-3 {
  height: 72px;
  position: relative;
  width: 284px;
}

.success-share-on-tw .property-1-SOL-instance {
  height: 36px !important;
  left: 14px !important;
  top: 14px !important;
  width: 36px !important;
}

.success-share-on-tw .element-4 {
  height: 72px;
  left: 78px;
  position: absolute;
  top: 0;
  width: 206px;
}

.success-share-on-tw .frame-124 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  position: relative;
}

.success-share-on-tw .tabs-6 {
  position: relative !important;
}

.success-share-on-tw .tiers-3 {
  align-items: flex-start;
  background-color: #fafafa;
  border-radius: 20px;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 70px;
  padding: 50px 44px;
  position: relative;
}

.success-share-on-tw .frame-125 {
  height: 94px;
  position: relative;
  width: 1644px;
}

.success-share-on-tw .frame-126 {
  height: 60px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1644px;
}

.success-share-on-tw .frame-127 {
  align-items: center;
  display: inline-flex;
  gap: 10px;
  left: 0;
  position: absolute;
  top: 0;
}

.success-share-on-tw .frame-128 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.success-share-on-tw .icons-11 {
  height: 60px !important;
  position: relative !important;
  width: 60px !important;
}

.success-share-on-tw .icons-12 {
  margin-left: -13px !important;
}

.success-share-on-tw .div-7 {
  color: transparent;
  font-family: "ABeeZee", Helvetica;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.success-share-on-tw .text-wrapper-79 {
  color: #202020;
  font-style: italic;
}

.success-share-on-tw .text-wrapper-80 {
  color: #ffba15;
  font-style: italic;
}

.success-share-on-tw .text-wrapper-81 {
  color: #000000;
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  left: 1598px;
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  position: absolute;
  text-align: right;
  top: 18px;
  width: 46px;
}

.success-share-on-tw .component-5508-instance {
  left: 0 !important;
  position: absolute !important;
  top: 100px !important;
}

.success-share-on-tw .frame-129 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 1644px;
}

.success-share-on-tw .frame-130 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 78px;
  position: relative;
}

.success-share-on-tw .frame-131 {
  align-items: center;
  display: flex;
  gap: 10px;
  position: relative;
  width: 232px;
}

.success-share-on-tw .frame-132 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6px;
  position: relative;
}

.success-share-on-tw .text-wrapper-82 {
  color: var(--text-600);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.success-share-on-tw .frame-133 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4.66px;
  position: relative;
}

.success-share-on-tw .text-wrapper-83 {
  color: var(--text-900);
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  margin-top: -1.17px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.success-share-on-tw .wallet-11 {
  flex: 0 0 auto !important;
  padding: 16px !important;
}

.success-share-on-tw .wallet-12 {
  margin-top: -1px !important;
}

.success-share-on-tw .frame-134 {
  align-items: center;
  display: flex;
  justify-content: space-between;
  left: 0;
  position: absolute;
  top: 0;
  width: 1644px;
}

.success-share-on-tw .frame-135 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
}

.success-share-on-tw .icons-30 {
  height: 29px !important;
  position: relative !important;
  width: 60px !important;
}

.success-share-on-tw .text-wrapper-84 {
  color: #000000;
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  position: relative;
  text-align: right;
  width: 46px;
}

.success-share-on-tw .stake-NUTS-for-free-3 {
  color: var(--text-900);
  font-family: "ABeeZee", Helvetica;
  font-size: 24px;
  font-style: italic;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.success-share-on-tw .nav-wrapper {
  height: 100px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1920px;
}

.success-share-on-tw .nav-4 {
  background-image: url(../../../static/img/rectangle-2-5.svg);
  background-size: 100% 100%;
  height: 100px;
}

.success-share-on-tw .group-33 {
  height: 51px;
  left: 92px;
  position: relative;
  top: 24px;
  width: 1736px;
}

.success-share-on-tw .logo-4 {
  align-items: center;
  display: inline-flex;
  gap: 8px;
  left: 0;
  position: absolute;
  top: 0;
}

.success-share-on-tw .group-34 {
  height: 50px;
  position: relative;
  width: 50px;
}

.success-share-on-tw .frame-136 {
  background-color: #ffffff;
  border-radius: 24.82px;
  height: 50px;
}

.success-share-on-tw .group-35 {
  background-image: url(../../../static/img/vector.svg);
  background-size: 100% 100%;
  height: 29px;
  left: 10px;
  position: relative;
  top: 10px;
  width: 29px;
}

.success-share-on-tw .group-36 {
  background-color: #ffffff;
  border-radius: 3.63px;
  height: 7px;
  left: 9px;
  position: absolute;
  top: 10px;
  width: 7px;
}

.success-share-on-tw .ellipse-16 {
  background-color: #000000;
  border-radius: 2.66px;
  height: 5px;
  left: 1px;
  position: relative;
  top: 1px;
  width: 5px;
}

.success-share-on-tw .group-37 {
  background-color: #ffffff;
  border-radius: 3.63px;
  height: 7px;
  left: 17px;
  position: absolute;
  top: 3px;
  width: 7px;
}

.success-share-on-tw .group-38 {
  height: 32.88px;
  position: relative;
  width: 106.81px;
}

.success-share-on-tw .group-39 {
  height: 27px;
  left: 0;
  position: absolute;
  top: 6px;
  width: 18px;
}

.success-share-on-tw .group-40 {
  border: 3.66px solid;
  border-color: #000000;
  border-radius: 8.82px;
  height: 18px;
  left: 19px;
  position: absolute;
  top: 6px;
  width: 18px;
}

.success-share-on-tw .ellipse-17 {
  background-color: #000000;
  border-radius: 3.46px;
  height: 7px;
  left: 3px;
  position: relative;
  top: 5px;
  width: 7px;
}

.success-share-on-tw .group-41 {
  border: 3.66px solid;
  border-color: #000000;
  border-radius: 8.82px;
  height: 18px;
  left: 39px;
  position: absolute;
  top: 6px;
  width: 18px;
}

.success-share-on-tw .ellipse-18 {
  background-color: #000000;
  border-radius: 3.46px;
  height: 7px;
  left: 5px;
  position: relative;
  top: 5px;
  width: 7px;
}

.success-share-on-tw .group-42 {
  height: 24px;
  left: 58px;
  position: absolute;
  top: 0;
  width: 18px;
}

.success-share-on-tw .overlap-group-6 {
  height: 24px;
  position: relative;
}

.success-share-on-tw .ellipse-19 {
  border: 3.66px solid;
  border-color: #000000;
  border-radius: 8.82px;
  height: 18px;
  left: 0;
  position: absolute;
  top: 6px;
  width: 18px;
}

.success-share-on-tw .rectangle-9 {
  background-color: #000000;
  border-radius: 0px 0px 1.82px 1.82px;
  height: 17px;
  left: 0;
  position: absolute;
  top: 0;
  width: 4px;
}

.success-share-on-tw .subtract-4 {
  height: 18px;
  left: 77px;
  position: absolute;
  top: 6px;
  width: 18px;
}

.success-share-on-tw .frame-137 {
  align-items: flex-start;
  display: flex;
  height: 18px;
  left: 96px;
  position: absolute;
  top: 6px;
  width: 11px;
}

.success-share-on-tw .rectangle-10 {
  background-color: #000000;
  height: 17.65px;
  position: relative;
  transform: rotate(180deg);
  width: 3.64px;
}

.success-share-on-tw .ellipse-20 {
  background-color: #000000;
  border-radius: 3.46px;
  height: 6.91px;
  position: relative;
  width: 6.91px;
}

.success-share-on-tw .frame-138 {
  height: 18px;
  left: 657px;
  position: absolute;
  top: 16px;
  width: 429px;
}

.success-share-on-tw .text-wrapper-85 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  left: 0;
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.success-share-on-tw .text-wrapper-86 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  left: 103px;
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.success-share-on-tw .text-wrapper-87 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  left: 209px;
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.success-share-on-tw .text-wrapper-88 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  left: 341px;
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.success-share-on-tw .frame-139 {
  align-items: center;
  display: inline-flex;
  gap: 20px;
  left: 1414px;
  position: absolute;
  top: 1px;
}

.success-share-on-tw .frame-140 {
  height: 40px;
  position: relative;
  width: 114px;
}

.success-share-on-tw .twitter-5 {
  background-color: #1c1c1c;
  border-radius: 500px;
  height: 40px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 40px;
}

.success-share-on-tw .x-instance {
  height: 24px !important;
  left: 8px !important;
  position: absolute !important;
  top: 8px !important;
  width: 24px !important;
}

.success-share-on-tw .discord-6 {
  height: 40px;
  left: 64px;
  position: absolute;
  top: 0;
  width: 40px;
}

.success-share-on-tw .wallet-13 {
  flex: 0 0 auto !important;
}

.success-share-on-tw .rectangle-11 {
  height: 1080px;
  left: 0;
  position: fixed;
  top: 0;
  width: 1920px;
}

.success-share-on-tw .wallet-connect-2 {
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 20px;
  display: block;
  flex-direction: column;
  gap: 20px;
  left: 684px;
  opacity: 0;
  padding: 50px 0px;
  position: fixed;
  top: 342px;
  transform: scale(1.1);
}

.success-share-on-tw .frame-141 {
  height: 82px;
  position: relative;
  width: 551px;
}

.success-share-on-tw .frame-142 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 12px;
  padding: 0px 40px;
  position: relative;
}

.success-share-on-tw .frame-143 {
  height: 32px;
  position: relative;
  width: 471px;
}

.success-share-on-tw .frame-144 {
  height: 32px;
  position: relative;
}

.success-share-on-tw .text-wrapper-89 {
  color: var(--text-900);
  font-family: var(--small-title-bold-font-family);
  font-size: var(--small-title-bold-font-size);
  font-style: var(--small-title-bold-font-style);
  font-weight: var(--small-title-bold-font-weight);
  left: 0;
  letter-spacing: var(--small-title-bold-letter-spacing);
  line-height: var(--small-title-bold-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.success-share-on-tw .property-1-variant7 {
  height: 24px !important;
  left: 447px !important;
  position: absolute !important;
  top: 4px !important;
  width: 24px !important;
}

.success-share-on-tw .you-ve-staked {
  color: var(--text-900);
  font-family: "ABeeZee", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.32px;
  line-height: normal;
  position: relative;
  width: 471px;
}

.success-share-on-tw .text-wrapper-90 {
  letter-spacing: 0.05px;
}

.success-share-on-tw .text-wrapper-91 {
  font-style: italic;
  letter-spacing: 0.05px;
}

.success-share-on-tw .frame-145 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  height: 108px;
  position: relative;
}

.success-share-on-tw .frame-146 {
  height: 108px;
  position: relative;
  width: 552px;
}

.success-share-on-tw .frame-147 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  position: relative;
}

.success-share-on-tw .frame-148 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  padding: 0px 40px;
  position: relative;
}

.success-share-on-tw .frame-149 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 12px;
  height: 108px;
  position: relative;
}

.success-share-on-tw .GOO-staking-is-now-wrapper {
  background-color: #ffffff;
  border: 1px solid;
  border-color: var(--primary-500main);
  border-radius: 10px;
  height: 108px;
  position: relative;
  width: 472px;
}

.success-share-on-tw .GOO-staking-is-now {
  background-color: transparent;
  border: 0;
  color: var(--text-900);
  font-family: "ABeeZee", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 76px;
  left: 17px;
  letter-spacing: 0.32px;
  line-height: normal;
  padding: 0;
  position: relative;
  top: 17px;
  width: 439px;
}

.success-share-on-tw .frame-150 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  height: 66px;
  position: relative;
}

.success-share-on-tw .frame-151 {
  height: 115px;
  margin-bottom: -49px;
  position: relative;
  width: 552px;
}

.success-share-on-tw .frame-152 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  position: relative;
}

.success-share-on-tw .frame-153 {
  background-color: var(--text-900);
  border: 1px solid;
  border-color: var(--text-900);
  border-radius: 10px;
  height: 66px;
  position: relative;
  width: 472px;
}

.success-share-on-tw .frame-154 {
  align-items: center;
  display: inline-flex;
  gap: 8px;
  justify-content: center;
  left: 141px;
  position: relative;
  top: 13px;
}

.success-share-on-tw .property-1-twitter {
  border: 0.8px solid !important;
  border-radius: 20px !important;
  height: 40px !important;
  position: relative !important;
  width: 40px !important;
}

.success-share-on-tw .property-twitter-instance {
  border-radius: 333.33px !important;
  height: 27px !important;
  left: 6px !important;
  top: 6px !important;
  width: 27px !important;
}

.success-share-on-tw .property-1-twitter-instance {
  height: 16px !important;
  left: 5px !important;
  position: absolute !important;
  top: 5px !important;
  width: 16px !important;
}

.success-share-on-tw .text-wrapper-92 {
  color: #ffffff;
  font-family: "ABeeZee", Helvetica;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  letter-spacing: 0.32px;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}
