.stake-in-SOL {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.stake-in-SOL .div-17 {
  height: 1080px;
  overflow: hidden;
  position: relative;
  width: 1920px;
}

.stake-in-SOL .overlap-10 {
  background-color: #ffffff;
  height: 2083px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1920px;
}

.stake-in-SOL .frame-459 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 80px;
  left: 92px;
  position: absolute;
  top: 150px;
}

.stake-in-SOL .frame-460 {
  height: 319px;
  position: relative;
  width: 1733px;
}

.stake-in-SOL .frame-461 {
  height: 187px;
  left: 0;
  position: absolute;
  top: 0;
  width: 557px;
}

.stake-in-SOL .group-155 {
  height: 50px;
  left: 0;
  position: absolute;
  top: 0;
  width: 238px;
}

.stake-in-SOL .frame-462 {
  align-items: center;
  background-color: var(--text-150);
  border-radius: 10px;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  padding: 16px 14px;
  position: relative;
}

.stake-in-SOL .text-wrapper-253 {
  color: var(--primary-500main);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.stake-in-SOL .frame-463 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 14px;
  left: 0;
  position: absolute;
  top: 64px;
}

.stake-in-SOL .stake-to-mint-GOO-14 {
  color: transparent;
  font-family: var(--h3-bold-font-family);
  font-size: var(--h3-bold-font-size);
  font-style: var(--h3-bold-font-style);
  font-weight: var(--h3-bold-font-weight);
  letter-spacing: var(--h3-bold-letter-spacing);
  line-height: var(--h3-bold-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.stake-in-SOL .text-wrapper-254 {
  color: #202020;
  font-family: var(--h3-bold-font-family);
  font-size: var(--h3-bold-font-size);
  font-style: var(--h3-bold-font-style);
  font-weight: var(--h3-bold-font-weight);
  letter-spacing: var(--h3-bold-letter-spacing);
  line-height: var(--h3-bold-line-height);
}

.stake-in-SOL .text-wrapper-255 {
  color: #ffba15;
  font-family: var(--h3-bold-font-family);
  font-size: var(--h3-bold-font-size);
  font-style: var(--h3-bold-font-style);
  font-weight: var(--h3-bold-font-weight);
  letter-spacing: var(--h3-bold-letter-spacing);
  line-height: var(--h3-bold-line-height);
}

.stake-in-SOL .text-wrapper-256 {
  color: var(--text-600);
  font-family: "ABeeZee", Helvetica;
  font-size: 28px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.stake-in-SOL .frame-464 {
  height: 72px;
  left: 0;
  position: absolute;
  top: 247px;
  width: 1733px;
}

.stake-in-SOL .frame-465 {
  height: 72px;
  left: 0;
  position: absolute;
  top: 0;
  width: 293px;
}

.stake-in-SOL .text-wrapper-257 {
  color: var(--text-600);
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  left: 0;
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.stake-in-SOL .frame-466 {
  align-items: center;
  display: inline-flex;
  gap: 4.66px;
  left: 0;
  position: absolute;
  top: 37px;
}

.stake-in-SOL .text-wrapper-258 {
  color: var(--text-900);
  font-family: var(--small-title-bold-font-family);
  font-size: var(--small-title-bold-font-size);
  font-style: var(--small-title-bold-font-style);
  font-weight: var(--small-title-bold-font-weight);
  letter-spacing: var(--small-title-bold-letter-spacing);
  line-height: var(--small-title-bold-line-height);
  margin-top: -1.17px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.stake-in-SOL .frame-467 {
  height: 72px;
  left: 480px;
  position: absolute;
  top: 0;
  width: 293px;
}

.stake-in-SOL .frame-468 {
  height: 72px;
  left: 960px;
  position: absolute;
  top: 0;
  width: 293px;
}

.stake-in-SOL .frame-469 {
  height: 72px;
  left: 1440px;
  position: absolute;
  top: 0;
  width: 293px;
}

.stake-in-SOL .user-profile-8 {
  align-items: flex-start;
  background-color: #fafafa;
  border-radius: 20px;
  display: inline-flex;
  gap: 180px;
  justify-content: flex-end;
  left: 799px;
  overflow: hidden;
  padding: 50px 84px;
  position: absolute;
  top: 7px;
}

.stake-in-SOL .nuts-8 {
  height: 72px;
  position: relative;
  width: 302px;
}

.stake-in-SOL .icons-57 {
  border: 1.36px solid !important;
  border-radius: 34px !important;
  height: 68px !important;
  left: 0 !important;
  position: absolute !important;
  top: 2px !important;
  width: 68px !important;
}

.stake-in-SOL .icons-58 {
  height: 52px !important;
  left: 7px !important;
  top: 7px !important;
  width: 52px !important;
}

.stake-in-SOL .element-16 {
  height: 72px;
  left: 78px;
  position: absolute;
  top: 0;
  width: 224px;
}

.stake-in-SOL .sol-9 {
  height: 72px;
  position: relative;
  width: 284px;
}

.stake-in-SOL .icons-59 {
  height: 36px !important;
  left: 14px !important;
  top: 14px !important;
  width: 36px !important;
}

.stake-in-SOL .element-17 {
  height: 72px;
  left: 78px;
  position: absolute;
  top: 0;
  width: 206px;
}

.stake-in-SOL .frame-470 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  position: relative;
}

.stake-in-SOL .design-component-instance-node-5 {
  position: relative !important;
}

.stake-in-SOL .tiers-13 {
  align-items: flex-start;
  background-color: #fafafa;
  border-radius: 20px;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 70px;
  padding: 50px 44px;
  position: relative;
}

.stake-in-SOL .frame-471 {
  height: 94px;
  position: relative;
  width: 1644px;
}

.stake-in-SOL .frame-472 {
  height: 60px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1644px;
}

.stake-in-SOL .frame-473 {
  align-items: center;
  display: inline-flex;
  gap: 10px;
  left: 0;
  position: absolute;
  top: 0;
}

.stake-in-SOL .frame-474 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.stake-in-SOL .icons-60 {
  margin-left: -13px !important;
}

.stake-in-SOL .div-18 {
  color: transparent;
  font-family: "ABeeZee", Helvetica;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.stake-in-SOL .text-wrapper-259 {
  color: #202020;
  font-style: italic;
}

.stake-in-SOL .text-wrapper-260 {
  color: #ffba15;
  font-style: italic;
}

.stake-in-SOL .text-wrapper-261 {
  color: #000000;
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  left: 1598px;
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  position: absolute;
  text-align: right;
  top: 18px;
  width: 46px;
}

.stake-in-SOL .view-8 {
  left: 0 !important;
  position: absolute !important;
  top: 100px !important;
}

.stake-in-SOL .frame-475 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 1644px;
}

.stake-in-SOL .frame-476 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 78px;
  position: relative;
}

.stake-in-SOL .frame-477 {
  align-items: center;
  display: flex;
  gap: 10px;
  position: relative;
  width: 232px;
}

.stake-in-SOL .frame-478 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6px;
  position: relative;
}

.stake-in-SOL .text-wrapper-262 {
  color: var(--text-600);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.stake-in-SOL .frame-479 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4.66px;
  position: relative;
}

.stake-in-SOL .text-wrapper-263 {
  color: var(--text-900);
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  margin-top: -1.17px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.stake-in-SOL .icons-61 {
  height: 34px !important;
  left: 12px !important;
  position: absolute !important;
  top: 12px !important;
  width: 34px !important;
}

.stake-in-SOL .wallet-34 {
  flex: 0 0 auto !important;
  padding: 16px !important;
}

.stake-in-SOL .wallet-35 {
  margin-top: -1px !important;
}

.stake-in-SOL .frame-480 {
  align-items: center;
  display: flex;
  justify-content: space-between;
  left: 0;
  position: absolute;
  top: 0;
  width: 1644px;
}

.stake-in-SOL .frame-481 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
}

.stake-in-SOL .text-wrapper-264 {
  color: #000000;
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  position: relative;
  text-align: right;
  width: 46px;
}

.stake-in-SOL .stake-NUTS-for-free-12 {
  color: var(--text-900);
  font-family: "ABeeZee", Helvetica;
  font-size: 24px;
  font-style: italic;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.stake-in-SOL .nav-20 {
  height: 100px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1920px;
}

.stake-in-SOL .nav-21 {
  background-image: url(../../../static/img/rectangle-2-12.svg);
  background-size: 100% 100%;
  height: 100px;
}

.stake-in-SOL .group-156 {
  height: 51px;
  left: 92px;
  position: relative;
  top: 24px;
  width: 1736px;
}

.stake-in-SOL .logo-15 {
  align-items: center;
  display: inline-flex;
  gap: 8px;
  left: 0;
  position: absolute;
  top: 0;
}

.stake-in-SOL .group-157 {
  height: 50px;
  position: relative;
  width: 50px;
}

.stake-in-SOL .frame-482 {
  background-color: #ffffff;
  border-radius: 24.82px;
  height: 50px;
}

.stake-in-SOL .group-158 {
  background-image: url(../../../static/img/vector.svg);
  background-size: 100% 100%;
  height: 29px;
  left: 10px;
  position: relative;
  top: 10px;
  width: 29px;
}

.stake-in-SOL .group-159 {
  background-color: #ffffff;
  border-radius: 3.63px;
  height: 7px;
  left: 9px;
  position: absolute;
  top: 10px;
  width: 7px;
}

.stake-in-SOL .ellipse-71 {
  background-color: #000000;
  border-radius: 2.66px;
  height: 5px;
  left: 1px;
  position: relative;
  top: 1px;
  width: 5px;
}

.stake-in-SOL .group-160 {
  background-color: #ffffff;
  border-radius: 3.63px;
  height: 7px;
  left: 17px;
  position: absolute;
  top: 3px;
  width: 7px;
}

.stake-in-SOL .group-161 {
  height: 32.88px;
  position: relative;
  width: 106.81px;
}

.stake-in-SOL .group-162 {
  height: 27px;
  left: 0;
  position: absolute;
  top: 6px;
  width: 18px;
}

.stake-in-SOL .group-163 {
  border: 3.66px solid;
  border-color: #000000;
  border-radius: 8.82px;
  height: 18px;
  left: 19px;
  position: absolute;
  top: 6px;
  width: 18px;
}

.stake-in-SOL .ellipse-72 {
  background-color: #000000;
  border-radius: 3.46px;
  height: 7px;
  left: 3px;
  position: relative;
  top: 5px;
  width: 7px;
}

.stake-in-SOL .group-164 {
  border: 3.66px solid;
  border-color: #000000;
  border-radius: 8.82px;
  height: 18px;
  left: 39px;
  position: absolute;
  top: 6px;
  width: 18px;
}

.stake-in-SOL .ellipse-73 {
  background-color: #000000;
  border-radius: 3.46px;
  height: 7px;
  left: 5px;
  position: relative;
  top: 5px;
  width: 7px;
}

.stake-in-SOL .group-165 {
  height: 24px;
  left: 58px;
  position: absolute;
  top: 0;
  width: 18px;
}

.stake-in-SOL .overlap-group-17 {
  height: 24px;
  position: relative;
}

.stake-in-SOL .ellipse-74 {
  border: 3.66px solid;
  border-color: #000000;
  border-radius: 8.82px;
  height: 18px;
  left: 0;
  position: absolute;
  top: 6px;
  width: 18px;
}

.stake-in-SOL .rectangle-38 {
  background-color: #000000;
  border-radius: 0px 0px 1.82px 1.82px;
  height: 17px;
  left: 0;
  position: absolute;
  top: 0;
  width: 4px;
}

.stake-in-SOL .subtract-15 {
  height: 18px;
  left: 77px;
  position: absolute;
  top: 6px;
  width: 18px;
}

.stake-in-SOL .frame-483 {
  align-items: flex-start;
  display: flex;
  height: 18px;
  left: 96px;
  position: absolute;
  top: 6px;
  width: 11px;
}

.stake-in-SOL .rectangle-39 {
  background-color: #000000;
  height: 17.65px;
  position: relative;
  transform: rotate(180deg);
  width: 3.64px;
}

.stake-in-SOL .ellipse-75 {
  background-color: #000000;
  border-radius: 3.46px;
  height: 6.91px;
  position: relative;
  width: 6.91px;
}

.stake-in-SOL .frame-484 {
  height: 18px;
  left: 657px;
  position: absolute;
  top: 16px;
  width: 429px;
}

.stake-in-SOL .text-wrapper-265 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  left: 0;
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.stake-in-SOL .text-wrapper-266 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  left: 103px;
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.stake-in-SOL .text-wrapper-267 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  left: 209px;
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.stake-in-SOL .text-wrapper-268 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  left: 341px;
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.stake-in-SOL .frame-485 {
  align-items: center;
  display: inline-flex;
  gap: 20px;
  left: 1414px;
  position: absolute;
  top: 1px;
}

.stake-in-SOL .frame-486 {
  height: 40px;
  position: relative;
  width: 114px;
}

.stake-in-SOL .twitter-13 {
  background-color: #1c1c1c;
  border-radius: 500px;
  height: 40px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 40px;
}

.stake-in-SOL .x-9 {
  height: 24px !important;
  left: 8px !important;
  position: absolute !important;
  top: 8px !important;
  width: 24px !important;
}

.stake-in-SOL .discord-15 {
  height: 40px;
  left: 64px;
  position: absolute;
  top: 0;
  width: 40px;
}

.stake-in-SOL .wallet-36 {
  flex: 0 0 auto !important;
}

.stake-in-SOL .iconify-1-instance {
  height: 24px !important;
  position: relative !important;
  width: 24px !important;
}

.stake-in-SOL .rectangle-40 {
  height: 1080px;
  left: 0;
  position: fixed;
  top: 0;
  width: 1920px;
}

.stake-in-SOL .wallet-connect-6 {
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 20px;
  display: inline-flex;
  flex-direction: column;
  gap: 20px;
  left: 684px;
  padding: 50px 0px;
  position: fixed;
  top: 359px;
}

.stake-in-SOL .frame-487 {
  height: 62px;
  position: relative;
  width: 551px;
}

.stake-in-SOL .frame-488 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 12px;
  padding: 0px 40px;
  position: relative;
}

.stake-in-SOL .frame-489 {
  height: 32px;
  position: relative;
  width: 471px;
}

.stake-in-SOL .frame-490 {
  height: 32px;
  position: relative;
}

.stake-in-SOL .text-wrapper-269 {
  color: var(--text-900);
  font-family: var(--small-title-bold-font-family);
  font-size: var(--small-title-bold-font-size);
  font-style: var(--small-title-bold-font-style);
  font-weight: var(--small-title-bold-font-weight);
  left: 0;
  letter-spacing: var(--small-title-bold-letter-spacing);
  line-height: var(--small-title-bold-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.stake-in-SOL .iconify-89 {
  height: 24px !important;
  left: 447px !important;
  position: absolute !important;
  top: 4px !important;
  width: 24px !important;
}

.stake-in-SOL .please-note-you-can-3 {
  color: var(--text-900);
  font-family: "ABeeZee", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.32px;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.stake-in-SOL .text-wrapper-270 {
  letter-spacing: 0.05px;
}

.stake-in-SOL .text-wrapper-271 {
  font-style: italic;
  letter-spacing: 0.05px;
}

.stake-in-SOL .frame-491 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  height: 94px;
  position: relative;
}

.stake-in-SOL .frame-492 {
  height: 144px;
  margin-bottom: -50px;
  position: relative;
  width: 552px;
}

.stake-in-SOL .frame-493 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  position: relative;
}

.stake-in-SOL .frame-494 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  padding: 0px 40px;
  position: relative;
}

.stake-in-SOL .frame-495 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  position: relative;
}

.stake-in-SOL .frame-496 {
  height: 18px;
  position: relative;
  width: 472px;
}

.stake-in-SOL .frame-497 {
  height: 18px;
  left: 0;
  position: absolute;
  top: 0;
  width: 129px;
}

.stake-in-SOL .text-wrapper-272 {
  color: var(--text-600);
  font-family: "ABeeZee", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0.32px;
  line-height: normal;
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.stake-in-SOL .text-wrapper-273 {
  color: #000000;
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  left: 80px;
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.stake-in-SOL .frame-498 {
  height: 18px;
  left: 320px;
  position: absolute;
  top: 0;
  width: 152px;
}

.stake-in-SOL .text-wrapper-274 {
  color: var(--text-600);
  font-family: "ABeeZee", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 32px;
  letter-spacing: 0.32px;
  line-height: normal;
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.stake-in-SOL .text-wrapper-275 {
  color: #000000;
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  left: 110px;
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.stake-in-SOL .frame-499 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: var(--primary-500main);
  border-radius: 10px;
  height: 66px;
  position: relative;
  width: 472px;
}

.stake-in-SOL .icons-62 {
  border: 0.84px solid !important;
  border-radius: 21px !important;
  height: 42px !important;
  left: 20px !important;
  position: absolute !important;
  top: 12px !important;
  width: 42px !important;
}

.stake-in-SOL .icons-63 {
  height: 22px !important;
  left: 9px !important;
  top: 9px !important;
  width: 22px !important;
}

.stake-in-SOL .frame-500 {
  align-items: flex-start;
  display: inline-flex;
  gap: 187px;
  left: 72px;
  position: absolute;
  top: 24px;
}

.stake-in-SOL .text-wrapper-276 {
  color: var(--text-600);
  font-family: "ABeeZee", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.32px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.stake-in-SOL .text-wrapper-277 {
  color: #000000;
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.stake-in-SOL .frame-501 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  height: 66px;
  position: relative;
}

.stake-in-SOL .frame-502 {
  height: 115px;
  margin-bottom: -49px;
  position: relative;
  width: 552px;
}

.stake-in-SOL .frame-503 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  position: relative;
}

.stake-in-SOL .frame-504 {
  background-color: var(--primary-500main);
  border: 1px solid;
  border-color: var(--primary-500main);
  border-radius: 10px;
  height: 66px;
  position: relative;
  width: 472px;
}

.stake-in-SOL .text-wrapper-278 {
  color: #ffffff;
  font-family: "ABeeZee", Helvetica;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  left: 190px;
  letter-spacing: 0.32px;
  line-height: normal;
  position: absolute;
  top: 23px;
  white-space: nowrap;
}
