.stake-screen {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.stake-screen .stake-2 {
  height: 2067px;
  overflow: hidden;
  width: 1920px;
}

.stake-screen .overlap-9 {
  background-color: #ffffff;
  height: 2083px;
  position: relative;
}

.stake-screen .frame-431 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 80px;
  left: 92px;
  position: absolute;
  top: 150px;
}

.stake-screen .frame-432 {
  height: 319px;
  position: relative;
  width: 1733px;
}

.stake-screen .frame-433 {
  height: 187px;
  left: 0;
  position: absolute;
  top: 0;
  width: 557px;
}

.stake-screen .group-144 {
  height: 50px;
  left: 0;
  position: absolute;
  top: 0;
  width: 238px;
}

.stake-screen .frame-434 {
  align-items: center;
  background-color: var(--text-150);
  border-radius: 10px;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  padding: 16px 14px;
  position: relative;
}

.stake-screen .text-wrapper-237 {
  color: var(--primary-500main);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.stake-screen .frame-435 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 14px;
  left: 0;
  position: absolute;
  top: 64px;
}

.stake-screen .stake-to-mint-GOO-13 {
  color: transparent;
  font-family: var(--h3-bold-font-family);
  font-size: var(--h3-bold-font-size);
  font-style: var(--h3-bold-font-style);
  font-weight: var(--h3-bold-font-weight);
  letter-spacing: var(--h3-bold-letter-spacing);
  line-height: var(--h3-bold-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.stake-screen .text-wrapper-238 {
  color: #202020;
  font-family: var(--h3-bold-font-family);
  font-size: var(--h3-bold-font-size);
  font-style: var(--h3-bold-font-style);
  font-weight: var(--h3-bold-font-weight);
  letter-spacing: var(--h3-bold-letter-spacing);
  line-height: var(--h3-bold-line-height);
}

.stake-screen .text-wrapper-239 {
  color: #ffba15;
  font-family: var(--h3-bold-font-family);
  font-size: var(--h3-bold-font-size);
  font-style: var(--h3-bold-font-style);
  font-weight: var(--h3-bold-font-weight);
  letter-spacing: var(--h3-bold-letter-spacing);
  line-height: var(--h3-bold-line-height);
}

.stake-screen .text-wrapper-240 {
  color: var(--text-600);
  font-family: "ABeeZee", Helvetica;
  font-size: 28px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.stake-screen .frame-436 {
  height: 72px;
  left: 0;
  position: absolute;
  top: 247px;
  width: 1733px;
}

.stake-screen .frame-437 {
  height: 72px;
  left: 0;
  position: absolute;
  top: 0;
  width: 293px;
}

.stake-screen .text-wrapper-241 {
  color: var(--text-600);
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  left: 0;
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.stake-screen .frame-438 {
  align-items: center;
  display: inline-flex;
  gap: 4.66px;
  left: 0;
  position: absolute;
  top: 37px;
}

.stake-screen .text-wrapper-242 {
  color: var(--text-900);
  font-family: var(--small-title-bold-font-family);
  font-size: var(--small-title-bold-font-size);
  font-style: var(--small-title-bold-font-style);
  font-weight: var(--small-title-bold-font-weight);
  letter-spacing: var(--small-title-bold-letter-spacing);
  line-height: var(--small-title-bold-line-height);
  margin-top: -1.17px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.stake-screen .frame-439 {
  height: 72px;
  left: 480px;
  position: absolute;
  top: 0;
  width: 293px;
}

.stake-screen .frame-440 {
  height: 72px;
  left: 960px;
  position: absolute;
  top: 0;
  width: 293px;
}

.stake-screen .frame-441 {
  height: 72px;
  left: 1440px;
  position: absolute;
  top: 0;
  width: 293px;
}

.stake-screen .user-profile-7 {
  align-items: flex-start;
  background-color: #fafafa;
  border-radius: 20px;
  display: inline-flex;
  gap: 180px;
  justify-content: flex-end;
  left: 799px;
  overflow: hidden;
  padding: 50px 84px;
  position: absolute;
  top: 7px;
}

.stake-screen .nuts-7 {
  height: 72px;
  position: relative;
  width: 302px;
}

.stake-screen .icons-50 {
  border: 1.36px solid !important;
  border-radius: 34px !important;
  height: 68px !important;
  left: 0 !important;
  position: absolute !important;
  top: 2px !important;
  width: 68px !important;
}

.stake-screen .icons-51 {
  height: 52px !important;
  left: 7px !important;
  top: 7px !important;
  width: 52px !important;
}

.stake-screen .element-14 {
  height: 72px;
  left: 78px;
  position: absolute;
  top: 0;
  width: 224px;
}

.stake-screen .sol-8 {
  height: 72px;
  position: relative;
  width: 284px;
}

.stake-screen .icons-52 {
  height: 36px !important;
  left: 14px !important;
  top: 14px !important;
  width: 36px !important;
}

.stake-screen .element-15 {
  height: 72px;
  left: 78px;
  position: absolute;
  top: 0;
  width: 206px;
}

.stake-screen .frame-442 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  position: relative;
}

.stake-screen .design-component-instance-node-4 {
  position: relative !important;
}

.stake-screen .tiers-12 {
  align-items: flex-start;
  background-color: #fafafa;
  border-radius: 20px;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 70px;
  padding: 50px 44px;
  position: relative;
}

.stake-screen .frame-443 {
  height: 94px;
  position: relative;
  width: 1644px;
}

.stake-screen .frame-444 {
  height: 60px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1644px;
}

.stake-screen .frame-445 {
  align-items: center;
  display: inline-flex;
  gap: 10px;
  left: 0;
  position: absolute;
  top: 0;
}

.stake-screen .frame-446 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.stake-screen .icons-55 {
  height: 60px !important;
  position: relative !important;
  width: 60px !important;
}

.stake-screen .icons-56 {
  margin-left: -13px !important;
}

.stake-screen .div-16 {
  color: transparent;
  font-family: "ABeeZee", Helvetica;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.stake-screen .text-wrapper-243 {
  color: #202020;
  font-style: italic;
}

.stake-screen .text-wrapper-244 {
  color: #ffba15;
  font-style: italic;
}

.stake-screen .text-wrapper-245 {
  color: #000000;
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  left: 1598px;
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  position: absolute;
  text-align: right;
  top: 18px;
  width: 46px;
}

.stake-screen .view-7 {
  left: 0 !important;
  position: absolute !important;
  top: 100px !important;
}

.stake-screen .frame-447 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 1644px;
}

.stake-screen .frame-448 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 78px;
  position: relative;
}

.stake-screen .frame-449 {
  align-items: center;
  display: flex;
  gap: 10px;
  position: relative;
  width: 232px;
}

.stake-screen .property-1-money-bill-wave-alt-instance {
  height: 34px !important;
  left: 12px !important;
  position: absolute !important;
  top: 12px !important;
  width: 34px !important;
}

.stake-screen .frame-450 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6px;
  position: relative;
}

.stake-screen .text-wrapper-246 {
  color: var(--text-600);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.stake-screen .frame-451 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4.66px;
  position: relative;
}

.stake-screen .text-wrapper-247 {
  color: var(--text-900);
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  margin-top: -1.17px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.stake-screen .wallet-31 {
  flex: 0 0 auto !important;
  padding: 16px !important;
}

.stake-screen .wallet-32 {
  margin-top: -1px !important;
}

.stake-screen .frame-452 {
  align-items: center;
  display: flex;
  justify-content: space-between;
  left: 0;
  position: absolute;
  top: 0;
  width: 1644px;
}

.stake-screen .frame-453 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
}

.stake-screen .text-wrapper-248 {
  color: #000000;
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  position: relative;
  text-align: right;
  width: 46px;
}

.stake-screen .stake-NUTS-for-free-11 {
  color: var(--text-900);
  font-family: "ABeeZee", Helvetica;
  font-size: 24px;
  font-style: italic;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.stake-screen .footer-15 {
  left: 0 !important;
  position: absolute !important;
  top: 1789px !important;
}

.stake-screen .footer-16 {
  left: 656px !important;
}

.stake-screen .nav-18 {
  height: 100px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1920px;
}

.stake-screen .nav-19 {
  background-image: url(../../../static/img/image.svg);
  background-size: 100% 100%;
  height: 100px;
}

.stake-screen .group-145 {
  height: 51px;
  left: 92px;
  position: relative;
  top: 24px;
  width: 1736px;
}

.stake-screen .logo-14 {
  align-items: center;
  display: inline-flex;
  gap: 8px;
  left: 0;
  position: absolute;
  top: 0;
}

.stake-screen .group-146 {
  height: 50px;
  position: relative;
  width: 50px;
}

.stake-screen .frame-454 {
  background-color: #ffffff;
  border-radius: 24.82px;
  height: 50px;
}

.stake-screen .group-147 {
  background-image: url(../../../static/img/vector.svg);
  background-size: 100% 100%;
  height: 29px;
  left: 10px;
  position: relative;
  top: 10px;
  width: 29px;
}

.stake-screen .group-148 {
  background-color: #ffffff;
  border-radius: 3.63px;
  height: 7px;
  left: 9px;
  position: absolute;
  top: 10px;
  width: 7px;
}

.stake-screen .ellipse-66 {
  background-color: #000000;
  border-radius: 2.66px;
  height: 5px;
  left: 1px;
  position: relative;
  top: 1px;
  width: 5px;
}

.stake-screen .group-149 {
  background-color: #ffffff;
  border-radius: 3.63px;
  height: 7px;
  left: 17px;
  position: absolute;
  top: 3px;
  width: 7px;
}

.stake-screen .group-150 {
  height: 32.88px;
  position: relative;
  width: 106.81px;
}

.stake-screen .group-151 {
  height: 27px;
  left: 0;
  position: absolute;
  top: 6px;
  width: 18px;
}

.stake-screen .group-152 {
  border: 3.66px solid;
  border-color: #000000;
  border-radius: 8.82px;
  height: 18px;
  left: 19px;
  position: absolute;
  top: 6px;
  width: 18px;
}

.stake-screen .ellipse-67 {
  background-color: #000000;
  border-radius: 3.46px;
  height: 7px;
  left: 3px;
  position: relative;
  top: 5px;
  width: 7px;
}

.stake-screen .group-153 {
  border: 3.66px solid;
  border-color: #000000;
  border-radius: 8.82px;
  height: 18px;
  left: 39px;
  position: absolute;
  top: 6px;
  width: 18px;
}

.stake-screen .ellipse-68 {
  background-color: #000000;
  border-radius: 3.46px;
  height: 7px;
  left: 5px;
  position: relative;
  top: 5px;
  width: 7px;
}

.stake-screen .group-154 {
  height: 24px;
  left: 58px;
  position: absolute;
  top: 0;
  width: 18px;
}

.stake-screen .overlap-group-16 {
  height: 24px;
  position: relative;
}

.stake-screen .ellipse-69 {
  border: 3.66px solid;
  border-color: #000000;
  border-radius: 8.82px;
  height: 18px;
  left: 0;
  position: absolute;
  top: 6px;
  width: 18px;
}

.stake-screen .rectangle-36 {
  background-color: #000000;
  border-radius: 0px 0px 1.82px 1.82px;
  height: 17px;
  left: 0;
  position: absolute;
  top: 0;
  width: 4px;
}

.stake-screen .subtract-14 {
  height: 18px;
  left: 77px;
  position: absolute;
  top: 6px;
  width: 18px;
}

.stake-screen .frame-455 {
  align-items: flex-start;
  display: flex;
  height: 18px;
  left: 96px;
  position: absolute;
  top: 6px;
  width: 11px;
}

.stake-screen .rectangle-37 {
  background-color: #000000;
  height: 17.65px;
  position: relative;
  transform: rotate(180deg);
  width: 3.64px;
}

.stake-screen .ellipse-70 {
  background-color: #000000;
  border-radius: 3.46px;
  height: 6.91px;
  position: relative;
  width: 6.91px;
}

.stake-screen .frame-456 {
  height: 18px;
  left: 657px;
  position: absolute;
  top: 16px;
  width: 429px;
}

.stake-screen .text-wrapper-249 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  left: 0;
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.stake-screen .text-wrapper-250 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  left: 103px;
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.stake-screen .text-wrapper-251 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  left: 209px;
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.stake-screen .text-wrapper-252 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  left: 341px;
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.stake-screen .frame-457 {
  align-items: center;
  display: inline-flex;
  gap: 20px;
  left: 1414px;
  position: absolute;
  top: 1px;
}

.stake-screen .frame-458 {
  height: 40px;
  position: relative;
  width: 114px;
}

.stake-screen .twitter-12 {
  background-color: #1c1c1c;
  border-radius: 500px;
  height: 40px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 40px;
}

.stake-screen .x-8 {
  height: 24px !important;
  left: 8px !important;
  position: absolute !important;
  top: 8px !important;
  width: 24px !important;
}

.stake-screen .discord-14 {
  height: 40px;
  left: 64px;
  position: absolute;
  top: 0;
  width: 40px;
}

.stake-screen .wallet-33 {
  flex: 0 0 auto !important;
}

.stake-screen .iconify-1 {
  height: 24px !important;
  position: relative !important;
  width: 24px !important;
}
