.mobile-nav {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.mobile-nav .mobile-nav-status {
  position: relative;
}

.mobile-nav .frame-79 {
  height: 319px;
  left: 92px;
  position: absolute;
  top: 150px;
  width: 1733px;
}

.mobile-nav .frame-80 {
  height: 187px;
  left: 0;
  position: absolute;
  top: 0;
  width: 557px;
}

.mobile-nav .group-12 {
  height: 50px;
  left: 0;
  position: absolute;
  top: 0;
  width: 238px;
}

.mobile-nav .frame-81 {
  align-items: center;
  background-color: var(--text-150);
  border-radius: 10px;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  padding: 16px 14px;
  position: relative;
}

.mobile-nav .text-wrapper-52 {
  color: var(--primary-500main);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.mobile-nav .frame-82 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 14px;
  left: 0;
  position: absolute;
  top: 64px;
}

.mobile-nav .stake-to-mint-GOO-2 {
  color: transparent;
  font-family: var(--h3-bold-font-family);
  font-size: var(--h3-bold-font-size);
  font-style: var(--h3-bold-font-style);
  font-weight: var(--h3-bold-font-weight);
  letter-spacing: var(--h3-bold-letter-spacing);
  line-height: var(--h3-bold-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.mobile-nav .text-wrapper-53 {
  color: #202020;
  font-family: var(--h3-bold-font-family);
  font-size: var(--h3-bold-font-size);
  font-style: var(--h3-bold-font-style);
  font-weight: var(--h3-bold-font-weight);
  letter-spacing: var(--h3-bold-letter-spacing);
  line-height: var(--h3-bold-line-height);
}

.mobile-nav .text-wrapper-54 {
  color: #ffba15;
  font-family: var(--h3-bold-font-family);
  font-size: var(--h3-bold-font-size);
  font-style: var(--h3-bold-font-style);
  font-weight: var(--h3-bold-font-weight);
  letter-spacing: var(--h3-bold-letter-spacing);
  line-height: var(--h3-bold-line-height);
}

.mobile-nav .text-wrapper-55 {
  color: var(--text-600);
  font-family: "ABeeZee", Helvetica;
  font-size: 28px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.mobile-nav .frame-83 {
  height: 72px;
  left: 0;
  position: absolute;
  top: 247px;
  width: 1733px;
}

.mobile-nav .frame-84 {
  height: 72px;
  left: 0;
  position: absolute;
  top: 0;
  width: 293px;
}

.mobile-nav .text-wrapper-56 {
  color: var(--text-600);
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  left: 0;
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.mobile-nav .frame-85 {
  align-items: center;
  display: inline-flex;
  gap: 4.66px;
  left: 0;
  position: absolute;
  top: 37px;
}

.mobile-nav .text-wrapper-57 {
  color: var(--text-900);
  font-family: var(--small-title-bold-font-family);
  font-size: var(--small-title-bold-font-size);
  font-style: var(--small-title-bold-font-style);
  font-weight: var(--small-title-bold-font-weight);
  letter-spacing: var(--small-title-bold-letter-spacing);
  line-height: var(--small-title-bold-line-height);
  margin-top: -1.17px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.mobile-nav .frame-86 {
  height: 72px;
  left: 480px;
  position: absolute;
  top: 0;
  width: 293px;
}

.mobile-nav .frame-87 {
  height: 72px;
  left: 960px;
  position: absolute;
  top: 0;
  width: 293px;
}

.mobile-nav .frame-88 {
  height: 72px;
  left: 1440px;
  position: absolute;
  top: 0;
  width: 293px;
}

.mobile-nav .tabs-wrapper {
  height: 48px;
  left: 92px;
  position: absolute;
  top: 549px;
  width: 875px;
}

.mobile-nav .tabs-2 {
  width: 875px !important;
}

.mobile-nav .tabs-3 {
  color: var(--text-500) !important;
}

.mobile-nav .tabs-4 {
  color: var(--text-900) !important;
}

.mobile-nav .tabs-5 {
  width: unset !important;
}

.mobile-nav .frame-89 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  position: absolute;
}

.mobile-nav .frame-90 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.mobile-nav .wallet-7 {
  background-color: var(--white);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--text-500);
  height: 52px;
  margin-left: -1px;
  margin-right: -1px;
  margin-top: -1px;
  position: relative;
  width: 345px;
}

.mobile-nav .frame-91 {
  align-items: center;
  display: inline-flex;
  gap: 6px;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 16px;
}

.mobile-nav .property-1-wallet-fill {
  height: 24px !important;
  left: 319px !important;
  position: absolute !important;
  top: 13px !important;
  width: 24px !important;
}

.mobile-nav .wallet-8 {
  background-color: var(--white);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--text-500);
  height: 52px;
  margin-left: -1px;
  margin-right: -1px;
  position: relative;
  width: 345px;
}

.mobile-nav .frame-92 {
  align-items: center;
  display: inline-flex;
  gap: 6px;
  justify-content: center;
  position: relative;
  top: 16px;
}

.mobile-nav .text-wrapper-58 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.mobile-nav .wallet-9 {
  background-color: var(--white);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--text-500);
  height: 52px;
  margin-bottom: -1px;
  margin-left: -1px;
  margin-right: -1px;
  position: relative;
  width: 345px;
}

.mobile-nav .text-wrapper-59 {
  color: var(--text-900);
  font-family: var(--h4-bold-font-family);
  font-size: var(--h4-bold-font-size);
  font-style: var(--h4-bold-font-style);
  font-weight: var(--h4-bold-font-weight);
  letter-spacing: var(--h4-bold-letter-spacing);
  line-height: var(--h4-bold-line-height);
  margin-top: -1px;
  position: relative;
  width: 383px;
}

.mobile-nav .flexcontainer {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 52.8px;
  width: 1732px;
}

.mobile-nav .text {
  align-self: stretch;
  color: transparent;
  font-family: "ABeeZee", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.36px;
  line-height: 30px;
  position: relative;
}

.mobile-nav .text-wrapper-60 {
  color: #202020;
  letter-spacing: 0.06px;
}

.mobile-nav .text-wrapper-61 {
  color: #ffba15;
  font-style: italic;
  letter-spacing: 0.06px;
}

.mobile-nav .text-wrapper-62 {
  color: #202020;
  font-style: italic;
  letter-spacing: 0.06px;
}

.mobile-nav .frame-93 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 14px;
  position: relative;
}

.mobile-nav .frame-94 {
  height: 655px;
  position: relative;
  width: 1732px;
}

.mobile-nav .frame-95 {
  height: 63px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1732px;
}

.mobile-nav .frame-96 {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  left: 0;
  position: absolute;
  top: 0;
  width: 1732px;
}

.mobile-nav .text-wrapper-63 {
  color: #000000;
  font-family: "ABeeZee", Helvetica;
  font-size: 24px;
  font-style: italic;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.mobile-nav .iconify-30 {
  height: 34px !important;
  position: relative !important;
  width: 34px !important;
}

.mobile-nav .text-wrapper-64 {
  color: #000000;
  font-family: "ABeeZee", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0.36px;
  line-height: normal;
  position: absolute;
  top: 41px;
  width: 1732px;
}

.mobile-nav .frame-97 {
  height: 34px;
  left: 0;
  position: absolute;
  top: 103px;
  width: 1732px;
}

.mobile-nav .text-wrapper-65 {
  color: #000000;
  font-family: "ABeeZee", Helvetica;
  font-size: 24px;
  font-style: italic;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.mobile-nav .iconify-31 {
  height: 34px !important;
  left: 1698px !important;
  position: absolute !important;
  top: 0 !important;
  width: 34px !important;
}

.mobile-nav .frame-98 {
  height: 34px;
  left: 0;
  position: absolute;
  top: 177px;
  width: 1732px;
}

.mobile-nav .frame-99 {
  height: 34px;
  left: 0;
  position: absolute;
  top: 251px;
  width: 1732px;
}

.mobile-nav .frame-100 {
  height: 34px;
  left: 0;
  position: absolute;
  top: 325px;
  width: 1732px;
}

.mobile-nav .frame-101 {
  height: 34px;
  left: 0;
  position: absolute;
  top: 399px;
  width: 1732px;
}

.mobile-nav .frame-102 {
  height: 34px;
  left: 0;
  position: absolute;
  top: 473px;
  width: 1732px;
}

.mobile-nav .frame-103 {
  height: 34px;
  left: 0;
  position: absolute;
  top: 547px;
  width: 1732px;
}

.mobile-nav .frame-104 {
  height: 34px;
  left: 0;
  position: absolute;
  top: 621px;
  width: 1732px;
}

.mobile-nav .frame-105 {
  height: 30px;
  position: relative;
  width: 73.5px;
}

.mobile-nav .x-wrapper {
  background-color: #1c1c1c;
  border-radius: 375px;
  height: 30px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 30px;
}

.mobile-nav .x-6-instance {
  height: 18px !important;
  left: 6px !important;
  position: absolute !important;
  top: 6px !important;
  width: 18px !important;
}

.mobile-nav .discord-4 {
  height: 30px;
  left: 44px;
  position: absolute;
  top: 0;
  width: 30px;
}

.mobile-nav .element-goober-all-3 {
  color: var(--text-900);
  font-family: "DM Sans", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.24px;
  line-height: normal;
  position: relative;
  width: 309px;
}

.mobile-nav .text-wrapper-66 {
  letter-spacing: 0.03px;
}

.mobile-nav .text-wrapper-67 {
  font-family: "ABeeZee", Helvetica;
  letter-spacing: 0.03px;
}

.mobile-nav .text-wrapper-68 {
  font-family: "ABeeZee", Helvetica;
  letter-spacing: 0.03px;
  text-decoration: underline;
}

.mobile-nav .footer-3 {
  left: 0 !important;
  position: absolute !important;
  top: 1883px !important;
}

.mobile-nav .footer-4 {
  left: 656px !important;
}

.mobile-nav .nav-2 {
  background-image: url(../../../static/img/rectangle-2-4.svg);
  background-size: 100% 100%;
  height: 100px;
  left: 0;
  position: fixed;
  top: 0;
  width: 1920px;
}

.mobile-nav .group-13 {
  height: 51px;
  left: 92px;
  position: relative;
  top: 24px;
  width: 1736px;
}

.mobile-nav .logo-2 {
  align-items: center;
  display: inline-flex;
  gap: 8px;
  left: 0;
  position: absolute;
  top: 0;
}

.mobile-nav .group-14 {
  height: 50px;
  position: relative;
  width: 50px;
}

.mobile-nav .frame-106 {
  background-color: #ffffff;
  border-radius: 24.82px;
  height: 50px;
}

.mobile-nav .group-15 {
  background-image: url(../../../static/img/vector.svg);
  background-size: 100% 100%;
  height: 29px;
  left: 10px;
  position: relative;
  top: 10px;
  width: 29px;
}

.mobile-nav .group-16 {
  background-color: #ffffff;
  border-radius: 3.63px;
  height: 7px;
  left: 9px;
  position: absolute;
  top: 10px;
  width: 7px;
}

.mobile-nav .ellipse-6 {
  background-color: #000000;
  border-radius: 2.66px;
  height: 5px;
  left: 1px;
  position: relative;
  top: 1px;
  width: 5px;
}

.mobile-nav .group-17 {
  background-color: #ffffff;
  border-radius: 3.63px;
  height: 7px;
  left: 17px;
  position: absolute;
  top: 3px;
  width: 7px;
}

.mobile-nav .group-18 {
  height: 32.88px;
  position: relative;
  width: 106.81px;
}

.mobile-nav .group-19 {
  height: 27px;
  left: 0;
  position: absolute;
  top: 6px;
  width: 18px;
}

.mobile-nav .group-20 {
  border: 3.66px solid;
  border-color: #000000;
  border-radius: 8.82px;
  height: 18px;
  left: 19px;
  position: absolute;
  top: 6px;
  width: 18px;
}

.mobile-nav .ellipse-7 {
  background-color: #000000;
  border-radius: 3.46px;
  height: 7px;
  left: 3px;
  position: relative;
  top: 5px;
  width: 7px;
}

.mobile-nav .group-21 {
  border: 3.66px solid;
  border-color: #000000;
  border-radius: 8.82px;
  height: 18px;
  left: 39px;
  position: absolute;
  top: 6px;
  width: 18px;
}

.mobile-nav .ellipse-8 {
  background-color: #000000;
  border-radius: 3.46px;
  height: 7px;
  left: 5px;
  position: relative;
  top: 5px;
  width: 7px;
}

.mobile-nav .group-22 {
  height: 24px;
  left: 58px;
  position: absolute;
  top: 0;
  width: 18px;
}

.mobile-nav .overlap-group-4 {
  height: 24px;
  position: relative;
}

.mobile-nav .ellipse-9 {
  border: 3.66px solid;
  border-color: #000000;
  border-radius: 8.82px;
  height: 18px;
  left: 0;
  position: absolute;
  top: 6px;
  width: 18px;
}

.mobile-nav .rectangle-5 {
  background-color: #000000;
  border-radius: 0px 0px 1.82px 1.82px;
  height: 17px;
  left: 0;
  position: absolute;
  top: 0;
  width: 4px;
}

.mobile-nav .subtract-2 {
  height: 18px;
  left: 77px;
  position: absolute;
  top: 6px;
  width: 18px;
}

.mobile-nav .frame-107 {
  align-items: flex-start;
  display: flex;
  height: 18px;
  left: 96px;
  position: absolute;
  top: 6px;
  width: 11px;
}

.mobile-nav .rectangle-6 {
  background-color: #000000;
  height: 17.65px;
  position: relative;
  transform: rotate(180deg);
  width: 3.64px;
}

.mobile-nav .ellipse-10 {
  background-color: #000000;
  border-radius: 3.46px;
  height: 6.91px;
  position: relative;
  width: 6.91px;
}

.mobile-nav .navbar {
  height: 18px;
  left: 657px;
  position: absolute;
  top: 16px;
  width: 429px;
}

.mobile-nav .text-wrapper-69 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  left: 0;
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.mobile-nav .text-wrapper-70 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  left: 103px;
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.mobile-nav .text-wrapper-71 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  left: 209px;
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.mobile-nav .text-wrapper-72 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  left: 341px;
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.mobile-nav .frame-108 {
  align-items: center;
  display: inline-flex;
  gap: 20px;
  left: 1421px;
  position: absolute;
  top: 1px;
}

.mobile-nav .frame-109 {
  height: 40px;
  position: relative;
  width: 114px;
}

.mobile-nav .x-6-wrapper {
  background-color: #1c1c1c;
  border-radius: 500px;
  height: 40px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 40px;
}

.mobile-nav .state-default-instance {
  height: 24px !important;
  left: 8px !important;
  position: absolute !important;
  top: 8px !important;
  width: 24px !important;
}

.mobile-nav .discord-5 {
  height: 40px;
  left: 64px;
  position: absolute;
  top: 0;
  width: 40px;
}

.mobile-nav .wallet-10 {
  flex: 0 0 auto !important;
}

.mobile-nav .iconify-70 {
  height: 24px !important;
  position: relative !important;
  width: 24px !important;
}

.mobile-nav .frame-110 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  left: 0;
  position: fixed;
  top: 0;
}

.mobile-nav .status-bar-instance {
  background-color: #ffffff !important;
  width: 375px !important;
}

.mobile-nav .status-bar-2 {
  color: var(--themes-black-100) !important;
}

.mobile-nav .nav-3 {
  background-color: #ffffff;
  height: 54px;
  position: relative;
  width: 375px;
}

.mobile-nav .logo-3 {
  align-items: center;
  display: inline-flex;
  gap: 5.44px;
  left: 15px;
  position: absolute;
  top: 10px;
}

.mobile-nav .group-23 {
  height: 33.98px;
  position: relative;
  width: 33.98px;
}

.mobile-nav .frame-111 {
  background-color: #ffffff;
  border-radius: 16.86px;
  height: 34px;
  width: 34px;
}

.mobile-nav .group-24 {
  background-image: url(../../../static/img/vector-5.svg);
  background-size: 100% 100%;
  height: 20px;
  left: 7px;
  position: relative;
  top: 7px;
  width: 20px;
}

.mobile-nav .group-25 {
  background-color: #ffffff;
  border-radius: 2.47px;
  height: 5px;
  left: 6px;
  position: absolute;
  top: 7px;
  width: 5px;
}

.mobile-nav .ellipse-11 {
  background-color: #000000;
  border-radius: 1.81px;
  height: 4px;
  left: 1px;
  position: relative;
  top: 1px;
  width: 4px;
}

.mobile-nav .group-26 {
  background-color: #ffffff;
  border-radius: 2.47px;
  height: 5px;
  left: 12px;
  position: absolute;
  top: 2px;
  width: 5px;
}

.mobile-nav .group-27 {
  height: 22.34px;
  position: relative;
  width: 72.59px;
}

.mobile-nav .group-28 {
  height: 18px;
  left: 0;
  position: absolute;
  top: 4px;
  width: 12px;
}

.mobile-nav .group-29 {
  border: 2.49px solid;
  border-color: #000000;
  border-radius: 6px;
  height: 12px;
  left: 13px;
  position: absolute;
  top: 4px;
  width: 12px;
}

.mobile-nav .ellipse-12 {
  background-color: #000000;
  border-radius: 2.35px;
  height: 5px;
  left: 2px;
  position: relative;
  top: 3px;
  width: 5px;
}

.mobile-nav .group-30 {
  border: 2.49px solid;
  border-color: #000000;
  border-radius: 6px;
  height: 12px;
  left: 26px;
  position: absolute;
  top: 4px;
  width: 12px;
}

.mobile-nav .ellipse-13 {
  background-color: #000000;
  border-radius: 2.35px;
  height: 5px;
  left: 4px;
  position: relative;
  top: 3px;
  width: 5px;
}

.mobile-nav .group-31 {
  height: 16px;
  left: 39px;
  position: absolute;
  top: 0;
  width: 12px;
}

.mobile-nav .overlap-group-5 {
  height: 16px;
  position: relative;
}

.mobile-nav .ellipse-14 {
  border: 2.49px solid;
  border-color: #000000;
  border-radius: 6px;
  height: 12px;
  left: 0;
  position: absolute;
  top: 4px;
  width: 12px;
}

.mobile-nav .rectangle-7 {
  background-color: #000000;
  border-radius: 0px 0px 1.24px 1.24px;
  height: 12px;
  left: 0;
  position: absolute;
  top: 0;
  width: 2px;
}

.mobile-nav .subtract-3 {
  height: 12px;
  left: 52px;
  position: absolute;
  top: 4px;
  width: 12px;
}

.mobile-nav .frame-112 {
  align-items: flex-start;
  display: flex;
  height: 12px;
  left: 65px;
  position: absolute;
  top: 4px;
  width: 7px;
}

.mobile-nav .rectangle-8 {
  background-color: #000000;
  height: 11.99px;
  position: relative;
  transform: rotate(180deg);
  width: 2.47px;
}

.mobile-nav .ellipse-15 {
  background-color: #000000;
  border-radius: 2.35px;
  height: 4.7px;
  position: relative;
  width: 4.7px;
}

.mobile-nav .menu {
  background-image: url(../../../static/img/iconify-27.svg);
  background-size: 100% 100%;
  height: 24px;
  left: 335px;
  position: absolute;
  top: 15px;
  width: 24px;
}
