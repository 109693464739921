.wallet-button {
    background-color: #FFBA15;
    /* Amber color background */
    color: white;
    /* White text */
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    /* Adjust space between icon and text */
    transition: background-color 0.3s ease;
}

.wallet-button {
    align-items: center;
    background-color: var(--primary-500main);
    border-radius: 10px;
    gap: 10px;
    justify-content: center;
    padding: 13px 16px;
}

.wallet-button:hover {
    background-color: #e0a806;
    /* Slightly darker amber on hover */
}

.wallet-button.connected {
    background-color: #cccccc;
    /* Gray background when connected */
}

.iconify-26 {
    height: 24px !important;
    position: relative !important;
    width: 24px !important;
}

.text-wrapper-5 {
    color: #ffffff;
    font-family: var(--content-bold-font-family);
    font-size: var(--content-bold-font-size);
    font-style: var(--content-bold-font-style);
    font-weight: var(--content-bold-font-weight);
    letter-spacing: var(--content-bold-letter-spacing);
    line-height: var(--content-bold-line-height);
    position: relative;
    white-space: nowrap;
    width: fit-content;
}