@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");

:root {
  --primary-color: #FFBA15;
  /* 选择时的颜色 */
  --secondary-color: #D4D4D4;
  /* 未选择时的颜色 */
  --background-color: #e6eef9;
  /* 背景颜色 */
  --active-text-color: #000000;
    /* 选中时的文字颜色 */
}

*,
*:after,
*:before {
  box-sizing: border-box;
}

body {
  font-family: "Inter", sans-serif;
  background-color: var(--background-color);
}

.container {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tabs {
  width: 875px;
  display: flex;
  position: relative;
  background-color: #fff;
  /* box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.15), 0 6px 12px 0 rgba(0, 0, 0, 0.15); */
  padding: 0.75rem;
  border-radius: 99px;

  * {
    z-index: 2;
  }
}

input[type="radio"] {
  display: none;
}

.tab {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 54px;
  width: 437.5px;
  font-size: 2rem;
  font-weight: 1000;
  font-style: "bold";
  color: var(--secondary-color);
  border-radius: 99px;
  cursor: pointer;
  transition: color 0.15s ease-in;
  gap: 15px
}

input[type="radio"]:checked+label {
  color: var(--active-text-color);
  transition: color 0.15s ease-in;
}

.glider {
  position: absolute;
  bottom: 0; /* Positioned at the bottom of the tabs */
  height: 4px; /* Thinner line for an underline */
  width: 400px; /* Match tab width */
  background-color: var(--secondary-color);
  z-index: 1;
  border-radius: 99px;
  transition: transform 0.25s ease-out; /* Ensure using transform for transition */
}

input[id="radio-1"]:checked~.glider {
  background-color: var(--primary-color);
  transform: translateX(0);
}

input[id="radio-2"]:checked~.glider {
  background-color: var(--primary-color);
  transform: translateX(108%);
}

@media (max-width: 700px) {
  .tabs {
    transform: scale(0.6);
  }
}