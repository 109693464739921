.instance-node-2 {
  height: 34px !important;
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
  width: 34px !important;
}

.instance-node-3 {
  height: 24px !important;
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
  width: 24px !important;
}

.property-copy {
  height: 20px !important;
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
  width: 20px !important;
}
