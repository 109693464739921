.mobile-tiers {
  align-items: flex-start;
  background-color: #fafafa;
  border-radius: 10px;
  display: inline-flex;
  flex-direction: column;
  gap: 16px;
  padding: 14px;
  position: relative;
}

.mobile-tiers .frame-27 {
  height: 123px;
  position: relative;
  width: 315px;
}

.mobile-tiers .frame-28 {
  height: 50px;
  left: 0;
  position: absolute;
  top: 0;
  width: 315px;
}

.mobile-tiers .frame-29 {
  height: 30px;
  left: 0;
  position: absolute;
  top: 0;
  width: 55px;
}

.mobile-tiers .frame-30 {
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.mobile-tiers .icons-25 {
  height: 30px !important;
  position: relative !important;
  width: 30px !important;
}

.mobile-tiers .p {
  color: transparent;
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  left: 0;
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  position: absolute;
  top: 31px;
  width: 315px;
}

.mobile-tiers .text-wrapper-20 {
  color: #202020;
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
}

.mobile-tiers .text-wrapper-21 {
  color: #ffba15;
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
}

.mobile-tiers .frame-31 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 13px;
  left: 0;
  position: absolute;
  top: 56px;
}

.mobile-tiers .frame-32 {
  height: 24px;
  position: relative;
  width: 315px;
}

.mobile-tiers .text-wrapper-22 {
  color: #000000;
  font-family: "ABeeZee", Helvetica;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  left: 269px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: -1px;
  width: 46px;
}

.mobile-tiers .rectangle-wrapper {
  background-color: #ffffff;
  border-radius: 25px;
  height: 10px;
  left: 0;
  position: absolute;
  top: 14px;
  width: 315px;
}

.mobile-tiers .rectangle-2 {
  background-color: var(--primary-500main);
  border-radius: 25px;
  height: 10px;
  width: 10px;
}

.mobile-tiers .frame-33 {
  height: 30px;
  position: relative;
  width: 315px;
}

.mobile-tiers .frame-34 {
  align-items: flex-start;
  display: inline-flex;
  gap: 20px;
  position: relative;
  top: 1px;
}

.mobile-tiers .frame-35 {
  height: 27px;
  position: relative;
  width: 80px;
}

.mobile-tiers .icons-2 {
  border: 0.48px solid !important;
  border-radius: 12px !important;
  height: 24px !important;
  left: 0 !important;
  position: absolute !important;
  top: 2px !important;
  width: 24px !important;
}

.mobile-tiers .frame-36 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 2px;
  left: 27px;
  position: absolute;
  top: 0;
}

.mobile-tiers .text-wrapper-23 {
  color: var(--text-600);
  font-family: var(--mobile-10px-font-family);
  font-size: var(--mobile-10px-font-size);
  font-style: var(--mobile-10px-font-style);
  font-weight: var(--mobile-10px-font-weight);
  letter-spacing: var(--mobile-10px-letter-spacing);
  line-height: var(--mobile-10px-line-height);
  margin-top: -0.55px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.mobile-tiers .frame-37 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 2.59px;
  position: relative;
}

.mobile-tiers .text-wrapper-24 {
  color: var(--text-900);
  font-family: "ABeeZee", Helvetica;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.65px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.mobile-tiers .frame-38 {
  height: 27px;
  position: relative;
  width: 103px;
}

.mobile-tiers .icons-21 {
  height: 24px !important;
  left: 0 !important;
  position: absolute !important;
  top: 2px !important;
  width: 24px !important;
}

.mobile-tiers .frame-39 {
  height: 27px;
  position: relative;
  width: 51px;
}

.mobile-tiers .iconify-62 {
  height: 14px !important;
  left: 5px !important;
  position: absolute !important;
  top: 5px !important;
  width: 14px !important;
}
