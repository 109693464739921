.stake-page-tiers {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.stake-page-tiers .div-8 {
  min-height: 2200px;
  position: relative;
      /* This makes it the reference for its absolutely positioned children */
  padding-bottom: 50px;
          /* Add padding at the bottom equal to the footer's height to ensure content isn't hidden behind it */
}

.stake-page-tiers .overlap-2 {
  background-color: #ffffff;
  height: 1317px;
  left: 0;
  position: absolute;
  top: 0;
  width: 375px;
}

.stake-page-tiers .frame-155 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 3px;
  left: 15px;
  position: absolute;
  top: 125px;
}

.stake-page-tiers .group-43 {
  height: 27px;
  position: relative;
  width: 147px;
}

.stake-page-tiers .frame-156 {
  align-items: center;
  background-color: var(--text-150);
  border-radius: 4px;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  padding: 8px 10px;
  position: relative;
}

.stake-page-tiers .text-wrapper-93 {
  color: var(--primary-500main);
  font-family: var(--mobile-10px-font-family);
  font-size: var(--mobile-10px-font-size);
  font-style: var(--mobile-10px-font-style);
  font-weight: var(--mobile-10px-font-weight);
  letter-spacing: var(--mobile-10px-letter-spacing);
  line-height: var(--mobile-10px-line-height);
  margin-top: -0.52px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.stake-page-tiers .frame-157 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
  position: relative;
}

.stake-page-tiers .stake-to-mint-GOO-4 {
  color: transparent;
  font-family: var(--small-title-bold-font-family);
  font-size: var(--small-title-bold-font-size);
  font-style: var(--small-title-bold-font-style);
  font-weight: var(--small-title-bold-font-weight);
  letter-spacing: var(--small-title-bold-letter-spacing);
  line-height: var(--small-title-bold-line-height);
  margin-top: -0.52px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.stake-page-tiers .text-wrapper-94 {
  color: #202020;
  font-family: var(--small-title-bold-font-family);
  font-size: var(--small-title-bold-font-size);
  font-style: var(--small-title-bold-font-style);
  font-weight: var(--small-title-bold-font-weight);
  letter-spacing: var(--small-title-bold-letter-spacing);
  line-height: var(--small-title-bold-line-height);
}

.stake-page-tiers .text-wrapper-95 {
  color: #ffba15;
  font-family: var(--small-title-bold-font-family);
  font-size: var(--small-title-bold-font-size);
  font-style: var(--small-title-bold-font-style);
  font-weight: var(--small-title-bold-font-weight);
  letter-spacing: var(--small-title-bold-letter-spacing);
  line-height: var(--small-title-bold-line-height);
}

.stake-page-tiers .text-wrapper-96 {
  color: var(--text-600);
  font-family: "ABeeZee", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.stake-page-tiers .frame-158 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 14px;
  left: 16px;
  position: absolute;
  top: 229px;
}

.stake-page-tiers .frame-159 {
  height: 33px;
  position: relative;
  width: 278.58px;
}

.stake-page-tiers .frame-160 {
  height: 33px;
  left: 0;
  position: absolute;
  top: 0;
  width: 134px;
}

.stake-page-tiers .text-wrapper-97 {
  color: var(--text-600);
  font-family: var(--mobile-10px-font-family);
  font-size: var(--mobile-10px-font-size);
  font-style: var(--mobile-10px-font-style);
  font-weight: var(--mobile-10px-font-weight);
  left: 0;
  letter-spacing: var(--mobile-10px-letter-spacing);
  line-height: var(--mobile-10px-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.stake-page-tiers .frame-161 {
  align-items: center;
  display: inline-flex;
  gap: 2.14px;
  left: 0;
  position: absolute;
  top: 15px;
}

.stake-page-tiers .text-wrapper-98 {
  color: var(--text-900);
  font-family: var(--notes-bold-font-family);
  font-size: var(--notes-bold-font-size);
  font-style: var(--notes-bold-font-style);
  font-weight: var(--notes-bold-font-weight);
  letter-spacing: var(--notes-bold-letter-spacing);
  line-height: var(--notes-bold-line-height);
  margin-top: -0.53px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.stake-page-tiers .frame-162 {
  height: 33px;
  left: 144px;
  position: absolute;
  top: 0;
  width: 134px;
}

.stake-page-tiers .frame-163 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 20px;
  left: 16px;
  position: absolute;
  top: 341px;
}

.stake-page-tiers .group-44 {
  height: 19px;
  position: relative;
  width: 343px;
}

.stake-page-tiers .vector-3 {
  height: 1px;
  left: 0;
  position: absolute;
  top: 19px;
  width: 343px;
}

.stake-page-tiers .frame-164 {
  height: 17px;
  left: 0;
  position: absolute;
  top: 0;
  width: 159px;
}

.stake-page-tiers .frame-165 {
  align-items: center;
  display: inline-flex;
  gap: 3.96px;
  left: 0;
  position: absolute;
  top: 0;
}

.stake-page-tiers .iconify-3 {
  height: 16px !important;
  position: relative !important;
  width: 16px !important;
}

.stake-page-tiers .text-wrapper-99 {
  color: var(--text-900);
  font-family: var(--notes-bold-font-family);
  font-size: var(--notes-bold-font-size);
  font-style: var(--notes-bold-font-style);
  font-weight: var(--notes-bold-font-weight);
  letter-spacing: var(--notes-bold-letter-spacing);
  line-height: var(--notes-bold-line-height);
  margin-top: -0.4px;
  position: relative;
  width: 37px;
}

.stake-page-tiers .vector-4 {
  height: 1px;
  left: 0;
  position: absolute;
  top: 19px;
  width: 168px;
}

.stake-page-tiers .frame-166 {
  height: 17px;
  left: 176px;
  position: absolute;
  top: 0;
  width: 159px;
}

.stake-page-tiers .text-wrapper-100 {
  color: var(--text-500);
  font-family: var(--notes-bold-font-family);
  font-size: var(--notes-bold-font-size);
  font-style: var(--notes-bold-font-style);
  font-weight: var(--notes-bold-font-weight);
  letter-spacing: var(--notes-bold-letter-spacing);
  line-height: var(--notes-bold-line-height);
  margin-top: -0.4px;
  position: relative;
  width: 37px;
}

.stake-page-tiers .vector-5 {
  height: 1px;
  left: 0;
  position: absolute;
  top: 19px;
  width: 169px;
}

.stake-page-tiers .instance-node-4 {
  flex: 0 0 auto !important;
}

.stake-page-tiers .mobile-tiers-instance {
  white-space: unset !important;
}

.stake-page-tiers .instance-node-5 {
  height: 14px !important;
  left: 5px !important;
  position: absolute !important;
  top: 5px !important;
  width: 14px !important;
}

.stake-page-tiers .instance-node-6 {
  margin-left: -5px !important;
}

.stake-page-tiers .mobile-tiers-2 {
  align-items: flex-start;
  background-color: #fafafa;
  border-radius: 10px;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  padding: 14px;
  position: relative;
}

.stake-page-tiers .frame-167 {
  height: 123px;
  position: relative;
  width: 315px;
}

.stake-page-tiers .frame-168 {
  height: 50px;
  left: 0;
  position: absolute;
  top: 0;
  width: 315px;
}

.stake-page-tiers .frame-169 {
  height: 30px;
  left: 0;
  position: absolute;
  top: 0;
  width: 55px;
}

.stake-page-tiers .frame-170 {
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.stake-page-tiers .icons-44 {
  height: 30px !important;
  position: relative !important;
  width: 30px !important;
}

.stake-page-tiers .stake-NUTS-for-free-4 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  left: 0;
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  position: absolute;
  top: 31px;
  width: 315px;
}

.stake-page-tiers .frame-171 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 13px;
  left: 0;
  position: absolute;
  top: 56px;
}

.stake-page-tiers .frame-172 {
  height: 24px;
  position: relative;
  width: 315px;
}

.stake-page-tiers .text-wrapper-101 {
  color: #000000;
  font-family: "ABeeZee", Helvetica;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  left: 269px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: -1px;
  width: 46px;
}

.stake-page-tiers .group-45 {
  background-color: #ffffff;
  border-radius: 25px;
  height: 10px;
  left: 0;
  position: absolute;
  top: 14px;
  width: 315px;
}

.stake-page-tiers .rectangle-12 {
  background-color: var(--primary-500main);
  border-radius: 25px;
  height: 10px;
  width: 10px;
}

.stake-page-tiers .frame-173 {
  height: 30px;
  position: relative;
  width: 315px;
}

.stake-page-tiers .frame-174 {
  align-items: flex-start;
  display: inline-flex;
  gap: 20px;
  position: relative;
  top: 1px;
}

.stake-page-tiers .frame-175 {
  height: 27px;
  position: relative;
  width: 80px;
}

.stake-page-tiers .icons-13 {
  border: 0.48px solid !important;
  border-radius: 12px !important;
  height: 24px !important;
  left: 0 !important;
  position: absolute !important;
  top: 2px !important;
  width: 24px !important;
}

.stake-page-tiers .frame-176 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 2px;
  left: 27px;
  position: absolute;
  top: 0;
}

.stake-page-tiers .text-wrapper-102 {
  color: var(--text-600);
  font-family: var(--mobile-10px-font-family);
  font-size: var(--mobile-10px-font-size);
  font-style: var(--mobile-10px-font-style);
  font-weight: var(--mobile-10px-font-weight);
  letter-spacing: var(--mobile-10px-letter-spacing);
  line-height: var(--mobile-10px-line-height);
  margin-top: -0.55px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.stake-page-tiers .frame-177 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 2.59px;
  position: relative;
}

.stake-page-tiers .text-wrapper-103 {
  color: var(--text-900);
  font-family: "ABeeZee", Helvetica;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.65px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.stake-page-tiers .frame-178 {
  height: 27px;
  position: relative;
  width: 103px;
}

.stake-page-tiers .icons-43 {
  height: 24px !important;
  left: 0 !important;
  position: absolute !important;
  top: 2px !important;
  width: 24px !important;
}

.stake-page-tiers .frame-179 {
  height: 27px;
  position: relative;
  width: 51px;
}

.stake-page-tiers .footer-5 {
  left: 0 !important;
  position: absolute !important;
  top: 1075px !important;
}

.stake-page-tiers .frame-180 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  left: 0;
  position: fixed;
  top: 0;
}

.stake-page-tiers .status-bar-3 {
  background-color: #ffffff !important;
  width: 375px !important;
}

.stake-page-tiers .status-bar-4 {
  color: var(--themes-black-100) !important;
}

.stake-page-tiers .nav-5 {
  background-color: #ffffff;
  height: 54px;
  position: relative;
  width: 375px;
}

.stake-page-tiers .logo-5 {
  align-items: center;
  display: inline-flex;
  gap: 5.44px;
  left: 15px;
  position: absolute;
  top: 10px;
}

.stake-page-tiers .group-46 {
  height: 33.98px;
  position: relative;
  width: 33.98px;
}

.stake-page-tiers .frame-181 {
  background-color: #ffffff;
  border-radius: 16.86px;
  height: 34px;
  width: 34px;
}

.stake-page-tiers .group-47 {
  background-image: url(../../../static/img/vector-5.svg);
  background-size: 100% 100%;
  height: 20px;
  left: 7px;
  position: relative;
  top: 7px;
  width: 20px;
}

.stake-page-tiers .group-48 {
  background-color: #ffffff;
  border-radius: 2.47px;
  height: 5px;
  left: 6px;
  position: absolute;
  top: 7px;
  width: 5px;
}

.stake-page-tiers .ellipse-21 {
  background-color: #000000;
  border-radius: 1.81px;
  height: 4px;
  left: 1px;
  position: relative;
  top: 1px;
  width: 4px;
}

.stake-page-tiers .group-49 {
  background-color: #ffffff;
  border-radius: 2.47px;
  height: 5px;
  left: 12px;
  position: absolute;
  top: 2px;
  width: 5px;
}

.stake-page-tiers .group-50 {
  height: 22.34px;
  position: relative;
  width: 72.59px;
}

.stake-page-tiers .group-51 {
  height: 18px;
  left: 0;
  position: absolute;
  top: 4px;
  width: 12px;
}

.stake-page-tiers .group-52 {
  border: 2.49px solid;
  border-color: #000000;
  border-radius: 6px;
  height: 12px;
  left: 13px;
  position: absolute;
  top: 4px;
  width: 12px;
}

.stake-page-tiers .ellipse-22 {
  background-color: #000000;
  border-radius: 2.35px;
  height: 5px;
  left: 2px;
  position: relative;
  top: 3px;
  width: 5px;
}

.stake-page-tiers .group-53 {
  border: 2.49px solid;
  border-color: #000000;
  border-radius: 6px;
  height: 12px;
  left: 26px;
  position: absolute;
  top: 4px;
  width: 12px;
}

.stake-page-tiers .ellipse-23 {
  background-color: #000000;
  border-radius: 2.35px;
  height: 5px;
  left: 4px;
  position: relative;
  top: 3px;
  width: 5px;
}

.stake-page-tiers .group-54 {
  height: 16px;
  left: 39px;
  position: absolute;
  top: 0;
  width: 12px;
}

.stake-page-tiers .overlap-group-7 {
  height: 16px;
  position: relative;
}

.stake-page-tiers .ellipse-24 {
  border: 2.49px solid;
  border-color: #000000;
  border-radius: 6px;
  height: 12px;
  left: 0;
  position: absolute;
  top: 4px;
  width: 12px;
}

.stake-page-tiers .rectangle-13 {
  background-color: #000000;
  border-radius: 0px 0px 1.24px 1.24px;
  height: 12px;
  left: 0;
  position: absolute;
  top: 0;
  width: 2px;
}

.stake-page-tiers .subtract-5 {
  height: 12px;
  left: 52px;
  position: absolute;
  top: 4px;
  width: 12px;
}

.stake-page-tiers .frame-182 {
  align-items: flex-start;
  display: flex;
  height: 12px;
  left: 65px;
  position: absolute;
  top: 4px;
  width: 7px;
}

.stake-page-tiers .rectangle-14 {
  background-color: #000000;
  height: 11.99px;
  position: relative;
  transform: rotate(180deg);
  width: 2.47px;
}

.stake-page-tiers .ellipse-25 {
  background-color: #000000;
  border-radius: 2.35px;
  height: 4.7px;
  position: relative;
  width: 4.7px;
}

.stake-page-tiers .menu-2 {
  display: block;
  height: 24px;
  left: 335px;
  position: absolute;
  top: 15px;
  width: 24px;
}

.stake-page-tiers .frame-183 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 80px;
  left: 92px;
  position: absolute;
  top: 150px;
}

.stake-page-tiers .frame-184 {
  height: 319px;
  position: relative;
  width: 1733px;
}

.stake-page-tiers .frame-185 {
  height: 187px;
  left: 0;
  position: absolute;
  top: 0;
  width: 557px;
}

.stake-page-tiers .group-55 {
  height: 50px;
  left: 0;
  position: absolute;
  top: 0;
  width: 238px;
}

.stake-page-tiers .frame-186 {
  align-items: center;
  background-color: var(--text-150);
  border-radius: 10px;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  padding: 16px 14px;
  position: relative;
}

.stake-page-tiers .text-wrapper-104 {
  color: var(--primary-500main);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.stake-page-tiers .frame-187 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 14px;
  left: 0;
  position: absolute;
  top: 64px;
}

.stake-page-tiers .stake-to-mint-GOO-5 {
  color: transparent;
  font-family: var(--h3-bold-font-family);
  font-size: var(--h3-bold-font-size);
  font-style: var(--h3-bold-font-style);
  font-weight: var(--h3-bold-font-weight);
  letter-spacing: var(--h3-bold-letter-spacing);
  line-height: var(--h3-bold-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.stake-page-tiers .text-wrapper-105 {
  color: #202020;
  font-family: var(--h3-bold-font-family);
  font-size: var(--h3-bold-font-size);
  font-style: var(--h3-bold-font-style);
  font-weight: var(--h3-bold-font-weight);
  letter-spacing: var(--h3-bold-letter-spacing);
  line-height: var(--h3-bold-line-height);
}

.stake-page-tiers .text-wrapper-106 {
  color: #ffba15;
  font-family: var(--h3-bold-font-family);
  font-size: var(--h3-bold-font-size);
  font-style: var(--h3-bold-font-style);
  font-weight: var(--h3-bold-font-weight);
  letter-spacing: var(--h3-bold-letter-spacing);
  line-height: var(--h3-bold-line-height);
}

.stake-page-tiers .text-wrapper-107 {
  color: var(--text-600);
  font-family: "ABeeZee", Helvetica;
  font-size: 28px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.stake-page-tiers .frame-188 {
  height: 72px;
  left: 0;
  position: absolute;
  top: 247px;
  width: 1733px;
}

.stake-page-tiers .frame-189 {
  height: 72px;
  left: 0;
  position: absolute;
  top: 0;
  width: 293px;
}

.stake-page-tiers .text-wrapper-108 {
  color: var(--text-600);
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  left: 0;
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.stake-page-tiers .frame-190 {
  align-items: center;
  display: inline-flex;
  gap: 4.66px;
  left: 0;
  position: absolute;
  top: 37px;
}

.stake-page-tiers .text-wrapper-109 {
  color: var(--text-900);
  font-family: var(--small-title-bold-font-family);
  font-size: var(--small-title-bold-font-size);
  font-style: var(--small-title-bold-font-style);
  font-weight: var(--small-title-bold-font-weight);
  letter-spacing: var(--small-title-bold-letter-spacing);
  line-height: var(--small-title-bold-line-height);
  margin-top: -1.17px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.stake-page-tiers .frame-191 {
  height: 72px;
  left: 480px;
  position: absolute;
  top: 0;
  width: 293px;
}

.stake-page-tiers .element-d-hr-min-s {
  color: transparent;
  font-family: "Aeonik TRIAL-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.17px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

@media (min-width: 1920px) {
  .stake-page-tiers .element-d-hr-min-s {
    font-size: 28px;
  }
}
.stake-page-tiers .text-wrapper-110 {
  color: #202020;
}

.stake-page-tiers .text-wrapper-111 {
  color: #d4d4d4;
}

.stake-page-tiers .frame-192 {
  height: 72px;
  left: 960px;
  position: absolute;
  top: 0;
  width: 293px;
}

.stake-page-tiers .frame-193 {
  height: 72px;
  left: 1440px;
  position: absolute;
  top: 0;
  width: 293px;
}

.stake-page-tiers .frame-194 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  position: relative;
}

.stake-page-tiers .tabs-7 {
  position: relative !important;
}

.stake-page-tiers .tiers-instance {
  font-family: "Aeonik TRIAL-RegularItalic", Helvetica !important;
  font-size: 20px !important;
  font-style: italic !important;
  font-weight: 400 !important;
  letter-spacing: 0 !important;
  line-height: normal !important;
}

.stake-page-tiers .iconify-13 {
  height: 34px !important;
  left: 12px !important;
  position: absolute !important;
  top: 12px !important;
  width: 34px !important;
}

.stake-page-tiers .iconify-26-instance {
  height: 24px !important;
  position: relative !important;
  width: 24px !important;
}

.stake-page-tiers .icons-14 {
  margin-left: -13px !important;
}

/* .stake-page-tiers .footer-6 {
  left: 0 !important;
  position: absolute !important;
  top: 1805px !important;
} */

.stake-page-tiers .footer-6 {
  position: absolute;
  /* or 'absolute' if it must be relative to a specific container */
  bottom: 0;
  left: 0;
  width: 100%;
  /* height: 50px; */
  /* Ensure it spans the full width */
}

.stake-page-tiers .footer-7 {
  left: 656px !important;
}

.stake-page-tiers .nav-6 {
  background-image: url(../../../static/img/rectangle-2-4-2.svg);
  background-size: 100% 100%;
  height: 100px;
  left: 0;
  position: fixed;
  top: 0;
  width: 1920px;
}

.stake-page-tiers .group-56 {
  height: 51px;
  left: 92px;
  position: relative;
  top: 24px;
  width: 1736px;
}

.stake-page-tiers .logo-6 {
  align-items: center;
  display: inline-flex;
  gap: 8px;
  left: 0;
  position: absolute;
  top: 0;
}

.stake-page-tiers .group-57 {
  height: 50px;
  position: relative;
  width: 50px;
}

.stake-page-tiers .frame-195 {
  background-color: #ffffff;
  border-radius: 24.82px;
  height: 50px;
}

.stake-page-tiers .group-58 {
  background-image: url(../../../static/img/vector.svg);
  background-size: 100% 100%;
  height: 29px;
  left: 10px;
  position: relative;
  top: 10px;
  width: 29px;
}

.stake-page-tiers .group-59 {
  background-color: #ffffff;
  border-radius: 3.63px;
  height: 7px;
  left: 9px;
  position: absolute;
  top: 10px;
  width: 7px;
}

.stake-page-tiers .ellipse-26 {
  background-color: #000000;
  border-radius: 2.66px;
  height: 5px;
  left: 1px;
  position: relative;
  top: 1px;
  width: 5px;
}

.stake-page-tiers .group-60 {
  background-color: #ffffff;
  border-radius: 3.63px;
  height: 7px;
  left: 17px;
  position: absolute;
  top: 3px;
  width: 7px;
}

.stake-page-tiers .group-61 {
  height: 32.88px;
  position: relative;
  width: 106.81px;
}

.stake-page-tiers .group-62 {
  height: 27px;
  left: 0;
  position: absolute;
  top: 6px;
  width: 18px;
}

.stake-page-tiers .group-63 {
  border: 3.66px solid;
  border-color: #000000;
  border-radius: 8.82px;
  height: 18px;
  left: 19px;
  position: absolute;
  top: 6px;
  width: 18px;
}

.stake-page-tiers .ellipse-27 {
  background-color: #000000;
  border-radius: 3.46px;
  height: 7px;
  left: 3px;
  position: relative;
  top: 5px;
  width: 7px;
}

.stake-page-tiers .group-64 {
  border: 3.66px solid;
  border-color: #000000;
  border-radius: 8.82px;
  height: 18px;
  left: 39px;
  position: absolute;
  top: 6px;
  width: 18px;
}

.stake-page-tiers .ellipse-28 {
  background-color: #000000;
  border-radius: 3.46px;
  height: 7px;
  left: 5px;
  position: relative;
  top: 5px;
  width: 7px;
}

.stake-page-tiers .group-65 {
  height: 24px;
  left: 58px;
  position: absolute;
  top: 0;
  width: 18px;
}

.stake-page-tiers .overlap-group-8 {
  height: 24px;
  position: relative;
}

.stake-page-tiers .ellipse-29 {
  border: 3.66px solid;
  border-color: #000000;
  border-radius: 8.82px;
  height: 18px;
  left: 0;
  position: absolute;
  top: 6px;
  width: 18px;
}

.stake-page-tiers .rectangle-15 {
  background-color: #000000;
  border-radius: 0px 0px 1.82px 1.82px;
  height: 17px;
  left: 0;
  position: absolute;
  top: 0;
  width: 4px;
}

.stake-page-tiers .subtract-6 {
  height: 18px;
  left: 77px;
  position: absolute;
  top: 6px;
  width: 18px;
}

.stake-page-tiers .frame-196 {
  align-items: flex-start;
  display: flex;
  height: 18px;
  left: 96px;
  position: absolute;
  top: 6px;
  width: 11px;
}

.stake-page-tiers .rectangle-16 {
  background-color: #000000;
  height: 17.65px;
  position: relative;
  transform: rotate(180deg);
  width: 3.64px;
}

.stake-page-tiers .ellipse-30 {
  background-color: #000000;
  border-radius: 3.46px;
  height: 6.91px;
  position: relative;
  width: 6.91px;
}

.stake-page-tiers .navbar-2 {
  height: 18px;
  left: 657px;
  position: absolute;
  top: 16px;
  width: 429px;
}

.stake-page-tiers .text-wrapper-112 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  left: 0;
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.stake-page-tiers .text-wrapper-113 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  left: 103px;
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.stake-page-tiers .text-wrapper-114 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  left: 209px;
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.stake-page-tiers .text-wrapper-115 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  left: 341px;
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.stake-page-tiers .frame-197 {
  align-items: center;
  display: inline-flex;
  gap: 20px;
  left: 1421px;
  position: absolute;
  top: 1px;
}

.stake-page-tiers .frame-198 {
  height: 40px;
  position: relative;
  width: 114px;
}

.stake-page-tiers .state-default-wrapper {
  background-color: #1c1c1c;
  border-radius: 500px;
  height: 40px;
  left: 0;
  overflow: auto;
  position: absolute;
  top: 0;
  width: 40px;
}

.stake-page-tiers .x-12-instance {
  height: 24px !important;
  left: 8px !important;
  position: absolute !important;
  top: 8px !important;
  width: 24px !important;
}

.stake-page-tiers .discord-7 {
  height: 40px;
  left: 64px;
  position: absolute;
  top: 0;
  width: 40px;
}

.stake-page-tiers .rules-list {
  list-style-type: disc;
  /* Using disc as bullet points */
  padding-left: 20px;
  padding-right: 100px;
  /* Indent for bullet points */
  margin: 0;
  /* Removing default margin */
  color: #202020;
  /* Default text color */
  font-family: "ABeeZee", Helvetica;
  font-size: 18px;
  letter-spacing: 0.36px;
  line-height: 30px;
}

.stake-page-tiers .rules-list li {
  margin-bottom: 10px;
  /* Space between list items */
}

.stake-page-tiers .text-wrapper-59 {
  color: var(--text-900);
  font-family: var(--h4-bold-font-family);
  font-size: var(--h4-bold-font-size);
  font-style: var(--h4-bold-font-style);
  font-weight: var(--h4-bold-font-weight);
  letter-spacing: var(--h4-bold-letter-spacing);
  line-height: var(--h4-bold-line-height);
  margin-top: -1px;
  position: relative;
  width: 383px;
  margin-bottom: 20px;
}

.stake-page-tiers .text-wrapper-61 {
  color: #ffba15;
  font-style: italic;
  letter-spacing: 0.06px;
}