.state-default {
  height: 48px !important;
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
  width: 48px !important;
}

.state-hover {
  height: 48px !important;
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
  width: 51px !important;
}
