.element-d-hr-min-s {
    color: transparent;
    font-family: "Aeonik TRIAL-Bold", Helvetica;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.17px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

@media (min-width: 768px) {
    .countdown {
        font-size: 28px;
    }
}

.text-wrapper-231 {
    color: #202020;
}

.text-wrapper-232 {
    color: #d4d4d4;
}