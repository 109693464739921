.staked-info {
  align-items: flex-start;
  background-color: #fafafa;
  border-radius: 10px;
  display: inline-flex;
  gap: 28px;
  padding: 16px;
  position: relative;
}

.staked-info .frame-47 {
  height: 33px;
  position: relative;
  width: 146px;
}

.staked-info .icons-3 {
  border: 0.6px solid !important;
  border-radius: 15px !important;
  height: 30px !important;
  left: 0 !important;
  position: absolute !important;
  top: 2px !important;
  width: 30px !important;
}

.staked-info .icons-4 {
  height: 23px !important;
  left: 3px !important;
  top: 3px !important;
  width: 23px !important;
}

.staked-info .frame-48 {
  height: 33px;
  left: 34px;
  position: absolute;
  top: 0;
  width: 134px;
}

.staked-info .text-wrapper-32 {
  color: var(--text-600);
  font-family: var(--mobile-10px-font-family);
  font-size: var(--mobile-10px-font-size);
  font-style: var(--mobile-10px-font-style);
  font-weight: var(--mobile-10px-font-weight);
  left: 0;
  letter-spacing: var(--mobile-10px-letter-spacing);
  line-height: var(--mobile-10px-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.staked-info .frame-49 {
  align-items: center;
  display: inline-flex;
  gap: 2.14px;
  left: 0;
  position: absolute;
  top: 15px;
}

.staked-info .text-wrapper-33 {
  color: var(--text-900);
  font-family: var(--notes-bold-font-family);
  font-size: var(--notes-bold-font-size);
  font-style: var(--notes-bold-font-style);
  font-weight: var(--notes-bold-font-weight);
  letter-spacing: var(--notes-bold-letter-spacing);
  line-height: var(--notes-bold-line-height);
  margin-top: -0.53px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.staked-info .frame-50 {
  height: 33px;
  position: relative;
  width: 137px;
}

.staked-info .property-1-SOL {
  height: 16px !important;
  left: 6px !important;
  top: 6px !important;
  width: 16px !important;
}
