.typing {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.typing .div-10 {
  height: 1080px;
  overflow: hidden;
  position: relative;
  width: 1920px;
}

.typing .overlap-4 {
  background-color: #ffffff;
  height: 2083px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1920px;
}

.typing .frame-235 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 80px;
  left: 92px;
  position: absolute;
  top: 150px;
}

.typing .frame-236 {
  height: 319px;
  position: relative;
  width: 1733px;
}

.typing .frame-237 {
  height: 187px;
  left: 0;
  position: absolute;
  top: 0;
  width: 557px;
}

.typing .group-77 {
  height: 50px;
  left: 0;
  position: absolute;
  top: 0;
  width: 238px;
}

.typing .frame-238 {
  align-items: center;
  background-color: var(--text-150);
  border-radius: 10px;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  padding: 16px 14px;
  position: relative;
}

.typing .text-wrapper-133 {
  color: var(--primary-500main);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.typing .frame-239 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 14px;
  left: 0;
  position: absolute;
  top: 64px;
}

.typing .stake-to-mint-GOO-7 {
  color: transparent;
  font-family: var(--h3-bold-font-family);
  font-size: var(--h3-bold-font-size);
  font-style: var(--h3-bold-font-style);
  font-weight: var(--h3-bold-font-weight);
  letter-spacing: var(--h3-bold-letter-spacing);
  line-height: var(--h3-bold-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.typing .text-wrapper-134 {
  color: #202020;
  font-family: var(--h3-bold-font-family);
  font-size: var(--h3-bold-font-size);
  font-style: var(--h3-bold-font-style);
  font-weight: var(--h3-bold-font-weight);
  letter-spacing: var(--h3-bold-letter-spacing);
  line-height: var(--h3-bold-line-height);
}

.typing .text-wrapper-135 {
  color: #ffba15;
  font-family: var(--h3-bold-font-family);
  font-size: var(--h3-bold-font-size);
  font-style: var(--h3-bold-font-style);
  font-weight: var(--h3-bold-font-weight);
  letter-spacing: var(--h3-bold-letter-spacing);
  line-height: var(--h3-bold-line-height);
}

.typing .text-wrapper-136 {
  color: var(--text-600);
  font-family: "ABeeZee", Helvetica;
  font-size: 28px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.typing .frame-240 {
  height: 72px;
  left: 0;
  position: absolute;
  top: 247px;
  width: 1733px;
}

.typing .frame-241 {
  height: 72px;
  left: 0;
  position: absolute;
  top: 0;
  width: 293px;
}

.typing .text-wrapper-137 {
  color: var(--text-600);
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  left: 0;
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.typing .frame-242 {
  align-items: center;
  display: inline-flex;
  gap: 4.66px;
  left: 0;
  position: absolute;
  top: 37px;
}

.typing .text-wrapper-138 {
  color: var(--text-900);
  font-family: var(--small-title-bold-font-family);
  font-size: var(--small-title-bold-font-size);
  font-style: var(--small-title-bold-font-style);
  font-weight: var(--small-title-bold-font-weight);
  letter-spacing: var(--small-title-bold-letter-spacing);
  line-height: var(--small-title-bold-line-height);
  margin-top: -1.17px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.typing .frame-243 {
  height: 72px;
  left: 480px;
  position: absolute;
  top: 0;
  width: 293px;
}

.typing .frame-244 {
  height: 72px;
  left: 960px;
  position: absolute;
  top: 0;
  width: 293px;
}

.typing .frame-245 {
  height: 72px;
  left: 1440px;
  position: absolute;
  top: 0;
  width: 293px;
}

.typing .user-profile-3 {
  align-items: flex-start;
  background-color: #fafafa;
  border-radius: 20px;
  display: inline-flex;
  gap: 180px;
  justify-content: flex-end;
  left: 799px;
  overflow: hidden;
  padding: 50px 84px;
  position: absolute;
  top: 7px;
}

.typing .nuts-3 {
  height: 72px;
  position: relative;
  width: 302px;
}

.typing .icons-16 {
  border: 1.36px solid !important;
  border-radius: 34px !important;
  height: 68px !important;
  left: 0 !important;
  position: absolute !important;
  top: 2px !important;
  width: 68px !important;
}

.typing .icons-17 {
  height: 52px !important;
  left: 7px !important;
  top: 7px !important;
  width: 52px !important;
}

.typing .element-5 {
  height: 72px;
  left: 78px;
  position: absolute;
  top: 0;
  width: 224px;
}

.typing .sol-4 {
  height: 72px;
  position: relative;
  width: 284px;
}

.typing .icons-18 {
  height: 36px !important;
  left: 14px !important;
  top: 14px !important;
  width: 36px !important;
}

.typing .element-6 {
  height: 72px;
  left: 78px;
  position: absolute;
  top: 0;
  width: 206px;
}

.typing .frame-246 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  position: relative;
}

.typing .tabs-9 {
  position: relative !important;
}

.typing .tiers-7 {
  align-items: flex-start;
  background-color: #fafafa;
  border-radius: 20px;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 70px;
  padding: 50px 44px;
  position: relative;
}

.typing .frame-247 {
  height: 94px;
  position: relative;
  width: 1644px;
}

.typing .frame-248 {
  height: 60px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1644px;
}

.typing .frame-249 {
  align-items: center;
  display: inline-flex;
  gap: 10px;
  left: 0;
  position: absolute;
  top: 0;
}

.typing .frame-250 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.typing .icons-19 {
  height: 60px !important;
  position: relative !important;
  width: 60px !important;
}

.typing .icons-20 {
  margin-left: -13px !important;
}

.typing .div-11 {
  color: transparent;
  font-family: "ABeeZee", Helvetica;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.typing .text-wrapper-139 {
  color: #202020;
  font-style: italic;
}

.typing .text-wrapper-140 {
  color: #ffba15;
  font-style: italic;
}

.typing .text-wrapper-141 {
  color: #000000;
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  left: 1598px;
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  position: absolute;
  text-align: right;
  top: 18px;
  width: 46px;
}

.typing .view-3 {
  left: 0 !important;
  position: absolute !important;
  top: 100px !important;
}

.typing .frame-251 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 1644px;
}

.typing .frame-252 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 78px;
  position: relative;
}

.typing .frame-253 {
  align-items: center;
  display: flex;
  gap: 10px;
  position: relative;
  width: 232px;
}

.typing .property-money-instance {
  height: 34px !important;
  left: 12px !important;
  position: absolute !important;
  top: 12px !important;
  width: 34px !important;
}

.typing .frame-254 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6px;
  position: relative;
}

.typing .text-wrapper-142 {
  color: var(--text-600);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.typing .frame-255 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4.66px;
  position: relative;
}

.typing .text-wrapper-143 {
  color: var(--text-900);
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  margin-top: -1.17px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.typing .wallet-16 {
  flex: 0 0 auto !important;
  padding: 16px !important;
}

.typing .wallet-17 {
  margin-top: -1px !important;
}

.typing .frame-256 {
  align-items: center;
  display: flex;
  justify-content: space-between;
  left: 0;
  position: absolute;
  top: 0;
  width: 1644px;
}

.typing .frame-257 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
}

.typing .icons-30-instance {
  height: 29px !important;
  position: relative !important;
  width: 60px !important;
}

.typing .text-wrapper-144 {
  color: #000000;
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  position: relative;
  text-align: right;
  width: 46px;
}

.typing .stake-NUTS-for-free-6 {
  color: var(--text-900);
  font-family: "ABeeZee", Helvetica;
  font-size: 24px;
  font-style: italic;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.typing .nav-8 {
  height: 100px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1920px;
}

.typing .nav-9 {
  background-image: url(../../../static/img/rectangle-2-8.svg);
  background-size: 100% 100%;
  height: 100px;
}

.typing .group-78 {
  height: 51px;
  left: 92px;
  position: relative;
  top: 24px;
  width: 1736px;
}

.typing .logo-8 {
  align-items: center;
  display: inline-flex;
  gap: 8px;
  left: 0;
  position: absolute;
  top: 0;
}

.typing .group-79 {
  height: 50px;
  position: relative;
  width: 50px;
}

.typing .frame-258 {
  background-color: #ffffff;
  border-radius: 24.82px;
  height: 50px;
}

.typing .group-80 {
  background-image: url(../../../static/img/vector.svg);
  background-size: 100% 100%;
  height: 29px;
  left: 10px;
  position: relative;
  top: 10px;
  width: 29px;
}

.typing .group-81 {
  background-color: #ffffff;
  border-radius: 3.63px;
  height: 7px;
  left: 9px;
  position: absolute;
  top: 10px;
  width: 7px;
}

.typing .ellipse-36 {
  background-color: #000000;
  border-radius: 2.66px;
  height: 5px;
  left: 1px;
  position: relative;
  top: 1px;
  width: 5px;
}

.typing .group-82 {
  background-color: #ffffff;
  border-radius: 3.63px;
  height: 7px;
  left: 17px;
  position: absolute;
  top: 3px;
  width: 7px;
}

.typing .group-83 {
  height: 32.88px;
  position: relative;
  width: 106.81px;
}

.typing .group-84 {
  height: 27px;
  left: 0;
  position: absolute;
  top: 6px;
  width: 18px;
}

.typing .group-85 {
  border: 3.66px solid;
  border-color: #000000;
  border-radius: 8.82px;
  height: 18px;
  left: 19px;
  position: absolute;
  top: 6px;
  width: 18px;
}

.typing .ellipse-37 {
  background-color: #000000;
  border-radius: 3.46px;
  height: 7px;
  left: 3px;
  position: relative;
  top: 5px;
  width: 7px;
}

.typing .group-86 {
  border: 3.66px solid;
  border-color: #000000;
  border-radius: 8.82px;
  height: 18px;
  left: 39px;
  position: absolute;
  top: 6px;
  width: 18px;
}

.typing .ellipse-38 {
  background-color: #000000;
  border-radius: 3.46px;
  height: 7px;
  left: 5px;
  position: relative;
  top: 5px;
  width: 7px;
}

.typing .group-87 {
  height: 24px;
  left: 58px;
  position: absolute;
  top: 0;
  width: 18px;
}

.typing .overlap-group-10 {
  height: 24px;
  position: relative;
}

.typing .ellipse-39 {
  border: 3.66px solid;
  border-color: #000000;
  border-radius: 8.82px;
  height: 18px;
  left: 0;
  position: absolute;
  top: 6px;
  width: 18px;
}

.typing .rectangle-20 {
  background-color: #000000;
  border-radius: 0px 0px 1.82px 1.82px;
  height: 17px;
  left: 0;
  position: absolute;
  top: 0;
  width: 4px;
}

.typing .subtract-8 {
  height: 18px;
  left: 77px;
  position: absolute;
  top: 6px;
  width: 18px;
}

.typing .frame-259 {
  align-items: flex-start;
  display: flex;
  height: 18px;
  left: 96px;
  position: absolute;
  top: 6px;
  width: 11px;
}

.typing .rectangle-21 {
  background-color: #000000;
  height: 17.65px;
  position: relative;
  transform: rotate(180deg);
  width: 3.64px;
}

.typing .ellipse-40 {
  background-color: #000000;
  border-radius: 3.46px;
  height: 6.91px;
  position: relative;
  width: 6.91px;
}

.typing .frame-260 {
  height: 18px;
  left: 657px;
  position: absolute;
  top: 16px;
  width: 429px;
}

.typing .text-wrapper-145 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  left: 0;
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.typing .text-wrapper-146 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  left: 103px;
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.typing .text-wrapper-147 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  left: 209px;
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.typing .text-wrapper-148 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  left: 341px;
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.typing .frame-261 {
  align-items: center;
  display: inline-flex;
  gap: 20px;
  left: 1414px;
  position: absolute;
  top: 1px;
}

.typing .frame-262 {
  height: 40px;
  position: relative;
  width: 114px;
}

.typing .twitter-7 {
  background-color: #1c1c1c;
  border-radius: 500px;
  height: 40px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 40px;
}

.typing .x-3 {
  height: 24px !important;
  left: 8px !important;
  position: absolute !important;
  top: 8px !important;
  width: 24px !important;
}

.typing .discord-9 {
  height: 40px;
  left: 64px;
  position: absolute;
  top: 0;
  width: 40px;
}

.typing .wallet-18 {
  flex: 0 0 auto !important;
}

.typing .rectangle-22 {
  height: 1080px;
  left: 0;
  position: fixed;
  top: 0;
  width: 1920px;
}

.typing .wallet-connect-4 {
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 20px;
  display: inline-flex;
  flex-direction: column;
  gap: 20px;
  left: 684px;
  padding: 50px 0px;
  position: fixed;
  top: 359px;
}

.typing .frame-263 {
  height: 62px;
  position: relative;
  width: 551px;
}

.typing .frame-264 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 12px;
  padding: 0px 40px;
  position: relative;
}

.typing .frame-265 {
  height: 32px;
  position: relative;
  width: 471px;
}

.typing .frame-266 {
  height: 32px;
  position: relative;
}

.typing .text-wrapper-149 {
  color: var(--text-900);
  font-family: var(--small-title-bold-font-family);
  font-size: var(--small-title-bold-font-size);
  font-style: var(--small-title-bold-font-style);
  font-weight: var(--small-title-bold-font-weight);
  left: 0;
  letter-spacing: var(--small-title-bold-letter-spacing);
  line-height: var(--small-title-bold-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.typing .iconify-27 {
  height: 24px !important;
  left: 447px !important;
  position: absolute !important;
  top: 4px !important;
  width: 24px !important;
}

.typing .please-note-you-can {
  color: var(--text-900);
  font-family: "ABeeZee", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.32px;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.typing .text-wrapper-150 {
  letter-spacing: 0.05px;
}

.typing .text-wrapper-151 {
  font-style: italic;
  letter-spacing: 0.05px;
}

.typing .frame-267 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  height: 94px;
  position: relative;
}

.typing .frame-268 {
  height: 144px;
  margin-bottom: -50px;
  position: relative;
  width: 552px;
}

.typing .frame-269 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  position: relative;
}

.typing .frame-270 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  padding: 0px 40px;
  position: relative;
}

.typing .frame-271 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  position: relative;
}

.typing .frame-272 {
  height: 18px;
  position: relative;
  width: 472px;
}

.typing .frame-273 {
  height: 18px;
  left: 0;
  position: absolute;
  top: 0;
  width: 129px;
}

.typing .text-wrapper-152 {
  color: var(--text-600);
  font-family: "ABeeZee", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0.32px;
  line-height: normal;
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.typing .text-wrapper-153 {
  color: #000000;
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  left: 80px;
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.typing .frame-274 {
  height: 18px;
  left: 320px;
  position: absolute;
  top: 0;
  width: 152px;
}

.typing .text-wrapper-154 {
  color: var(--text-600);
  font-family: "ABeeZee", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 32px;
  letter-spacing: 0.32px;
  line-height: normal;
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.typing .text-wrapper-155 {
  color: #000000;
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  left: 110px;
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.typing .frame-275 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: var(--primary-500main);
  border-radius: 10px;
  height: 66px;
  position: relative;
  width: 472px;
}

.typing .icons-22 {
  border: 0.84px solid !important;
  border-radius: 21px !important;
  height: 42px !important;
  left: 20px !important;
  position: absolute !important;
  top: 12px !important;
  width: 42px !important;
}

.typing .icons-23 {
  height: 22px !important;
  left: 9px !important;
  top: 9px !important;
  width: 22px !important;
}

.typing .frame-276 {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  left: 72px;
  position: absolute;
  top: 24px;
  width: 380px;
}

.typing .input {
  background-color: transparent;
  border: 0;
  color: transparent;
  flex: 0 0 auto;
  font-family: "ABeeZee", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.32px;
  line-height: normal;
  margin-top: -1px;
  padding: 0;
  position: relative;
  white-space: nowrap;
}

.typing .text-wrapper-156 {
  color: #000000;
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.typing .frame-277 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  height: 66px;
  position: relative;
}

.typing .frame-278 {
  height: 115px;
  margin-bottom: -49px;
  position: relative;
  width: 552px;
}

.typing .frame-279 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  position: relative;
}

.typing .frame-280 {
  background-color: var(--primary-500main);
  border: 1px solid;
  border-color: var(--primary-500main);
  border-radius: 10px;
  height: 66px;
  position: relative;
  width: 472px;
}

.typing .text-wrapper-157 {
  color: #ffffff;
  font-family: "ABeeZee", Helvetica;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  left: 190px;
  letter-spacing: 0.32px;
  line-height: normal;
  position: absolute;
  top: 23px;
  white-space: nowrap;
}
