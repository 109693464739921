.faq-container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.faq-item {
    margin-bottom: 20px;
    padding-bottom: 20px;
}

.faq-section {
    padding: 20px;
    background-color: #f8f8f8;
    /* Light background for the FAQ section */
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);/* Optional shadow for better distinction */
    margin-top: 20px;
    width: 95%;
    /* Spacing from previous content */
}

.faq-title {
    margin-bottom: 20px;
    color: #333;
    /* Dark grey for text */
    font-size: 32px;
    font-weight: 700;
    /* Larger font size for headers */
}

.faq-question {
    font-weight: bold;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 24px;
    font-weight: 1000;
    padding-right: 60px;
}

.blue-link {
    color: #FFBA15;
}

.faq-answer {
    font-size: 18px;
    /* Increased font size for better readability */
    margin-top: 10px;
    /* Adds space between the question and the answer */
    padding-right: 200px;
}

.faq-answer ul {
    margin-top: 10px;
    list-style-type: disc; /* Ensure bullet points are visible */
    padding-left: 40px; /* Increase padding to create more indentation */

}

.faq-answer li {
    margin-top: 10px;
    /* margin-bottom: 10px; */
    padding-bottom: 10px;
    /* Space between list items */
}

