.statusbar {
  position: relative;
}

.statusbar .truedepth-camera {
  background-color: var(--system-backgrounddark-baseprimary);
  border-radius: 100px;
  height: 37px;
}

.statusbar .truedepth-camera-2 {
  background-color: var(--system-backgrounddark-baseprimary);
  border-radius: 100px;
  height: 37px;
  left: 15px;
  position: absolute;
  top: 0;
  width: 80px;
}

.statusbar .facetime-camera {
  background-color: var(--system-backgrounddark-baseprimary);
  border-radius: 100px;
  height: 37px;
  left: 103px;
  position: absolute;
  top: 0;
  width: 37px;
}

.statusbar .facetime-camera-2 {
  background-color: var(--system-backgrounddark-baseprimary);
  border-radius: 100px;
  height: 37px;
  width: 37px;
}

.statusbar .overlap-group-2 {
  background-image: url(../../../static/img/blur.png);
  background-size: 100% 100%;
  height: 222px;
  left: -10px;
  position: relative;
  width: 393px;
}

.statusbar .truedepth-camera-3 {
  background-color: var(--system-backgrounddark-baseprimary);
  border-radius: 100px;
  height: 37px;
  left: 135px;
  position: absolute;
  top: 10px;
  width: 80px;
}

.statusbar .facetime-camera-3 {
  background-color: var(--system-backgrounddark-baseprimary);
  border-radius: 100px;
  height: 37px;
  left: 223px;
  position: absolute;
  top: 10px;
  width: 37px;
}

.statusbar .dynamic-island {
  background-color: var(--system-backgrounddark-baseprimary);
  border-radius: 42px;
  box-shadow: 0px 4px 8px #00000066;
  height: 200px;
  left: 10px;
  position: absolute;
  top: 0;
  width: 373px;
}

.statusbar.type-0-ultra {
  border-radius: 42px;
  height: 200px;
  width: 373px;
}

.statusbar.type-0-default {
  background-color: var(--system-backgrounddark-baseprimary);
  border-radius: 100px;
  height: 37px;
  width: 125px;
}

.statusbar.type-0-x-large {
  background-color: var(--system-backgrounddark-baseprimary);
  border-radius: 100px;
  box-shadow: 0px 4px 8px #00000066;
  height: 83px;
  width: 373px;
}

.statusbar.type-0-large {
  background-color: var(--system-backgrounddark-baseprimary);
  border-radius: 100px;
  height: 37px;
  width: 193px;
}

.statusbar.type-0-separated {
  align-items: flex-start;
  display: inline-flex;
  gap: 8px;
}

.statusbar.type-0-ultra .truedepth-camera {
  position: absolute;
  width: 80px;
}

.statusbar.type-0-default .truedepth-camera {
  left: 0;
  position: absolute;
  top: 0;
  width: 80px;
}

.statusbar.type-0-x-large .truedepth-camera {
  left: 125px;
  position: absolute;
  top: 10px;
  width: 80px;
}

.statusbar.type-0-large .truedepth-camera {
  left: 35px;
  position: absolute;
  top: 0;
  width: 80px;
}

.statusbar.type-0-separated .truedepth-camera {
  position: relative;
  width: 156px;
}

.statusbar.type-0-ultra .facetime-camera-2 {
  position: absolute;
}

.statusbar.type-0-default .facetime-camera-2 {
  left: 88px;
  position: absolute;
  top: 0;
}

.statusbar.type-0-x-large .facetime-camera-2 {
  left: 213px;
  position: absolute;
  top: 10px;
}

.statusbar.type-0-large .facetime-camera-2 {
  left: 123px;
  position: absolute;
  top: 0;
}

.statusbar.type-0-separated .facetime-camera-2 {
  position: relative;
}
