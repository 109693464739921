.property-target {
  background-color: #ffffff;
  border: 1.2px solid;
  border-color: var(--text-300);
  border-radius: 30px;
  height: 60px;
  overflow: hidden;
  position: relative;
  width: 60px;
}

.property-target .iconify-11 {
  height: 34px !important;
  left: 12px !important;
  position: absolute !important;
  top: 12px !important;
  width: 34px !important;
}
