@import "https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap";
@import "https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap";
.footer {
  background-color: #fff;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  padding: 14px 92px;
  display: inline-flex;
  position: relative;
}

.footer .frame-18 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 40px;
  display: inline-flex;
  position: relative;
}

.footer .frame-19 {
  width: 1736px;
  height: 149px;
  position: relative;
}

.footer .frame-20 {
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 0;
}

.footer .frame-21 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 12px;
  display: inline-flex;
  position: relative;
}

.footer .frame-22 {
  width: 124px;
  height: 23px;
  position: relative;
}

.footer .text-wrapper-13 {
  color: var(--text-800);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 0;
}

.footer .frame-23 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 14px;
  display: inline-flex;
  position: relative;
}

.footer .text-wrapper-14 {
  color: var(--text-900);
  letter-spacing: .32px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: ABeeZee, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.footer .text-wrapper-15 {
  color: var(--text-900);
  letter-spacing: .32px;
  white-space: nowrap;
  width: fit-content;
  font-family: ABeeZee, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.footer .frame-24 {
  width: 109px;
  height: 77px;
  position: absolute;
  top: 0;
  left: 1627px;
}

.footer .frame-25 {
  flex-direction: column;
  align-items: flex-end;
  gap: 12px;
  width: 109px;
  display: flex;
  position: relative;
}

.footer .text-wrapper-16 {
  color: var(--text-800);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  text-align: right;
  width: 109px;
  margin-top: -1px;
  position: relative;
}

.footer .group {
  width: 99px;
  height: 40px;
  position: relative;
}

.footer .twitter-2 {
  background-color: #1c1c1c;
  border-radius: 500px;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.footer .x-6 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 8px !important;
  left: 8px !important;
}

.footer .discord {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  left: 59px;
}

.footer .element-goober-all-wrapper {
  border-color: var(--text-300);
  border-top-style: solid;
  border-top-width: 2px;
  width: 1736px;
  height: 61px;
  position: relative;
}

.footer .element-goober-all {
  color: var(--text-900);
  letter-spacing: .32px;
  text-align: center;
  font-family: DM Sans, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 18px;
  left: 662px;
}

.footer .text-wrapper-17 {
  letter-spacing: .05px;
}

.footer .text-wrapper-18 {
  letter-spacing: .05px;
  font-family: ABeeZee, Helvetica;
}

.footer .text-wrapper-19 {
  letter-spacing: .05px;
  font-family: ABeeZee, Helvetica;
  text-decoration: underline;
}

.property-money, .property-NUTS {
  border: 1.2px solid;
  border-color: var(--text-300);
  background-color: #fff;
  border-radius: 30px;
  width: 60px;
  height: 60px;
  position: relative;
  overflow: hidden;
}

.property-NUTS .image {
  object-fit: cover;
  width: 46px;
  height: 46px;
  position: absolute;
  top: 6px;
  left: 6px;
}

.property-SOL {
  border: 1.2px solid;
  border-color: var(--text-300);
  background-color: #fff;
  border-radius: 30px;
  width: 60px;
  height: 60px;
  position: relative;
  overflow: hidden;
}

.property-SOL .img {
  object-fit: cover;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 13px;
  left: 13px;
}

.property-target {
  border: 1.2px solid;
  border-color: var(--text-300);
  background-color: #fff;
  border-radius: 30px;
  width: 60px;
  height: 60px;
  position: relative;
  overflow: hidden;
}

.property-target .iconify-11 {
  width: 34px !important;
  height: 34px !important;
  position: absolute !important;
  top: 12px !important;
  left: 12px !important;
}

.property-wrapper {
  background-color: #fff;
  border-radius: 25px;
  width: 1639px;
  height: 10px;
}

.property-wrapper .rectangle {
  background-color: var(--primary-500main);
  border-radius: 25px;
  height: 10px;
}

.property-wrapper .one {
  width: 26px;
}

.property-wrapper .fifty {
  width: 837px;
}

:root {
  --primary-color: #ffba15;
  --secondary-color: #d4d4d4;
  --background-color: #e6eef9;
  --active-text-color: #000;
}

*, :after, :before {
  box-sizing: border-box;
}

body {
  background-color: var(--background-color);
  font-family: Inter, sans-serif;
}

.container {
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  inset: 0;
}

.tabs {
  background-color: #fff;
  border-radius: 99px;
  width: 875px;
  padding: .75rem;
  display: flex;
  position: relative;

  & * {
    z-index: 2;
  }
}

input[type="radio"] {
  display: none;
}

.tab {
  font-size: 2rem;
  font-weight: 1000;
  font-style: "bold";
  color: var(--secondary-color);
  cursor: pointer;
  border-radius: 99px;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  width: 437.5px;
  height: 54px;
  transition: color .15s ease-in;
  display: flex;
}

input[type="radio"]:checked + label {
  color: var(--active-text-color);
  transition: color .15s ease-in;
}

.glider {
  background-color: var(--secondary-color);
  z-index: 1;
  border-radius: 99px;
  width: 400px;
  height: 4px;
  transition: transform .25s ease-out;
  position: absolute;
  bottom: 0;
}

input[id="radio-1"]:checked ~ .glider {
  background-color: var(--primary-color);
  transform: translateX(0);
}

input[id="radio-2"]:checked ~ .glider {
  background-color: var(--primary-color);
  transform: translateX(108%);
}

@media (width <= 700px) {
  .tabs {
    transform: scale(.6);
  }
}

.phantom {
  background-image: url("image-4.1ccfcd2c.png");
  background-position: 50%;
  background-size: cover;
  width: 24px;
  height: 24px;
}

.wallet {
  display: inline-flex;
  position: relative;
}

.wallet .div-2 {
  background-color: var(--primary-500main);
  border-radius: 10px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 13px 16px;
  display: inline-flex;
  position: relative;
}

.wallet .frame-5 {
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 6px;
  display: inline-flex;
  position: relative;
}

.wallet .phantom-instance {
  position: relative !important;
}

.wallet .text-wrapper-5 {
  color: #fff;
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.wallet .iconify-2 {
  width: 24px !important;
  height: 24px !important;
  position: relative !important;
}

.wallet .frame-wrapper {
  border: 1px solid;
  border-color: var(--text-300);
  background-color: #fff;
  border-radius: 10px;
  width: 188px;
  height: 144px;
  position: relative;
  overflow: hidden;
}

.wallet .frame-6 {
  flex-direction: column;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.wallet .div-wrapper {
  width: 188px;
  height: 48px;
  position: relative;
}

.wallet .frame-7 {
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: relative;
  top: 16px;
  left: 16px;
}

.wallet .text-wrapper-6 {
  color: var(--text-800);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: ABeeZee, Helvetica;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.wallet .frame-8 {
  background-color: var(--primary-500main);
  width: 188px;
  height: 48px;
  position: relative;
}

.wallet .text-wrapper-7 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: ABeeZee, Helvetica;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.wallet .text-wrapper-8 {
  color: var(--text-800);
  letter-spacing: 0;
  width: 94px;
  margin-top: -1px;
  font-family: ABeeZee, Helvetica;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.wallet.wallet-connect, .wallet.phantom-wallet-connected {
  background-color: var(--primary-500main);
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 13px 16px;
}

.wallet.expand {
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
}

.staked {
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.staked .div-4 {
  background-color: #fff;
  width: 1920px;
  height: 2083px;
  position: relative;
}

.staked .frame-51 {
  flex-direction: column;
  align-items: flex-start;
  gap: 80px;
  display: inline-flex;
  position: absolute;
  top: 150px;
  left: 92px;
}

.staked .frame-52 {
  width: 1733px;
  height: 319px;
  position: relative;
}

.staked .frame-53 {
  width: 557px;
  height: 187px;
  position: absolute;
  top: 0;
  left: 0;
}

.staked .group-3 {
  width: 238px;
  height: 50px;
  position: absolute;
  top: 0;
  left: 0;
}

.staked .frame-54 {
  background-color: var(--text-150);
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 16px 14px;
  display: inline-flex;
  position: relative;
}

.staked .text-wrapper-34 {
  color: var(--primary-500main);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.staked .frame-55 {
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
  display: inline-flex;
  position: absolute;
  top: 64px;
  left: 0;
}

.staked .stake-to-mint-GOO {
  color: #0000;
  font-family: var(--h3-bold-font-family);
  font-size: var(--h3-bold-font-size);
  font-style: var(--h3-bold-font-style);
  font-weight: var(--h3-bold-font-weight);
  letter-spacing: var(--h3-bold-letter-spacing);
  line-height: var(--h3-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.staked .text-wrapper-35 {
  color: #202020;
  font-family: var(--h3-bold-font-family);
  font-size: var(--h3-bold-font-size);
  font-style: var(--h3-bold-font-style);
  font-weight: var(--h3-bold-font-weight);
  letter-spacing: var(--h3-bold-letter-spacing);
  line-height: var(--h3-bold-line-height);
}

.staked .text-wrapper-36 {
  color: #ffba15;
  font-family: var(--h3-bold-font-family);
  font-size: var(--h3-bold-font-size);
  font-style: var(--h3-bold-font-style);
  font-weight: var(--h3-bold-font-weight);
  letter-spacing: var(--h3-bold-letter-spacing);
  line-height: var(--h3-bold-line-height);
}

.staked .text-wrapper-37 {
  color: var(--text-600);
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  font-family: ABeeZee, Helvetica;
  font-size: 28px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.staked .frame-56 {
  width: 1733px;
  height: 72px;
  position: absolute;
  top: 247px;
  left: 0;
}

.staked .frame-57 {
  width: 293px;
  height: 72px;
  position: absolute;
  top: 0;
  left: 0;
}

.staked .text-wrapper-38 {
  color: var(--text-600);
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 0;
}

.staked .frame-58 {
  align-items: center;
  gap: 4.66px;
  display: inline-flex;
  position: absolute;
  top: 37px;
  left: 0;
}

.staked .text-wrapper-39 {
  color: var(--text-900);
  font-family: var(--small-title-bold-font-family);
  font-size: var(--small-title-bold-font-size);
  font-style: var(--small-title-bold-font-style);
  font-weight: var(--small-title-bold-font-weight);
  letter-spacing: var(--small-title-bold-letter-spacing);
  line-height: var(--small-title-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1.17px;
  position: relative;
}

.staked .frame-59 {
  width: 293px;
  height: 72px;
  position: absolute;
  top: 0;
  left: 480px;
}

.staked .element-d-hr-min {
  color: #0000;
  font-family: var(--small-title-bold-font-family);
  font-size: var(--small-title-bold-font-size);
  font-style: var(--small-title-bold-font-style);
  font-weight: var(--small-title-bold-font-weight);
  letter-spacing: var(--small-title-bold-letter-spacing);
  line-height: var(--small-title-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1.17px;
  position: relative;
}

.staked .text-wrapper-40 {
  color: #202020;
  font-family: var(--small-title-bold-font-family);
  font-size: var(--small-title-bold-font-size);
  font-style: var(--small-title-bold-font-style);
  font-weight: var(--small-title-bold-font-weight);
  letter-spacing: var(--small-title-bold-letter-spacing);
  line-height: var(--small-title-bold-line-height);
}

.staked .text-wrapper-41 {
  color: #989898;
  font-family: var(--small-title-bold-font-family);
  font-size: var(--small-title-bold-font-size);
  font-style: var(--small-title-bold-font-style);
  font-weight: var(--small-title-bold-font-weight);
  letter-spacing: var(--small-title-bold-letter-spacing);
  line-height: var(--small-title-bold-line-height);
}

.staked .frame-60 {
  width: 293px;
  height: 72px;
  position: absolute;
  top: 0;
  left: 960px;
}

.staked .frame-61 {
  width: 293px;
  height: 72px;
  position: absolute;
  top: 0;
  left: 1440px;
}

.staked .user-profile {
  background-color: #fafafa;
  border-radius: 20px;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 180px;
  padding: 50px 84px;
  display: inline-flex;
  position: absolute;
  top: 7px;
  left: 799px;
  overflow: hidden;
}

.staked .nuts {
  width: 302px;
  height: 72px;
  position: relative;
}

.staked .icons-5 {
  border: 1.36px solid !important;
  border-radius: 34px !important;
  width: 68px !important;
  height: 68px !important;
  position: absolute !important;
  top: 2px !important;
  left: 0 !important;
}

.staked .icons-6 {
  width: 52px !important;
  height: 52px !important;
  top: 7px !important;
  left: 7px !important;
}

.staked .element {
  width: 224px;
  height: 72px;
  position: absolute;
  top: 0;
  left: 78px;
}

.staked .sol-2 {
  width: 284px;
  height: 72px;
  position: relative;
}

.staked .property-SOL-instance {
  width: 36px !important;
  height: 36px !important;
  top: 14px !important;
  left: 14px !important;
}

.staked .element-2 {
  width: 206px;
  height: 72px;
  position: absolute;
  top: 0;
  left: 78px;
}

.staked .frame-62 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 40px;
  display: inline-flex;
  position: relative;
}

.staked .tabs-instance {
  position: relative !important;
}

.staked .tiers-2 {
  background-color: #fafafa;
  border-radius: 20px;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 70px;
  padding: 50px 44px;
  display: inline-flex;
  position: relative;
}

.staked .frame-63 {
  width: 1644px;
  height: 94px;
  position: relative;
}

.staked .frame-64 {
  width: 1644px;
  height: 60px;
  position: absolute;
  top: 0;
  left: 0;
}

.staked .frame-65 {
  align-items: center;
  gap: 10px;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 0;
}

.staked .frame-66 {
  flex: none;
  align-items: center;
  display: inline-flex;
  position: relative;
}

.staked .icons-7 {
  width: 60px !important;
  height: 60px !important;
  position: relative !important;
}

.staked .icons-8 {
  margin-left: -13px !important;
}

.staked .div-5 {
  color: #0000;
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  font-family: ABeeZee, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.staked .text-wrapper-42 {
  color: #202020;
  font-style: italic;
}

.staked .text-wrapper-43 {
  color: #ffba15;
  font-style: italic;
}

.staked .text-wrapper-44 {
  color: #000;
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  text-align: right;
  width: 46px;
  position: absolute;
  top: 18px;
  left: 1598px;
}

.staked .view {
  position: absolute !important;
  top: 100px !important;
  left: 0 !important;
}

.staked .frame-67 {
  flex: none;
  justify-content: space-between;
  align-items: center;
  width: 1644px;
  display: flex;
  position: relative;
}

.staked .frame-68 {
  flex: none;
  align-items: flex-start;
  gap: 78px;
  display: inline-flex;
  position: relative;
}

.staked .frame-69 {
  align-items: center;
  gap: 10px;
  width: 232px;
  display: flex;
  position: relative;
}

.staked .frame-70 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 6px;
  display: inline-flex;
  position: relative;
}

.staked .text-wrapper-45 {
  color: var(--text-600);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.staked .frame-71 {
  flex: none;
  align-items: center;
  gap: 4.66px;
  display: inline-flex;
  position: relative;
}

.staked .text-wrapper-46 {
  color: var(--text-900);
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1.17px;
  position: relative;
}

.staked .wallet-2 {
  background-color: var(--primary-100) !important;
  flex: none !important;
}

.staked .property-1-star-bold-doutone {
  width: 24px !important;
  height: 24px !important;
  position: relative !important;
}

.staked .wallet-3 {
  color: var(--primary-500main) !important;
}

.staked .frame-72 {
  justify-content: space-between;
  align-items: center;
  width: 1644px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.staked .frame-73 {
  flex: none;
  align-items: center;
  gap: 10px;
  display: inline-flex;
  position: relative;
}

.staked .text-wrapper-47 {
  color: #000;
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  text-align: right;
  width: 46px;
  position: relative;
}

.staked .stake-NUTS-for-free-2 {
  color: var(--text-900);
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  font-family: ABeeZee, Helvetica;
  font-size: 24px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.staked .wallet-4 {
  flex: none !important;
  padding: 16px !important;
}

.staked .wallet-5 {
  margin-top: -1px !important;
}

.staked .footer-instance {
  position: absolute !important;
  top: 1805px !important;
  left: 0 !important;
}

.staked .footer-2 {
  left: 656px !important;
}

.staked .nav {
  width: 1920px;
  height: 100px;
  position: absolute;
  top: 0;
  left: 0;
}

.staked .group-wrapper {
  background-image: url("rectangle-2-3.5d9b2786.svg");
  background-size: 100% 100%;
  height: 100px;
}

.staked .group-4 {
  width: 1736px;
  height: 51px;
  position: relative;
  top: 24px;
  left: 92px;
}

.staked .logo {
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 0;
}

.staked .group-5 {
  width: 50px;
  height: 50px;
  position: relative;
}

.staked .frame-74 {
  background-color: #fff;
  border-radius: 24.82px;
  height: 50px;
}

.staked .group-6 {
  background-image: url("vector.563a97e7.svg");
  background-size: 100% 100%;
  width: 29px;
  height: 29px;
  position: relative;
  top: 10px;
  left: 10px;
}

.staked .ellipse-wrapper {
  background-color: #fff;
  border-radius: 3.63px;
  width: 7px;
  height: 7px;
  position: absolute;
  top: 10px;
  left: 9px;
}

.staked .ellipse {
  background-color: #000;
  border-radius: 2.66px;
  width: 5px;
  height: 5px;
  position: relative;
  top: 1px;
  left: 1px;
}

.staked .group-7 {
  background-color: #fff;
  border-radius: 3.63px;
  width: 7px;
  height: 7px;
  position: absolute;
  top: 3px;
  left: 17px;
}

.staked .group-8 {
  width: 106.81px;
  height: 32.88px;
  position: relative;
}

.staked .group-9 {
  width: 18px;
  height: 27px;
  position: absolute;
  top: 6px;
  left: 0;
}

.staked .group-10 {
  border: 3.66px solid #000;
  border-radius: 8.82px;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 6px;
  left: 19px;
}

.staked .ellipse-2 {
  background-color: #000;
  border-radius: 3.46px;
  width: 7px;
  height: 7px;
  position: relative;
  top: 5px;
  left: 3px;
}

.staked .group-11 {
  border: 3.66px solid #000;
  border-radius: 8.82px;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 6px;
  left: 39px;
}

.staked .ellipse-3 {
  background-color: #000;
  border-radius: 3.46px;
  width: 7px;
  height: 7px;
  position: relative;
  top: 5px;
  left: 5px;
}

.staked .overlap-group-wrapper {
  width: 18px;
  height: 24px;
  position: absolute;
  top: 0;
  left: 58px;
}

.staked .overlap-group-3 {
  height: 24px;
  position: relative;
}

.staked .ellipse-4 {
  border: 3.66px solid #000;
  border-radius: 8.82px;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 6px;
  left: 0;
}

.staked .rectangle-3 {
  background-color: #000;
  border-radius: 0 0 1.82px 1.82px;
  width: 4px;
  height: 17px;
  position: absolute;
  top: 0;
  left: 0;
}

.staked .subtract {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 6px;
  left: 77px;
}

.staked .frame-75 {
  align-items: flex-start;
  width: 11px;
  height: 18px;
  display: flex;
  position: absolute;
  top: 6px;
  left: 96px;
}

.staked .rectangle-4 {
  background-color: #000;
  width: 3.64px;
  height: 17.65px;
  position: relative;
  transform: rotate(180deg);
}

.staked .ellipse-5 {
  background-color: #000;
  border-radius: 3.46px;
  width: 6.91px;
  height: 6.91px;
  position: relative;
}

.staked .frame-76 {
  width: 429px;
  height: 18px;
  position: absolute;
  top: 16px;
  left: 657px;
}

.staked .text-wrapper-48 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 0;
}

.staked .text-wrapper-49 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 103px;
}

.staked .text-wrapper-50 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 209px;
}

.staked .text-wrapper-51 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 341px;
}

.staked .frame-77 {
  align-items: center;
  gap: 20px;
  display: inline-flex;
  position: absolute;
  top: 1px;
  left: 1414px;
}

.staked .frame-78 {
  width: 114px;
  height: 40px;
  position: relative;
}

.staked .twitter-4 {
  background-color: #1c1c1c;
  border-radius: 500px;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.staked .x-12 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 8px !important;
  left: 8px !important;
}

.staked .discord-3 {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  left: 64px;
}

.staked .wallet-6 {
  flex: none !important;
}

.statusbar {
  position: relative;
}

.statusbar .truedepth-camera {
  background-color: var(--system-backgrounddark-baseprimary);
  border-radius: 100px;
  height: 37px;
}

.statusbar .truedepth-camera-2 {
  background-color: var(--system-backgrounddark-baseprimary);
  border-radius: 100px;
  width: 80px;
  height: 37px;
  position: absolute;
  top: 0;
  left: 15px;
}

.statusbar .facetime-camera {
  background-color: var(--system-backgrounddark-baseprimary);
  border-radius: 100px;
  width: 37px;
  height: 37px;
  position: absolute;
  top: 0;
  left: 103px;
}

.statusbar .facetime-camera-2 {
  background-color: var(--system-backgrounddark-baseprimary);
  border-radius: 100px;
  width: 37px;
  height: 37px;
}

.statusbar .overlap-group-2 {
  background-image: url("blur.dc30ff80.png");
  background-size: 100% 100%;
  width: 393px;
  height: 222px;
  position: relative;
  left: -10px;
}

.statusbar .truedepth-camera-3 {
  background-color: var(--system-backgrounddark-baseprimary);
  border-radius: 100px;
  width: 80px;
  height: 37px;
  position: absolute;
  top: 10px;
  left: 135px;
}

.statusbar .facetime-camera-3 {
  background-color: var(--system-backgrounddark-baseprimary);
  border-radius: 100px;
  width: 37px;
  height: 37px;
  position: absolute;
  top: 10px;
  left: 223px;
}

.statusbar .dynamic-island {
  background-color: var(--system-backgrounddark-baseprimary);
  border-radius: 42px;
  width: 373px;
  height: 200px;
  position: absolute;
  top: 0;
  left: 10px;
  box-shadow: 0 4px 8px #0006;
}

.statusbar.type-0-ultra {
  border-radius: 42px;
  width: 373px;
  height: 200px;
}

.statusbar.type-0-default {
  background-color: var(--system-backgrounddark-baseprimary);
  border-radius: 100px;
  width: 125px;
  height: 37px;
}

.statusbar.type-0-x-large {
  background-color: var(--system-backgrounddark-baseprimary);
  border-radius: 100px;
  width: 373px;
  height: 83px;
  box-shadow: 0 4px 8px #0006;
}

.statusbar.type-0-large {
  background-color: var(--system-backgrounddark-baseprimary);
  border-radius: 100px;
  width: 193px;
  height: 37px;
}

.statusbar.type-0-separated {
  align-items: flex-start;
  gap: 8px;
  display: inline-flex;
}

.statusbar.type-0-ultra .truedepth-camera {
  width: 80px;
  position: absolute;
}

.statusbar.type-0-default .truedepth-camera {
  width: 80px;
  position: absolute;
  top: 0;
  left: 0;
}

.statusbar.type-0-x-large .truedepth-camera {
  width: 80px;
  position: absolute;
  top: 10px;
  left: 125px;
}

.statusbar.type-0-large .truedepth-camera {
  width: 80px;
  position: absolute;
  top: 0;
  left: 35px;
}

.statusbar.type-0-separated .truedepth-camera {
  width: 156px;
  position: relative;
}

.statusbar.type-0-ultra .facetime-camera-2 {
  position: absolute;
}

.statusbar.type-0-default .facetime-camera-2 {
  position: absolute;
  top: 0;
  left: 88px;
}

.statusbar.type-0-x-large .facetime-camera-2 {
  position: absolute;
  top: 10px;
  left: 213px;
}

.statusbar.type-0-large .facetime-camera-2 {
  position: absolute;
  top: 0;
  left: 123px;
}

.statusbar.type-0-separated .facetime-camera-2 {
  position: relative;
}

.mobile-nav {
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.mobile-nav .mobile-nav-status {
  position: relative;
}

.mobile-nav .frame-79 {
  width: 1733px;
  height: 319px;
  position: absolute;
  top: 150px;
  left: 92px;
}

.mobile-nav .frame-80 {
  width: 557px;
  height: 187px;
  position: absolute;
  top: 0;
  left: 0;
}

.mobile-nav .group-12 {
  width: 238px;
  height: 50px;
  position: absolute;
  top: 0;
  left: 0;
}

.mobile-nav .frame-81 {
  background-color: var(--text-150);
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 16px 14px;
  display: inline-flex;
  position: relative;
}

.mobile-nav .text-wrapper-52 {
  color: var(--primary-500main);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.mobile-nav .frame-82 {
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
  display: inline-flex;
  position: absolute;
  top: 64px;
  left: 0;
}

.mobile-nav .stake-to-mint-GOO-2 {
  color: #0000;
  font-family: var(--h3-bold-font-family);
  font-size: var(--h3-bold-font-size);
  font-style: var(--h3-bold-font-style);
  font-weight: var(--h3-bold-font-weight);
  letter-spacing: var(--h3-bold-letter-spacing);
  line-height: var(--h3-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.mobile-nav .text-wrapper-53 {
  color: #202020;
  font-family: var(--h3-bold-font-family);
  font-size: var(--h3-bold-font-size);
  font-style: var(--h3-bold-font-style);
  font-weight: var(--h3-bold-font-weight);
  letter-spacing: var(--h3-bold-letter-spacing);
  line-height: var(--h3-bold-line-height);
}

.mobile-nav .text-wrapper-54 {
  color: #ffba15;
  font-family: var(--h3-bold-font-family);
  font-size: var(--h3-bold-font-size);
  font-style: var(--h3-bold-font-style);
  font-weight: var(--h3-bold-font-weight);
  letter-spacing: var(--h3-bold-letter-spacing);
  line-height: var(--h3-bold-line-height);
}

.mobile-nav .text-wrapper-55 {
  color: var(--text-600);
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  font-family: ABeeZee, Helvetica;
  font-size: 28px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.mobile-nav .frame-83 {
  width: 1733px;
  height: 72px;
  position: absolute;
  top: 247px;
  left: 0;
}

.mobile-nav .frame-84 {
  width: 293px;
  height: 72px;
  position: absolute;
  top: 0;
  left: 0;
}

.mobile-nav .text-wrapper-56 {
  color: var(--text-600);
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 0;
}

.mobile-nav .frame-85 {
  align-items: center;
  gap: 4.66px;
  display: inline-flex;
  position: absolute;
  top: 37px;
  left: 0;
}

.mobile-nav .text-wrapper-57 {
  color: var(--text-900);
  font-family: var(--small-title-bold-font-family);
  font-size: var(--small-title-bold-font-size);
  font-style: var(--small-title-bold-font-style);
  font-weight: var(--small-title-bold-font-weight);
  letter-spacing: var(--small-title-bold-letter-spacing);
  line-height: var(--small-title-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1.17px;
  position: relative;
}

.mobile-nav .frame-86 {
  width: 293px;
  height: 72px;
  position: absolute;
  top: 0;
  left: 480px;
}

.mobile-nav .frame-87 {
  width: 293px;
  height: 72px;
  position: absolute;
  top: 0;
  left: 960px;
}

.mobile-nav .frame-88 {
  width: 293px;
  height: 72px;
  position: absolute;
  top: 0;
  left: 1440px;
}

.mobile-nav .tabs-wrapper {
  width: 875px;
  height: 48px;
  position: absolute;
  top: 549px;
  left: 92px;
}

.mobile-nav .tabs-2 {
  width: 875px !important;
}

.mobile-nav .tabs-3 {
  color: var(--text-500) !important;
}

.mobile-nav .tabs-4 {
  color: var(--text-900) !important;
}

.mobile-nav .tabs-5 {
  width: unset !important;
}

.mobile-nav .frame-89 {
  flex-direction: column;
  align-items: flex-start;
  display: inline-flex;
  position: absolute;
}

.mobile-nav .frame-90 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.mobile-nav .wallet-7 {
  background-color: var(--white);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--text-500);
  width: 345px;
  height: 52px;
  margin-top: -1px;
  margin-left: -1px;
  margin-right: -1px;
  position: relative;
}

.mobile-nav .frame-91 {
  justify-content: center;
  align-items: center;
  gap: 6px;
  display: inline-flex;
  position: absolute;
  top: 16px;
  left: 0;
}

.mobile-nav .property-1-wallet-fill {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 13px !important;
  left: 319px !important;
}

.mobile-nav .wallet-8 {
  background-color: var(--white);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--text-500);
  width: 345px;
  height: 52px;
  margin-left: -1px;
  margin-right: -1px;
  position: relative;
}

.mobile-nav .frame-92 {
  justify-content: center;
  align-items: center;
  gap: 6px;
  display: inline-flex;
  position: relative;
  top: 16px;
}

.mobile-nav .text-wrapper-58 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.mobile-nav .wallet-9 {
  background-color: var(--white);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--text-500);
  width: 345px;
  height: 52px;
  margin-bottom: -1px;
  margin-left: -1px;
  margin-right: -1px;
  position: relative;
}

.mobile-nav .text-wrapper-59 {
  color: var(--text-900);
  font-family: var(--h4-bold-font-family);
  font-size: var(--h4-bold-font-size);
  font-style: var(--h4-bold-font-style);
  font-weight: var(--h4-bold-font-weight);
  letter-spacing: var(--h4-bold-letter-spacing);
  line-height: var(--h4-bold-line-height);
  width: 383px;
  margin-top: -1px;
  position: relative;
}

.mobile-nav .flexcontainer {
  flex-direction: column;
  align-items: flex-start;
  gap: 52.8px;
  width: 1732px;
  display: flex;
}

.mobile-nav .text {
  color: #0000;
  letter-spacing: .36px;
  align-self: stretch;
  font-family: ABeeZee, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  position: relative;
}

.mobile-nav .text-wrapper-60 {
  color: #202020;
  letter-spacing: .06px;
}

.mobile-nav .text-wrapper-61 {
  color: #ffba15;
  letter-spacing: .06px;
  font-style: italic;
}

.mobile-nav .text-wrapper-62 {
  color: #202020;
  letter-spacing: .06px;
  font-style: italic;
}

.mobile-nav .frame-93 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 14px;
  display: inline-flex;
  position: relative;
}

.mobile-nav .frame-94 {
  width: 1732px;
  height: 655px;
  position: relative;
}

.mobile-nav .frame-95 {
  width: 1732px;
  height: 63px;
  position: absolute;
  top: 0;
  left: 0;
}

.mobile-nav .frame-96 {
  justify-content: space-between;
  align-items: flex-start;
  width: 1732px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.mobile-nav .text-wrapper-63 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: ABeeZee, Helvetica;
  font-size: 24px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.mobile-nav .iconify-30 {
  width: 34px !important;
  height: 34px !important;
  position: relative !important;
}

.mobile-nav .text-wrapper-64 {
  color: #000;
  letter-spacing: .36px;
  width: 1732px;
  font-family: ABeeZee, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 41px;
  left: 0;
}

.mobile-nav .frame-97 {
  width: 1732px;
  height: 34px;
  position: absolute;
  top: 103px;
  left: 0;
}

.mobile-nav .text-wrapper-65 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  font-family: ABeeZee, Helvetica;
  font-size: 24px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 0;
}

.mobile-nav .iconify-31 {
  width: 34px !important;
  height: 34px !important;
  position: absolute !important;
  top: 0 !important;
  left: 1698px !important;
}

.mobile-nav .frame-98 {
  width: 1732px;
  height: 34px;
  position: absolute;
  top: 177px;
  left: 0;
}

.mobile-nav .frame-99 {
  width: 1732px;
  height: 34px;
  position: absolute;
  top: 251px;
  left: 0;
}

.mobile-nav .frame-100 {
  width: 1732px;
  height: 34px;
  position: absolute;
  top: 325px;
  left: 0;
}

.mobile-nav .frame-101 {
  width: 1732px;
  height: 34px;
  position: absolute;
  top: 399px;
  left: 0;
}

.mobile-nav .frame-102 {
  width: 1732px;
  height: 34px;
  position: absolute;
  top: 473px;
  left: 0;
}

.mobile-nav .frame-103 {
  width: 1732px;
  height: 34px;
  position: absolute;
  top: 547px;
  left: 0;
}

.mobile-nav .frame-104 {
  width: 1732px;
  height: 34px;
  position: absolute;
  top: 621px;
  left: 0;
}

.mobile-nav .frame-105 {
  width: 73.5px;
  height: 30px;
  position: relative;
}

.mobile-nav .x-wrapper {
  background-color: #1c1c1c;
  border-radius: 375px;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.mobile-nav .x-6-instance {
  width: 18px !important;
  height: 18px !important;
  position: absolute !important;
  top: 6px !important;
  left: 6px !important;
}

.mobile-nav .discord-4 {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 0;
  left: 44px;
}

.mobile-nav .element-goober-all-3 {
  color: var(--text-900);
  letter-spacing: .24px;
  width: 309px;
  font-family: DM Sans, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.mobile-nav .text-wrapper-66 {
  letter-spacing: .03px;
}

.mobile-nav .text-wrapper-67 {
  letter-spacing: .03px;
  font-family: ABeeZee, Helvetica;
}

.mobile-nav .text-wrapper-68 {
  letter-spacing: .03px;
  font-family: ABeeZee, Helvetica;
  text-decoration: underline;
}

.mobile-nav .footer-3 {
  position: absolute !important;
  top: 1883px !important;
  left: 0 !important;
}

.mobile-nav .footer-4 {
  left: 656px !important;
}

.mobile-nav .nav-2 {
  background-image: url("rectangle-2-4.b0fe6656.svg");
  background-size: 100% 100%;
  width: 1920px;
  height: 100px;
  position: fixed;
  top: 0;
  left: 0;
}

.mobile-nav .group-13 {
  width: 1736px;
  height: 51px;
  position: relative;
  top: 24px;
  left: 92px;
}

.mobile-nav .logo-2 {
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 0;
}

.mobile-nav .group-14 {
  width: 50px;
  height: 50px;
  position: relative;
}

.mobile-nav .frame-106 {
  background-color: #fff;
  border-radius: 24.82px;
  height: 50px;
}

.mobile-nav .group-15 {
  background-image: url("vector.563a97e7.svg");
  background-size: 100% 100%;
  width: 29px;
  height: 29px;
  position: relative;
  top: 10px;
  left: 10px;
}

.mobile-nav .group-16 {
  background-color: #fff;
  border-radius: 3.63px;
  width: 7px;
  height: 7px;
  position: absolute;
  top: 10px;
  left: 9px;
}

.mobile-nav .ellipse-6 {
  background-color: #000;
  border-radius: 2.66px;
  width: 5px;
  height: 5px;
  position: relative;
  top: 1px;
  left: 1px;
}

.mobile-nav .group-17 {
  background-color: #fff;
  border-radius: 3.63px;
  width: 7px;
  height: 7px;
  position: absolute;
  top: 3px;
  left: 17px;
}

.mobile-nav .group-18 {
  width: 106.81px;
  height: 32.88px;
  position: relative;
}

.mobile-nav .group-19 {
  width: 18px;
  height: 27px;
  position: absolute;
  top: 6px;
  left: 0;
}

.mobile-nav .group-20 {
  border: 3.66px solid #000;
  border-radius: 8.82px;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 6px;
  left: 19px;
}

.mobile-nav .ellipse-7 {
  background-color: #000;
  border-radius: 3.46px;
  width: 7px;
  height: 7px;
  position: relative;
  top: 5px;
  left: 3px;
}

.mobile-nav .group-21 {
  border: 3.66px solid #000;
  border-radius: 8.82px;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 6px;
  left: 39px;
}

.mobile-nav .ellipse-8 {
  background-color: #000;
  border-radius: 3.46px;
  width: 7px;
  height: 7px;
  position: relative;
  top: 5px;
  left: 5px;
}

.mobile-nav .group-22 {
  width: 18px;
  height: 24px;
  position: absolute;
  top: 0;
  left: 58px;
}

.mobile-nav .overlap-group-4 {
  height: 24px;
  position: relative;
}

.mobile-nav .ellipse-9 {
  border: 3.66px solid #000;
  border-radius: 8.82px;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 6px;
  left: 0;
}

.mobile-nav .rectangle-5 {
  background-color: #000;
  border-radius: 0 0 1.82px 1.82px;
  width: 4px;
  height: 17px;
  position: absolute;
  top: 0;
  left: 0;
}

.mobile-nav .subtract-2 {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 6px;
  left: 77px;
}

.mobile-nav .frame-107 {
  align-items: flex-start;
  width: 11px;
  height: 18px;
  display: flex;
  position: absolute;
  top: 6px;
  left: 96px;
}

.mobile-nav .rectangle-6 {
  background-color: #000;
  width: 3.64px;
  height: 17.65px;
  position: relative;
  transform: rotate(180deg);
}

.mobile-nav .ellipse-10 {
  background-color: #000;
  border-radius: 3.46px;
  width: 6.91px;
  height: 6.91px;
  position: relative;
}

.mobile-nav .navbar {
  width: 429px;
  height: 18px;
  position: absolute;
  top: 16px;
  left: 657px;
}

.mobile-nav .text-wrapper-69 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 0;
}

.mobile-nav .text-wrapper-70 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 103px;
}

.mobile-nav .text-wrapper-71 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 209px;
}

.mobile-nav .text-wrapper-72 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 341px;
}

.mobile-nav .frame-108 {
  align-items: center;
  gap: 20px;
  display: inline-flex;
  position: absolute;
  top: 1px;
  left: 1421px;
}

.mobile-nav .frame-109 {
  width: 114px;
  height: 40px;
  position: relative;
}

.mobile-nav .x-6-wrapper {
  background-color: #1c1c1c;
  border-radius: 500px;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.mobile-nav .state-default-instance {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 8px !important;
  left: 8px !important;
}

.mobile-nav .discord-5 {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  left: 64px;
}

.mobile-nav .wallet-10 {
  flex: none !important;
}

.mobile-nav .iconify-70 {
  width: 24px !important;
  height: 24px !important;
  position: relative !important;
}

.mobile-nav .frame-110 {
  flex-direction: column;
  align-items: flex-start;
  display: inline-flex;
  position: fixed;
  top: 0;
  left: 0;
}

.mobile-nav .status-bar-instance {
  background-color: #fff !important;
  width: 375px !important;
}

.mobile-nav .status-bar-2 {
  color: var(--themes-black-100) !important;
}

.mobile-nav .nav-3 {
  background-color: #fff;
  width: 375px;
  height: 54px;
  position: relative;
}

.mobile-nav .logo-3 {
  align-items: center;
  gap: 5.44px;
  display: inline-flex;
  position: absolute;
  top: 10px;
  left: 15px;
}

.mobile-nav .group-23 {
  width: 33.98px;
  height: 33.98px;
  position: relative;
}

.mobile-nav .frame-111 {
  background-color: #fff;
  border-radius: 16.86px;
  width: 34px;
  height: 34px;
}

.mobile-nav .group-24 {
  background-image: url("vector-5.a5aed117.svg");
  background-size: 100% 100%;
  width: 20px;
  height: 20px;
  position: relative;
  top: 7px;
  left: 7px;
}

.mobile-nav .group-25 {
  background-color: #fff;
  border-radius: 2.47px;
  width: 5px;
  height: 5px;
  position: absolute;
  top: 7px;
  left: 6px;
}

.mobile-nav .ellipse-11 {
  background-color: #000;
  border-radius: 1.81px;
  width: 4px;
  height: 4px;
  position: relative;
  top: 1px;
  left: 1px;
}

.mobile-nav .group-26 {
  background-color: #fff;
  border-radius: 2.47px;
  width: 5px;
  height: 5px;
  position: absolute;
  top: 2px;
  left: 12px;
}

.mobile-nav .group-27 {
  width: 72.59px;
  height: 22.34px;
  position: relative;
}

.mobile-nav .group-28 {
  width: 12px;
  height: 18px;
  position: absolute;
  top: 4px;
  left: 0;
}

.mobile-nav .group-29 {
  border: 2.49px solid #000;
  border-radius: 6px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 4px;
  left: 13px;
}

.mobile-nav .ellipse-12 {
  background-color: #000;
  border-radius: 2.35px;
  width: 5px;
  height: 5px;
  position: relative;
  top: 3px;
  left: 2px;
}

.mobile-nav .group-30 {
  border: 2.49px solid #000;
  border-radius: 6px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 4px;
  left: 26px;
}

.mobile-nav .ellipse-13 {
  background-color: #000;
  border-radius: 2.35px;
  width: 5px;
  height: 5px;
  position: relative;
  top: 3px;
  left: 4px;
}

.mobile-nav .group-31 {
  width: 12px;
  height: 16px;
  position: absolute;
  top: 0;
  left: 39px;
}

.mobile-nav .overlap-group-5 {
  height: 16px;
  position: relative;
}

.mobile-nav .ellipse-14 {
  border: 2.49px solid #000;
  border-radius: 6px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 4px;
  left: 0;
}

.mobile-nav .rectangle-7 {
  background-color: #000;
  border-radius: 0 0 1.24px 1.24px;
  width: 2px;
  height: 12px;
  position: absolute;
  top: 0;
  left: 0;
}

.mobile-nav .subtract-3 {
  width: 12px;
  height: 12px;
  position: absolute;
  top: 4px;
  left: 52px;
}

.mobile-nav .frame-112 {
  align-items: flex-start;
  width: 7px;
  height: 12px;
  display: flex;
  position: absolute;
  top: 4px;
  left: 65px;
}

.mobile-nav .rectangle-8 {
  background-color: #000;
  width: 2.47px;
  height: 11.99px;
  position: relative;
  transform: rotate(180deg);
}

.mobile-nav .ellipse-15 {
  background-color: #000;
  border-radius: 2.35px;
  width: 4.7px;
  height: 4.7px;
  position: relative;
}

.mobile-nav .menu {
  background-image: url("iconify-27.92aa50f3.svg");
  background-size: 100% 100%;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 15px;
  left: 335px;
}

.icons-54 {
  width: 60px !important;
  height: 60px !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.twitter {
  background-color: #1c1c1c;
  border-radius: 500px;
  width: 40px;
  height: 40px;
  position: relative;
  overflow: hidden;
}

.property-twitter {
  border: 1.2px solid;
  border-color: var(--text-300);
  background-color: #fff;
  border-radius: 30px;
  width: 60px;
  height: 60px;
  overflow: hidden;
}

.icons-53 {
  width: 60px !important;
  height: 60px !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.success-share-on-tw {
  background-color: #0000;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.success-share-on-tw .div-6 {
  width: 1920px;
  height: 1080px;
  position: relative;
  overflow: hidden;
}

.success-share-on-tw .overlap {
  background-color: #fff;
  width: 1920px;
  height: 2083px;
  position: absolute;
  top: 0;
  left: 0;
}

.success-share-on-tw .frame-113 {
  flex-direction: column;
  align-items: flex-start;
  gap: 80px;
  display: inline-flex;
  position: absolute;
  top: 150px;
  left: 92px;
}

.success-share-on-tw .frame-114 {
  width: 1733px;
  height: 319px;
  position: relative;
}

.success-share-on-tw .frame-115 {
  width: 557px;
  height: 187px;
  position: absolute;
  top: 0;
  left: 0;
}

.success-share-on-tw .group-32 {
  width: 238px;
  height: 50px;
  position: absolute;
  top: 0;
  left: 0;
}

.success-share-on-tw .frame-116 {
  background-color: var(--text-150);
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 16px 14px;
  display: inline-flex;
  position: relative;
}

.success-share-on-tw .text-wrapper-73 {
  color: var(--primary-500main);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.success-share-on-tw .frame-117 {
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
  display: inline-flex;
  position: absolute;
  top: 64px;
  left: 0;
}

.success-share-on-tw .stake-to-mint-GOO-3 {
  color: #0000;
  font-family: var(--h3-bold-font-family);
  font-size: var(--h3-bold-font-size);
  font-style: var(--h3-bold-font-style);
  font-weight: var(--h3-bold-font-weight);
  letter-spacing: var(--h3-bold-letter-spacing);
  line-height: var(--h3-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.success-share-on-tw .text-wrapper-74 {
  color: #202020;
  font-family: var(--h3-bold-font-family);
  font-size: var(--h3-bold-font-size);
  font-style: var(--h3-bold-font-style);
  font-weight: var(--h3-bold-font-weight);
  letter-spacing: var(--h3-bold-letter-spacing);
  line-height: var(--h3-bold-line-height);
}

.success-share-on-tw .text-wrapper-75 {
  color: #ffba15;
  font-family: var(--h3-bold-font-family);
  font-size: var(--h3-bold-font-size);
  font-style: var(--h3-bold-font-style);
  font-weight: var(--h3-bold-font-weight);
  letter-spacing: var(--h3-bold-letter-spacing);
  line-height: var(--h3-bold-line-height);
}

.success-share-on-tw .text-wrapper-76 {
  color: var(--text-600);
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  font-family: ABeeZee, Helvetica;
  font-size: 28px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.success-share-on-tw .frame-118 {
  width: 1733px;
  height: 72px;
  position: absolute;
  top: 247px;
  left: 0;
}

.success-share-on-tw .frame-119 {
  width: 293px;
  height: 72px;
  position: absolute;
  top: 0;
  left: 0;
}

.success-share-on-tw .text-wrapper-77 {
  color: var(--text-600);
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 0;
}

.success-share-on-tw .frame-120 {
  align-items: center;
  gap: 4.66px;
  display: inline-flex;
  position: absolute;
  top: 37px;
  left: 0;
}

.success-share-on-tw .text-wrapper-78 {
  color: var(--text-900);
  font-family: var(--small-title-bold-font-family);
  font-size: var(--small-title-bold-font-size);
  font-style: var(--small-title-bold-font-style);
  font-weight: var(--small-title-bold-font-weight);
  letter-spacing: var(--small-title-bold-letter-spacing);
  line-height: var(--small-title-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1.17px;
  position: relative;
}

.success-share-on-tw .frame-121 {
  width: 293px;
  height: 72px;
  position: absolute;
  top: 0;
  left: 480px;
}

.success-share-on-tw .frame-122 {
  width: 293px;
  height: 72px;
  position: absolute;
  top: 0;
  left: 960px;
}

.success-share-on-tw .frame-123 {
  width: 293px;
  height: 72px;
  position: absolute;
  top: 0;
  left: 1440px;
}

.success-share-on-tw .user-profile-2 {
  background-color: #fafafa;
  border-radius: 20px;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 180px;
  padding: 50px 84px;
  display: inline-flex;
  position: absolute;
  top: 7px;
  left: 799px;
  overflow: hidden;
}

.success-share-on-tw .nuts-2 {
  width: 302px;
  height: 72px;
  position: relative;
}

.success-share-on-tw .icons-9 {
  border: 1.36px solid !important;
  border-radius: 34px !important;
  width: 68px !important;
  height: 68px !important;
  position: absolute !important;
  top: 2px !important;
  left: 0 !important;
}

.success-share-on-tw .icons-10 {
  width: 52px !important;
  height: 52px !important;
  top: 7px !important;
  left: 7px !important;
}

.success-share-on-tw .element-3 {
  width: 224px;
  height: 72px;
  position: absolute;
  top: 0;
  left: 78px;
}

.success-share-on-tw .sol-3 {
  width: 284px;
  height: 72px;
  position: relative;
}

.success-share-on-tw .property-1-SOL-instance {
  width: 36px !important;
  height: 36px !important;
  top: 14px !important;
  left: 14px !important;
}

.success-share-on-tw .element-4 {
  width: 206px;
  height: 72px;
  position: absolute;
  top: 0;
  left: 78px;
}

.success-share-on-tw .frame-124 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 40px;
  display: inline-flex;
  position: relative;
}

.success-share-on-tw .tabs-6 {
  position: relative !important;
}

.success-share-on-tw .tiers-3 {
  background-color: #fafafa;
  border-radius: 20px;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 70px;
  padding: 50px 44px;
  display: inline-flex;
  position: relative;
}

.success-share-on-tw .frame-125 {
  width: 1644px;
  height: 94px;
  position: relative;
}

.success-share-on-tw .frame-126 {
  width: 1644px;
  height: 60px;
  position: absolute;
  top: 0;
  left: 0;
}

.success-share-on-tw .frame-127 {
  align-items: center;
  gap: 10px;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 0;
}

.success-share-on-tw .frame-128 {
  flex: none;
  align-items: center;
  display: inline-flex;
  position: relative;
}

.success-share-on-tw .icons-11 {
  width: 60px !important;
  height: 60px !important;
  position: relative !important;
}

.success-share-on-tw .icons-12 {
  margin-left: -13px !important;
}

.success-share-on-tw .div-7 {
  color: #0000;
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  font-family: ABeeZee, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.success-share-on-tw .text-wrapper-79 {
  color: #202020;
  font-style: italic;
}

.success-share-on-tw .text-wrapper-80 {
  color: #ffba15;
  font-style: italic;
}

.success-share-on-tw .text-wrapper-81 {
  color: #000;
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  text-align: right;
  width: 46px;
  position: absolute;
  top: 18px;
  left: 1598px;
}

.success-share-on-tw .component-5508-instance {
  position: absolute !important;
  top: 100px !important;
  left: 0 !important;
}

.success-share-on-tw .frame-129 {
  flex: none;
  justify-content: space-between;
  align-items: center;
  width: 1644px;
  display: flex;
  position: relative;
}

.success-share-on-tw .frame-130 {
  flex: none;
  align-items: flex-start;
  gap: 78px;
  display: inline-flex;
  position: relative;
}

.success-share-on-tw .frame-131 {
  align-items: center;
  gap: 10px;
  width: 232px;
  display: flex;
  position: relative;
}

.success-share-on-tw .frame-132 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 6px;
  display: inline-flex;
  position: relative;
}

.success-share-on-tw .text-wrapper-82 {
  color: var(--text-600);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.success-share-on-tw .frame-133 {
  flex: none;
  align-items: center;
  gap: 4.66px;
  display: inline-flex;
  position: relative;
}

.success-share-on-tw .text-wrapper-83 {
  color: var(--text-900);
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1.17px;
  position: relative;
}

.success-share-on-tw .wallet-11 {
  flex: none !important;
  padding: 16px !important;
}

.success-share-on-tw .wallet-12 {
  margin-top: -1px !important;
}

.success-share-on-tw .frame-134 {
  justify-content: space-between;
  align-items: center;
  width: 1644px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.success-share-on-tw .frame-135 {
  flex: none;
  align-items: center;
  gap: 10px;
  display: inline-flex;
  position: relative;
}

.success-share-on-tw .icons-30 {
  width: 60px !important;
  height: 29px !important;
  position: relative !important;
}

.success-share-on-tw .text-wrapper-84 {
  color: #000;
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  text-align: right;
  width: 46px;
  position: relative;
}

.success-share-on-tw .stake-NUTS-for-free-3 {
  color: var(--text-900);
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  font-family: ABeeZee, Helvetica;
  font-size: 24px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.success-share-on-tw .nav-wrapper {
  width: 1920px;
  height: 100px;
  position: absolute;
  top: 0;
  left: 0;
}

.success-share-on-tw .nav-4 {
  background-image: url("rectangle-2-5.8fdda743.svg");
  background-size: 100% 100%;
  height: 100px;
}

.success-share-on-tw .group-33 {
  width: 1736px;
  height: 51px;
  position: relative;
  top: 24px;
  left: 92px;
}

.success-share-on-tw .logo-4 {
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 0;
}

.success-share-on-tw .group-34 {
  width: 50px;
  height: 50px;
  position: relative;
}

.success-share-on-tw .frame-136 {
  background-color: #fff;
  border-radius: 24.82px;
  height: 50px;
}

.success-share-on-tw .group-35 {
  background-image: url("vector.563a97e7.svg");
  background-size: 100% 100%;
  width: 29px;
  height: 29px;
  position: relative;
  top: 10px;
  left: 10px;
}

.success-share-on-tw .group-36 {
  background-color: #fff;
  border-radius: 3.63px;
  width: 7px;
  height: 7px;
  position: absolute;
  top: 10px;
  left: 9px;
}

.success-share-on-tw .ellipse-16 {
  background-color: #000;
  border-radius: 2.66px;
  width: 5px;
  height: 5px;
  position: relative;
  top: 1px;
  left: 1px;
}

.success-share-on-tw .group-37 {
  background-color: #fff;
  border-radius: 3.63px;
  width: 7px;
  height: 7px;
  position: absolute;
  top: 3px;
  left: 17px;
}

.success-share-on-tw .group-38 {
  width: 106.81px;
  height: 32.88px;
  position: relative;
}

.success-share-on-tw .group-39 {
  width: 18px;
  height: 27px;
  position: absolute;
  top: 6px;
  left: 0;
}

.success-share-on-tw .group-40 {
  border: 3.66px solid #000;
  border-radius: 8.82px;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 6px;
  left: 19px;
}

.success-share-on-tw .ellipse-17 {
  background-color: #000;
  border-radius: 3.46px;
  width: 7px;
  height: 7px;
  position: relative;
  top: 5px;
  left: 3px;
}

.success-share-on-tw .group-41 {
  border: 3.66px solid #000;
  border-radius: 8.82px;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 6px;
  left: 39px;
}

.success-share-on-tw .ellipse-18 {
  background-color: #000;
  border-radius: 3.46px;
  width: 7px;
  height: 7px;
  position: relative;
  top: 5px;
  left: 5px;
}

.success-share-on-tw .group-42 {
  width: 18px;
  height: 24px;
  position: absolute;
  top: 0;
  left: 58px;
}

.success-share-on-tw .overlap-group-6 {
  height: 24px;
  position: relative;
}

.success-share-on-tw .ellipse-19 {
  border: 3.66px solid #000;
  border-radius: 8.82px;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 6px;
  left: 0;
}

.success-share-on-tw .rectangle-9 {
  background-color: #000;
  border-radius: 0 0 1.82px 1.82px;
  width: 4px;
  height: 17px;
  position: absolute;
  top: 0;
  left: 0;
}

.success-share-on-tw .subtract-4 {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 6px;
  left: 77px;
}

.success-share-on-tw .frame-137 {
  align-items: flex-start;
  width: 11px;
  height: 18px;
  display: flex;
  position: absolute;
  top: 6px;
  left: 96px;
}

.success-share-on-tw .rectangle-10 {
  background-color: #000;
  width: 3.64px;
  height: 17.65px;
  position: relative;
  transform: rotate(180deg);
}

.success-share-on-tw .ellipse-20 {
  background-color: #000;
  border-radius: 3.46px;
  width: 6.91px;
  height: 6.91px;
  position: relative;
}

.success-share-on-tw .frame-138 {
  width: 429px;
  height: 18px;
  position: absolute;
  top: 16px;
  left: 657px;
}

.success-share-on-tw .text-wrapper-85 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 0;
}

.success-share-on-tw .text-wrapper-86 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 103px;
}

.success-share-on-tw .text-wrapper-87 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 209px;
}

.success-share-on-tw .text-wrapper-88 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 341px;
}

.success-share-on-tw .frame-139 {
  align-items: center;
  gap: 20px;
  display: inline-flex;
  position: absolute;
  top: 1px;
  left: 1414px;
}

.success-share-on-tw .frame-140 {
  width: 114px;
  height: 40px;
  position: relative;
}

.success-share-on-tw .twitter-5 {
  background-color: #1c1c1c;
  border-radius: 500px;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.success-share-on-tw .x-instance {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 8px !important;
  left: 8px !important;
}

.success-share-on-tw .discord-6 {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  left: 64px;
}

.success-share-on-tw .wallet-13 {
  flex: none !important;
}

.success-share-on-tw .rectangle-11 {
  width: 1920px;
  height: 1080px;
  position: fixed;
  top: 0;
  left: 0;
}

.success-share-on-tw .wallet-connect-2 {
  opacity: 0;
  background-color: #fff;
  border-radius: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  padding: 50px 0;
  display: block;
  position: fixed;
  top: 342px;
  left: 684px;
  transform: scale(1.1);
}

.success-share-on-tw .frame-141 {
  width: 551px;
  height: 82px;
  position: relative;
}

.success-share-on-tw .frame-142 {
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  padding: 0 40px;
  display: inline-flex;
  position: relative;
}

.success-share-on-tw .frame-143 {
  width: 471px;
  height: 32px;
  position: relative;
}

.success-share-on-tw .frame-144 {
  height: 32px;
  position: relative;
}

.success-share-on-tw .text-wrapper-89 {
  color: var(--text-900);
  font-family: var(--small-title-bold-font-family);
  font-size: var(--small-title-bold-font-size);
  font-style: var(--small-title-bold-font-style);
  font-weight: var(--small-title-bold-font-weight);
  letter-spacing: var(--small-title-bold-letter-spacing);
  line-height: var(--small-title-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 0;
}

.success-share-on-tw .property-1-variant7 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 4px !important;
  left: 447px !important;
}

.success-share-on-tw .you-ve-staked {
  color: var(--text-900);
  letter-spacing: .32px;
  width: 471px;
  font-family: ABeeZee, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.success-share-on-tw .text-wrapper-90 {
  letter-spacing: .05px;
}

.success-share-on-tw .text-wrapper-91 {
  letter-spacing: .05px;
  font-style: italic;
}

.success-share-on-tw .frame-145 {
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  height: 108px;
  display: inline-flex;
  position: relative;
}

.success-share-on-tw .frame-146 {
  width: 552px;
  height: 108px;
  position: relative;
}

.success-share-on-tw .frame-147 {
  flex-direction: column;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.success-share-on-tw .frame-148 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 10px;
  padding: 0 40px;
  display: inline-flex;
  position: relative;
}

.success-share-on-tw .frame-149 {
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  height: 108px;
  display: inline-flex;
  position: relative;
}

.success-share-on-tw .GOO-staking-is-now-wrapper {
  border: 1px solid;
  border-color: var(--primary-500main);
  background-color: #fff;
  border-radius: 10px;
  width: 472px;
  height: 108px;
  position: relative;
}

.success-share-on-tw .GOO-staking-is-now {
  color: var(--text-900);
  letter-spacing: .32px;
  background-color: #0000;
  border: 0;
  width: 439px;
  height: 76px;
  padding: 0;
  font-family: ABeeZee, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: relative;
  top: 17px;
  left: 17px;
}

.success-share-on-tw .frame-150 {
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  height: 66px;
  display: inline-flex;
  position: relative;
}

.success-share-on-tw .frame-151 {
  width: 552px;
  height: 115px;
  margin-bottom: -49px;
  position: relative;
}

.success-share-on-tw .frame-152 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 10px;
  display: inline-flex;
  position: relative;
}

.success-share-on-tw .frame-153 {
  background-color: var(--text-900);
  border: 1px solid;
  border-color: var(--text-900);
  border-radius: 10px;
  width: 472px;
  height: 66px;
  position: relative;
}

.success-share-on-tw .frame-154 {
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: relative;
  top: 13px;
  left: 141px;
}

.success-share-on-tw .property-1-twitter {
  border: .8px solid !important;
  border-radius: 20px !important;
  width: 40px !important;
  height: 40px !important;
  position: relative !important;
}

.success-share-on-tw .property-twitter-instance {
  border-radius: 333.33px !important;
  width: 27px !important;
  height: 27px !important;
  top: 6px !important;
  left: 6px !important;
}

.success-share-on-tw .property-1-twitter-instance {
  width: 16px !important;
  height: 16px !important;
  position: absolute !important;
  top: 5px !important;
  left: 5px !important;
}

.success-share-on-tw .text-wrapper-92 {
  color: #fff;
  letter-spacing: .32px;
  white-space: nowrap;
  width: fit-content;
  font-family: ABeeZee, Helvetica;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.footer-wrapper {
  background-color: #fff;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  padding: 24px 30px;
  display: inline-flex;
  position: relative;
}

.footer-wrapper .frame-40 {
  width: 315px;
  height: 108px;
  position: relative;
}

.footer-wrapper .frame-41 {
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 0;
}

.footer-wrapper .frame-42 {
  width: 124px;
  height: 16px;
  position: relative;
}

.footer-wrapper .text-wrapper-25 {
  color: var(--text-800);
  letter-spacing: 0;
  white-space: nowrap;
  font-family: ABeeZee, Helvetica;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 0;
}

.footer-wrapper .frame-43 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 10px;
  display: inline-flex;
  position: relative;
}

.footer-wrapper .text-wrapper-26 {
  color: var(--text-900);
  letter-spacing: .24px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: ABeeZee, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.footer-wrapper .text-wrapper-27 {
  color: var(--text-900);
  letter-spacing: .24px;
  white-space: nowrap;
  width: fit-content;
  font-family: ABeeZee, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.footer-wrapper .frame-44 {
  width: 109px;
  height: 77px;
  position: absolute;
  top: 0;
  left: 206px;
}

.footer-wrapper .frame-45 {
  flex-direction: column;
  align-items: flex-end;
  gap: 6px;
  width: 109px;
  display: flex;
  position: relative;
}

.footer-wrapper .text-wrapper-28 {
  color: var(--text-800);
  letter-spacing: 0;
  text-align: right;
  width: 109px;
  margin-top: -1px;
  font-family: ABeeZee, Helvetica;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.footer-wrapper .group-2 {
  width: 67.5px;
  height: 30px;
  position: relative;
}

.footer-wrapper .twitter-3 {
  background-color: #1c1c1c;
  border-radius: 375px;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.footer-wrapper .x-15 {
  width: 18px !important;
  height: 18px !important;
  position: absolute !important;
  top: 6px !important;
  left: 6px !important;
}

.footer-wrapper .discord-2 {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 0;
  left: 38px;
}

.footer-wrapper .frame-46 {
  border-color: var(--text-300);
  border-top-style: solid;
  border-top-width: 1px;
  width: 315px;
  height: 26px;
  position: relative;
}

.footer-wrapper .element-goober-all-2 {
  color: var(--text-900);
  letter-spacing: .24px;
  text-align: center;
  width: 309px;
  font-family: DM Sans, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 4px;
  left: 3px;
}

.footer-wrapper .text-wrapper-29 {
  letter-spacing: .03px;
}

.footer-wrapper .text-wrapper-30 {
  letter-spacing: .03px;
  font-family: ABeeZee, Helvetica;
}

.footer-wrapper .text-wrapper-31 {
  letter-spacing: .03px;
  font-family: ABeeZee, Helvetica;
  text-decoration: underline;
}

.icons {
  width: 30px;
  height: 30px;
}

.icons .property-NUTS-instance {
  width: unset !important;
  border: .6px solid !important;
  border-radius: 15px !important;
  height: 30px !important;
}

.icons .property-1-NUTS-instance {
  width: 23px !important;
  height: 23px !important;
  top: 3px !important;
  left: 3px !important;
}

.icons .image-2 {
  object-fit: cover;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 6px;
  left: 6px;
}

.icons.sol {
  border: .6px solid;
  border-color: var(--text-300);
  background-color: #fff;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
}

.wallet-wrapper {
  background-color: var(--primary-100);
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 315px;
  height: 44px;
  padding: 12px 62px;
  display: flex;
  position: relative;
}

.wallet-wrapper .frame-26 {
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 6px;
  display: inline-flex;
  position: relative;
}

.wallet-wrapper .iconify-8 {
  width: 20px !important;
  height: 20px !important;
  position: relative !important;
}

.wallet-wrapper .connect-wallet-to {
  color: var(--primary-500main);
  font-family: var(--notes-bold-font-family);
  font-size: var(--notes-bold-font-size);
  font-style: var(--notes-bold-font-style);
  font-weight: var(--notes-bold-font-weight);
  letter-spacing: var(--notes-bold-letter-spacing);
  line-height: var(--notes-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.mobile-tiers {
  background-color: #fafafa;
  border-radius: 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  padding: 14px;
  display: inline-flex;
  position: relative;
}

.mobile-tiers .frame-27 {
  width: 315px;
  height: 123px;
  position: relative;
}

.mobile-tiers .frame-28 {
  width: 315px;
  height: 50px;
  position: absolute;
  top: 0;
  left: 0;
}

.mobile-tiers .frame-29 {
  width: 55px;
  height: 30px;
  position: absolute;
  top: 0;
  left: 0;
}

.mobile-tiers .frame-30 {
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.mobile-tiers .icons-25 {
  width: 30px !important;
  height: 30px !important;
  position: relative !important;
}

.mobile-tiers .p {
  color: #0000;
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  width: 315px;
  position: absolute;
  top: 31px;
  left: 0;
}

.mobile-tiers .text-wrapper-20 {
  color: #202020;
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
}

.mobile-tiers .text-wrapper-21 {
  color: #ffba15;
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
}

.mobile-tiers .frame-31 {
  flex-direction: column;
  align-items: flex-start;
  gap: 13px;
  display: inline-flex;
  position: absolute;
  top: 56px;
  left: 0;
}

.mobile-tiers .frame-32 {
  width: 315px;
  height: 24px;
  position: relative;
}

.mobile-tiers .text-wrapper-22 {
  color: #000;
  letter-spacing: 0;
  text-align: right;
  width: 46px;
  font-family: ABeeZee, Helvetica;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 269px;
}

.mobile-tiers .rectangle-wrapper {
  background-color: #fff;
  border-radius: 25px;
  width: 315px;
  height: 10px;
  position: absolute;
  top: 14px;
  left: 0;
}

.mobile-tiers .rectangle-2 {
  background-color: var(--primary-500main);
  border-radius: 25px;
  width: 10px;
  height: 10px;
}

.mobile-tiers .frame-33 {
  width: 315px;
  height: 30px;
  position: relative;
}

.mobile-tiers .frame-34 {
  align-items: flex-start;
  gap: 20px;
  display: inline-flex;
  position: relative;
  top: 1px;
}

.mobile-tiers .frame-35 {
  width: 80px;
  height: 27px;
  position: relative;
}

.mobile-tiers .icons-2 {
  border: .48px solid !important;
  border-radius: 12px !important;
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 2px !important;
  left: 0 !important;
}

.mobile-tiers .frame-36 {
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 27px;
}

.mobile-tiers .text-wrapper-23 {
  color: var(--text-600);
  font-family: var(--mobile-10px-font-family);
  font-size: var(--mobile-10px-font-size);
  font-style: var(--mobile-10px-font-style);
  font-weight: var(--mobile-10px-font-weight);
  letter-spacing: var(--mobile-10px-letter-spacing);
  line-height: var(--mobile-10px-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -.55px;
  position: relative;
}

.mobile-tiers .frame-37 {
  flex: none;
  align-items: center;
  gap: 2.59px;
  display: inline-flex;
  position: relative;
}

.mobile-tiers .text-wrapper-24 {
  color: var(--text-900);
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  margin-top: -.65px;
  font-family: ABeeZee, Helvetica;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.mobile-tiers .frame-38 {
  width: 103px;
  height: 27px;
  position: relative;
}

.mobile-tiers .icons-21 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 2px !important;
  left: 0 !important;
}

.mobile-tiers .frame-39 {
  width: 51px;
  height: 27px;
  position: relative;
}

.mobile-tiers .iconify-62 {
  width: 14px !important;
  height: 14px !important;
  position: absolute !important;
  top: 5px !important;
  left: 5px !important;
}

.tiers {
  background-color: #fafafa;
  border-radius: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 70px;
  padding: 50px 44px;
  display: inline-flex;
  position: relative;
}

.tiers .frame-9 {
  width: 1644px;
  height: 94px;
  position: relative;
}

.tiers .frame-10 {
  justify-content: space-between;
  align-items: center;
  width: 1644px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.tiers .frame-11 {
  flex: none;
  align-items: center;
  gap: 10px;
  display: inline-flex;
  position: relative;
}

.tiers .frame-12 {
  flex: none;
  align-items: center;
  display: inline-flex;
  position: relative;
}

.tiers .stake-NUTS-for-free {
  color: #0000;
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  font-family: Aeonik TRIAL-Bold, Helvetica;
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.tiers .span {
  color: #202020;
}

.tiers .text-wrapper-9 {
  color: #ffba15;
}

.tiers .text-wrapper-10 {
  color: #000;
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  text-align: right;
  width: 46px;
  position: relative;
}

.tiers .component-5508 {
  position: absolute !important;
  top: 100px !important;
  left: 0 !important;
}

.tiers .frame-13 {
  flex: none;
  justify-content: space-between;
  align-items: center;
  width: 1644px;
  display: flex;
  position: relative;
}

.tiers .frame-14 {
  flex: none;
  align-items: flex-start;
  gap: 78px;
  display: inline-flex;
  position: relative;
}

.tiers .frame-15 {
  align-items: center;
  gap: 10px;
  width: 232px;
  display: flex;
  position: relative;
}

.tiers .frame-16 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 6px;
  display: inline-flex;
  position: relative;
}

.tiers .text-wrapper-11 {
  color: var(--text-600);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.tiers .frame-17 {
  flex: none;
  align-items: center;
  gap: 4.66px;
  display: inline-flex;
  position: relative;
}

.tiers .text-wrapper-12 {
  color: var(--text-900);
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1.17px;
  position: relative;
}

.tiers .property-1-money-bill-wave-alt {
  width: 34px !important;
  height: 34px !important;
  position: absolute !important;
  top: 12px !important;
  left: 12px !important;
}

.tiers .wallet-instance {
  background-color: var(--primary-100) !important;
  flex: none !important;
}

.tiers .design-component-instance-node {
  color: var(--primary-500main) !important;
}

.tiers .iconify-26 {
  width: 24px !important;
  height: 24px !important;
  position: relative !important;
}

.tiers .property-1-NUTS {
  margin-left: -13px !important;
}

.tiers .icon-instance-node {
  width: 60px !important;
  height: 60px !important;
  position: relative !important;
}

.faq-container {
  flex-direction: column;
  width: 100%;
  display: flex;
}

.faq-item {
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.faq-section {
  background-color: #f8f8f8;
  border-radius: 8px;
  width: 95%;
  margin-top: 20px;
  padding: 20px;
  box-shadow: 0 2px 4px #0000001a;
}

.faq-title {
  color: #333;
  margin-bottom: 20px;
  font-size: 32px;
  font-weight: 700;
}

.faq-question {
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  padding-right: 60px;
  font-size: 24px;
  font-weight: 1000;
  display: flex;
}

.blue-link {
  color: #ffba15;
}

.faq-answer {
  margin-top: 10px;
  padding-right: 200px;
  font-size: 18px;
}

.faq-answer ul {
  margin-top: 10px;
  padding-left: 40px;
  list-style-type: disc;
}

.faq-answer li {
  margin-top: 10px;
  padding-bottom: 10px;
}

.wallet-button {
  color: #fff;
  cursor: pointer;
  background-color: #ffba15;
  background-color: var(--primary-500main);
  border: none;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 13px 16px;
  font-size: 16px;
  transition: background-color .3s;
  display: flex;
}

.wallet-button:hover {
  background-color: #e0a806;
}

.wallet-button.connected {
  background-color: #ccc;
}

.iconify-26 {
  width: 24px !important;
  height: 24px !important;
  position: relative !important;
}

.text-wrapper-5 {
  color: #fff;
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.wallet-adapter-button {
  color: #fff;
  cursor: pointer;
  background-color: #0000;
  border: none;
  border-radius: 4px;
  align-items: center;
  height: 48px;
  padding: 0 24px;
  font-family: DM Sans, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 48px;
  display: flex;
}

.wallet-adapter-button-trigger {
  background-color: #512da8;
}

.wallet-adapter-button:not([disabled]):focus-visible {
  outline-color: #fff;
}

.wallet-adapter-button:not([disabled]):hover {
  background-color: #1a1f2e;
}

.wallet-adapter-button[disabled] {
  color: #999;
  cursor: not-allowed;
  background: #404144;
}

.wallet-adapter-button-end-icon, .wallet-adapter-button-start-icon, .wallet-adapter-button-end-icon img, .wallet-adapter-button-start-icon img {
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  display: flex;
}

.wallet-adapter-button-end-icon {
  margin-left: 12px;
}

.wallet-adapter-button-start-icon {
  margin-right: 12px;
}

.wallet-adapter-collapse {
  width: 100%;
}

.wallet-adapter-dropdown {
  display: inline-block;
  position: relative;
}

.wallet-adapter-dropdown-list {
  z-index: 99;
  grid-row-gap: 10px;
  opacity: 0;
  visibility: hidden;
  background: #2c2d30;
  border-radius: 10px;
  grid-template-rows: 1fr;
  margin: 0;
  padding: 10px;
  font-family: DM Sans, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
  list-style: none;
  transition: opacity .2s, transform .2s, visibility .2s;
  display: grid;
  position: absolute;
  top: 100%;
  right: 0;
  box-shadow: 0 8px 20px #0009;
}

.wallet-adapter-dropdown-list-active {
  opacity: 1;
  visibility: visible;
  transform: translateY(10px);
}

.wallet-adapter-dropdown-list-item {
  cursor: pointer;
  white-space: nowrap;
  box-sizing: border-box;
  color: #fff;
  border: none;
  border-radius: 6px;
  outline: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 37px;
  padding: 0 20px;
  font-size: 14px;
  font-weight: 600;
  display: flex;
}

.wallet-adapter-dropdown-list-item:not([disabled]):hover {
  background-color: #1a1f2e;
}

.wallet-adapter-modal-collapse-button svg {
  fill: #999;
  align-self: center;
}

.wallet-adapter-modal-collapse-button.wallet-adapter-modal-collapse-button-active svg {
  transition: transform .15s ease-in;
  transform: rotate(180deg);
}

.wallet-adapter-modal {
  opacity: 0;
  z-index: 1040;
  background: #00000080;
  transition: opacity .15s linear;
  position: fixed;
  inset: 0;
  overflow-y: auto;
}

.wallet-adapter-modal.wallet-adapter-modal-fade-in {
  opacity: 1;
}

.wallet-adapter-modal-button-close {
  cursor: pointer;
  background: #1a1f2e;
  border: none;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  padding: 12px;
  display: flex;
  position: absolute;
  top: 18px;
  right: 18px;
}

.wallet-adapter-modal-button-close:focus-visible {
  outline-color: #fff;
}

.wallet-adapter-modal-button-close svg {
  fill: #777;
  transition: fill .2s;
}

.wallet-adapter-modal-button-close:hover svg {
  fill: #fff;
}

.wallet-adapter-modal-overlay {
  background: #00000080;
  position: fixed;
  inset: 0;
}

.wallet-adapter-modal-container {
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 6rem);
  margin: 3rem;
  display: flex;
}

@media (width <= 480px) {
  .wallet-adapter-modal-container {
    min-height: calc(100vh - 2rem);
    margin: 1rem;
  }
}

.wallet-adapter-modal-wrapper {
  box-sizing: border-box;
  z-index: 1050;
  background: #10141f;
  border-radius: 10px;
  flex-direction: column;
  flex: 1;
  align-items: center;
  max-width: 400px;
  font-family: DM Sans, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
  display: flex;
  position: relative;
  box-shadow: 0 8px 20px #0009;
}

.wallet-adapter-modal-wrapper .wallet-adapter-button {
  width: 100%;
}

.wallet-adapter-modal-title {
  text-align: center;
  color: #fff;
  margin: 0;
  padding: 64px 48px 48px;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
}

@media (width <= 374px) {
  .wallet-adapter-modal-title {
    font-size: 18px;
  }
}

.wallet-adapter-modal-list {
  width: 100%;
  margin: 0 0 12px;
  padding: 0;
  list-style: none;
}

.wallet-adapter-modal-list .wallet-adapter-button {
  border-radius: 0;
  font-size: 18px;
  font-weight: 400;
}

.wallet-adapter-modal-list .wallet-adapter-button-end-icon, .wallet-adapter-modal-list .wallet-adapter-button-start-icon, .wallet-adapter-modal-list .wallet-adapter-button-end-icon img, .wallet-adapter-modal-list .wallet-adapter-button-start-icon img {
  width: 28px;
  height: 28px;
}

.wallet-adapter-modal-list .wallet-adapter-button span {
  opacity: .6;
  margin-left: auto;
  font-size: 14px;
}

.wallet-adapter-modal-list-more {
  cursor: pointer;
  color: #fff;
  background-color: #0000;
  border: none;
  align-self: flex-end;
  align-items: center;
  padding: 12px 24px 24px 12px;
  display: flex;
}

.wallet-adapter-modal-list-more svg {
  fill: #fff;
  margin-left: .5rem;
  transition: all .1s;
}

.wallet-adapter-modal-list-more-icon-rotate {
  transform: rotate(180deg);
}

.wallet-adapter-modal-middle {
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 24px 24px;
  display: flex;
}

.wallet-adapter-modal-middle-button {
  cursor: pointer;
  color: #fff;
  background-color: #512da8;
  border: none;
  border-radius: 8px;
  width: 100%;
  margin-top: 48px;
  padding: 12px;
  font-size: 18px;
  display: block;
}

.element-d-hr-min-s {
  color: #0000;
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1.17px;
  font-family: Aeonik TRIAL-Bold, Helvetica;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

@media (width >= 768px) {
  .countdown {
    font-size: 28px;
  }
}

.text-wrapper-231 {
  color: #202020;
}

.text-wrapper-232 {
  color: #d4d4d4;
}

.stake-page-tiers {
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.stake-page-tiers .div-8 {
  min-height: 2200px;
  padding-bottom: 50px;
  position: relative;
}

.stake-page-tiers .overlap-2 {
  background-color: #fff;
  width: 375px;
  height: 1317px;
  position: absolute;
  top: 0;
  left: 0;
}

.stake-page-tiers .frame-155 {
  flex-direction: column;
  align-items: flex-start;
  gap: 3px;
  display: inline-flex;
  position: absolute;
  top: 125px;
  left: 15px;
}

.stake-page-tiers .group-43 {
  width: 147px;
  height: 27px;
  position: relative;
}

.stake-page-tiers .frame-156 {
  background-color: var(--text-150);
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 8px 10px;
  display: inline-flex;
  position: relative;
}

.stake-page-tiers .text-wrapper-93 {
  color: var(--primary-500main);
  font-family: var(--mobile-10px-font-family);
  font-size: var(--mobile-10px-font-size);
  font-style: var(--mobile-10px-font-style);
  font-weight: var(--mobile-10px-font-weight);
  letter-spacing: var(--mobile-10px-letter-spacing);
  line-height: var(--mobile-10px-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -.52px;
  position: relative;
}

.stake-page-tiers .frame-157 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 4px;
  display: inline-flex;
  position: relative;
}

.stake-page-tiers .stake-to-mint-GOO-4 {
  color: #0000;
  font-family: var(--small-title-bold-font-family);
  font-size: var(--small-title-bold-font-size);
  font-style: var(--small-title-bold-font-style);
  font-weight: var(--small-title-bold-font-weight);
  letter-spacing: var(--small-title-bold-letter-spacing);
  line-height: var(--small-title-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -.52px;
  position: relative;
}

.stake-page-tiers .text-wrapper-94 {
  color: #202020;
  font-family: var(--small-title-bold-font-family);
  font-size: var(--small-title-bold-font-size);
  font-style: var(--small-title-bold-font-style);
  font-weight: var(--small-title-bold-font-weight);
  letter-spacing: var(--small-title-bold-letter-spacing);
  line-height: var(--small-title-bold-line-height);
}

.stake-page-tiers .text-wrapper-95 {
  color: #ffba15;
  font-family: var(--small-title-bold-font-family);
  font-size: var(--small-title-bold-font-size);
  font-style: var(--small-title-bold-font-style);
  font-weight: var(--small-title-bold-font-weight);
  letter-spacing: var(--small-title-bold-letter-spacing);
  line-height: var(--small-title-bold-line-height);
}

.stake-page-tiers .text-wrapper-96 {
  color: var(--text-600);
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  font-family: ABeeZee, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.stake-page-tiers .frame-158 {
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
  display: inline-flex;
  position: absolute;
  top: 229px;
  left: 16px;
}

.stake-page-tiers .frame-159 {
  width: 278.58px;
  height: 33px;
  position: relative;
}

.stake-page-tiers .frame-160 {
  width: 134px;
  height: 33px;
  position: absolute;
  top: 0;
  left: 0;
}

.stake-page-tiers .text-wrapper-97 {
  color: var(--text-600);
  font-family: var(--mobile-10px-font-family);
  font-size: var(--mobile-10px-font-size);
  font-style: var(--mobile-10px-font-style);
  font-weight: var(--mobile-10px-font-weight);
  letter-spacing: var(--mobile-10px-letter-spacing);
  line-height: var(--mobile-10px-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 0;
}

.stake-page-tiers .frame-161 {
  align-items: center;
  gap: 2.14px;
  display: inline-flex;
  position: absolute;
  top: 15px;
  left: 0;
}

.stake-page-tiers .text-wrapper-98 {
  color: var(--text-900);
  font-family: var(--notes-bold-font-family);
  font-size: var(--notes-bold-font-size);
  font-style: var(--notes-bold-font-style);
  font-weight: var(--notes-bold-font-weight);
  letter-spacing: var(--notes-bold-letter-spacing);
  line-height: var(--notes-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -.53px;
  position: relative;
}

.stake-page-tiers .frame-162 {
  width: 134px;
  height: 33px;
  position: absolute;
  top: 0;
  left: 144px;
}

.stake-page-tiers .frame-163 {
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  display: inline-flex;
  position: absolute;
  top: 341px;
  left: 16px;
}

.stake-page-tiers .group-44 {
  width: 343px;
  height: 19px;
  position: relative;
}

.stake-page-tiers .vector-3 {
  width: 343px;
  height: 1px;
  position: absolute;
  top: 19px;
  left: 0;
}

.stake-page-tiers .frame-164 {
  width: 159px;
  height: 17px;
  position: absolute;
  top: 0;
  left: 0;
}

.stake-page-tiers .frame-165 {
  align-items: center;
  gap: 3.96px;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 0;
}

.stake-page-tiers .iconify-3 {
  width: 16px !important;
  height: 16px !important;
  position: relative !important;
}

.stake-page-tiers .text-wrapper-99 {
  color: var(--text-900);
  font-family: var(--notes-bold-font-family);
  font-size: var(--notes-bold-font-size);
  font-style: var(--notes-bold-font-style);
  font-weight: var(--notes-bold-font-weight);
  letter-spacing: var(--notes-bold-letter-spacing);
  line-height: var(--notes-bold-line-height);
  width: 37px;
  margin-top: -.4px;
  position: relative;
}

.stake-page-tiers .vector-4 {
  width: 168px;
  height: 1px;
  position: absolute;
  top: 19px;
  left: 0;
}

.stake-page-tiers .frame-166 {
  width: 159px;
  height: 17px;
  position: absolute;
  top: 0;
  left: 176px;
}

.stake-page-tiers .text-wrapper-100 {
  color: var(--text-500);
  font-family: var(--notes-bold-font-family);
  font-size: var(--notes-bold-font-size);
  font-style: var(--notes-bold-font-style);
  font-weight: var(--notes-bold-font-weight);
  letter-spacing: var(--notes-bold-letter-spacing);
  line-height: var(--notes-bold-line-height);
  width: 37px;
  margin-top: -.4px;
  position: relative;
}

.stake-page-tiers .vector-5 {
  width: 169px;
  height: 1px;
  position: absolute;
  top: 19px;
  left: 0;
}

.stake-page-tiers .instance-node-4 {
  flex: none !important;
}

.stake-page-tiers .mobile-tiers-instance {
  white-space: unset !important;
}

.stake-page-tiers .instance-node-5 {
  width: 14px !important;
  height: 14px !important;
  position: absolute !important;
  top: 5px !important;
  left: 5px !important;
}

.stake-page-tiers .instance-node-6 {
  margin-left: -5px !important;
}

.stake-page-tiers .mobile-tiers-2 {
  background-color: #fafafa;
  border-radius: 10px;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 16px;
  padding: 14px;
  display: inline-flex;
  position: relative;
}

.stake-page-tiers .frame-167 {
  width: 315px;
  height: 123px;
  position: relative;
}

.stake-page-tiers .frame-168 {
  width: 315px;
  height: 50px;
  position: absolute;
  top: 0;
  left: 0;
}

.stake-page-tiers .frame-169 {
  width: 55px;
  height: 30px;
  position: absolute;
  top: 0;
  left: 0;
}

.stake-page-tiers .frame-170 {
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.stake-page-tiers .icons-44 {
  width: 30px !important;
  height: 30px !important;
  position: relative !important;
}

.stake-page-tiers .stake-NUTS-for-free-4 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  width: 315px;
  position: absolute;
  top: 31px;
  left: 0;
}

.stake-page-tiers .frame-171 {
  flex-direction: column;
  align-items: flex-start;
  gap: 13px;
  display: inline-flex;
  position: absolute;
  top: 56px;
  left: 0;
}

.stake-page-tiers .frame-172 {
  width: 315px;
  height: 24px;
  position: relative;
}

.stake-page-tiers .text-wrapper-101 {
  color: #000;
  letter-spacing: 0;
  text-align: right;
  width: 46px;
  font-family: ABeeZee, Helvetica;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 269px;
}

.stake-page-tiers .group-45 {
  background-color: #fff;
  border-radius: 25px;
  width: 315px;
  height: 10px;
  position: absolute;
  top: 14px;
  left: 0;
}

.stake-page-tiers .rectangle-12 {
  background-color: var(--primary-500main);
  border-radius: 25px;
  width: 10px;
  height: 10px;
}

.stake-page-tiers .frame-173 {
  width: 315px;
  height: 30px;
  position: relative;
}

.stake-page-tiers .frame-174 {
  align-items: flex-start;
  gap: 20px;
  display: inline-flex;
  position: relative;
  top: 1px;
}

.stake-page-tiers .frame-175 {
  width: 80px;
  height: 27px;
  position: relative;
}

.stake-page-tiers .icons-13 {
  border: .48px solid !important;
  border-radius: 12px !important;
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 2px !important;
  left: 0 !important;
}

.stake-page-tiers .frame-176 {
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 27px;
}

.stake-page-tiers .text-wrapper-102 {
  color: var(--text-600);
  font-family: var(--mobile-10px-font-family);
  font-size: var(--mobile-10px-font-size);
  font-style: var(--mobile-10px-font-style);
  font-weight: var(--mobile-10px-font-weight);
  letter-spacing: var(--mobile-10px-letter-spacing);
  line-height: var(--mobile-10px-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -.55px;
  position: relative;
}

.stake-page-tiers .frame-177 {
  flex: none;
  align-items: center;
  gap: 2.59px;
  display: inline-flex;
  position: relative;
}

.stake-page-tiers .text-wrapper-103 {
  color: var(--text-900);
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  margin-top: -.65px;
  font-family: ABeeZee, Helvetica;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.stake-page-tiers .frame-178 {
  width: 103px;
  height: 27px;
  position: relative;
}

.stake-page-tiers .icons-43 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 2px !important;
  left: 0 !important;
}

.stake-page-tiers .frame-179 {
  width: 51px;
  height: 27px;
  position: relative;
}

.stake-page-tiers .footer-5 {
  position: absolute !important;
  top: 1075px !important;
  left: 0 !important;
}

.stake-page-tiers .frame-180 {
  flex-direction: column;
  align-items: flex-start;
  display: inline-flex;
  position: fixed;
  top: 0;
  left: 0;
}

.stake-page-tiers .status-bar-3 {
  background-color: #fff !important;
  width: 375px !important;
}

.stake-page-tiers .status-bar-4 {
  color: var(--themes-black-100) !important;
}

.stake-page-tiers .nav-5 {
  background-color: #fff;
  width: 375px;
  height: 54px;
  position: relative;
}

.stake-page-tiers .logo-5 {
  align-items: center;
  gap: 5.44px;
  display: inline-flex;
  position: absolute;
  top: 10px;
  left: 15px;
}

.stake-page-tiers .group-46 {
  width: 33.98px;
  height: 33.98px;
  position: relative;
}

.stake-page-tiers .frame-181 {
  background-color: #fff;
  border-radius: 16.86px;
  width: 34px;
  height: 34px;
}

.stake-page-tiers .group-47 {
  background-image: url("vector-5.a5aed117.svg");
  background-size: 100% 100%;
  width: 20px;
  height: 20px;
  position: relative;
  top: 7px;
  left: 7px;
}

.stake-page-tiers .group-48 {
  background-color: #fff;
  border-radius: 2.47px;
  width: 5px;
  height: 5px;
  position: absolute;
  top: 7px;
  left: 6px;
}

.stake-page-tiers .ellipse-21 {
  background-color: #000;
  border-radius: 1.81px;
  width: 4px;
  height: 4px;
  position: relative;
  top: 1px;
  left: 1px;
}

.stake-page-tiers .group-49 {
  background-color: #fff;
  border-radius: 2.47px;
  width: 5px;
  height: 5px;
  position: absolute;
  top: 2px;
  left: 12px;
}

.stake-page-tiers .group-50 {
  width: 72.59px;
  height: 22.34px;
  position: relative;
}

.stake-page-tiers .group-51 {
  width: 12px;
  height: 18px;
  position: absolute;
  top: 4px;
  left: 0;
}

.stake-page-tiers .group-52 {
  border: 2.49px solid #000;
  border-radius: 6px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 4px;
  left: 13px;
}

.stake-page-tiers .ellipse-22 {
  background-color: #000;
  border-radius: 2.35px;
  width: 5px;
  height: 5px;
  position: relative;
  top: 3px;
  left: 2px;
}

.stake-page-tiers .group-53 {
  border: 2.49px solid #000;
  border-radius: 6px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 4px;
  left: 26px;
}

.stake-page-tiers .ellipse-23 {
  background-color: #000;
  border-radius: 2.35px;
  width: 5px;
  height: 5px;
  position: relative;
  top: 3px;
  left: 4px;
}

.stake-page-tiers .group-54 {
  width: 12px;
  height: 16px;
  position: absolute;
  top: 0;
  left: 39px;
}

.stake-page-tiers .overlap-group-7 {
  height: 16px;
  position: relative;
}

.stake-page-tiers .ellipse-24 {
  border: 2.49px solid #000;
  border-radius: 6px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 4px;
  left: 0;
}

.stake-page-tiers .rectangle-13 {
  background-color: #000;
  border-radius: 0 0 1.24px 1.24px;
  width: 2px;
  height: 12px;
  position: absolute;
  top: 0;
  left: 0;
}

.stake-page-tiers .subtract-5 {
  width: 12px;
  height: 12px;
  position: absolute;
  top: 4px;
  left: 52px;
}

.stake-page-tiers .frame-182 {
  align-items: flex-start;
  width: 7px;
  height: 12px;
  display: flex;
  position: absolute;
  top: 4px;
  left: 65px;
}

.stake-page-tiers .rectangle-14 {
  background-color: #000;
  width: 2.47px;
  height: 11.99px;
  position: relative;
  transform: rotate(180deg);
}

.stake-page-tiers .ellipse-25 {
  background-color: #000;
  border-radius: 2.35px;
  width: 4.7px;
  height: 4.7px;
  position: relative;
}

.stake-page-tiers .menu-2 {
  width: 24px;
  height: 24px;
  display: block;
  position: absolute;
  top: 15px;
  left: 335px;
}

.stake-page-tiers .frame-183 {
  flex-direction: column;
  align-items: flex-start;
  gap: 80px;
  display: inline-flex;
  position: absolute;
  top: 150px;
  left: 92px;
}

.stake-page-tiers .frame-184 {
  width: 1733px;
  height: 319px;
  position: relative;
}

.stake-page-tiers .frame-185 {
  width: 557px;
  height: 187px;
  position: absolute;
  top: 0;
  left: 0;
}

.stake-page-tiers .group-55 {
  width: 238px;
  height: 50px;
  position: absolute;
  top: 0;
  left: 0;
}

.stake-page-tiers .frame-186 {
  background-color: var(--text-150);
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 16px 14px;
  display: inline-flex;
  position: relative;
}

.stake-page-tiers .text-wrapper-104 {
  color: var(--primary-500main);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.stake-page-tiers .frame-187 {
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
  display: inline-flex;
  position: absolute;
  top: 64px;
  left: 0;
}

.stake-page-tiers .stake-to-mint-GOO-5 {
  color: #0000;
  font-family: var(--h3-bold-font-family);
  font-size: var(--h3-bold-font-size);
  font-style: var(--h3-bold-font-style);
  font-weight: var(--h3-bold-font-weight);
  letter-spacing: var(--h3-bold-letter-spacing);
  line-height: var(--h3-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.stake-page-tiers .text-wrapper-105 {
  color: #202020;
  font-family: var(--h3-bold-font-family);
  font-size: var(--h3-bold-font-size);
  font-style: var(--h3-bold-font-style);
  font-weight: var(--h3-bold-font-weight);
  letter-spacing: var(--h3-bold-letter-spacing);
  line-height: var(--h3-bold-line-height);
}

.stake-page-tiers .text-wrapper-106 {
  color: #ffba15;
  font-family: var(--h3-bold-font-family);
  font-size: var(--h3-bold-font-size);
  font-style: var(--h3-bold-font-style);
  font-weight: var(--h3-bold-font-weight);
  letter-spacing: var(--h3-bold-letter-spacing);
  line-height: var(--h3-bold-line-height);
}

.stake-page-tiers .text-wrapper-107 {
  color: var(--text-600);
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  font-family: ABeeZee, Helvetica;
  font-size: 28px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.stake-page-tiers .frame-188 {
  width: 1733px;
  height: 72px;
  position: absolute;
  top: 247px;
  left: 0;
}

.stake-page-tiers .frame-189 {
  width: 293px;
  height: 72px;
  position: absolute;
  top: 0;
  left: 0;
}

.stake-page-tiers .text-wrapper-108 {
  color: var(--text-600);
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 0;
}

.stake-page-tiers .frame-190 {
  align-items: center;
  gap: 4.66px;
  display: inline-flex;
  position: absolute;
  top: 37px;
  left: 0;
}

.stake-page-tiers .text-wrapper-109 {
  color: var(--text-900);
  font-family: var(--small-title-bold-font-family);
  font-size: var(--small-title-bold-font-size);
  font-style: var(--small-title-bold-font-style);
  font-weight: var(--small-title-bold-font-weight);
  letter-spacing: var(--small-title-bold-letter-spacing);
  line-height: var(--small-title-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1.17px;
  position: relative;
}

.stake-page-tiers .frame-191 {
  width: 293px;
  height: 72px;
  position: absolute;
  top: 0;
  left: 480px;
}

.stake-page-tiers .element-d-hr-min-s {
  color: #0000;
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1.17px;
  font-family: Aeonik TRIAL-Bold, Helvetica;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

@media (width >= 1920px) {
  .stake-page-tiers .element-d-hr-min-s {
    font-size: 28px;
  }
}

.stake-page-tiers .text-wrapper-110 {
  color: #202020;
}

.stake-page-tiers .text-wrapper-111 {
  color: #d4d4d4;
}

.stake-page-tiers .frame-192 {
  width: 293px;
  height: 72px;
  position: absolute;
  top: 0;
  left: 960px;
}

.stake-page-tiers .frame-193 {
  width: 293px;
  height: 72px;
  position: absolute;
  top: 0;
  left: 1440px;
}

.stake-page-tiers .frame-194 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 40px;
  display: inline-flex;
  position: relative;
}

.stake-page-tiers .tabs-7 {
  position: relative !important;
}

.stake-page-tiers .tiers-instance {
  letter-spacing: 0 !important;
  font-family: Aeonik TRIAL-RegularItalic, Helvetica !important;
  font-size: 20px !important;
  font-style: italic !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.stake-page-tiers .iconify-13 {
  width: 34px !important;
  height: 34px !important;
  position: absolute !important;
  top: 12px !important;
  left: 12px !important;
}

.stake-page-tiers .iconify-26-instance {
  width: 24px !important;
  height: 24px !important;
  position: relative !important;
}

.stake-page-tiers .icons-14 {
  margin-left: -13px !important;
}

.stake-page-tiers .footer-6 {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.stake-page-tiers .footer-7 {
  left: 656px !important;
}

.stake-page-tiers .nav-6 {
  background-image: url("rectangle-2-4-2.dc2a98b1.svg");
  background-size: 100% 100%;
  width: 1920px;
  height: 100px;
  position: fixed;
  top: 0;
  left: 0;
}

.stake-page-tiers .group-56 {
  width: 1736px;
  height: 51px;
  position: relative;
  top: 24px;
  left: 92px;
}

.stake-page-tiers .logo-6 {
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 0;
}

.stake-page-tiers .group-57 {
  width: 50px;
  height: 50px;
  position: relative;
}

.stake-page-tiers .frame-195 {
  background-color: #fff;
  border-radius: 24.82px;
  height: 50px;
}

.stake-page-tiers .group-58 {
  background-image: url("vector.563a97e7.svg");
  background-size: 100% 100%;
  width: 29px;
  height: 29px;
  position: relative;
  top: 10px;
  left: 10px;
}

.stake-page-tiers .group-59 {
  background-color: #fff;
  border-radius: 3.63px;
  width: 7px;
  height: 7px;
  position: absolute;
  top: 10px;
  left: 9px;
}

.stake-page-tiers .ellipse-26 {
  background-color: #000;
  border-radius: 2.66px;
  width: 5px;
  height: 5px;
  position: relative;
  top: 1px;
  left: 1px;
}

.stake-page-tiers .group-60 {
  background-color: #fff;
  border-radius: 3.63px;
  width: 7px;
  height: 7px;
  position: absolute;
  top: 3px;
  left: 17px;
}

.stake-page-tiers .group-61 {
  width: 106.81px;
  height: 32.88px;
  position: relative;
}

.stake-page-tiers .group-62 {
  width: 18px;
  height: 27px;
  position: absolute;
  top: 6px;
  left: 0;
}

.stake-page-tiers .group-63 {
  border: 3.66px solid #000;
  border-radius: 8.82px;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 6px;
  left: 19px;
}

.stake-page-tiers .ellipse-27 {
  background-color: #000;
  border-radius: 3.46px;
  width: 7px;
  height: 7px;
  position: relative;
  top: 5px;
  left: 3px;
}

.stake-page-tiers .group-64 {
  border: 3.66px solid #000;
  border-radius: 8.82px;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 6px;
  left: 39px;
}

.stake-page-tiers .ellipse-28 {
  background-color: #000;
  border-radius: 3.46px;
  width: 7px;
  height: 7px;
  position: relative;
  top: 5px;
  left: 5px;
}

.stake-page-tiers .group-65 {
  width: 18px;
  height: 24px;
  position: absolute;
  top: 0;
  left: 58px;
}

.stake-page-tiers .overlap-group-8 {
  height: 24px;
  position: relative;
}

.stake-page-tiers .ellipse-29 {
  border: 3.66px solid #000;
  border-radius: 8.82px;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 6px;
  left: 0;
}

.stake-page-tiers .rectangle-15 {
  background-color: #000;
  border-radius: 0 0 1.82px 1.82px;
  width: 4px;
  height: 17px;
  position: absolute;
  top: 0;
  left: 0;
}

.stake-page-tiers .subtract-6 {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 6px;
  left: 77px;
}

.stake-page-tiers .frame-196 {
  align-items: flex-start;
  width: 11px;
  height: 18px;
  display: flex;
  position: absolute;
  top: 6px;
  left: 96px;
}

.stake-page-tiers .rectangle-16 {
  background-color: #000;
  width: 3.64px;
  height: 17.65px;
  position: relative;
  transform: rotate(180deg);
}

.stake-page-tiers .ellipse-30 {
  background-color: #000;
  border-radius: 3.46px;
  width: 6.91px;
  height: 6.91px;
  position: relative;
}

.stake-page-tiers .navbar-2 {
  width: 429px;
  height: 18px;
  position: absolute;
  top: 16px;
  left: 657px;
}

.stake-page-tiers .text-wrapper-112 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 0;
}

.stake-page-tiers .text-wrapper-113 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 103px;
}

.stake-page-tiers .text-wrapper-114 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 209px;
}

.stake-page-tiers .text-wrapper-115 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 341px;
}

.stake-page-tiers .frame-197 {
  align-items: center;
  gap: 20px;
  display: inline-flex;
  position: absolute;
  top: 1px;
  left: 1421px;
}

.stake-page-tiers .frame-198 {
  width: 114px;
  height: 40px;
  position: relative;
}

.stake-page-tiers .state-default-wrapper {
  background-color: #1c1c1c;
  border-radius: 500px;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: auto;
}

.stake-page-tiers .x-12-instance {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 8px !important;
  left: 8px !important;
}

.stake-page-tiers .discord-7 {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  left: 64px;
}

.stake-page-tiers .rules-list {
  color: #202020;
  letter-spacing: .36px;
  margin: 0;
  padding-left: 20px;
  padding-right: 100px;
  font-family: ABeeZee, Helvetica;
  font-size: 18px;
  line-height: 30px;
  list-style-type: disc;
}

.stake-page-tiers .rules-list li {
  margin-bottom: 10px;
}

.stake-page-tiers .text-wrapper-59 {
  color: var(--text-900);
  font-family: var(--h4-bold-font-family);
  font-size: var(--h4-bold-font-size);
  font-style: var(--h4-bold-font-style);
  font-weight: var(--h4-bold-font-weight);
  letter-spacing: var(--h4-bold-letter-spacing);
  line-height: var(--h4-bold-line-height);
  width: 383px;
  margin-top: -1px;
  margin-bottom: 20px;
  position: relative;
}

.stake-page-tiers .text-wrapper-61 {
  color: #ffba15;
  letter-spacing: .06px;
  font-style: italic;
}

.instance-node-2 {
  width: 34px !important;
  height: 34px !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.instance-node-3 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.property-copy {
  width: 20px !important;
  height: 20px !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.connect-wallet {
  background-color: #0000;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.connect-wallet .div-9 {
  width: 1920px;
  height: 1080px;
  position: relative;
  overflow: hidden;
}

.connect-wallet .overlap-3 {
  background-color: #fff;
  width: 1920px;
  height: 2083px;
  position: absolute;
  top: 0;
  left: 0;
}

.connect-wallet .frame-199 {
  flex-direction: column;
  align-items: flex-start;
  gap: 80px;
  display: inline-flex;
  position: relative;
  top: 150px;
  left: 92px;
}

.connect-wallet .frame-200 {
  width: 1733px;
  height: 319px;
  position: relative;
}

.connect-wallet .frame-201 {
  width: 557px;
  height: 187px;
  position: absolute;
  top: 0;
  left: 0;
}

.connect-wallet .group-66 {
  width: 238px;
  height: 50px;
  position: absolute;
  top: 0;
  left: 0;
}

.connect-wallet .frame-202 {
  background-color: var(--text-150);
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 16px 14px;
  display: inline-flex;
  position: relative;
}

.connect-wallet .text-wrapper-116 {
  color: var(--primary-500main);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.connect-wallet .frame-203 {
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
  display: inline-flex;
  position: absolute;
  top: 64px;
  left: 0;
}

.connect-wallet .stake-to-mint-GOO-6 {
  color: #0000;
  font-family: var(--h3-bold-font-family);
  font-size: var(--h3-bold-font-size);
  font-style: var(--h3-bold-font-style);
  font-weight: var(--h3-bold-font-weight);
  letter-spacing: var(--h3-bold-letter-spacing);
  line-height: var(--h3-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.connect-wallet .text-wrapper-117 {
  color: #202020;
  font-family: var(--h3-bold-font-family);
  font-size: var(--h3-bold-font-size);
  font-style: var(--h3-bold-font-style);
  font-weight: var(--h3-bold-font-weight);
  letter-spacing: var(--h3-bold-letter-spacing);
  line-height: var(--h3-bold-line-height);
}

.connect-wallet .text-wrapper-118 {
  color: #ffba15;
  font-family: var(--h3-bold-font-family);
  font-size: var(--h3-bold-font-size);
  font-style: var(--h3-bold-font-style);
  font-weight: var(--h3-bold-font-weight);
  letter-spacing: var(--h3-bold-letter-spacing);
  line-height: var(--h3-bold-line-height);
}

.connect-wallet .text-wrapper-119 {
  color: var(--text-600);
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  font-family: ABeeZee, Helvetica;
  font-size: 28px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.connect-wallet .frame-204 {
  width: 1733px;
  height: 72px;
  position: absolute;
  top: 247px;
  left: 0;
}

.connect-wallet .frame-205 {
  width: 293px;
  height: 72px;
  position: absolute;
  top: 0;
  left: 0;
}

.connect-wallet .text-wrapper-120 {
  color: var(--text-600);
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 0;
}

.connect-wallet .frame-206 {
  align-items: center;
  gap: 4.66px;
  display: inline-flex;
  position: absolute;
  top: 37px;
  left: 0;
}

.connect-wallet .text-wrapper-121 {
  color: var(--text-900);
  font-family: var(--small-title-bold-font-family);
  font-size: var(--small-title-bold-font-size);
  font-style: var(--small-title-bold-font-style);
  font-weight: var(--small-title-bold-font-weight);
  letter-spacing: var(--small-title-bold-letter-spacing);
  line-height: var(--small-title-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1.17px;
  position: relative;
}

.connect-wallet .frame-207 {
  width: 293px;
  height: 72px;
  position: absolute;
  top: 0;
  left: 480px;
}

.connect-wallet .frame-208 {
  width: 293px;
  height: 72px;
  position: absolute;
  top: 0;
  left: 960px;
}

.connect-wallet .frame-209 {
  width: 293px;
  height: 72px;
  position: absolute;
  top: 0;
  left: 1440px;
}

.connect-wallet .frame-210 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 40px;
  display: inline-flex;
  position: relative;
}

.connect-wallet .tabs-8 {
  position: relative !important;
}

.connect-wallet .design-component-instance-node-2 {
  flex: none !important;
}

.connect-wallet .icons-15 {
  margin-left: -13px !important;
}

.connect-wallet .iconify-15 {
  width: 34px !important;
  height: 34px !important;
  position: absolute !important;
  top: 12px !important;
  left: 12px !important;
}

.connect-wallet .iconify-4 {
  width: 24px !important;
  height: 24px !important;
  position: relative !important;
}

.connect-wallet .icons-36 {
  width: 60px !important;
  height: 29px !important;
  position: relative !important;
}

.connect-wallet .tiers-4 {
  font-family: ABeeZee, Helvetica !important;
  font-weight: 400 !important;
}

.connect-wallet .tiers-5 {
  font-style: italic !important;
}

.connect-wallet .tiers-6 {
  background-color: #fafafa;
  border-radius: 20px;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 70px;
  padding: 50px 44px;
  display: inline-flex;
  position: relative;
}

.connect-wallet .frame-211 {
  width: 1644px;
  height: 94px;
  position: relative;
}

.connect-wallet .frame-212 {
  justify-content: space-between;
  align-items: center;
  width: 1644px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.connect-wallet .frame-213 {
  flex: none;
  align-items: center;
  gap: 10px;
  display: inline-flex;
  position: relative;
}

.connect-wallet .frame-214 {
  flex: none;
  align-items: center;
  display: inline-flex;
  position: relative;
}

.connect-wallet .stake-NUTS-for-free-5 {
  color: var(--text-900);
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  font-family: ABeeZee, Helvetica;
  font-size: 24px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.connect-wallet .text-wrapper-122 {
  color: #000;
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  text-align: right;
  width: 46px;
  position: relative;
}

.connect-wallet .view-2 {
  position: absolute !important;
  top: 100px !important;
  left: 0 !important;
}

.connect-wallet .frame-215 {
  flex: none;
  justify-content: space-between;
  align-items: center;
  width: 1644px;
  display: flex;
  position: relative;
}

.connect-wallet .frame-216 {
  flex: none;
  align-items: flex-start;
  gap: 78px;
  display: inline-flex;
  position: relative;
}

.connect-wallet .frame-217 {
  align-items: center;
  gap: 10px;
  width: 232px;
  display: flex;
  position: relative;
}

.connect-wallet .frame-218 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 6px;
  display: inline-flex;
  position: relative;
}

.connect-wallet .text-wrapper-123 {
  color: var(--text-600);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.connect-wallet .frame-219 {
  flex: none;
  align-items: center;
  gap: 4.66px;
  display: inline-flex;
  position: relative;
}

.connect-wallet .text-wrapper-124 {
  color: var(--text-900);
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1.17px;
  position: relative;
}

.connect-wallet .wallet-14 {
  background-color: var(--primary-100) !important;
  flex: none !important;
}

.connect-wallet .wallet-15 {
  color: var(--primary-500main) !important;
}

.connect-wallet .nav-7 {
  background-image: url("rectangle-2-7.e495885a.svg");
  background-size: 100% 100%;
  width: 1920px;
  height: 100px;
  position: fixed;
  top: 0;
  left: 0;
}

.connect-wallet .group-67 {
  width: 1736px;
  height: 51px;
  position: relative;
  top: 24px;
  left: 92px;
}

.connect-wallet .logo-7 {
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 0;
}

.connect-wallet .group-68 {
  width: 50px;
  height: 50px;
  position: relative;
}

.connect-wallet .frame-220 {
  background-color: #fff;
  border-radius: 24.82px;
  height: 50px;
}

.connect-wallet .group-69 {
  background-image: url("vector.563a97e7.svg");
  background-size: 100% 100%;
  width: 29px;
  height: 29px;
  position: relative;
  top: 10px;
  left: 10px;
}

.connect-wallet .group-70 {
  background-color: #fff;
  border-radius: 3.63px;
  width: 7px;
  height: 7px;
  position: absolute;
  top: 10px;
  left: 9px;
}

.connect-wallet .ellipse-31 {
  background-color: #000;
  border-radius: 2.66px;
  width: 5px;
  height: 5px;
  position: relative;
  top: 1px;
  left: 1px;
}

.connect-wallet .group-71 {
  background-color: #fff;
  border-radius: 3.63px;
  width: 7px;
  height: 7px;
  position: absolute;
  top: 3px;
  left: 17px;
}

.connect-wallet .group-72 {
  width: 106.81px;
  height: 32.88px;
  position: relative;
}

.connect-wallet .group-73 {
  width: 18px;
  height: 27px;
  position: absolute;
  top: 6px;
  left: 0;
}

.connect-wallet .group-74 {
  border: 3.66px solid #000;
  border-radius: 8.82px;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 6px;
  left: 19px;
}

.connect-wallet .ellipse-32 {
  background-color: #000;
  border-radius: 3.46px;
  width: 7px;
  height: 7px;
  position: relative;
  top: 5px;
  left: 3px;
}

.connect-wallet .group-75 {
  border: 3.66px solid #000;
  border-radius: 8.82px;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 6px;
  left: 39px;
}

.connect-wallet .ellipse-33 {
  background-color: #000;
  border-radius: 3.46px;
  width: 7px;
  height: 7px;
  position: relative;
  top: 5px;
  left: 5px;
}

.connect-wallet .group-76 {
  width: 18px;
  height: 24px;
  position: absolute;
  top: 0;
  left: 58px;
}

.connect-wallet .overlap-group-9 {
  height: 24px;
  position: relative;
}

.connect-wallet .ellipse-34 {
  border: 3.66px solid #000;
  border-radius: 8.82px;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 6px;
  left: 0;
}

.connect-wallet .rectangle-17 {
  background-color: #000;
  border-radius: 0 0 1.82px 1.82px;
  width: 4px;
  height: 17px;
  position: absolute;
  top: 0;
  left: 0;
}

.connect-wallet .subtract-7 {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 6px;
  left: 77px;
}

.connect-wallet .frame-221 {
  align-items: flex-start;
  width: 11px;
  height: 18px;
  display: flex;
  position: absolute;
  top: 6px;
  left: 96px;
}

.connect-wallet .rectangle-18 {
  background-color: #000;
  width: 3.64px;
  height: 17.65px;
  position: relative;
  transform: rotate(180deg);
}

.connect-wallet .ellipse-35 {
  background-color: #000;
  border-radius: 3.46px;
  width: 6.91px;
  height: 6.91px;
  position: relative;
}

.connect-wallet .navbar-3 {
  width: 429px;
  height: 18px;
  position: absolute;
  top: 16px;
  left: 657px;
}

.connect-wallet .text-wrapper-125 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 0;
}

.connect-wallet .text-wrapper-126 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 103px;
}

.connect-wallet .text-wrapper-127 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 209px;
}

.connect-wallet .text-wrapper-128 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 341px;
}

.connect-wallet .frame-222 {
  align-items: center;
  gap: 20px;
  display: inline-flex;
  position: absolute;
  top: 1px;
  left: 1421px;
}

.connect-wallet .frame-223 {
  width: 114px;
  height: 40px;
  position: relative;
}

.connect-wallet .twitter-6 {
  background-color: #1c1c1c;
  border-radius: 500px;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.connect-wallet .x-2 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 8px !important;
  left: 8px !important;
}

.connect-wallet .discord-8 {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  left: 64px;
}

.connect-wallet .rectangle-19 {
  width: 1920px;
  height: 1080px;
  position: fixed;
  top: 0;
  left: 0;
}

.connect-wallet .wallet-connect-3 {
  background-color: #11141d;
  border-radius: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  padding: 50px 0;
  display: inline-flex;
  position: fixed;
  top: 359px;
  left: 684px;
}

.connect-wallet .frame-224 {
  width: 551px;
  height: 62px;
  position: relative;
}

.connect-wallet .frame-225 {
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  padding: 0 40px;
  display: inline-flex;
  position: relative;
}

.connect-wallet .frame-226 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 10px;
  display: inline-flex;
  position: relative;
}

.connect-wallet .frame-227 {
  flex: none;
  align-items: center;
  gap: 246px;
  display: inline-flex;
  position: relative;
}

.connect-wallet .text-wrapper-129 {
  color: #fff;
  font-family: var(--small-title-bold-font-family);
  font-size: var(--small-title-bold-font-size);
  font-style: var(--small-title-bold-font-style);
  font-weight: var(--small-title-bold-font-weight);
  letter-spacing: var(--small-title-bold-letter-spacing);
  line-height: var(--small-title-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.connect-wallet .text-wrapper-130 {
  color: #fff;
  letter-spacing: .32px;
  white-space: nowrap;
  width: fit-content;
  font-family: ABeeZee, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.connect-wallet .frame-228 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 10px;
  display: inline-flex;
  position: relative;
}

.connect-wallet .frame-229 {
  width: 552px;
  height: 180px;
  position: relative;
}

.connect-wallet .frame-230 {
  flex-direction: column;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.connect-wallet .frame-231 {
  background-color: #191d29;
  width: 552px;
  height: 60px;
  position: relative;
}

.connect-wallet .frame-232 {
  align-items: center;
  gap: 12px;
  display: inline-flex;
  position: absolute;
  top: 16px;
  left: 40px;
}

.connect-wallet .frame-233 {
  width: 28px;
  height: 28px;
  position: relative;
}

.connect-wallet .text-wrapper-131 {
  color: #fff;
  letter-spacing: .36px;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  font-family: ABeeZee, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.connect-wallet .text-wrapper-132 {
  color: #fff;
  letter-spacing: 0;
  opacity: .6;
  text-align: right;
  height: 18px;
  font-family: DM Sans, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 20px;
  left: 451px;
}

.connect-wallet .frame-234 {
  width: 552px;
  height: 60px;
  position: relative;
}

.connect-wallet .image-3 {
  object-fit: cover;
  width: 28px;
  height: 28px;
  position: relative;
}

.typing {
  background-color: #0000;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.typing .div-10 {
  width: 1920px;
  height: 1080px;
  position: relative;
  overflow: hidden;
}

.typing .overlap-4 {
  background-color: #fff;
  width: 1920px;
  height: 2083px;
  position: absolute;
  top: 0;
  left: 0;
}

.typing .frame-235 {
  flex-direction: column;
  align-items: flex-start;
  gap: 80px;
  display: inline-flex;
  position: absolute;
  top: 150px;
  left: 92px;
}

.typing .frame-236 {
  width: 1733px;
  height: 319px;
  position: relative;
}

.typing .frame-237 {
  width: 557px;
  height: 187px;
  position: absolute;
  top: 0;
  left: 0;
}

.typing .group-77 {
  width: 238px;
  height: 50px;
  position: absolute;
  top: 0;
  left: 0;
}

.typing .frame-238 {
  background-color: var(--text-150);
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 16px 14px;
  display: inline-flex;
  position: relative;
}

.typing .text-wrapper-133 {
  color: var(--primary-500main);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.typing .frame-239 {
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
  display: inline-flex;
  position: absolute;
  top: 64px;
  left: 0;
}

.typing .stake-to-mint-GOO-7 {
  color: #0000;
  font-family: var(--h3-bold-font-family);
  font-size: var(--h3-bold-font-size);
  font-style: var(--h3-bold-font-style);
  font-weight: var(--h3-bold-font-weight);
  letter-spacing: var(--h3-bold-letter-spacing);
  line-height: var(--h3-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.typing .text-wrapper-134 {
  color: #202020;
  font-family: var(--h3-bold-font-family);
  font-size: var(--h3-bold-font-size);
  font-style: var(--h3-bold-font-style);
  font-weight: var(--h3-bold-font-weight);
  letter-spacing: var(--h3-bold-letter-spacing);
  line-height: var(--h3-bold-line-height);
}

.typing .text-wrapper-135 {
  color: #ffba15;
  font-family: var(--h3-bold-font-family);
  font-size: var(--h3-bold-font-size);
  font-style: var(--h3-bold-font-style);
  font-weight: var(--h3-bold-font-weight);
  letter-spacing: var(--h3-bold-letter-spacing);
  line-height: var(--h3-bold-line-height);
}

.typing .text-wrapper-136 {
  color: var(--text-600);
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  font-family: ABeeZee, Helvetica;
  font-size: 28px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.typing .frame-240 {
  width: 1733px;
  height: 72px;
  position: absolute;
  top: 247px;
  left: 0;
}

.typing .frame-241 {
  width: 293px;
  height: 72px;
  position: absolute;
  top: 0;
  left: 0;
}

.typing .text-wrapper-137 {
  color: var(--text-600);
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 0;
}

.typing .frame-242 {
  align-items: center;
  gap: 4.66px;
  display: inline-flex;
  position: absolute;
  top: 37px;
  left: 0;
}

.typing .text-wrapper-138 {
  color: var(--text-900);
  font-family: var(--small-title-bold-font-family);
  font-size: var(--small-title-bold-font-size);
  font-style: var(--small-title-bold-font-style);
  font-weight: var(--small-title-bold-font-weight);
  letter-spacing: var(--small-title-bold-letter-spacing);
  line-height: var(--small-title-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1.17px;
  position: relative;
}

.typing .frame-243 {
  width: 293px;
  height: 72px;
  position: absolute;
  top: 0;
  left: 480px;
}

.typing .frame-244 {
  width: 293px;
  height: 72px;
  position: absolute;
  top: 0;
  left: 960px;
}

.typing .frame-245 {
  width: 293px;
  height: 72px;
  position: absolute;
  top: 0;
  left: 1440px;
}

.typing .user-profile-3 {
  background-color: #fafafa;
  border-radius: 20px;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 180px;
  padding: 50px 84px;
  display: inline-flex;
  position: absolute;
  top: 7px;
  left: 799px;
  overflow: hidden;
}

.typing .nuts-3 {
  width: 302px;
  height: 72px;
  position: relative;
}

.typing .icons-16 {
  border: 1.36px solid !important;
  border-radius: 34px !important;
  width: 68px !important;
  height: 68px !important;
  position: absolute !important;
  top: 2px !important;
  left: 0 !important;
}

.typing .icons-17 {
  width: 52px !important;
  height: 52px !important;
  top: 7px !important;
  left: 7px !important;
}

.typing .element-5 {
  width: 224px;
  height: 72px;
  position: absolute;
  top: 0;
  left: 78px;
}

.typing .sol-4 {
  width: 284px;
  height: 72px;
  position: relative;
}

.typing .icons-18 {
  width: 36px !important;
  height: 36px !important;
  top: 14px !important;
  left: 14px !important;
}

.typing .element-6 {
  width: 206px;
  height: 72px;
  position: absolute;
  top: 0;
  left: 78px;
}

.typing .frame-246 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 40px;
  display: inline-flex;
  position: relative;
}

.typing .tabs-9 {
  position: relative !important;
}

.typing .tiers-7 {
  background-color: #fafafa;
  border-radius: 20px;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 70px;
  padding: 50px 44px;
  display: inline-flex;
  position: relative;
}

.typing .frame-247 {
  width: 1644px;
  height: 94px;
  position: relative;
}

.typing .frame-248 {
  width: 1644px;
  height: 60px;
  position: absolute;
  top: 0;
  left: 0;
}

.typing .frame-249 {
  align-items: center;
  gap: 10px;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 0;
}

.typing .frame-250 {
  flex: none;
  align-items: center;
  display: inline-flex;
  position: relative;
}

.typing .icons-19 {
  width: 60px !important;
  height: 60px !important;
  position: relative !important;
}

.typing .icons-20 {
  margin-left: -13px !important;
}

.typing .div-11 {
  color: #0000;
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  font-family: ABeeZee, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.typing .text-wrapper-139 {
  color: #202020;
  font-style: italic;
}

.typing .text-wrapper-140 {
  color: #ffba15;
  font-style: italic;
}

.typing .text-wrapper-141 {
  color: #000;
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  text-align: right;
  width: 46px;
  position: absolute;
  top: 18px;
  left: 1598px;
}

.typing .view-3 {
  position: absolute !important;
  top: 100px !important;
  left: 0 !important;
}

.typing .frame-251 {
  flex: none;
  justify-content: space-between;
  align-items: center;
  width: 1644px;
  display: flex;
  position: relative;
}

.typing .frame-252 {
  flex: none;
  align-items: flex-start;
  gap: 78px;
  display: inline-flex;
  position: relative;
}

.typing .frame-253 {
  align-items: center;
  gap: 10px;
  width: 232px;
  display: flex;
  position: relative;
}

.typing .property-money-instance {
  width: 34px !important;
  height: 34px !important;
  position: absolute !important;
  top: 12px !important;
  left: 12px !important;
}

.typing .frame-254 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 6px;
  display: inline-flex;
  position: relative;
}

.typing .text-wrapper-142 {
  color: var(--text-600);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.typing .frame-255 {
  flex: none;
  align-items: center;
  gap: 4.66px;
  display: inline-flex;
  position: relative;
}

.typing .text-wrapper-143 {
  color: var(--text-900);
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1.17px;
  position: relative;
}

.typing .wallet-16 {
  flex: none !important;
  padding: 16px !important;
}

.typing .wallet-17 {
  margin-top: -1px !important;
}

.typing .frame-256 {
  justify-content: space-between;
  align-items: center;
  width: 1644px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.typing .frame-257 {
  flex: none;
  align-items: center;
  gap: 10px;
  display: inline-flex;
  position: relative;
}

.typing .icons-30-instance {
  width: 60px !important;
  height: 29px !important;
  position: relative !important;
}

.typing .text-wrapper-144 {
  color: #000;
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  text-align: right;
  width: 46px;
  position: relative;
}

.typing .stake-NUTS-for-free-6 {
  color: var(--text-900);
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  font-family: ABeeZee, Helvetica;
  font-size: 24px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.typing .nav-8 {
  width: 1920px;
  height: 100px;
  position: absolute;
  top: 0;
  left: 0;
}

.typing .nav-9 {
  background-image: url("rectangle-2-8.c7759248.svg");
  background-size: 100% 100%;
  height: 100px;
}

.typing .group-78 {
  width: 1736px;
  height: 51px;
  position: relative;
  top: 24px;
  left: 92px;
}

.typing .logo-8 {
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 0;
}

.typing .group-79 {
  width: 50px;
  height: 50px;
  position: relative;
}

.typing .frame-258 {
  background-color: #fff;
  border-radius: 24.82px;
  height: 50px;
}

.typing .group-80 {
  background-image: url("vector.563a97e7.svg");
  background-size: 100% 100%;
  width: 29px;
  height: 29px;
  position: relative;
  top: 10px;
  left: 10px;
}

.typing .group-81 {
  background-color: #fff;
  border-radius: 3.63px;
  width: 7px;
  height: 7px;
  position: absolute;
  top: 10px;
  left: 9px;
}

.typing .ellipse-36 {
  background-color: #000;
  border-radius: 2.66px;
  width: 5px;
  height: 5px;
  position: relative;
  top: 1px;
  left: 1px;
}

.typing .group-82 {
  background-color: #fff;
  border-radius: 3.63px;
  width: 7px;
  height: 7px;
  position: absolute;
  top: 3px;
  left: 17px;
}

.typing .group-83 {
  width: 106.81px;
  height: 32.88px;
  position: relative;
}

.typing .group-84 {
  width: 18px;
  height: 27px;
  position: absolute;
  top: 6px;
  left: 0;
}

.typing .group-85 {
  border: 3.66px solid #000;
  border-radius: 8.82px;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 6px;
  left: 19px;
}

.typing .ellipse-37 {
  background-color: #000;
  border-radius: 3.46px;
  width: 7px;
  height: 7px;
  position: relative;
  top: 5px;
  left: 3px;
}

.typing .group-86 {
  border: 3.66px solid #000;
  border-radius: 8.82px;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 6px;
  left: 39px;
}

.typing .ellipse-38 {
  background-color: #000;
  border-radius: 3.46px;
  width: 7px;
  height: 7px;
  position: relative;
  top: 5px;
  left: 5px;
}

.typing .group-87 {
  width: 18px;
  height: 24px;
  position: absolute;
  top: 0;
  left: 58px;
}

.typing .overlap-group-10 {
  height: 24px;
  position: relative;
}

.typing .ellipse-39 {
  border: 3.66px solid #000;
  border-radius: 8.82px;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 6px;
  left: 0;
}

.typing .rectangle-20 {
  background-color: #000;
  border-radius: 0 0 1.82px 1.82px;
  width: 4px;
  height: 17px;
  position: absolute;
  top: 0;
  left: 0;
}

.typing .subtract-8 {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 6px;
  left: 77px;
}

.typing .frame-259 {
  align-items: flex-start;
  width: 11px;
  height: 18px;
  display: flex;
  position: absolute;
  top: 6px;
  left: 96px;
}

.typing .rectangle-21 {
  background-color: #000;
  width: 3.64px;
  height: 17.65px;
  position: relative;
  transform: rotate(180deg);
}

.typing .ellipse-40 {
  background-color: #000;
  border-radius: 3.46px;
  width: 6.91px;
  height: 6.91px;
  position: relative;
}

.typing .frame-260 {
  width: 429px;
  height: 18px;
  position: absolute;
  top: 16px;
  left: 657px;
}

.typing .text-wrapper-145 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 0;
}

.typing .text-wrapper-146 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 103px;
}

.typing .text-wrapper-147 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 209px;
}

.typing .text-wrapper-148 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 341px;
}

.typing .frame-261 {
  align-items: center;
  gap: 20px;
  display: inline-flex;
  position: absolute;
  top: 1px;
  left: 1414px;
}

.typing .frame-262 {
  width: 114px;
  height: 40px;
  position: relative;
}

.typing .twitter-7 {
  background-color: #1c1c1c;
  border-radius: 500px;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.typing .x-3 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 8px !important;
  left: 8px !important;
}

.typing .discord-9 {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  left: 64px;
}

.typing .wallet-18 {
  flex: none !important;
}

.typing .rectangle-22 {
  width: 1920px;
  height: 1080px;
  position: fixed;
  top: 0;
  left: 0;
}

.typing .wallet-connect-4 {
  background-color: #fff;
  border-radius: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  padding: 50px 0;
  display: inline-flex;
  position: fixed;
  top: 359px;
  left: 684px;
}

.typing .frame-263 {
  width: 551px;
  height: 62px;
  position: relative;
}

.typing .frame-264 {
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  padding: 0 40px;
  display: inline-flex;
  position: relative;
}

.typing .frame-265 {
  width: 471px;
  height: 32px;
  position: relative;
}

.typing .frame-266 {
  height: 32px;
  position: relative;
}

.typing .text-wrapper-149 {
  color: var(--text-900);
  font-family: var(--small-title-bold-font-family);
  font-size: var(--small-title-bold-font-size);
  font-style: var(--small-title-bold-font-style);
  font-weight: var(--small-title-bold-font-weight);
  letter-spacing: var(--small-title-bold-letter-spacing);
  line-height: var(--small-title-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 0;
}

.typing .iconify-27 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 4px !important;
  left: 447px !important;
}

.typing .please-note-you-can {
  color: var(--text-900);
  letter-spacing: .32px;
  white-space: nowrap;
  width: fit-content;
  font-family: ABeeZee, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.typing .text-wrapper-150 {
  letter-spacing: .05px;
}

.typing .text-wrapper-151 {
  letter-spacing: .05px;
  font-style: italic;
}

.typing .frame-267 {
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  height: 94px;
  display: inline-flex;
  position: relative;
}

.typing .frame-268 {
  width: 552px;
  height: 144px;
  margin-bottom: -50px;
  position: relative;
}

.typing .frame-269 {
  flex-direction: column;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.typing .frame-270 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 10px;
  padding: 0 40px;
  display: inline-flex;
  position: relative;
}

.typing .frame-271 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 12px;
  display: inline-flex;
  position: relative;
}

.typing .frame-272 {
  width: 472px;
  height: 18px;
  position: relative;
}

.typing .frame-273 {
  width: 129px;
  height: 18px;
  position: absolute;
  top: 0;
  left: 0;
}

.typing .text-wrapper-152 {
  color: var(--text-600);
  letter-spacing: .32px;
  white-space: nowrap;
  font-family: ABeeZee, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 0;
}

.typing .text-wrapper-153 {
  color: #000;
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 80px;
}

.typing .frame-274 {
  width: 152px;
  height: 18px;
  position: absolute;
  top: 0;
  left: 320px;
}

.typing .text-wrapper-154 {
  color: var(--text-600);
  letter-spacing: .32px;
  white-space: nowrap;
  font-family: ABeeZee, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 32px;
}

.typing .text-wrapper-155 {
  color: #000;
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 110px;
}

.typing .frame-275 {
  border: 1px solid;
  border-color: var(--primary-500main);
  background-color: #fff;
  border-radius: 10px;
  width: 472px;
  height: 66px;
  position: relative;
}

.typing .icons-22 {
  border: .84px solid !important;
  border-radius: 21px !important;
  width: 42px !important;
  height: 42px !important;
  position: absolute !important;
  top: 12px !important;
  left: 20px !important;
}

.typing .icons-23 {
  width: 22px !important;
  height: 22px !important;
  top: 9px !important;
  left: 9px !important;
}

.typing .frame-276 {
  justify-content: space-between;
  align-items: flex-start;
  width: 380px;
  display: flex;
  position: absolute;
  top: 24px;
  left: 72px;
}

.typing .input {
  color: #0000;
  letter-spacing: .32px;
  white-space: nowrap;
  background-color: #0000;
  border: 0;
  flex: none;
  margin-top: -1px;
  padding: 0;
  font-family: ABeeZee, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.typing .text-wrapper-156 {
  color: #000;
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.typing .frame-277 {
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  height: 66px;
  display: inline-flex;
  position: relative;
}

.typing .frame-278 {
  width: 552px;
  height: 115px;
  margin-bottom: -49px;
  position: relative;
}

.typing .frame-279 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 10px;
  display: inline-flex;
  position: relative;
}

.typing .frame-280 {
  background-color: var(--primary-500main);
  border: 1px solid;
  border-color: var(--primary-500main);
  border-radius: 10px;
  width: 472px;
  height: 66px;
  position: relative;
}

.typing .text-wrapper-157 {
  color: #fff;
  letter-spacing: .32px;
  white-space: nowrap;
  font-family: ABeeZee, Helvetica;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 23px;
  left: 190px;
}

.staked-info {
  background-color: #fafafa;
  border-radius: 10px;
  align-items: flex-start;
  gap: 28px;
  padding: 16px;
  display: inline-flex;
  position: relative;
}

.staked-info .frame-47 {
  width: 146px;
  height: 33px;
  position: relative;
}

.staked-info .icons-3 {
  border: .6px solid !important;
  border-radius: 15px !important;
  width: 30px !important;
  height: 30px !important;
  position: absolute !important;
  top: 2px !important;
  left: 0 !important;
}

.staked-info .icons-4 {
  width: 23px !important;
  height: 23px !important;
  top: 3px !important;
  left: 3px !important;
}

.staked-info .frame-48 {
  width: 134px;
  height: 33px;
  position: absolute;
  top: 0;
  left: 34px;
}

.staked-info .text-wrapper-32 {
  color: var(--text-600);
  font-family: var(--mobile-10px-font-family);
  font-size: var(--mobile-10px-font-size);
  font-style: var(--mobile-10px-font-style);
  font-weight: var(--mobile-10px-font-weight);
  letter-spacing: var(--mobile-10px-letter-spacing);
  line-height: var(--mobile-10px-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 0;
}

.staked-info .frame-49 {
  align-items: center;
  gap: 2.14px;
  display: inline-flex;
  position: absolute;
  top: 15px;
  left: 0;
}

.staked-info .text-wrapper-33 {
  color: var(--text-900);
  font-family: var(--notes-bold-font-family);
  font-size: var(--notes-bold-font-size);
  font-style: var(--notes-bold-font-style);
  font-weight: var(--notes-bold-font-weight);
  letter-spacing: var(--notes-bold-letter-spacing);
  line-height: var(--notes-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -.53px;
  position: relative;
}

.staked-info .frame-50 {
  width: 137px;
  height: 33px;
  position: relative;
}

.staked-info .property-1-SOL {
  width: 16px !important;
  height: 16px !important;
  top: 6px !important;
  left: 6px !important;
}

.stake {
  background-color: #0000;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.stake .overlap-5 {
  width: 375px;
  height: 1357px;
  position: absolute;
  top: 0;
  left: 0;
}

.stake .rectangle-23 {
  background-color: #fff;
  width: 375px;
  height: 1317px;
  position: absolute;
  top: 0;
  left: 0;
}

.stake .frame-281 {
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  display: inline-flex;
  position: absolute;
  top: 125px;
  left: 16px;
}

.stake .frame-282 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 20px;
  display: inline-flex;
  position: relative;
}

.stake .frame-283 {
  width: 278.58px;
  height: 184px;
  position: relative;
}

.stake .frame-284 {
  width: 263px;
  height: 82px;
  position: absolute;
  top: 0;
  left: 0;
}

.stake .group-88 {
  width: 147px;
  height: 27px;
  position: absolute;
  top: 0;
  left: 0;
}

.stake .frame-285 {
  background-color: var(--text-150);
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 8px 10px;
  display: inline-flex;
  position: relative;
}

.stake .text-wrapper-158 {
  color: var(--primary-500main);
  font-family: var(--mobile-10px-font-family);
  font-size: var(--mobile-10px-font-size);
  font-style: var(--mobile-10px-font-style);
  font-weight: var(--mobile-10px-font-weight);
  letter-spacing: var(--mobile-10px-letter-spacing);
  line-height: var(--mobile-10px-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -.52px;
  position: relative;
}

.stake .frame-286 {
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  display: inline-flex;
  position: absolute;
  top: 30px;
  left: 0;
}

.stake .stake-to-mint-GOO-8 {
  color: #0000;
  font-family: var(--small-title-bold-font-family);
  font-size: var(--small-title-bold-font-size);
  font-style: var(--small-title-bold-font-style);
  font-weight: var(--small-title-bold-font-weight);
  letter-spacing: var(--small-title-bold-letter-spacing);
  line-height: var(--small-title-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -.52px;
  position: relative;
}

.stake .text-wrapper-159 {
  color: #202020;
  font-family: var(--small-title-bold-font-family);
  font-size: var(--small-title-bold-font-size);
  font-style: var(--small-title-bold-font-style);
  font-weight: var(--small-title-bold-font-weight);
  letter-spacing: var(--small-title-bold-letter-spacing);
  line-height: var(--small-title-bold-line-height);
}

.stake .text-wrapper-160 {
  color: #ffba15;
  font-family: var(--small-title-bold-font-family);
  font-size: var(--small-title-bold-font-size);
  font-style: var(--small-title-bold-font-style);
  font-weight: var(--small-title-bold-font-weight);
  letter-spacing: var(--small-title-bold-letter-spacing);
  line-height: var(--small-title-bold-line-height);
}

.stake .text-wrapper-161 {
  color: var(--text-600);
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  font-family: ABeeZee, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.stake .frame-287 {
  width: 279px;
  height: 80px;
  position: absolute;
  top: 104px;
  left: 0;
}

.stake .frame-288 {
  width: 279px;
  height: 33px;
  position: absolute;
  top: 0;
  left: 0;
}

.stake .frame-289 {
  width: 134px;
  height: 33px;
  position: absolute;
  top: 0;
  left: 0;
}

.stake .text-wrapper-162 {
  color: var(--text-600);
  font-family: var(--mobile-10px-font-family);
  font-size: var(--mobile-10px-font-size);
  font-style: var(--mobile-10px-font-style);
  font-weight: var(--mobile-10px-font-weight);
  letter-spacing: var(--mobile-10px-letter-spacing);
  line-height: var(--mobile-10px-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 0;
}

.stake .frame-290 {
  align-items: center;
  gap: 2.14px;
  display: inline-flex;
  position: absolute;
  top: 15px;
  left: 0;
}

.stake .text-wrapper-163 {
  color: var(--text-900);
  font-family: var(--notes-bold-font-family);
  font-size: var(--notes-bold-font-size);
  font-style: var(--notes-bold-font-style);
  font-weight: var(--notes-bold-font-weight);
  letter-spacing: var(--notes-bold-letter-spacing);
  line-height: var(--notes-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -.53px;
  position: relative;
}

.stake .frame-291 {
  width: 134px;
  height: 33px;
  position: absolute;
  top: 0;
  left: 144px;
}

.stake .frame-292 {
  width: 279px;
  height: 33px;
  position: absolute;
  top: 47px;
  left: 0;
}

.stake .instance-node-7 {
  flex: none !important;
}

.stake .frame-293 {
  width: 343px;
  height: 700px;
  position: relative;
}

.stake .group-89 {
  width: 343px;
  height: 19px;
  position: absolute;
  top: 0;
  left: 0;
}

.stake .vector-6 {
  width: 343px;
  height: 1px;
  position: absolute;
  top: 19px;
  left: 0;
}

.stake .frame-294 {
  width: 159px;
  height: 17px;
  position: absolute;
  top: 0;
  left: 0;
}

.stake .frame-295 {
  align-items: center;
  gap: 3.96px;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 0;
}

.stake .iconify-5 {
  width: 16px !important;
  height: 16px !important;
  position: relative !important;
}

.stake .text-wrapper-164 {
  color: var(--text-900);
  font-family: var(--notes-bold-font-family);
  font-size: var(--notes-bold-font-size);
  font-style: var(--notes-bold-font-style);
  font-weight: var(--notes-bold-font-weight);
  letter-spacing: var(--notes-bold-letter-spacing);
  line-height: var(--notes-bold-line-height);
  width: 37px;
  margin-top: -.4px;
  position: relative;
}

.stake .vector-7 {
  width: 168px;
  height: 1px;
  position: absolute;
  top: 19px;
  left: 0;
}

.stake .frame-296 {
  width: 159px;
  height: 17px;
  position: absolute;
  top: 0;
  left: 176px;
}

.stake .text-wrapper-165 {
  color: var(--text-500);
  font-family: var(--notes-bold-font-family);
  font-size: var(--notes-bold-font-size);
  font-style: var(--notes-bold-font-style);
  font-weight: var(--notes-bold-font-weight);
  letter-spacing: var(--notes-bold-letter-spacing);
  line-height: var(--notes-bold-line-height);
  width: 37px;
  margin-top: -.4px;
  position: relative;
}

.stake .vector-8 {
  width: 169px;
  height: 1px;
  position: absolute;
  top: 19px;
  left: 0;
}

.stake .instance-node-8 {
  background-color: var(--primary-500main) !important;
}

.stake .mobile-tiers-3 {
  position: absolute !important;
  top: 39px !important;
  left: 0 !important;
}

.stake .mobile-tiers-4 {
  margin-left: -5px !important;
  position: relative !important;
}

.stake .mobile-tiers-5 {
  color: var(--white) !important;
}

.stake .instance-node-9 {
  width: 14px !important;
  height: 14px !important;
  position: absolute !important;
  top: 5px !important;
  left: 5px !important;
}

.stake .mobile-tiers-6 {
  position: absolute !important;
  top: 266px !important;
  left: 0 !important;
}

.stake .instance-node-10 {
  margin-left: -5px !important;
}

.stake .mobile-tiers-7 {
  background-color: #fafafa;
  border-radius: 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  padding: 14px;
  display: inline-flex;
  position: absolute;
  top: 493px;
  left: 0;
}

.stake .frame-297 {
  width: 315px;
  height: 123px;
  position: relative;
}

.stake .frame-298 {
  width: 315px;
  height: 50px;
  position: absolute;
  top: 0;
  left: 0;
}

.stake .frame-299 {
  width: 55px;
  height: 30px;
  position: absolute;
  top: 0;
  left: 0;
}

.stake .frame-300 {
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.stake .icons-40 {
  width: 30px !important;
  height: 30px !important;
  position: relative !important;
}

.stake .stake-NUTS-for-free-7 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  width: 315px;
  position: absolute;
  top: 31px;
  left: 0;
}

.stake .frame-301 {
  flex-direction: column;
  align-items: flex-start;
  gap: 13px;
  display: inline-flex;
  position: absolute;
  top: 56px;
  left: 0;
}

.stake .frame-302 {
  width: 315px;
  height: 24px;
  position: relative;
}

.stake .text-wrapper-166 {
  color: #000;
  letter-spacing: 0;
  text-align: right;
  width: 46px;
  font-family: ABeeZee, Helvetica;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 269px;
}

.stake .group-90 {
  background-color: #fff;
  border-radius: 25px;
  width: 315px;
  height: 10px;
  position: absolute;
  top: 14px;
  left: 0;
}

.stake .rectangle-24 {
  background-color: var(--primary-500main);
  border-radius: 25px;
  width: 10px;
  height: 10px;
}

.stake .frame-303 {
  width: 315px;
  height: 30px;
  position: relative;
}

.stake .frame-304 {
  align-items: flex-start;
  gap: 20px;
  display: inline-flex;
  position: relative;
  top: 1px;
}

.stake .frame-305 {
  width: 80px;
  height: 27px;
  position: relative;
}

.stake .icons-24 {
  border: .48px solid !important;
  border-radius: 12px !important;
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 2px !important;
  left: 0 !important;
}

.stake .frame-306 {
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 27px;
}

.stake .text-wrapper-167 {
  color: var(--text-600);
  font-family: var(--mobile-10px-font-family);
  font-size: var(--mobile-10px-font-size);
  font-style: var(--mobile-10px-font-style);
  font-weight: var(--mobile-10px-font-weight);
  letter-spacing: var(--mobile-10px-letter-spacing);
  line-height: var(--mobile-10px-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -.55px;
  position: relative;
}

.stake .frame-307 {
  flex: none;
  align-items: center;
  gap: 2.59px;
  display: inline-flex;
  position: relative;
}

.stake .text-wrapper-168 {
  color: var(--text-900);
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  margin-top: -.65px;
  font-family: ABeeZee, Helvetica;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.stake .frame-308 {
  width: 103px;
  height: 27px;
  position: relative;
}

.stake .icons-27 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 2px !important;
  left: 0 !important;
}

.stake .frame-309 {
  width: 51px;
  height: 27px;
  position: relative;
}

.stake .wallet-19 {
  color: var(--white) !important;
  white-space: unset !important;
}

.stake .footer-8 {
  position: absolute !important;
  top: 1151px !important;
  left: 0 !important;
}

.stake .frame-310 {
  flex-direction: column;
  align-items: flex-start;
  display: inline-flex;
  position: fixed;
  top: 0;
  left: 0;
}

.stake .status-bar-5 {
  background-color: #fff !important;
  width: 375px !important;
}

.stake .status-bar-6 {
  color: var(--themes-black-100) !important;
}

.stake .nav-10 {
  background-color: #fff;
  width: 375px;
  height: 54px;
  position: relative;
}

.stake .logo-9 {
  align-items: center;
  gap: 5.44px;
  display: inline-flex;
  position: absolute;
  top: 10px;
  left: 15px;
}

.stake .group-91 {
  width: 33.98px;
  height: 33.98px;
  position: relative;
}

.stake .frame-311 {
  background-color: #fff;
  border-radius: 16.86px;
  width: 34px;
  height: 34px;
}

.stake .group-92 {
  background-image: url("vector-15.c45a7388.svg");
  background-size: 100% 100%;
  width: 20px;
  height: 20px;
  position: relative;
  top: 7px;
  left: 7px;
}

.stake .group-93 {
  background-color: #fff;
  border-radius: 2.47px;
  width: 5px;
  height: 5px;
  position: absolute;
  top: 7px;
  left: 6px;
}

.stake .ellipse-41 {
  background-color: #000;
  border-radius: 1.81px;
  width: 4px;
  height: 4px;
  position: relative;
  top: 1px;
  left: 1px;
}

.stake .group-94 {
  background-color: #fff;
  border-radius: 2.47px;
  width: 5px;
  height: 5px;
  position: absolute;
  top: 2px;
  left: 12px;
}

.stake .group-95 {
  width: 72.59px;
  height: 22.34px;
  position: relative;
}

.stake .group-96 {
  width: 12px;
  height: 18px;
  position: absolute;
  top: 4px;
  left: 0;
}

.stake .group-97 {
  border: 2.49px solid #000;
  border-radius: 6px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 4px;
  left: 13px;
}

.stake .ellipse-42 {
  background-color: #000;
  border-radius: 2.35px;
  width: 5px;
  height: 5px;
  position: relative;
  top: 3px;
  left: 2px;
}

.stake .group-98 {
  border: 2.49px solid #000;
  border-radius: 6px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 4px;
  left: 26px;
}

.stake .ellipse-43 {
  background-color: #000;
  border-radius: 2.35px;
  width: 5px;
  height: 5px;
  position: relative;
  top: 3px;
  left: 4px;
}

.stake .group-99 {
  width: 12px;
  height: 16px;
  position: absolute;
  top: 0;
  left: 39px;
}

.stake .overlap-group-11 {
  height: 16px;
  position: relative;
}

.stake .ellipse-44 {
  border: 2.49px solid #000;
  border-radius: 6px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 4px;
  left: 0;
}

.stake .rectangle-25 {
  background-color: #000;
  border-radius: 0 0 1.24px 1.24px;
  width: 2px;
  height: 12px;
  position: absolute;
  top: 0;
  left: 0;
}

.stake .subtract-9 {
  width: 12px;
  height: 12px;
  position: absolute;
  top: 4px;
  left: 52px;
}

.stake .frame-312 {
  align-items: flex-start;
  width: 7px;
  height: 12px;
  display: flex;
  position: absolute;
  top: 4px;
  left: 65px;
}

.stake .rectangle-26 {
  background-color: #000;
  width: 2.47px;
  height: 11.99px;
  position: relative;
  transform: rotate(180deg);
}

.stake .ellipse-45 {
  background-color: #000;
  border-radius: 2.35px;
  width: 4.7px;
  height: 4.7px;
  position: relative;
}

.stake .menu-3 {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 15px;
  left: 335px;
}

.stake .overlap-6 {
  background-color: #fff;
  height: 2083px;
  position: relative;
}

.stake .frame-313 {
  flex-direction: column;
  align-items: flex-start;
  gap: 80px;
  display: inline-flex;
  position: absolute;
  top: 150px;
  left: 92px;
}

.stake .frame-314 {
  width: 1733px;
  height: 319px;
  position: relative;
}

.stake .frame-315 {
  width: 557px;
  height: 187px;
  position: absolute;
  top: 0;
  left: 0;
}

.stake .group-100 {
  width: 238px;
  height: 50px;
  position: absolute;
  top: 0;
  left: 0;
}

.stake .frame-316 {
  background-color: var(--text-150);
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 16px 14px;
  display: inline-flex;
  position: relative;
}

.stake .text-wrapper-169 {
  color: var(--primary-500main);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.stake .frame-317 {
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
  display: inline-flex;
  position: absolute;
  top: 64px;
  left: 0;
}

.stake .stake-to-mint-GOO-9 {
  color: #0000;
  font-family: var(--h3-bold-font-family);
  font-size: var(--h3-bold-font-size);
  font-style: var(--h3-bold-font-style);
  font-weight: var(--h3-bold-font-weight);
  letter-spacing: var(--h3-bold-letter-spacing);
  line-height: var(--h3-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.stake .text-wrapper-170 {
  color: #202020;
  font-family: var(--h3-bold-font-family);
  font-size: var(--h3-bold-font-size);
  font-style: var(--h3-bold-font-style);
  font-weight: var(--h3-bold-font-weight);
  letter-spacing: var(--h3-bold-letter-spacing);
  line-height: var(--h3-bold-line-height);
}

.stake .text-wrapper-171 {
  color: #ffba15;
  font-family: var(--h3-bold-font-family);
  font-size: var(--h3-bold-font-size);
  font-style: var(--h3-bold-font-style);
  font-weight: var(--h3-bold-font-weight);
  letter-spacing: var(--h3-bold-letter-spacing);
  line-height: var(--h3-bold-line-height);
}

.stake .text-wrapper-172 {
  color: var(--text-600);
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  font-family: ABeeZee, Helvetica;
  font-size: 28px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.stake .frame-318 {
  width: 1733px;
  height: 72px;
  position: absolute;
  top: 247px;
  left: 0;
}

.stake .frame-319 {
  width: 293px;
  height: 72px;
  position: absolute;
  top: 0;
  left: 0;
}

.stake .text-wrapper-173 {
  color: var(--text-600);
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 0;
}

.stake .frame-320 {
  align-items: center;
  gap: 4.66px;
  display: inline-flex;
  position: absolute;
  top: 37px;
  left: 0;
}

.stake .text-wrapper-174 {
  color: var(--text-900);
  font-family: var(--small-title-bold-font-family);
  font-size: var(--small-title-bold-font-size);
  font-style: var(--small-title-bold-font-style);
  font-weight: var(--small-title-bold-font-weight);
  letter-spacing: var(--small-title-bold-letter-spacing);
  line-height: var(--small-title-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1.17px;
  position: relative;
}

.stake .frame-321 {
  width: 293px;
  height: 72px;
  position: absolute;
  top: 0;
  left: 480px;
}

.stake .frame-322 {
  width: 293px;
  height: 72px;
  position: absolute;
  top: 0;
  left: 960px;
}

.stake .frame-323 {
  width: 293px;
  height: 72px;
  position: absolute;
  top: 0;
  left: 1440px;
}

.stake .user-profile-4 {
  background-color: #fafafa;
  border-radius: 20px;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 180px;
  padding: 50px 84px;
  display: inline-flex;
  position: absolute;
  top: 7px;
  left: 799px;
  overflow: hidden;
}

.stake .nuts-4 {
  width: 302px;
  height: 72px;
  position: relative;
}

.stake .icons-26 {
  border: 1.36px solid !important;
  border-radius: 34px !important;
  width: 68px !important;
  height: 68px !important;
  position: absolute !important;
  top: 2px !important;
  left: 0 !important;
}

.stake .icons-28 {
  width: 52px !important;
  height: 52px !important;
  top: 7px !important;
  left: 7px !important;
}

.stake .element-7 {
  width: 224px;
  height: 72px;
  position: absolute;
  top: 0;
  left: 78px;
}

.stake .sol-5 {
  width: 284px;
  height: 72px;
  position: relative;
}

.stake .icons-29 {
  width: 36px !important;
  height: 36px !important;
  top: 14px !important;
  left: 14px !important;
}

.stake .element-8 {
  width: 206px;
  height: 72px;
  position: absolute;
  top: 0;
  left: 78px;
}

.stake .frame-324 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 40px;
  display: inline-flex;
  position: relative;
}

.stake .tabs-10 {
  position: relative !important;
}

.stake .tiers-8 {
  background-color: #fafafa;
  border-radius: 20px;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 70px;
  padding: 50px 44px;
  display: inline-flex;
  position: relative;
}

.stake .frame-325 {
  width: 1644px;
  height: 94px;
  position: relative;
}

.stake .frame-326 {
  width: 1644px;
  height: 60px;
  position: absolute;
  top: 0;
  left: 0;
}

.stake .frame-327 {
  align-items: center;
  gap: 10px;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 0;
}

.stake .frame-328 {
  flex: none;
  align-items: center;
  display: inline-flex;
  position: relative;
}

.stake .icons-31 {
  width: 60px !important;
  height: 60px !important;
  position: relative !important;
}

.stake .icons-32 {
  margin-left: -13px !important;
}

.stake .div-12 {
  color: #0000;
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  font-family: ABeeZee, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.stake .text-wrapper-175 {
  color: #202020;
  font-style: italic;
}

.stake .text-wrapper-176 {
  color: #ffba15;
  font-style: italic;
}

.stake .text-wrapper-177 {
  color: #000;
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  text-align: right;
  width: 46px;
  position: absolute;
  top: 18px;
  left: 1598px;
}

.stake .view-4 {
  position: absolute !important;
  top: 100px !important;
  left: 0 !important;
}

.stake .frame-329 {
  flex: none;
  justify-content: space-between;
  align-items: center;
  width: 1644px;
  display: flex;
  position: relative;
}

.stake .frame-330 {
  flex: none;
  align-items: flex-start;
  gap: 78px;
  display: inline-flex;
  position: relative;
}

.stake .frame-331 {
  align-items: center;
  gap: 10px;
  width: 232px;
  display: flex;
  position: relative;
}

.stake .instance-node-11 {
  width: 34px !important;
  height: 34px !important;
  position: absolute !important;
  top: 12px !important;
  left: 12px !important;
}

.stake .frame-332 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 6px;
  display: inline-flex;
  position: relative;
}

.stake .text-wrapper-178 {
  color: var(--text-600);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.stake .frame-333 {
  flex: none;
  align-items: center;
  gap: 4.66px;
  display: inline-flex;
  position: relative;
}

.stake .text-wrapper-179 {
  color: var(--text-900);
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1.17px;
  position: relative;
}

.stake .wallet-20 {
  flex: none !important;
  padding: 16px !important;
}

.stake .wallet-21 {
  margin-top: -1px !important;
}

.stake .frame-334 {
  justify-content: space-between;
  align-items: center;
  width: 1644px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.stake .frame-335 {
  flex: none;
  align-items: center;
  gap: 10px;
  display: inline-flex;
  position: relative;
}

.stake .text-wrapper-180 {
  color: #000;
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  text-align: right;
  width: 46px;
  position: relative;
}

.stake .stake-NUTS-for-free-8 {
  color: var(--text-900);
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  font-family: ABeeZee, Helvetica;
  font-size: 24px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.stake .footer-9 {
  position: absolute !important;
  top: 1789px !important;
  left: 0 !important;
}

.stake .footer-10 {
  left: 656px !important;
}

.stake .nav-11 {
  width: 1920px;
  height: 100px;
  position: absolute;
  top: 0;
  left: 0;
}

.stake .nav-12 {
  background-image: url("image.6f06755b.svg");
  background-size: 100% 100%;
  height: 100px;
}

.stake .group-101 {
  width: 1736px;
  height: 51px;
  position: relative;
  top: 24px;
  left: 92px;
}

.stake .logo-10 {
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 0;
}

.stake .group-102 {
  width: 50px;
  height: 50px;
  position: relative;
}

.stake .frame-336 {
  background-color: #fff;
  border-radius: 24.82px;
  height: 50px;
}

.stake .group-103 {
  background-image: url("vector.563a97e7.svg");
  background-size: 100% 100%;
  width: 29px;
  height: 29px;
  position: relative;
  top: 10px;
  left: 10px;
}

.stake .group-104 {
  background-color: #fff;
  border-radius: 3.63px;
  width: 7px;
  height: 7px;
  position: absolute;
  top: 10px;
  left: 9px;
}

.stake .ellipse-46 {
  background-color: #000;
  border-radius: 2.66px;
  width: 5px;
  height: 5px;
  position: relative;
  top: 1px;
  left: 1px;
}

.stake .group-105 {
  background-color: #fff;
  border-radius: 3.63px;
  width: 7px;
  height: 7px;
  position: absolute;
  top: 3px;
  left: 17px;
}

.stake .group-106 {
  width: 106.81px;
  height: 32.88px;
  position: relative;
}

.stake .group-107 {
  width: 18px;
  height: 27px;
  position: absolute;
  top: 6px;
  left: 0;
}

.stake .group-108 {
  border: 3.66px solid #000;
  border-radius: 8.82px;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 6px;
  left: 19px;
}

.stake .ellipse-47 {
  background-color: #000;
  border-radius: 3.46px;
  width: 7px;
  height: 7px;
  position: relative;
  top: 5px;
  left: 3px;
}

.stake .group-109 {
  border: 3.66px solid #000;
  border-radius: 8.82px;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 6px;
  left: 39px;
}

.stake .ellipse-48 {
  background-color: #000;
  border-radius: 3.46px;
  width: 7px;
  height: 7px;
  position: relative;
  top: 5px;
  left: 5px;
}

.stake .group-110 {
  width: 18px;
  height: 24px;
  position: absolute;
  top: 0;
  left: 58px;
}

.stake .overlap-group-12 {
  height: 24px;
  position: relative;
}

.stake .ellipse-49 {
  border: 3.66px solid #000;
  border-radius: 8.82px;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 6px;
  left: 0;
}

.stake .rectangle-27 {
  background-color: #000;
  border-radius: 0 0 1.82px 1.82px;
  width: 4px;
  height: 17px;
  position: absolute;
  top: 0;
  left: 0;
}

.stake .subtract-10 {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 6px;
  left: 77px;
}

.stake .frame-337 {
  align-items: flex-start;
  width: 11px;
  height: 18px;
  display: flex;
  position: absolute;
  top: 6px;
  left: 96px;
}

.stake .rectangle-28 {
  background-color: #000;
  width: 3.64px;
  height: 17.65px;
  position: relative;
  transform: rotate(180deg);
}

.stake .ellipse-50 {
  background-color: #000;
  border-radius: 3.46px;
  width: 6.91px;
  height: 6.91px;
  position: relative;
}

.stake .frame-338 {
  width: 429px;
  height: 18px;
  position: absolute;
  top: 16px;
  left: 657px;
}

.stake .text-wrapper-181 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 0;
}

.stake .text-wrapper-182 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 103px;
}

.stake .text-wrapper-183 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 209px;
}

.stake .text-wrapper-184 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 341px;
}

.stake .frame-339 {
  align-items: center;
  gap: 20px;
  display: inline-flex;
  position: absolute;
  top: 1px;
  left: 1414px;
}

.stake .frame-340 {
  width: 114px;
  height: 40px;
  position: relative;
}

.stake .twitter-8 {
  background-color: #1c1c1c;
  border-radius: 500px;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.stake .x-4 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 8px !important;
  left: 8px !important;
}

.stake .discord-10 {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  left: 64px;
}

.stake-tiers {
  background-color: #0000;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.stake-tiers .overlap-wrapper {
  width: 1920px;
  height: 2067px;
  overflow: hidden;
}

.stake-tiers .overlap-7 {
  background-color: #fff;
  height: 2083px;
  position: relative;
}

.stake-tiers .frame-341 {
  flex-direction: column;
  align-items: flex-start;
  gap: 80px;
  display: inline-flex;
  position: absolute;
  top: 150px;
  left: 92px;
}

.stake-tiers .frame-342 {
  width: 1733px;
  height: 319px;
  position: relative;
}

.stake-tiers .frame-343 {
  width: 557px;
  height: 187px;
  position: absolute;
  top: 0;
  left: 0;
}

.stake-tiers .group-111 {
  width: 238px;
  height: 50px;
  position: absolute;
  top: 0;
  left: 0;
}

.stake-tiers .frame-344 {
  background-color: var(--text-150);
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 16px 14px;
  display: inline-flex;
  position: relative;
}

.stake-tiers .text-wrapper-185 {
  color: var(--primary-500main);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.stake-tiers .frame-345 {
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
  display: inline-flex;
  position: absolute;
  top: 64px;
  left: 0;
}

.stake-tiers .stake-to-mint-GOO-10 {
  color: #0000;
  font-family: var(--h3-bold-font-family);
  font-size: var(--h3-bold-font-size);
  font-style: var(--h3-bold-font-style);
  font-weight: var(--h3-bold-font-weight);
  letter-spacing: var(--h3-bold-letter-spacing);
  line-height: var(--h3-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.stake-tiers .text-wrapper-186 {
  color: #202020;
  font-family: var(--h3-bold-font-family);
  font-size: var(--h3-bold-font-size);
  font-style: var(--h3-bold-font-style);
  font-weight: var(--h3-bold-font-weight);
  letter-spacing: var(--h3-bold-letter-spacing);
  line-height: var(--h3-bold-line-height);
}

.stake-tiers .text-wrapper-187 {
  color: #ffba15;
  font-family: var(--h3-bold-font-family);
  font-size: var(--h3-bold-font-size);
  font-style: var(--h3-bold-font-style);
  font-weight: var(--h3-bold-font-weight);
  letter-spacing: var(--h3-bold-letter-spacing);
  line-height: var(--h3-bold-line-height);
}

.stake-tiers .text-wrapper-188 {
  color: var(--text-600);
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  font-family: ABeeZee, Helvetica;
  font-size: 28px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.stake-tiers .frame-346 {
  width: 1733px;
  height: 72px;
  position: absolute;
  top: 247px;
  left: 0;
}

.stake-tiers .frame-347 {
  width: 293px;
  height: 72px;
  position: absolute;
  top: 0;
  left: 0;
}

.stake-tiers .text-wrapper-189 {
  color: var(--text-600);
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 0;
}

.stake-tiers .frame-348 {
  align-items: center;
  gap: 4.66px;
  display: inline-flex;
  position: absolute;
  top: 37px;
  left: 0;
}

.stake-tiers .text-wrapper-190 {
  color: var(--text-900);
  font-family: var(--small-title-bold-font-family);
  font-size: var(--small-title-bold-font-size);
  font-style: var(--small-title-bold-font-style);
  font-weight: var(--small-title-bold-font-weight);
  letter-spacing: var(--small-title-bold-letter-spacing);
  line-height: var(--small-title-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1.17px;
  position: relative;
}

.stake-tiers .frame-349 {
  width: 293px;
  height: 72px;
  position: absolute;
  top: 0;
  left: 480px;
}

.stake-tiers .frame-350 {
  width: 293px;
  height: 72px;
  position: absolute;
  top: 0;
  left: 960px;
}

.stake-tiers .frame-351 {
  width: 293px;
  height: 72px;
  position: absolute;
  top: 0;
  left: 1440px;
}

.stake-tiers .user-profile-5 {
  background-color: #fafafa;
  border-radius: 20px;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 180px;
  padding: 50px 84px;
  display: inline-flex;
  position: absolute;
  top: 7px;
  left: 799px;
  overflow: hidden;
}

.stake-tiers .nuts-5 {
  width: 302px;
  height: 72px;
  position: relative;
}

.stake-tiers .icons-33 {
  border: 1.36px solid !important;
  border-radius: 34px !important;
  width: 68px !important;
  height: 68px !important;
  position: absolute !important;
  top: 2px !important;
  left: 0 !important;
}

.stake-tiers .icons-34 {
  width: 52px !important;
  height: 52px !important;
  top: 7px !important;
  left: 7px !important;
}

.stake-tiers .element-9 {
  width: 224px;
  height: 72px;
  position: absolute;
  top: 0;
  left: 78px;
}

.stake-tiers .sol-6 {
  width: 284px;
  height: 72px;
  position: relative;
}

.stake-tiers .icons-35 {
  width: 36px !important;
  height: 36px !important;
  top: 14px !important;
  left: 14px !important;
}

.stake-tiers .element-10 {
  width: 206px;
  height: 72px;
  position: absolute;
  top: 0;
  left: 78px;
}

.stake-tiers .frame-352 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 40px;
  display: inline-flex;
  position: relative;
}

.stake-tiers .tabs-11 {
  position: relative !important;
}

.stake-tiers .tiers-9 {
  background-color: #fafafa;
  border-radius: 20px;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 70px;
  padding: 50px 44px;
  display: inline-flex;
  position: relative;
}

.stake-tiers .frame-353 {
  width: 1644px;
  height: 94px;
  position: relative;
}

.stake-tiers .frame-354 {
  width: 1644px;
  height: 60px;
  position: absolute;
  top: 0;
  left: 0;
}

.stake-tiers .frame-355 {
  align-items: center;
  gap: 10px;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 0;
}

.stake-tiers .frame-356 {
  flex: none;
  align-items: center;
  display: inline-flex;
  position: relative;
}

.stake-tiers .icons-37 {
  width: 60px !important;
  height: 60px !important;
  position: relative !important;
}

.stake-tiers .icons-38 {
  margin-left: -13px !important;
}

.stake-tiers .div-13 {
  color: #0000;
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  font-family: ABeeZee, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.stake-tiers .text-wrapper-191 {
  color: #202020;
  font-style: italic;
}

.stake-tiers .text-wrapper-192 {
  color: #ffba15;
  font-style: italic;
}

.stake-tiers .text-wrapper-193 {
  color: #000;
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  text-align: right;
  width: 46px;
  position: absolute;
  top: 18px;
  left: 1598px;
}

.stake-tiers .view-5 {
  position: absolute !important;
  top: 100px !important;
  left: 0 !important;
}

.stake-tiers .frame-357 {
  flex: none;
  justify-content: space-between;
  align-items: center;
  width: 1644px;
  display: flex;
  position: relative;
}

.stake-tiers .frame-358 {
  flex: none;
  align-items: flex-start;
  gap: 78px;
  display: inline-flex;
  position: relative;
}

.stake-tiers .frame-359 {
  align-items: center;
  gap: 10px;
  width: 232px;
  display: flex;
  position: relative;
}

.stake-tiers .iconify-92 {
  width: 34px !important;
  height: 34px !important;
  position: absolute !important;
  top: 12px !important;
  left: 12px !important;
}

.stake-tiers .frame-360 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 6px;
  display: inline-flex;
  position: relative;
}

.stake-tiers .text-wrapper-194 {
  color: var(--text-600);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.stake-tiers .frame-361 {
  flex: none;
  align-items: center;
  gap: 4.66px;
  display: inline-flex;
  position: relative;
}

.stake-tiers .text-wrapper-195 {
  color: var(--text-900);
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1.17px;
  position: relative;
}

.stake-tiers .wallet-22 {
  background-color: #fff !important;
  flex: none !important;
  padding: 16px !important;
}

.stake-tiers .wallet-23 {
  color: var(--text-500) !important;
  margin-top: -1px !important;
}

.stake-tiers .frame-362 {
  justify-content: space-between;
  align-items: center;
  width: 1644px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.stake-tiers .frame-363 {
  flex: none;
  align-items: center;
  gap: 10px;
  display: inline-flex;
  position: relative;
}

.stake-tiers .text-wrapper-196 {
  color: #000;
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  text-align: right;
  width: 46px;
  position: relative;
}

.stake-tiers .stake-NUTS-for-free-9 {
  color: var(--text-900);
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  font-family: Aeonik TRIAL-Bold, Helvetica;
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.stake-tiers .wallet-24 {
  flex: none !important;
  padding: 16px !important;
}

.stake-tiers .wallet-25 {
  margin-top: -1px !important;
}

.stake-tiers .footer-11 {
  position: absolute !important;
  top: 1789px !important;
  left: 0 !important;
}

.stake-tiers .footer-12 {
  left: 656px !important;
}

.stake-tiers .nav-13 {
  width: 1920px;
  height: 100px;
  position: absolute;
  top: 0;
  left: 0;
}

.stake-tiers .nav-14 {
  background-image: url("rectangle-2-10.f28c8881.svg");
  background-size: 100% 100%;
  height: 100px;
}

.stake-tiers .group-112 {
  width: 1736px;
  height: 51px;
  position: relative;
  top: 24px;
  left: 92px;
}

.stake-tiers .logo-11 {
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 0;
}

.stake-tiers .group-113 {
  width: 50px;
  height: 50px;
  position: relative;
}

.stake-tiers .frame-364 {
  background-color: #fff;
  border-radius: 24.82px;
  height: 50px;
}

.stake-tiers .group-114 {
  background-image: url("vector.563a97e7.svg");
  background-size: 100% 100%;
  width: 29px;
  height: 29px;
  position: relative;
  top: 10px;
  left: 10px;
}

.stake-tiers .group-115 {
  background-color: #fff;
  border-radius: 3.63px;
  width: 7px;
  height: 7px;
  position: absolute;
  top: 10px;
  left: 9px;
}

.stake-tiers .ellipse-51 {
  background-color: #000;
  border-radius: 2.66px;
  width: 5px;
  height: 5px;
  position: relative;
  top: 1px;
  left: 1px;
}

.stake-tiers .group-116 {
  background-color: #fff;
  border-radius: 3.63px;
  width: 7px;
  height: 7px;
  position: absolute;
  top: 3px;
  left: 17px;
}

.stake-tiers .group-117 {
  width: 106.81px;
  height: 32.88px;
  position: relative;
}

.stake-tiers .group-118 {
  width: 18px;
  height: 27px;
  position: absolute;
  top: 6px;
  left: 0;
}

.stake-tiers .group-119 {
  border: 3.66px solid #000;
  border-radius: 8.82px;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 6px;
  left: 19px;
}

.stake-tiers .ellipse-52 {
  background-color: #000;
  border-radius: 3.46px;
  width: 7px;
  height: 7px;
  position: relative;
  top: 5px;
  left: 3px;
}

.stake-tiers .group-120 {
  border: 3.66px solid #000;
  border-radius: 8.82px;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 6px;
  left: 39px;
}

.stake-tiers .ellipse-53 {
  background-color: #000;
  border-radius: 3.46px;
  width: 7px;
  height: 7px;
  position: relative;
  top: 5px;
  left: 5px;
}

.stake-tiers .group-121 {
  width: 18px;
  height: 24px;
  position: absolute;
  top: 0;
  left: 58px;
}

.stake-tiers .overlap-group-13 {
  height: 24px;
  position: relative;
}

.stake-tiers .ellipse-54 {
  border: 3.66px solid #000;
  border-radius: 8.82px;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 6px;
  left: 0;
}

.stake-tiers .rectangle-29 {
  background-color: #000;
  border-radius: 0 0 1.82px 1.82px;
  width: 4px;
  height: 17px;
  position: absolute;
  top: 0;
  left: 0;
}

.stake-tiers .subtract-11 {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 6px;
  left: 77px;
}

.stake-tiers .frame-365 {
  align-items: flex-start;
  width: 11px;
  height: 18px;
  display: flex;
  position: absolute;
  top: 6px;
  left: 96px;
}

.stake-tiers .rectangle-30 {
  background-color: #000;
  width: 3.64px;
  height: 17.65px;
  position: relative;
  transform: rotate(180deg);
}

.stake-tiers .ellipse-55 {
  background-color: #000;
  border-radius: 3.46px;
  width: 6.91px;
  height: 6.91px;
  position: relative;
}

.stake-tiers .frame-366 {
  width: 429px;
  height: 18px;
  position: absolute;
  top: 16px;
  left: 657px;
}

.stake-tiers .text-wrapper-197 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 0;
}

.stake-tiers .text-wrapper-198 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 103px;
}

.stake-tiers .text-wrapper-199 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 209px;
}

.stake-tiers .text-wrapper-200 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 341px;
}

.stake-tiers .frame-367 {
  align-items: center;
  gap: 20px;
  display: inline-flex;
  position: absolute;
  top: 1px;
  left: 1414px;
}

.stake-tiers .frame-368 {
  width: 114px;
  height: 40px;
  position: relative;
}

.stake-tiers .twitter-9 {
  background-color: #1c1c1c;
  border-radius: 500px;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.stake-tiers .x-5 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 8px !important;
  left: 8px !important;
}

.stake-tiers .discord-11 {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  left: 64px;
}

.stake-tiers .wallet-26 {
  flex: none !important;
}

.state-default {
  width: 48px !important;
  height: 48px !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.state-hover {
  width: 51px !important;
  height: 48px !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.stake-in-NUTS {
  background-color: #0000;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.stake-in-NUTS .div-14 {
  width: 1920px;
  height: 1080px;
  position: relative;
  overflow: hidden;
}

.stake-in-NUTS .overlap-8 {
  background-color: #fff;
  width: 1920px;
  height: 2083px;
  position: absolute;
  top: 0;
  left: 0;
}

.stake-in-NUTS .frame-369 {
  flex-direction: column;
  align-items: flex-start;
  gap: 80px;
  display: inline-flex;
  position: absolute;
  top: 150px;
  left: 92px;
}

.stake-in-NUTS .frame-370 {
  width: 1733px;
  height: 319px;
  position: relative;
}

.stake-in-NUTS .frame-371 {
  width: 557px;
  height: 187px;
  position: absolute;
  top: 0;
  left: 0;
}

.stake-in-NUTS .group-122 {
  width: 238px;
  height: 50px;
  position: absolute;
  top: 0;
  left: 0;
}

.stake-in-NUTS .frame-372 {
  background-color: var(--text-150);
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 16px 14px;
  display: inline-flex;
  position: relative;
}

.stake-in-NUTS .text-wrapper-201 {
  color: var(--primary-500main);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.stake-in-NUTS .frame-373 {
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
  display: inline-flex;
  position: absolute;
  top: 64px;
  left: 0;
}

.stake-in-NUTS .stake-to-mint-GOO-11 {
  color: #0000;
  font-family: var(--h3-bold-font-family);
  font-size: var(--h3-bold-font-size);
  font-style: var(--h3-bold-font-style);
  font-weight: var(--h3-bold-font-weight);
  letter-spacing: var(--h3-bold-letter-spacing);
  line-height: var(--h3-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.stake-in-NUTS .text-wrapper-202 {
  color: #202020;
  font-family: var(--h3-bold-font-family);
  font-size: var(--h3-bold-font-size);
  font-style: var(--h3-bold-font-style);
  font-weight: var(--h3-bold-font-weight);
  letter-spacing: var(--h3-bold-letter-spacing);
  line-height: var(--h3-bold-line-height);
}

.stake-in-NUTS .text-wrapper-203 {
  color: #ffba15;
  font-family: var(--h3-bold-font-family);
  font-size: var(--h3-bold-font-size);
  font-style: var(--h3-bold-font-style);
  font-weight: var(--h3-bold-font-weight);
  letter-spacing: var(--h3-bold-letter-spacing);
  line-height: var(--h3-bold-line-height);
}

.stake-in-NUTS .text-wrapper-204 {
  color: var(--text-600);
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  font-family: ABeeZee, Helvetica;
  font-size: 28px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.stake-in-NUTS .frame-374 {
  width: 1733px;
  height: 72px;
  position: absolute;
  top: 247px;
  left: 0;
}

.stake-in-NUTS .frame-375 {
  width: 293px;
  height: 72px;
  position: absolute;
  top: 0;
  left: 0;
}

.stake-in-NUTS .text-wrapper-205 {
  color: var(--text-600);
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 0;
}

.stake-in-NUTS .frame-376 {
  align-items: center;
  gap: 4.66px;
  display: inline-flex;
  position: absolute;
  top: 37px;
  left: 0;
}

.stake-in-NUTS .text-wrapper-206 {
  color: var(--text-900);
  font-family: var(--small-title-bold-font-family);
  font-size: var(--small-title-bold-font-size);
  font-style: var(--small-title-bold-font-style);
  font-weight: var(--small-title-bold-font-weight);
  letter-spacing: var(--small-title-bold-letter-spacing);
  line-height: var(--small-title-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1.17px;
  position: relative;
}

.stake-in-NUTS .frame-377 {
  width: 293px;
  height: 72px;
  position: absolute;
  top: 0;
  left: 480px;
}

.stake-in-NUTS .frame-378 {
  width: 293px;
  height: 72px;
  position: absolute;
  top: 0;
  left: 960px;
}

.stake-in-NUTS .frame-379 {
  width: 293px;
  height: 72px;
  position: absolute;
  top: 0;
  left: 1440px;
}

.stake-in-NUTS .user-profile-6 {
  background-color: #fafafa;
  border-radius: 20px;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 180px;
  padding: 50px 84px;
  display: inline-flex;
  position: absolute;
  top: 7px;
  left: 799px;
  overflow: hidden;
}

.stake-in-NUTS .nuts-6 {
  width: 302px;
  height: 72px;
  position: relative;
}

.stake-in-NUTS .icons-39 {
  border: 1.36px solid !important;
  border-radius: 34px !important;
  width: 68px !important;
  height: 68px !important;
  position: absolute !important;
  top: 2px !important;
  left: 0 !important;
}

.stake-in-NUTS .icons-41 {
  width: 52px !important;
  height: 52px !important;
  top: 7px !important;
  left: 7px !important;
}

.stake-in-NUTS .element-11 {
  width: 224px;
  height: 72px;
  position: absolute;
  top: 0;
  left: 78px;
}

.stake-in-NUTS .sol-7 {
  width: 284px;
  height: 72px;
  position: relative;
}

.stake-in-NUTS .icons-42 {
  width: 36px !important;
  height: 36px !important;
  top: 14px !important;
  left: 14px !important;
}

.stake-in-NUTS .element-12 {
  width: 206px;
  height: 72px;
  position: absolute;
  top: 0;
  left: 78px;
}

.stake-in-NUTS .frame-380 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 40px;
  display: inline-flex;
  position: relative;
}

.stake-in-NUTS .tabs-12 {
  position: relative !important;
}

.stake-in-NUTS .tiers-10 {
  background-color: #fafafa;
  border-radius: 20px;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 70px;
  padding: 50px 44px;
  display: inline-flex;
  position: relative;
}

.stake-in-NUTS .frame-381 {
  width: 1644px;
  height: 94px;
  position: relative;
}

.stake-in-NUTS .frame-382 {
  width: 1644px;
  height: 60px;
  position: absolute;
  top: 0;
  left: 0;
}

.stake-in-NUTS .frame-383 {
  align-items: center;
  gap: 10px;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 0;
}

.stake-in-NUTS .frame-384 {
  flex: none;
  align-items: center;
  display: inline-flex;
  position: relative;
}

.stake-in-NUTS .icons-45 {
  margin-left: -13px !important;
}

.stake-in-NUTS .div-15 {
  color: #0000;
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  font-family: ABeeZee, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.stake-in-NUTS .text-wrapper-207 {
  color: #202020;
  font-style: italic;
}

.stake-in-NUTS .text-wrapper-208 {
  color: #ffba15;
  font-style: italic;
}

.stake-in-NUTS .text-wrapper-209 {
  color: #000;
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  text-align: right;
  width: 46px;
  position: absolute;
  top: 18px;
  left: 1598px;
}

.stake-in-NUTS .view-6 {
  position: absolute !important;
  top: 100px !important;
  left: 0 !important;
}

.stake-in-NUTS .frame-385 {
  flex: none;
  justify-content: space-between;
  align-items: center;
  width: 1644px;
  display: flex;
  position: relative;
}

.stake-in-NUTS .frame-386 {
  flex: none;
  align-items: flex-start;
  gap: 78px;
  display: inline-flex;
  position: relative;
}

.stake-in-NUTS .frame-387 {
  align-items: center;
  gap: 10px;
  width: 232px;
  display: flex;
  position: relative;
}

.stake-in-NUTS .frame-388 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 6px;
  display: inline-flex;
  position: relative;
}

.stake-in-NUTS .text-wrapper-210 {
  color: var(--text-600);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.stake-in-NUTS .frame-389 {
  flex: none;
  align-items: center;
  gap: 4.66px;
  display: inline-flex;
  position: relative;
}

.stake-in-NUTS .text-wrapper-211 {
  color: var(--text-900);
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1.17px;
  position: relative;
}

.stake-in-NUTS .wallet-27 {
  flex: none !important;
  padding: 16px !important;
}

.stake-in-NUTS .wallet-28 {
  margin-top: -1px !important;
}

.stake-in-NUTS .frame-390 {
  justify-content: space-between;
  align-items: center;
  width: 1644px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.stake-in-NUTS .frame-391 {
  flex: none;
  align-items: center;
  gap: 10px;
  display: inline-flex;
  position: relative;
}

.stake-in-NUTS .text-wrapper-212 {
  color: #000;
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  text-align: right;
  width: 46px;
  position: relative;
}

.stake-in-NUTS .stake-NUTS-for-free-10 {
  color: var(--text-900);
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  font-family: ABeeZee, Helvetica;
  font-size: 24px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.stake-in-NUTS .nav-15 {
  width: 1920px;
  height: 100px;
  position: absolute;
  top: 0;
  left: 0;
}

.stake-in-NUTS .nav-16 {
  background-size: 100% 100%;
  height: 100px;
}

.stake-in-NUTS .group-123 {
  width: 1736px;
  height: 51px;
  position: relative;
  top: 24px;
  left: 92px;
}

.stake-in-NUTS .logo-12 {
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 0;
}

.stake-in-NUTS .group-124 {
  width: 50px;
  height: 50px;
  position: relative;
}

.stake-in-NUTS .frame-392 {
  background-color: #fff;
  border-radius: 24.82px;
  height: 50px;
}

.stake-in-NUTS .group-125 {
  background-size: 100% 100%;
  width: 29px;
  height: 29px;
  position: relative;
  top: 10px;
  left: 10px;
}

.stake-in-NUTS .group-126 {
  background-color: #fff;
  border-radius: 3.63px;
  width: 7px;
  height: 7px;
  position: absolute;
  top: 10px;
  left: 9px;
}

.stake-in-NUTS .ellipse-56 {
  background-color: #000;
  border-radius: 2.66px;
  width: 5px;
  height: 5px;
  position: relative;
  top: 1px;
  left: 1px;
}

.stake-in-NUTS .group-127 {
  background-color: #fff;
  border-radius: 3.63px;
  width: 7px;
  height: 7px;
  position: absolute;
  top: 3px;
  left: 17px;
}

.stake-in-NUTS .group-128 {
  width: 106.81px;
  height: 32.88px;
  position: relative;
}

.stake-in-NUTS .group-129 {
  width: 18px;
  height: 27px;
  position: absolute;
  top: 6px;
  left: 0;
}

.stake-in-NUTS .group-130 {
  border: 3.66px solid #000;
  border-radius: 8.82px;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 6px;
  left: 19px;
}

.stake-in-NUTS .ellipse-57 {
  background-color: #000;
  border-radius: 3.46px;
  width: 7px;
  height: 7px;
  position: relative;
  top: 5px;
  left: 3px;
}

.stake-in-NUTS .group-131 {
  border: 3.66px solid #000;
  border-radius: 8.82px;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 6px;
  left: 39px;
}

.stake-in-NUTS .ellipse-58 {
  background-color: #000;
  border-radius: 3.46px;
  width: 7px;
  height: 7px;
  position: relative;
  top: 5px;
  left: 5px;
}

.stake-in-NUTS .group-132 {
  width: 18px;
  height: 24px;
  position: absolute;
  top: 0;
  left: 58px;
}

.stake-in-NUTS .overlap-group-14 {
  height: 24px;
  position: relative;
}

.stake-in-NUTS .ellipse-59 {
  border: 3.66px solid #000;
  border-radius: 8.82px;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 6px;
  left: 0;
}

.stake-in-NUTS .rectangle-31 {
  background-color: #000;
  border-radius: 0 0 1.82px 1.82px;
  width: 4px;
  height: 17px;
  position: absolute;
  top: 0;
  left: 0;
}

.stake-in-NUTS .subtract-12 {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 6px;
  left: 77px;
}

.stake-in-NUTS .frame-393 {
  align-items: flex-start;
  width: 11px;
  height: 18px;
  display: flex;
  position: absolute;
  top: 6px;
  left: 96px;
}

.stake-in-NUTS .rectangle-32 {
  background-color: #000;
  width: 3.64px;
  height: 17.65px;
  position: relative;
  transform: rotate(180deg);
}

.stake-in-NUTS .ellipse-60 {
  background-color: #000;
  border-radius: 3.46px;
  width: 6.91px;
  height: 6.91px;
  position: relative;
}

.stake-in-NUTS .frame-394 {
  width: 429px;
  height: 18px;
  position: absolute;
  top: 16px;
  left: 657px;
}

.stake-in-NUTS .text-wrapper-213 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 0;
}

.stake-in-NUTS .text-wrapper-214 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 103px;
}

.stake-in-NUTS .text-wrapper-215 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 209px;
}

.stake-in-NUTS .text-wrapper-216 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 341px;
}

.stake-in-NUTS .frame-395 {
  align-items: center;
  gap: 20px;
  display: inline-flex;
  position: absolute;
  top: 1px;
  left: 1414px;
}

.stake-in-NUTS .frame-396 {
  width: 114px;
  height: 40px;
  position: relative;
}

.stake-in-NUTS .twitter-10 {
  background-color: #1c1c1c;
  border-radius: 500px;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.stake-in-NUTS .discord-12 {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  left: 64px;
}

.stake-in-NUTS .wallet-29 {
  background-image: unset !important;
  position: relative !important;
}

.stake-in-NUTS .wallet-30 {
  flex: none !important;
}

.stake-in-NUTS .rectangle-33 {
  width: 1920px;
  height: 1080px;
  position: fixed;
  top: 0;
  left: 0;
}

.stake-in-NUTS .wallet-connect-5 {
  opacity: 0;
  background-color: #fff;
  border-radius: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  padding: 50px 0;
  display: block;
  position: fixed;
  top: 359px;
  left: 684px;
  transform: scale(1.1);
}

.stake-in-NUTS .frame-397 {
  width: 551px;
  height: 62px;
  position: relative;
}

.stake-in-NUTS .frame-398 {
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  padding: 0 40px;
  display: inline-flex;
  position: relative;
}

.stake-in-NUTS .frame-399 {
  width: 471px;
  height: 32px;
  position: relative;
}

.stake-in-NUTS .frame-400 {
  height: 32px;
  position: relative;
}

.stake-in-NUTS .text-wrapper-217 {
  color: var(--text-900);
  font-family: var(--small-title-bold-font-family);
  font-size: var(--small-title-bold-font-size);
  font-style: var(--small-title-bold-font-style);
  font-weight: var(--small-title-bold-font-weight);
  letter-spacing: var(--small-title-bold-letter-spacing);
  line-height: var(--small-title-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 0;
}

.stake-in-NUTS .please-note-you-can-2 {
  color: var(--text-900);
  letter-spacing: .32px;
  white-space: nowrap;
  width: fit-content;
  font-family: ABeeZee, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.stake-in-NUTS .text-wrapper-218 {
  letter-spacing: .05px;
}

.stake-in-NUTS .text-wrapper-219 {
  letter-spacing: .05px;
  font-style: italic;
}

.stake-in-NUTS .frame-401 {
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  height: 94px;
  display: inline-flex;
  position: relative;
}

.stake-in-NUTS .frame-402 {
  width: 552px;
  height: 144px;
  margin-bottom: -50px;
  position: relative;
}

.stake-in-NUTS .frame-403 {
  flex-direction: column;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.stake-in-NUTS .frame-404 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 10px;
  padding: 0 40px;
  display: inline-flex;
  position: relative;
}

.stake-in-NUTS .frame-405 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 12px;
  display: inline-flex;
  position: relative;
}

.stake-in-NUTS .frame-406 {
  width: 472px;
  height: 18px;
  position: relative;
}

.stake-in-NUTS .frame-407 {
  width: 129px;
  height: 18px;
  position: absolute;
  top: 0;
  left: 0;
}

.stake-in-NUTS .text-wrapper-220 {
  color: var(--text-600);
  letter-spacing: .32px;
  white-space: nowrap;
  font-family: ABeeZee, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 0;
}

.stake-in-NUTS .text-wrapper-221 {
  color: #000;
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 80px;
}

.stake-in-NUTS .frame-408 {
  width: 152px;
  height: 18px;
  position: absolute;
  top: 0;
  left: 320px;
}

.stake-in-NUTS .text-wrapper-222 {
  color: #000;
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 78px;
}

.stake-in-NUTS .frame-409 {
  border: 1px solid;
  border-color: var(--primary-500main);
  background-color: #fff;
  border-radius: 10px;
  width: 472px;
  height: 66px;
  position: relative;
}

.stake-in-NUTS .icons-46 {
  border: .84px solid !important;
  border-radius: 21px !important;
  width: 42px !important;
  height: 42px !important;
  position: absolute !important;
  top: 12px !important;
  left: 20px !important;
}

.stake-in-NUTS .icons-47 {
  width: 32px !important;
  height: 32px !important;
  top: 4px !important;
  left: 4px !important;
}

.stake-in-NUTS .frame-410 {
  align-items: flex-start;
  gap: 187px;
  display: inline-flex;
  position: absolute;
  top: 24px;
  left: 72px;
}

.stake-in-NUTS .enter-NUTS-amounts {
  color: var(--text-600);
  letter-spacing: .32px;
  white-space: nowrap;
  background-color: #0000;
  border: 0;
  flex: none;
  margin-top: -1px;
  padding: 0;
  font-family: ABeeZee, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.stake-in-NUTS .text-wrapper-223 {
  color: #000;
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.stake-in-NUTS .frame-411 {
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  height: 66px;
  display: inline-flex;
  position: relative;
}

.stake-in-NUTS .frame-412 {
  width: 552px;
  height: 115px;
  margin-bottom: -49px;
  position: relative;
}

.stake-in-NUTS .frame-413 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 10px;
  display: inline-flex;
  position: relative;
}

.stake-in-NUTS .frame-414 {
  background-color: var(--primary-500main);
  border: 1px solid;
  border-color: var(--primary-500main);
  border-radius: 10px;
  width: 472px;
  height: 66px;
  position: relative;
}

.stake-in-NUTS .text-wrapper-224 {
  color: #fff;
  letter-spacing: .32px;
  white-space: nowrap;
  font-family: ABeeZee, Helvetica;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 23px;
  left: 190px;
}

.stake-page-tiers-screen {
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.stake-page-tiers-screen .stake-page-tiers-2 {
  background-color: #fff;
  width: 1920px;
  height: 2083px;
  position: relative;
}

.stake-page-tiers-screen .frame-415 {
  flex-direction: column;
  align-items: flex-start;
  gap: 80px;
  display: inline-flex;
  position: absolute;
  top: 150px;
  left: 92px;
}

.stake-page-tiers-screen .frame-416 {
  width: 1733px;
  height: 319px;
  position: relative;
}

.stake-page-tiers-screen .frame-417 {
  width: 557px;
  height: 187px;
  position: absolute;
  top: 0;
  left: 0;
}

.stake-page-tiers-screen .group-133 {
  width: 238px;
  height: 50px;
  position: absolute;
  top: 0;
  left: 0;
}

.stake-page-tiers-screen .frame-418 {
  background-color: var(--text-150);
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 16px 14px;
  display: inline-flex;
  position: relative;
}

.stake-page-tiers-screen .text-wrapper-225 {
  color: var(--primary-500main);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.stake-page-tiers-screen .frame-419 {
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
  display: inline-flex;
  position: absolute;
  top: 64px;
  left: 0;
}

.stake-page-tiers-screen .stake-to-mint-GOO-12 {
  color: #0000;
  font-family: var(--h3-bold-font-family);
  font-size: var(--h3-bold-font-size);
  font-style: var(--h3-bold-font-style);
  font-weight: var(--h3-bold-font-weight);
  letter-spacing: var(--h3-bold-letter-spacing);
  line-height: var(--h3-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.stake-page-tiers-screen .text-wrapper-226 {
  color: #202020;
  font-family: var(--h3-bold-font-family);
  font-size: var(--h3-bold-font-size);
  font-style: var(--h3-bold-font-style);
  font-weight: var(--h3-bold-font-weight);
  letter-spacing: var(--h3-bold-letter-spacing);
  line-height: var(--h3-bold-line-height);
}

.stake-page-tiers-screen .text-wrapper-227 {
  color: #ffba15;
  font-family: var(--h3-bold-font-family);
  font-size: var(--h3-bold-font-size);
  font-style: var(--h3-bold-font-style);
  font-weight: var(--h3-bold-font-weight);
  letter-spacing: var(--h3-bold-letter-spacing);
  line-height: var(--h3-bold-line-height);
}

.stake-page-tiers-screen .text-wrapper-228 {
  color: var(--text-600);
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  font-family: ABeeZee, Helvetica;
  font-size: 28px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.stake-page-tiers-screen .frame-420 {
  width: 1733px;
  height: 72px;
  position: absolute;
  top: 247px;
  left: 0;
}

.stake-page-tiers-screen .frame-421 {
  width: 293px;
  height: 72px;
  position: absolute;
  top: 0;
  left: 0;
}

.stake-page-tiers-screen .text-wrapper-229 {
  color: var(--text-600);
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 0;
}

.stake-page-tiers-screen .frame-422 {
  align-items: center;
  gap: 4.66px;
  display: inline-flex;
  position: absolute;
  top: 37px;
  left: 0;
}

.stake-page-tiers-screen .text-wrapper-230 {
  color: var(--text-900);
  font-family: var(--small-title-bold-font-family);
  font-size: var(--small-title-bold-font-size);
  font-style: var(--small-title-bold-font-style);
  font-weight: var(--small-title-bold-font-weight);
  letter-spacing: var(--small-title-bold-letter-spacing);
  line-height: var(--small-title-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1.17px;
  position: relative;
}

.stake-page-tiers-screen .frame-423 {
  width: 293px;
  height: 72px;
  position: absolute;
  top: 0;
  left: 480px;
}

.stake-page-tiers-screen .element-13 {
  color: #0000;
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1.17px;
  font-family: Aeonik TRIAL-Bold, Helvetica;
  font-size: 28px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.stake-page-tiers-screen .text-wrapper-231 {
  color: #202020;
}

.stake-page-tiers-screen .text-wrapper-232 {
  color: #d4d4d4;
}

.stake-page-tiers-screen .frame-424 {
  width: 293px;
  height: 72px;
  position: absolute;
  top: 0;
  left: 960px;
}

.stake-page-tiers-screen .frame-425 {
  width: 293px;
  height: 72px;
  position: absolute;
  top: 0;
  left: 1440px;
}

.stake-page-tiers-screen .frame-426 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 40px;
  display: inline-flex;
  position: relative;
}

.stake-page-tiers-screen .tabs-13 {
  position: relative !important;
}

.stake-page-tiers-screen .design-component-instance-node-3 {
  flex: none !important;
}

.stake-page-tiers-screen .icons-48 {
  width: 60px !important;
  height: 60px !important;
  position: relative !important;
}

.stake-page-tiers-screen .icons-49 {
  margin-left: -13px !important;
}

.stake-page-tiers-screen .iconify-42 {
  width: 34px !important;
  height: 34px !important;
  position: absolute !important;
  top: 12px !important;
  left: 12px !important;
}

.stake-page-tiers-screen .tiers-11 {
  letter-spacing: 0 !important;
  font-family: Aeonik TRIAL-RegularItalic, Helvetica !important;
  font-size: 20px !important;
  font-style: italic !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.stake-page-tiers-screen .iconify-instance {
  width: 24px !important;
  height: 24px !important;
  position: relative !important;
}

.stake-page-tiers-screen .footer-13 {
  position: absolute !important;
  top: 1805px !important;
  left: 0 !important;
}

.stake-page-tiers-screen .footer-14 {
  left: 656px !important;
}

.stake-page-tiers-screen .nav-17 {
  background-image: url("rectangle-2-4-2.dc2a98b1.svg");
  background-size: 100% 100%;
  width: 1920px;
  height: 100px;
  position: fixed;
  top: 0;
  left: 0;
}

.stake-page-tiers-screen .group-134 {
  width: 1736px;
  height: 51px;
  position: relative;
  top: 24px;
  left: 92px;
}

.stake-page-tiers-screen .logo-13 {
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 0;
}

.stake-page-tiers-screen .group-135 {
  width: 50px;
  height: 50px;
  position: relative;
}

.stake-page-tiers-screen .frame-427 {
  background-color: #fff;
  border-radius: 24.82px;
  height: 50px;
}

.stake-page-tiers-screen .group-136 {
  background-image: url("vector.563a97e7.svg");
  background-size: 100% 100%;
  width: 29px;
  height: 29px;
  position: relative;
  top: 10px;
  left: 10px;
}

.stake-page-tiers-screen .group-137 {
  background-color: #fff;
  border-radius: 3.63px;
  width: 7px;
  height: 7px;
  position: absolute;
  top: 10px;
  left: 9px;
}

.stake-page-tiers-screen .ellipse-61 {
  background-color: #000;
  border-radius: 2.66px;
  width: 5px;
  height: 5px;
  position: relative;
  top: 1px;
  left: 1px;
}

.stake-page-tiers-screen .group-138 {
  background-color: #fff;
  border-radius: 3.63px;
  width: 7px;
  height: 7px;
  position: absolute;
  top: 3px;
  left: 17px;
}

.stake-page-tiers-screen .group-139 {
  width: 106.81px;
  height: 32.88px;
  position: relative;
}

.stake-page-tiers-screen .group-140 {
  width: 18px;
  height: 27px;
  position: absolute;
  top: 6px;
  left: 0;
}

.stake-page-tiers-screen .group-141 {
  border: 3.66px solid #000;
  border-radius: 8.82px;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 6px;
  left: 19px;
}

.stake-page-tiers-screen .ellipse-62 {
  background-color: #000;
  border-radius: 3.46px;
  width: 7px;
  height: 7px;
  position: relative;
  top: 5px;
  left: 3px;
}

.stake-page-tiers-screen .group-142 {
  border: 3.66px solid #000;
  border-radius: 8.82px;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 6px;
  left: 39px;
}

.stake-page-tiers-screen .ellipse-63 {
  background-color: #000;
  border-radius: 3.46px;
  width: 7px;
  height: 7px;
  position: relative;
  top: 5px;
  left: 5px;
}

.stake-page-tiers-screen .group-143 {
  width: 18px;
  height: 24px;
  position: absolute;
  top: 0;
  left: 58px;
}

.stake-page-tiers-screen .overlap-group-15 {
  height: 24px;
  position: relative;
}

.stake-page-tiers-screen .ellipse-64 {
  border: 3.66px solid #000;
  border-radius: 8.82px;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 6px;
  left: 0;
}

.stake-page-tiers-screen .rectangle-34 {
  background-color: #000;
  border-radius: 0 0 1.82px 1.82px;
  width: 4px;
  height: 17px;
  position: absolute;
  top: 0;
  left: 0;
}

.stake-page-tiers-screen .subtract-13 {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 6px;
  left: 77px;
}

.stake-page-tiers-screen .frame-428 {
  align-items: flex-start;
  width: 11px;
  height: 18px;
  display: flex;
  position: absolute;
  top: 6px;
  left: 96px;
}

.stake-page-tiers-screen .rectangle-35 {
  background-color: #000;
  width: 3.64px;
  height: 17.65px;
  position: relative;
  transform: rotate(180deg);
}

.stake-page-tiers-screen .ellipse-65 {
  background-color: #000;
  border-radius: 3.46px;
  width: 6.91px;
  height: 6.91px;
  position: relative;
}

.stake-page-tiers-screen .navbar-4 {
  width: 429px;
  height: 18px;
  position: absolute;
  top: 16px;
  left: 657px;
}

.stake-page-tiers-screen .text-wrapper-233 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 0;
}

.stake-page-tiers-screen .text-wrapper-234 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 103px;
}

.stake-page-tiers-screen .text-wrapper-235 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 209px;
}

.stake-page-tiers-screen .text-wrapper-236 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 341px;
}

.stake-page-tiers-screen .frame-429 {
  align-items: center;
  gap: 20px;
  display: inline-flex;
  position: absolute;
  top: 1px;
  left: 1421px;
}

.stake-page-tiers-screen .frame-430 {
  width: 114px;
  height: 40px;
  position: relative;
}

.stake-page-tiers-screen .twitter-11 {
  background-color: #1c1c1c;
  border-radius: 500px;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.stake-page-tiers-screen .x-7 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 8px !important;
  left: 8px !important;
}

.stake-page-tiers-screen .discord-13 {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  left: 64px;
}

.stake-screen {
  background-color: #0000;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.stake-screen .stake-2 {
  width: 1920px;
  height: 2067px;
  overflow: hidden;
}

.stake-screen .overlap-9 {
  background-color: #fff;
  height: 2083px;
  position: relative;
}

.stake-screen .frame-431 {
  flex-direction: column;
  align-items: flex-start;
  gap: 80px;
  display: inline-flex;
  position: absolute;
  top: 150px;
  left: 92px;
}

.stake-screen .frame-432 {
  width: 1733px;
  height: 319px;
  position: relative;
}

.stake-screen .frame-433 {
  width: 557px;
  height: 187px;
  position: absolute;
  top: 0;
  left: 0;
}

.stake-screen .group-144 {
  width: 238px;
  height: 50px;
  position: absolute;
  top: 0;
  left: 0;
}

.stake-screen .frame-434 {
  background-color: var(--text-150);
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 16px 14px;
  display: inline-flex;
  position: relative;
}

.stake-screen .text-wrapper-237 {
  color: var(--primary-500main);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.stake-screen .frame-435 {
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
  display: inline-flex;
  position: absolute;
  top: 64px;
  left: 0;
}

.stake-screen .stake-to-mint-GOO-13 {
  color: #0000;
  font-family: var(--h3-bold-font-family);
  font-size: var(--h3-bold-font-size);
  font-style: var(--h3-bold-font-style);
  font-weight: var(--h3-bold-font-weight);
  letter-spacing: var(--h3-bold-letter-spacing);
  line-height: var(--h3-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.stake-screen .text-wrapper-238 {
  color: #202020;
  font-family: var(--h3-bold-font-family);
  font-size: var(--h3-bold-font-size);
  font-style: var(--h3-bold-font-style);
  font-weight: var(--h3-bold-font-weight);
  letter-spacing: var(--h3-bold-letter-spacing);
  line-height: var(--h3-bold-line-height);
}

.stake-screen .text-wrapper-239 {
  color: #ffba15;
  font-family: var(--h3-bold-font-family);
  font-size: var(--h3-bold-font-size);
  font-style: var(--h3-bold-font-style);
  font-weight: var(--h3-bold-font-weight);
  letter-spacing: var(--h3-bold-letter-spacing);
  line-height: var(--h3-bold-line-height);
}

.stake-screen .text-wrapper-240 {
  color: var(--text-600);
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  font-family: ABeeZee, Helvetica;
  font-size: 28px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.stake-screen .frame-436 {
  width: 1733px;
  height: 72px;
  position: absolute;
  top: 247px;
  left: 0;
}

.stake-screen .frame-437 {
  width: 293px;
  height: 72px;
  position: absolute;
  top: 0;
  left: 0;
}

.stake-screen .text-wrapper-241 {
  color: var(--text-600);
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 0;
}

.stake-screen .frame-438 {
  align-items: center;
  gap: 4.66px;
  display: inline-flex;
  position: absolute;
  top: 37px;
  left: 0;
}

.stake-screen .text-wrapper-242 {
  color: var(--text-900);
  font-family: var(--small-title-bold-font-family);
  font-size: var(--small-title-bold-font-size);
  font-style: var(--small-title-bold-font-style);
  font-weight: var(--small-title-bold-font-weight);
  letter-spacing: var(--small-title-bold-letter-spacing);
  line-height: var(--small-title-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1.17px;
  position: relative;
}

.stake-screen .frame-439 {
  width: 293px;
  height: 72px;
  position: absolute;
  top: 0;
  left: 480px;
}

.stake-screen .frame-440 {
  width: 293px;
  height: 72px;
  position: absolute;
  top: 0;
  left: 960px;
}

.stake-screen .frame-441 {
  width: 293px;
  height: 72px;
  position: absolute;
  top: 0;
  left: 1440px;
}

.stake-screen .user-profile-7 {
  background-color: #fafafa;
  border-radius: 20px;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 180px;
  padding: 50px 84px;
  display: inline-flex;
  position: absolute;
  top: 7px;
  left: 799px;
  overflow: hidden;
}

.stake-screen .nuts-7 {
  width: 302px;
  height: 72px;
  position: relative;
}

.stake-screen .icons-50 {
  border: 1.36px solid !important;
  border-radius: 34px !important;
  width: 68px !important;
  height: 68px !important;
  position: absolute !important;
  top: 2px !important;
  left: 0 !important;
}

.stake-screen .icons-51 {
  width: 52px !important;
  height: 52px !important;
  top: 7px !important;
  left: 7px !important;
}

.stake-screen .element-14 {
  width: 224px;
  height: 72px;
  position: absolute;
  top: 0;
  left: 78px;
}

.stake-screen .sol-8 {
  width: 284px;
  height: 72px;
  position: relative;
}

.stake-screen .icons-52 {
  width: 36px !important;
  height: 36px !important;
  top: 14px !important;
  left: 14px !important;
}

.stake-screen .element-15 {
  width: 206px;
  height: 72px;
  position: absolute;
  top: 0;
  left: 78px;
}

.stake-screen .frame-442 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 40px;
  display: inline-flex;
  position: relative;
}

.stake-screen .design-component-instance-node-4 {
  position: relative !important;
}

.stake-screen .tiers-12 {
  background-color: #fafafa;
  border-radius: 20px;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 70px;
  padding: 50px 44px;
  display: inline-flex;
  position: relative;
}

.stake-screen .frame-443 {
  width: 1644px;
  height: 94px;
  position: relative;
}

.stake-screen .frame-444 {
  width: 1644px;
  height: 60px;
  position: absolute;
  top: 0;
  left: 0;
}

.stake-screen .frame-445 {
  align-items: center;
  gap: 10px;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 0;
}

.stake-screen .frame-446 {
  flex: none;
  align-items: center;
  display: inline-flex;
  position: relative;
}

.stake-screen .icons-55 {
  width: 60px !important;
  height: 60px !important;
  position: relative !important;
}

.stake-screen .icons-56 {
  margin-left: -13px !important;
}

.stake-screen .div-16 {
  color: #0000;
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  font-family: ABeeZee, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.stake-screen .text-wrapper-243 {
  color: #202020;
  font-style: italic;
}

.stake-screen .text-wrapper-244 {
  color: #ffba15;
  font-style: italic;
}

.stake-screen .text-wrapper-245 {
  color: #000;
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  text-align: right;
  width: 46px;
  position: absolute;
  top: 18px;
  left: 1598px;
}

.stake-screen .view-7 {
  position: absolute !important;
  top: 100px !important;
  left: 0 !important;
}

.stake-screen .frame-447 {
  flex: none;
  justify-content: space-between;
  align-items: center;
  width: 1644px;
  display: flex;
  position: relative;
}

.stake-screen .frame-448 {
  flex: none;
  align-items: flex-start;
  gap: 78px;
  display: inline-flex;
  position: relative;
}

.stake-screen .frame-449 {
  align-items: center;
  gap: 10px;
  width: 232px;
  display: flex;
  position: relative;
}

.stake-screen .property-1-money-bill-wave-alt-instance {
  width: 34px !important;
  height: 34px !important;
  position: absolute !important;
  top: 12px !important;
  left: 12px !important;
}

.stake-screen .frame-450 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 6px;
  display: inline-flex;
  position: relative;
}

.stake-screen .text-wrapper-246 {
  color: var(--text-600);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.stake-screen .frame-451 {
  flex: none;
  align-items: center;
  gap: 4.66px;
  display: inline-flex;
  position: relative;
}

.stake-screen .text-wrapper-247 {
  color: var(--text-900);
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1.17px;
  position: relative;
}

.stake-screen .wallet-31 {
  flex: none !important;
  padding: 16px !important;
}

.stake-screen .wallet-32 {
  margin-top: -1px !important;
}

.stake-screen .frame-452 {
  justify-content: space-between;
  align-items: center;
  width: 1644px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.stake-screen .frame-453 {
  flex: none;
  align-items: center;
  gap: 10px;
  display: inline-flex;
  position: relative;
}

.stake-screen .text-wrapper-248 {
  color: #000;
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  text-align: right;
  width: 46px;
  position: relative;
}

.stake-screen .stake-NUTS-for-free-11 {
  color: var(--text-900);
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  font-family: ABeeZee, Helvetica;
  font-size: 24px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.stake-screen .footer-15 {
  position: absolute !important;
  top: 1789px !important;
  left: 0 !important;
}

.stake-screen .footer-16 {
  left: 656px !important;
}

.stake-screen .nav-18 {
  width: 1920px;
  height: 100px;
  position: absolute;
  top: 0;
  left: 0;
}

.stake-screen .nav-19 {
  background-image: url("image.6f06755b.svg");
  background-size: 100% 100%;
  height: 100px;
}

.stake-screen .group-145 {
  width: 1736px;
  height: 51px;
  position: relative;
  top: 24px;
  left: 92px;
}

.stake-screen .logo-14 {
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 0;
}

.stake-screen .group-146 {
  width: 50px;
  height: 50px;
  position: relative;
}

.stake-screen .frame-454 {
  background-color: #fff;
  border-radius: 24.82px;
  height: 50px;
}

.stake-screen .group-147 {
  background-image: url("vector.563a97e7.svg");
  background-size: 100% 100%;
  width: 29px;
  height: 29px;
  position: relative;
  top: 10px;
  left: 10px;
}

.stake-screen .group-148 {
  background-color: #fff;
  border-radius: 3.63px;
  width: 7px;
  height: 7px;
  position: absolute;
  top: 10px;
  left: 9px;
}

.stake-screen .ellipse-66 {
  background-color: #000;
  border-radius: 2.66px;
  width: 5px;
  height: 5px;
  position: relative;
  top: 1px;
  left: 1px;
}

.stake-screen .group-149 {
  background-color: #fff;
  border-radius: 3.63px;
  width: 7px;
  height: 7px;
  position: absolute;
  top: 3px;
  left: 17px;
}

.stake-screen .group-150 {
  width: 106.81px;
  height: 32.88px;
  position: relative;
}

.stake-screen .group-151 {
  width: 18px;
  height: 27px;
  position: absolute;
  top: 6px;
  left: 0;
}

.stake-screen .group-152 {
  border: 3.66px solid #000;
  border-radius: 8.82px;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 6px;
  left: 19px;
}

.stake-screen .ellipse-67 {
  background-color: #000;
  border-radius: 3.46px;
  width: 7px;
  height: 7px;
  position: relative;
  top: 5px;
  left: 3px;
}

.stake-screen .group-153 {
  border: 3.66px solid #000;
  border-radius: 8.82px;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 6px;
  left: 39px;
}

.stake-screen .ellipse-68 {
  background-color: #000;
  border-radius: 3.46px;
  width: 7px;
  height: 7px;
  position: relative;
  top: 5px;
  left: 5px;
}

.stake-screen .group-154 {
  width: 18px;
  height: 24px;
  position: absolute;
  top: 0;
  left: 58px;
}

.stake-screen .overlap-group-16 {
  height: 24px;
  position: relative;
}

.stake-screen .ellipse-69 {
  border: 3.66px solid #000;
  border-radius: 8.82px;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 6px;
  left: 0;
}

.stake-screen .rectangle-36 {
  background-color: #000;
  border-radius: 0 0 1.82px 1.82px;
  width: 4px;
  height: 17px;
  position: absolute;
  top: 0;
  left: 0;
}

.stake-screen .subtract-14 {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 6px;
  left: 77px;
}

.stake-screen .frame-455 {
  align-items: flex-start;
  width: 11px;
  height: 18px;
  display: flex;
  position: absolute;
  top: 6px;
  left: 96px;
}

.stake-screen .rectangle-37 {
  background-color: #000;
  width: 3.64px;
  height: 17.65px;
  position: relative;
  transform: rotate(180deg);
}

.stake-screen .ellipse-70 {
  background-color: #000;
  border-radius: 3.46px;
  width: 6.91px;
  height: 6.91px;
  position: relative;
}

.stake-screen .frame-456 {
  width: 429px;
  height: 18px;
  position: absolute;
  top: 16px;
  left: 657px;
}

.stake-screen .text-wrapper-249 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 0;
}

.stake-screen .text-wrapper-250 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 103px;
}

.stake-screen .text-wrapper-251 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 209px;
}

.stake-screen .text-wrapper-252 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 341px;
}

.stake-screen .frame-457 {
  align-items: center;
  gap: 20px;
  display: inline-flex;
  position: absolute;
  top: 1px;
  left: 1414px;
}

.stake-screen .frame-458 {
  width: 114px;
  height: 40px;
  position: relative;
}

.stake-screen .twitter-12 {
  background-color: #1c1c1c;
  border-radius: 500px;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.stake-screen .x-8 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 8px !important;
  left: 8px !important;
}

.stake-screen .discord-14 {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  left: 64px;
}

.stake-screen .wallet-33 {
  flex: none !important;
}

.stake-screen .iconify-1 {
  width: 24px !important;
  height: 24px !important;
  position: relative !important;
}

.stake-in-SOL {
  background-color: #0000;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.stake-in-SOL .div-17 {
  width: 1920px;
  height: 1080px;
  position: relative;
  overflow: hidden;
}

.stake-in-SOL .overlap-10 {
  background-color: #fff;
  width: 1920px;
  height: 2083px;
  position: absolute;
  top: 0;
  left: 0;
}

.stake-in-SOL .frame-459 {
  flex-direction: column;
  align-items: flex-start;
  gap: 80px;
  display: inline-flex;
  position: absolute;
  top: 150px;
  left: 92px;
}

.stake-in-SOL .frame-460 {
  width: 1733px;
  height: 319px;
  position: relative;
}

.stake-in-SOL .frame-461 {
  width: 557px;
  height: 187px;
  position: absolute;
  top: 0;
  left: 0;
}

.stake-in-SOL .group-155 {
  width: 238px;
  height: 50px;
  position: absolute;
  top: 0;
  left: 0;
}

.stake-in-SOL .frame-462 {
  background-color: var(--text-150);
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 16px 14px;
  display: inline-flex;
  position: relative;
}

.stake-in-SOL .text-wrapper-253 {
  color: var(--primary-500main);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.stake-in-SOL .frame-463 {
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
  display: inline-flex;
  position: absolute;
  top: 64px;
  left: 0;
}

.stake-in-SOL .stake-to-mint-GOO-14 {
  color: #0000;
  font-family: var(--h3-bold-font-family);
  font-size: var(--h3-bold-font-size);
  font-style: var(--h3-bold-font-style);
  font-weight: var(--h3-bold-font-weight);
  letter-spacing: var(--h3-bold-letter-spacing);
  line-height: var(--h3-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.stake-in-SOL .text-wrapper-254 {
  color: #202020;
  font-family: var(--h3-bold-font-family);
  font-size: var(--h3-bold-font-size);
  font-style: var(--h3-bold-font-style);
  font-weight: var(--h3-bold-font-weight);
  letter-spacing: var(--h3-bold-letter-spacing);
  line-height: var(--h3-bold-line-height);
}

.stake-in-SOL .text-wrapper-255 {
  color: #ffba15;
  font-family: var(--h3-bold-font-family);
  font-size: var(--h3-bold-font-size);
  font-style: var(--h3-bold-font-style);
  font-weight: var(--h3-bold-font-weight);
  letter-spacing: var(--h3-bold-letter-spacing);
  line-height: var(--h3-bold-line-height);
}

.stake-in-SOL .text-wrapper-256 {
  color: var(--text-600);
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  font-family: ABeeZee, Helvetica;
  font-size: 28px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.stake-in-SOL .frame-464 {
  width: 1733px;
  height: 72px;
  position: absolute;
  top: 247px;
  left: 0;
}

.stake-in-SOL .frame-465 {
  width: 293px;
  height: 72px;
  position: absolute;
  top: 0;
  left: 0;
}

.stake-in-SOL .text-wrapper-257 {
  color: var(--text-600);
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 0;
}

.stake-in-SOL .frame-466 {
  align-items: center;
  gap: 4.66px;
  display: inline-flex;
  position: absolute;
  top: 37px;
  left: 0;
}

.stake-in-SOL .text-wrapper-258 {
  color: var(--text-900);
  font-family: var(--small-title-bold-font-family);
  font-size: var(--small-title-bold-font-size);
  font-style: var(--small-title-bold-font-style);
  font-weight: var(--small-title-bold-font-weight);
  letter-spacing: var(--small-title-bold-letter-spacing);
  line-height: var(--small-title-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1.17px;
  position: relative;
}

.stake-in-SOL .frame-467 {
  width: 293px;
  height: 72px;
  position: absolute;
  top: 0;
  left: 480px;
}

.stake-in-SOL .frame-468 {
  width: 293px;
  height: 72px;
  position: absolute;
  top: 0;
  left: 960px;
}

.stake-in-SOL .frame-469 {
  width: 293px;
  height: 72px;
  position: absolute;
  top: 0;
  left: 1440px;
}

.stake-in-SOL .user-profile-8 {
  background-color: #fafafa;
  border-radius: 20px;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 180px;
  padding: 50px 84px;
  display: inline-flex;
  position: absolute;
  top: 7px;
  left: 799px;
  overflow: hidden;
}

.stake-in-SOL .nuts-8 {
  width: 302px;
  height: 72px;
  position: relative;
}

.stake-in-SOL .icons-57 {
  border: 1.36px solid !important;
  border-radius: 34px !important;
  width: 68px !important;
  height: 68px !important;
  position: absolute !important;
  top: 2px !important;
  left: 0 !important;
}

.stake-in-SOL .icons-58 {
  width: 52px !important;
  height: 52px !important;
  top: 7px !important;
  left: 7px !important;
}

.stake-in-SOL .element-16 {
  width: 224px;
  height: 72px;
  position: absolute;
  top: 0;
  left: 78px;
}

.stake-in-SOL .sol-9 {
  width: 284px;
  height: 72px;
  position: relative;
}

.stake-in-SOL .icons-59 {
  width: 36px !important;
  height: 36px !important;
  top: 14px !important;
  left: 14px !important;
}

.stake-in-SOL .element-17 {
  width: 206px;
  height: 72px;
  position: absolute;
  top: 0;
  left: 78px;
}

.stake-in-SOL .frame-470 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 40px;
  display: inline-flex;
  position: relative;
}

.stake-in-SOL .design-component-instance-node-5 {
  position: relative !important;
}

.stake-in-SOL .tiers-13 {
  background-color: #fafafa;
  border-radius: 20px;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 70px;
  padding: 50px 44px;
  display: inline-flex;
  position: relative;
}

.stake-in-SOL .frame-471 {
  width: 1644px;
  height: 94px;
  position: relative;
}

.stake-in-SOL .frame-472 {
  width: 1644px;
  height: 60px;
  position: absolute;
  top: 0;
  left: 0;
}

.stake-in-SOL .frame-473 {
  align-items: center;
  gap: 10px;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 0;
}

.stake-in-SOL .frame-474 {
  flex: none;
  align-items: center;
  display: inline-flex;
  position: relative;
}

.stake-in-SOL .icons-60 {
  margin-left: -13px !important;
}

.stake-in-SOL .div-18 {
  color: #0000;
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  font-family: ABeeZee, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.stake-in-SOL .text-wrapper-259 {
  color: #202020;
  font-style: italic;
}

.stake-in-SOL .text-wrapper-260 {
  color: #ffba15;
  font-style: italic;
}

.stake-in-SOL .text-wrapper-261 {
  color: #000;
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  text-align: right;
  width: 46px;
  position: absolute;
  top: 18px;
  left: 1598px;
}

.stake-in-SOL .view-8 {
  position: absolute !important;
  top: 100px !important;
  left: 0 !important;
}

.stake-in-SOL .frame-475 {
  flex: none;
  justify-content: space-between;
  align-items: center;
  width: 1644px;
  display: flex;
  position: relative;
}

.stake-in-SOL .frame-476 {
  flex: none;
  align-items: flex-start;
  gap: 78px;
  display: inline-flex;
  position: relative;
}

.stake-in-SOL .frame-477 {
  align-items: center;
  gap: 10px;
  width: 232px;
  display: flex;
  position: relative;
}

.stake-in-SOL .frame-478 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 6px;
  display: inline-flex;
  position: relative;
}

.stake-in-SOL .text-wrapper-262 {
  color: var(--text-600);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.stake-in-SOL .frame-479 {
  flex: none;
  align-items: center;
  gap: 4.66px;
  display: inline-flex;
  position: relative;
}

.stake-in-SOL .text-wrapper-263 {
  color: var(--text-900);
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1.17px;
  position: relative;
}

.stake-in-SOL .icons-61 {
  width: 34px !important;
  height: 34px !important;
  position: absolute !important;
  top: 12px !important;
  left: 12px !important;
}

.stake-in-SOL .wallet-34 {
  flex: none !important;
  padding: 16px !important;
}

.stake-in-SOL .wallet-35 {
  margin-top: -1px !important;
}

.stake-in-SOL .frame-480 {
  justify-content: space-between;
  align-items: center;
  width: 1644px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.stake-in-SOL .frame-481 {
  flex: none;
  align-items: center;
  gap: 10px;
  display: inline-flex;
  position: relative;
}

.stake-in-SOL .text-wrapper-264 {
  color: #000;
  font-family: var(--content-title-bold-font-family);
  font-size: var(--content-title-bold-font-size);
  font-style: var(--content-title-bold-font-style);
  font-weight: var(--content-title-bold-font-weight);
  letter-spacing: var(--content-title-bold-letter-spacing);
  line-height: var(--content-title-bold-line-height);
  text-align: right;
  width: 46px;
  position: relative;
}

.stake-in-SOL .stake-NUTS-for-free-12 {
  color: var(--text-900);
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  font-family: ABeeZee, Helvetica;
  font-size: 24px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.stake-in-SOL .nav-20 {
  width: 1920px;
  height: 100px;
  position: absolute;
  top: 0;
  left: 0;
}

.stake-in-SOL .nav-21 {
  background-image: url("rectangle-2-12.01ad66c7.svg");
  background-size: 100% 100%;
  height: 100px;
}

.stake-in-SOL .group-156 {
  width: 1736px;
  height: 51px;
  position: relative;
  top: 24px;
  left: 92px;
}

.stake-in-SOL .logo-15 {
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 0;
}

.stake-in-SOL .group-157 {
  width: 50px;
  height: 50px;
  position: relative;
}

.stake-in-SOL .frame-482 {
  background-color: #fff;
  border-radius: 24.82px;
  height: 50px;
}

.stake-in-SOL .group-158 {
  background-image: url("vector.563a97e7.svg");
  background-size: 100% 100%;
  width: 29px;
  height: 29px;
  position: relative;
  top: 10px;
  left: 10px;
}

.stake-in-SOL .group-159 {
  background-color: #fff;
  border-radius: 3.63px;
  width: 7px;
  height: 7px;
  position: absolute;
  top: 10px;
  left: 9px;
}

.stake-in-SOL .ellipse-71 {
  background-color: #000;
  border-radius: 2.66px;
  width: 5px;
  height: 5px;
  position: relative;
  top: 1px;
  left: 1px;
}

.stake-in-SOL .group-160 {
  background-color: #fff;
  border-radius: 3.63px;
  width: 7px;
  height: 7px;
  position: absolute;
  top: 3px;
  left: 17px;
}

.stake-in-SOL .group-161 {
  width: 106.81px;
  height: 32.88px;
  position: relative;
}

.stake-in-SOL .group-162 {
  width: 18px;
  height: 27px;
  position: absolute;
  top: 6px;
  left: 0;
}

.stake-in-SOL .group-163 {
  border: 3.66px solid #000;
  border-radius: 8.82px;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 6px;
  left: 19px;
}

.stake-in-SOL .ellipse-72 {
  background-color: #000;
  border-radius: 3.46px;
  width: 7px;
  height: 7px;
  position: relative;
  top: 5px;
  left: 3px;
}

.stake-in-SOL .group-164 {
  border: 3.66px solid #000;
  border-radius: 8.82px;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 6px;
  left: 39px;
}

.stake-in-SOL .ellipse-73 {
  background-color: #000;
  border-radius: 3.46px;
  width: 7px;
  height: 7px;
  position: relative;
  top: 5px;
  left: 5px;
}

.stake-in-SOL .group-165 {
  width: 18px;
  height: 24px;
  position: absolute;
  top: 0;
  left: 58px;
}

.stake-in-SOL .overlap-group-17 {
  height: 24px;
  position: relative;
}

.stake-in-SOL .ellipse-74 {
  border: 3.66px solid #000;
  border-radius: 8.82px;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 6px;
  left: 0;
}

.stake-in-SOL .rectangle-38 {
  background-color: #000;
  border-radius: 0 0 1.82px 1.82px;
  width: 4px;
  height: 17px;
  position: absolute;
  top: 0;
  left: 0;
}

.stake-in-SOL .subtract-15 {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 6px;
  left: 77px;
}

.stake-in-SOL .frame-483 {
  align-items: flex-start;
  width: 11px;
  height: 18px;
  display: flex;
  position: absolute;
  top: 6px;
  left: 96px;
}

.stake-in-SOL .rectangle-39 {
  background-color: #000;
  width: 3.64px;
  height: 17.65px;
  position: relative;
  transform: rotate(180deg);
}

.stake-in-SOL .ellipse-75 {
  background-color: #000;
  border-radius: 3.46px;
  width: 6.91px;
  height: 6.91px;
  position: relative;
}

.stake-in-SOL .frame-484 {
  width: 429px;
  height: 18px;
  position: absolute;
  top: 16px;
  left: 657px;
}

.stake-in-SOL .text-wrapper-265 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 0;
}

.stake-in-SOL .text-wrapper-266 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 103px;
}

.stake-in-SOL .text-wrapper-267 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 209px;
}

.stake-in-SOL .text-wrapper-268 {
  color: var(--text-900);
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 341px;
}

.stake-in-SOL .frame-485 {
  align-items: center;
  gap: 20px;
  display: inline-flex;
  position: absolute;
  top: 1px;
  left: 1414px;
}

.stake-in-SOL .frame-486 {
  width: 114px;
  height: 40px;
  position: relative;
}

.stake-in-SOL .twitter-13 {
  background-color: #1c1c1c;
  border-radius: 500px;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.stake-in-SOL .x-9 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 8px !important;
  left: 8px !important;
}

.stake-in-SOL .discord-15 {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  left: 64px;
}

.stake-in-SOL .wallet-36 {
  flex: none !important;
}

.stake-in-SOL .iconify-1-instance {
  width: 24px !important;
  height: 24px !important;
  position: relative !important;
}

.stake-in-SOL .rectangle-40 {
  width: 1920px;
  height: 1080px;
  position: fixed;
  top: 0;
  left: 0;
}

.stake-in-SOL .wallet-connect-6 {
  background-color: #fff;
  border-radius: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  padding: 50px 0;
  display: inline-flex;
  position: fixed;
  top: 359px;
  left: 684px;
}

.stake-in-SOL .frame-487 {
  width: 551px;
  height: 62px;
  position: relative;
}

.stake-in-SOL .frame-488 {
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  padding: 0 40px;
  display: inline-flex;
  position: relative;
}

.stake-in-SOL .frame-489 {
  width: 471px;
  height: 32px;
  position: relative;
}

.stake-in-SOL .frame-490 {
  height: 32px;
  position: relative;
}

.stake-in-SOL .text-wrapper-269 {
  color: var(--text-900);
  font-family: var(--small-title-bold-font-family);
  font-size: var(--small-title-bold-font-size);
  font-style: var(--small-title-bold-font-style);
  font-weight: var(--small-title-bold-font-weight);
  letter-spacing: var(--small-title-bold-letter-spacing);
  line-height: var(--small-title-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 0;
}

.stake-in-SOL .iconify-89 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 4px !important;
  left: 447px !important;
}

.stake-in-SOL .please-note-you-can-3 {
  color: var(--text-900);
  letter-spacing: .32px;
  white-space: nowrap;
  width: fit-content;
  font-family: ABeeZee, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.stake-in-SOL .text-wrapper-270 {
  letter-spacing: .05px;
}

.stake-in-SOL .text-wrapper-271 {
  letter-spacing: .05px;
  font-style: italic;
}

.stake-in-SOL .frame-491 {
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  height: 94px;
  display: inline-flex;
  position: relative;
}

.stake-in-SOL .frame-492 {
  width: 552px;
  height: 144px;
  margin-bottom: -50px;
  position: relative;
}

.stake-in-SOL .frame-493 {
  flex-direction: column;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.stake-in-SOL .frame-494 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 10px;
  padding: 0 40px;
  display: inline-flex;
  position: relative;
}

.stake-in-SOL .frame-495 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 12px;
  display: inline-flex;
  position: relative;
}

.stake-in-SOL .frame-496 {
  width: 472px;
  height: 18px;
  position: relative;
}

.stake-in-SOL .frame-497 {
  width: 129px;
  height: 18px;
  position: absolute;
  top: 0;
  left: 0;
}

.stake-in-SOL .text-wrapper-272 {
  color: var(--text-600);
  letter-spacing: .32px;
  white-space: nowrap;
  font-family: ABeeZee, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 0;
}

.stake-in-SOL .text-wrapper-273 {
  color: #000;
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 80px;
}

.stake-in-SOL .frame-498 {
  width: 152px;
  height: 18px;
  position: absolute;
  top: 0;
  left: 320px;
}

.stake-in-SOL .text-wrapper-274 {
  color: var(--text-600);
  letter-spacing: .32px;
  white-space: nowrap;
  font-family: ABeeZee, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 32px;
}

.stake-in-SOL .text-wrapper-275 {
  color: #000;
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 110px;
}

.stake-in-SOL .frame-499 {
  border: 1px solid;
  border-color: var(--primary-500main);
  background-color: #fff;
  border-radius: 10px;
  width: 472px;
  height: 66px;
  position: relative;
}

.stake-in-SOL .icons-62 {
  border: .84px solid !important;
  border-radius: 21px !important;
  width: 42px !important;
  height: 42px !important;
  position: absolute !important;
  top: 12px !important;
  left: 20px !important;
}

.stake-in-SOL .icons-63 {
  width: 22px !important;
  height: 22px !important;
  top: 9px !important;
  left: 9px !important;
}

.stake-in-SOL .frame-500 {
  align-items: flex-start;
  gap: 187px;
  display: inline-flex;
  position: absolute;
  top: 24px;
  left: 72px;
}

.stake-in-SOL .text-wrapper-276 {
  color: var(--text-600);
  letter-spacing: .32px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: ABeeZee, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.stake-in-SOL .text-wrapper-277 {
  color: #000;
  font-family: var(--content-bold-font-family);
  font-size: var(--content-bold-font-size);
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.stake-in-SOL .frame-501 {
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  height: 66px;
  display: inline-flex;
  position: relative;
}

.stake-in-SOL .frame-502 {
  width: 552px;
  height: 115px;
  margin-bottom: -49px;
  position: relative;
}

.stake-in-SOL .frame-503 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 10px;
  display: inline-flex;
  position: relative;
}

.stake-in-SOL .frame-504 {
  background-color: var(--primary-500main);
  border: 1px solid;
  border-color: var(--primary-500main);
  border-radius: 10px;
  width: 472px;
  height: 66px;
  position: relative;
}

.stake-in-SOL .text-wrapper-278 {
  color: #fff;
  letter-spacing: .32px;
  white-space: nowrap;
  font-family: ABeeZee, Helvetica;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 23px;
  left: 190px;
}
/*# sourceMappingURL=index.49b31d93.css.map */
